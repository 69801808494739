<!-- breadcrumbs area start -->
		 <div class="title-breadcrumbs">
				 <div class="title-breadcrumbs-inner">
						 <div class="container-fluid">
								 <nav class="woocommerce-breadcrumb">
										<a [routerLink]="(['/'] | localize)" href="javascript:void(0)" class="ccblack">{{ 'home' | translate }}</a>
										<span class="separator"> / </span>
                    {{ 'create_account' | translate }}
								 </nav>
						 </div>
				 </div>
		 </div>
		 <!-- breadcrumbs area End -->

  <div class="login-register-area pt-30 pb-70">
	<div class="container-fluid c-container-50">
		<div class="row">

      <form  [formGroup]="registerForm" (ngSubmit)="onSubmit(captchaElem)">

				<div class="col-md-6 leftDivider float-left" style="border-right: 1px solid #ccc;">

					<p class="title_h3  c-font-16 helveticaltsd c-font-bold font-theme-color mb-20">{{ 'register_title' | translate }}</p>
					<p class="c-font-14 helveticaltsd c-font-bold font-theme-color mb-10" [innerHtml]="'register_text' | translate"></p>

						<div class="form-group">
							<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 p-0 float-left noPadMob mb1rm">
								<label class="control-label" for="firstname">{{ 'firstname' | translate }}<i class="font-theme-color pl-02 c-font-12  fa fa-asterisk"></i></label>
								<input type="text" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" formControlName="firstname"   maxlength="32" id="firstname" class="form-control c-square valRequired " />
                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                   <div *ngIf="f.firstname.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
							</div>
							<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pr-0 pl-20 float-left noPadMob">
								<label class="control-label" for="lastname">{{ 'lastname' | translate }}<i class="font-theme-color pl-02 c-font-12  fa fa-asterisk hidden"></i></label>
								<input type="text" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" formControlName="lastname" maxlength="32" id="lastname" class="form-control c-square valRequired valIgnore" />
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                   <div *ngIf="f.lastname.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
							</div>
						</div>


					<div class="b2breg" style="display:block">
						<div class="form-group">
							<label class="control-label" for="address">{{ 'address' | translate }}</label>
							<input type="text" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address" id="address" maxlength="32" class="form-control valRequired c-square" />
              <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                 <div *ngIf="f.address.errors.required">{{ 'msg_valRequired' | translate }}</div>
             </div>
						</div>
						<div class="form-group">
							<label class="control-label" for="address2">{{ 'addresscomplement' | translate }} </label>
							<input type="text" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" formControlName="address2"   maxlength="32" id="address2" class="form-control c-square"/>
              <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
                 <div *ngIf="f.address2.errors.required">{{ 'msg_valRequired' | translate }}</div>
             </div>
						</div>
						<div class="form-group">
							<div class="form-group col-md-5 pad0 mb-0">
								<label class="control-label" for="postalcode">{{ "postalcod" | translate }} </label>
								<input type="text" [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" formControlName="postalcode"   id="postalcode" (change)="getpostalcode()" class="form-control valRequired c-square" />
                <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                   <div *ngIf="f.postalcode.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
								</div>
							<div class="form-group col-md-7 pr-0 pl20 mb-0">
								<label class="control-label" for="locality">{{ "locality" | translate }} </label>
								<input type="text"  [ngClass]="{ 'is-invalid': submitted && f.locality.errors }" formControlName="locality"   id="locality" class="form-control valRequired c-square" />
                <div *ngIf="submitted && f.locality.errors" class="invalid-feedback">
                   <div *ngIf="f.locality.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
							</div>
						</div>

						<div class="form-group float-left full-width country-select clearfix">
								<label class="control-label full-width float-left">{{ 'country' | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
								<select *ngIf=" this.countries "   [ngClass]="{ 'is-invalid': submitted && f.idcountry.errors }" formControlName="idcountry"   id="idcountry" class="form-control c-square c-theme countryControl full-width float-left" >
									<option value="" >{{ 'selectcountry' | translate }}</option>

                    <ng-container *ngFor="let country of this.countries | keyvalue;  ">
											<option value="{{ country.key }}"  >{{ country.value.description[this.translate.currentLang] }}</option>
                    </ng-container >
								</select>
                <div *ngIf="submitted && f.idcountry.errors" class="invalid-feedback">
                   <div *ngIf="f.idcountry.errors.required">{{ 'msg_valRequired' | translate }}</div>
               </div>
						</div>
            <div class="form-group  ">
              <div class="row">
            <div class="col-md-6  col-sm-12 col-xs-12">

							<label class="control-label" for="nif"><span class="niftext">{{ 'nif' | translate }}</span><span class="niffrtext" style="display:none;">{{ 'nif_fr' | translate }}</span><i class="font-theme-color pl-02 c-font-12 fa fa-asterisk hidden"></i></label>
							<input type="text"   [ngClass]="{ 'is-invalid': submitted && f.nif.errors }" formControlName="nif" id="nif" class="form-control c-square nifControl " />
              <div *ngIf="submitted && f.nif.errors" class="invalid-feedback">
                 <div *ngIf="f.nif.errors.NifValidatorPT">{{ 'msg_valNif' | translate }}</div>
             </div>
            </div>
  <div class="col-md-6  col-sm-12 col-xs-12">
  							<label class="control-label" for="telephone">{{ 'telephone' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
                <div class="row display-flex-xs" style="width: 100%;">
                  <div class="col-md-4 pr-0 col-md-4 float-left inital_code_base_div">

					  <ng-container *ngIf="this.telephone_callingcode  ">
							<ng-select2 [options]="this.optionsCalling" [data]="this.telephone_callingcode" [value]="351" class="inital_code_base selectpicker" [ngClass]="{ 'is-invalid': submitted && f.telephone_callingcode.errors }" formControlName="telephone_callingcode"  id="initialcode_phone">
							</ng-select2 >
					 </ng-container>

              </div>
  <div class="col-md-8 pl-min-sm-0 float-left width-phone-xs">

              <input type="text" maxlength="20" [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }" formControlName="telephone" id="telephone" class="form-control c-square valRequired valNumber" />
              </div></div>

              <div *ngIf="submitted && f.telephone.errors" class="invalid-feedback">
                 <div *ngIf="f.telephone.errors.required">{{ 'msg_valRequired' | translate }}</div>
             </div>


  						</div>
              	</div>
              </div>
					</div>




				</div>
				<div class="col-md-6 rightDivider float-left">
					<!-- <span class="title_h3  c-font-16 helveticaltsd c-font-bold font-theme-color mb-20 hidden-xs">{{ 'loginright' | translate }}</span> -->
					<!--<div class="form-group">
						<label class="control-label" for="nome"><?php echo $lang["personchargresp"]; ?><i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
						<input type="text" name="userweb[obs]" maxlength="32" id="nome" class="form-control c-square valRequired" />
					</div>-->

					<div class="form-group margin-top-form">
						<label class="control-label" for="email">{{ 'email' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
						<input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email" id="email" class="form-control c-square valRequired valEmail" />
            <div *ngIf="this.submitted && f.email.errors" class="invalid-feedback">
               <div *ngIf="f.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
               <div *ngIf="f.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
           </div>
					</div>


					<div class="form-group ">
						<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 p-0 float-left noPadMob mb1rm">
							<label class="control-label" for="password">{{ 'password' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
							<input type="password" id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password" class="form-control c-square valRequired" />
              <div *ngIf="this.submitted && f.password.errors" class="invalid-feedback">
                 <div *ngIf="f.password.errors.required">{{ 'msg_valRequired' | translate }}</div>
             </div>
						</div>
						<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pr-0 pl-20 float-left noPadMob">
							<label class="control-label" for="passwordconf">{{ 'confirmpassword' | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
							<input type="password" id="passwordconf" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" formControlName="confirmPassword" class="form-control c-square valRequired valMatch" />
              <div *ngIf="this.submitted && f.confirmPassword.errors" class="invalid-feedback">
                 <div *ngIf="f.confirmPassword.errors.required">{{ 'msg_valRequired' | translate }}</div>
                 <div *ngIf="f.confirmPassword.errors.mustMatch">{{ 'msg_valMatchP' | translate }}</div>
             </div>
						</div>
					</div>
					<div class="form-group">
					<div class="form-group col-sm-12 col-xs-12 pad0">
						<re-captcha #captchaElem  (resolved)="resolved($event)" siteKey="6Lds048UAAAAAPY786uuoV17-F0s-sVRkcWFUTMS"></re-captcha>
					</div>
					</div>
					<div class="form-group">
						<div class="form-group col-sm-6 col-xs-12 pad0">
							<div class="c-checkbox">
								<input id="checkbox122" formControlName="newsletter" name="newsletter" class="c-check" type="checkbox">
								<label for="checkbox122" class="c-font-14 helveticaltsd">
									<span class="inc"></span>
									<span class="check"></span>
									<span class="box"></span> {{ 'newsletter_subscribe' | translate }}  </label>
							</div>
						</div>
					</div>

					<div class="form-group help-text mt-10">
						<span class="help-block col-sm-6 col-xs-12 c-font-14 helveticaltsd ccblack pad0"><i class="font-theme-color pr-1 c-font-14 fa fa-asterisk"></i>{{ 'requiredfields' | translate }}.</span>
						<div class="col-md-6 pull-right text-right pad0">
							<button class="button wpcf7-form-control register_button  " type="submit">{{ 'register' | translate }}</button>
						</div>
					</div>

				</div>
			</form>

		</div>
	</div>
</div>
