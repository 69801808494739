<div class="title-breadcrumbs">
		<div class="title-breadcrumbs-inner">
				<div class="container-fluid">
						<nav class="woocommerce-breadcrumb">
								<a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate }}</a>
								<span class="separator"> / </span>
								{{ 'allbrands' | translate }}
						</nav>
				</div>
		</div>
</div>


<div class="container-fluid c-container-50 ">
  <div class="c-layout-sidebar-content c-border-2x  pt-50 pb-70  ">
    <div class="row">
      <div class="col-12 c-font-14   img-page">

<div class="col-12   " *ngIf="this.bandsmenu" style="">
        <div class="  ">
		<div class="hidden-xs hidden-sm"><br/></div>
    <div class="row">
		<div class=" col-12  brandsMenuHolder  " style="background-color:#ddd">


					<div class=" col-6  col-md-2   col-xs-6 col-sm-6 mt-10 brandLogo2" *ngFor="let brands of this.bandsmenu;  ">
					<a [routerLink]=" (['/'+this.translate.currentLang+'/show' ,   (this.menu.url )+'-'+this.menu.attr1+this.menu.idmenu, (brands.url )+'-b'+brands.idbrand   ] )  " href="javascript:void(0)" ><img style="width:100%" [src]=" this.apiService.imagesDir+brands.logo "> </a>
					</div>

		</div>
    </div>
	</div>
</div>
<div   class="clearfix"></div>

        <div class="col-12   " *ngIf="this.allbrands">


          <ng-container *ngFor="let b of  (this.allbrands | keyvalue);let i=index;let last = last">
              <div class="col-4  mt-20 "  [id]="'target_'+(b.key | lowercase)"   >
                <fieldset >
                  <legend  >{{ b.key  }}</legend>

                    <ng-container *ngFor="let c of b.value; ">
                      <span *ngIf="!this.menu">{{ c.name }} </span>
                      <a *ngIf="this.menu" [routerLink]="(['/'+this.translate.currentLang+'/show' , (this.menu?.descriptiontranslate ? (this.menu.descriptiontranslate | translate | urlpipe | lowercase  ) : (this.menu.url )+'-'+this.menu.attr1+this.menu.idmenu) , (c.url )+'-b'+c.idbrand  ] )"  href="javascript:void(0)"  ><span>{{ c.name }}</span></a><br>


                    </ng-container>

                </fieldset>
              </div>


              <div *ngIf="(i+1)%3==0" class="clearfix"></div>

              <ng-container *ngIf="last   ">
                {{ loadLetter() }}
              </ng-container>

              </ng-container>



      </div>


      </div>
  </div>
</div>
</div>
