import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TranslateCacheModule, TranslateCacheSettings, TranslateCacheService } from 'ngx-translate-cache';

import { ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: translateCacheFactory,
        deps: [TranslateService, TranslateCacheSettings]
      },
      cacheMechanism: 'Cookie'
    })
  ],
  exports: [TranslateModule]
})
export class I18nModule {

  constructor(private localize: LocalizeRouterService,translate: TranslateService, translateCacheService: TranslateCacheService,   private route: ActivatedRoute) {

    translateCacheService.init();



        let myArray: Array<string> = []
        myArray.push("pt"); myArray.push("es");

        translate.addLangs(myArray);
        let varl = "";
        if(localize.parser.currentLang){
          varl = localize.parser.currentLang ;
        }else{
          varl =  translateCacheService.getCachedLanguage() || translate.getBrowserLang();
        }
        var pathArray = window.location.pathname.split('/');
        if(pathArray[1].match(/pt|es/)){
          varl = pathArray[1];
        }
        const browserLang = varl;

        if(browserLang!=translate.currentLang){
          translate.use(browserLang.match(/pt|es/) ? browserLang : 'pt');

        }
        if(!localize){
          localize.parser.currentLang=browserLang.match(/pt|es/) ? browserLang : 'pt';
        }






  }



}

export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, undefined, ".json?v=1");
}


export function translateCacheFactory(
  translateService: TranslateService,
  translateCacheSettings: TranslateCacheSettings
) {
  return new TranslateCacheService(translateService, translateCacheSettings);
}
