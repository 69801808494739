<div class="title-breadcrumbs">
	<div class="title-breadcrumbs-inner">
		<div class="container-fluid">
			<nav class="woocommerce-breadcrumb">
				<a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate }}</a>
				<span class="separator"> / </span>
				{{ 'myaccount' | translate }}
			</nav>
		</div>
	</div>
</div>

<div class="my-account white-bg pt-50 pb-30">
	<div class="container-fluid">
		<div class="account-dashboard">
			<div class="row">
				<div class="col-md-12 col-lg-2   d-lg-block">
					<span class="title_h3 c-title c-font-uppercase c-font-bold">{{ 'myaccount' | translate }}</span>
					<ul class="nav flex-column dashboard-list" role="tablist">
						<li>
							<a class="nav-link" [routerLink]="(['/account'] | localize)" href="javascript:void(0);">{{ 'accinfo' | translate }}</a>
						</li>
						<li>
							<a class="nav-link" [routerLink]="(['/profile'] | localize)" href="javascript:void(0);">{{ 'editinfo' | translate }}</a>
						</li>
						<li>
							<a class="nav-link active" [routerLink]="(['/orders'] | localize)" href="javascript:void(0);">{{ 'orders' | translate }}</a>
						</li>
						<li  >
							<a class="nav-link" [routerLink]="(['/wishlist'] | localize)" href="javascript:void(0);">{{ 'wishlist' | translate }}</a>
						</li>
						<li>
							<a class="nav-link" (click)="this.logoutservice.sendClickEvent()" href="javascript:void(0);">{{ 'logout' | translate }}</a>
						</li>
					</ul>
				</div>
				<div class="col-md-12 col-lg-10">

					<!-- <div class="row">
						<div class="col-lg-3 col-md-12" *ngFor="let order of ordersGrid">
							<div class="order-container">
								<div class="image-container">
									<img [src]="getImage(order.image)" >
								</div>
								<div class="order-info">
									<div class="order-info-title">
										<div>
											<h6>{{order.numberdoc}}</h6>
											<p>{{ order.date }}</p>
										</div>
										<div>
											<a class="btn wpcf7-form-control search_button filter-sm-button title_filter_items" [href]="this.apiService.PHP_API_SERVER+'?controller=order&action=get&doc='+btoa(order.iddocument)" target="_blank">
												<i class="fas fa-file-pdf"></i>&nbsp;
												<span>{{ 'doctitle' | translate }}</span>
											  </a>
										</div>
									</div>
									<div class="order-info-description">
										<div class="payment-view" style="display: flex;">
											<ng-container *ngIf="order.paymentmethod=='MB'">
												<ng-container *ngIf="!order.datepayment && !order.cancellationreason">
													<button (click)="showmb(order)" class="btn c-btn-red btn-sm c-btn-sbold  pull-right btn-danger" style="border-radius:0;">{{ 'mbbutton' | translate }}</button><br>
													<div style="margin-right: 10px;"></div>
														<button (click)="changePaymentMean(order)"  class="btn c-btn-red btn-sm c-btn-sbold  pull-right btn-danger" style="border-radius:0;">{{ 'choose_method_resume' | translate }}</button>
												</ng-container>
												<ng-container *ngIf="order.datepayment || order.cancellationreason">
													{{ order.paymentmethod | translate }}
												</ng-container>
											</ng-container>
											<ng-container *ngIf="order.paymentmethod!='MB'">
												<ng-container *ngIf="order.paymentmethod!='COB'">
													<ng-container *ngIf="!order.datepayment && !order.cancellationreason ">
														<button (click)="changePaymentMean(order)" class="btn c-btn-red btn-sm c-btn-sbold  pull-right btn-danger" style="border-radius:0;">{{ 'choose_method_resume' | translate }}</button>
													</ng-container>
													<ng-container *ngIf="order.datepayment || order.cancellationreason">
														{{ order.paymentmethod | translate }}
													</ng-container>
												</ng-container>
												<ng-container *ngIf="order.paymentmethod=='COB'">
													{{ order.paymentmethod | translate }}
												</ng-container>
											</ng-container>
										</div>
									</div>
									<div class="order-info-bottom">
										<p><b>{{ 'state' | translate }}: </b> {{order.statelang[this.translate.currentLang] ? order.statelang[this.translate.currentLang] : order.state}}</p>
									</div>
								</div>
							</div>
						</div>
					</div> -->


					<div class="mat-app-background basic-container ">
						<div class="example-container ">
							<div class="example-loading-shade"
							*ngIf="isLoadingResults || isRateLimitReached">
							<mat-spinner *ngIf="isLoadingResults"></mat-spinner>
							<div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
								{{ 'ERROR_CONNECT_API' | translate }}
							</div>
						</div>


						  <input matInput     placeholder="{{ 'filter' | translate }}" #inputSearch>


						<div class="example-table-container mat-elevation-z8">

							<table mat-table [dataSource]="dataTable" class="example-table"
							matSort matSortActive="datedocument" matSortDisableClear matSortDirection="desc">

							<ng-container matColumnDef="numberdoc">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ordernr' | translate }}</th>
								<td mat-cell *matCellDef="let row">{{row.numberdoc}}</td>
							</ng-container>


							<ng-container matColumnDef="date">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'date' | translate }}</th>
								<td mat-cell *matCellDef="let row">{{row.date }}</td>
							</ng-container>


							<ng-container matColumnDef="total">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'total' | translate }}</th>
								<td mat-cell *matCellDef="let row">{{row.total}} {{row.currencysymbol}}</td>
							</ng-container>


							<ng-container matColumnDef="paymentmethod">
								<th mat-header-cell *matHeaderCellDef mat-sort-header >
									{{ 'payment' | translate }}
								</th>
								<td mat-cell *matCellDef="let row">
									<div class="payment-view" style="display: flex;">
										<ng-container *ngIf="row.paymentmethod=='MB'">
											<ng-container *ngIf="!row.datepayment && !row.cancellationreason && row.state != 'Recusado'">
												<button (click)="showmb(row)" class="btn c-btn-red btn-sm c-btn-sbold  pull-right btn-danger" style="border-radius:0;">{{ 'mbbutton' | translate }}</button><br>
												<div style="margin-right: 10px;"></div>
													<button (click)="changePaymentMean(row)"  class="btn c-btn-red btn-sm c-btn-sbold  pull-right btn-danger" style="border-radius:0;">{{ 'choose_method_resume' | translate }}</button>
											</ng-container>
											<ng-container *ngIf="row.datepayment || row.cancellationreason || row.state == 'Recusado'">
												{{ row.paymentmethod | translate }}
											</ng-container>
										</ng-container>
										<ng-container *ngIf="row.paymentmethod!='MB'">
											<ng-container *ngIf="row.paymentmethod!='COB'">
												<ng-container *ngIf="!row.datepayment && !row.cancellationreason && row.state != 'Recusado'">
													<button (click)="changePaymentMean(row)" class="btn c-btn-red btn-sm c-btn-sbold  pull-right btn-danger" style="border-radius:0;">{{ 'choose_method_resume' | translate }}</button>
												</ng-container>
												<ng-container *ngIf="row.datepayment || row.cancellationreason || row.state == 'Recusado'">
													{{ row.paymentmethod | translate }}
												</ng-container>
											</ng-container>
											<ng-container *ngIf="row.paymentmethod=='COB'">
												{{ row.paymentmethod | translate }}
											</ng-container>
										</ng-container>
									</div>
								</td>
							</ng-container>

							<ng-container matColumnDef="shipmode">
								<th mat-header-cell *matHeaderCellDef mat-sort-header  >
									{{ 'shipmode' | translate }}
								</th>
								<td mat-cell *matCellDef="let row">{{row.shipmode }}</td>
							</ng-container>

							<ng-container matColumnDef="state">
								<th mat-header-cell *matHeaderCellDef mat-sort-header  >
									{{ 'state' | translate }}
								</th>
								<td mat-cell *matCellDef="let row">{{ row.statelang[this.translate.currentLang] ? row.statelang[this.translate.currentLang] : row.state }}</td>
							</ng-container>

							<ng-container matColumnDef="icon">
								<th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>

								</th>
								<td mat-cell *matCellDef="let row">


										<a class="btn wpcf7-form-control search_button filter-sm-button title_filter_items" [href]="this.apiService.PHP_API_SERVER+'?controller=order&action=get&doc='+btoa(row.iddocument)" target="_blank">
										  <i class="fas fa-file-pdf"></i>&nbsp;
										  <span>{{ 'doctitle' | translate }}</span>
										</a>


								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
						</table>
					</div>

					<mat-paginator [hidePageSize]="true" [length]="resultsLength" [pageSize]="10"></mat-paginator>
				</div>
			</div>


		</div>
	</div>
</div>
</div>
</div>


<modal #ModalChangePayment    >

  <modal-content>


    <div class=" row">
      <div class="  col-md-12 col-xs-12 col-sm-12">
        <ng-container   *ngIf="this.payments">
          <ng-container   *ngFor="let payment of this.payments;">
            <div   class="c-radio">
              <input value="{{ payment.idpaymentwebsite }}" (change)="this.selectPayment=payment.idpaymentwebsite;selectPaymentAbbr=payment.abbr"  abrv="{{ payment.abbr }}" type="radio" name="payment"   class="c-radio" id="radioP{{ payment.idpaymentwebsite }}">
              <label class="c-font-bold c-font-14" for="radioP{{ payment.idpaymentwebsite }}">
                <span class="inc"></span><span class="check"></span>
                <span class="box"></span> {{ payment.abbr | translate  }}
              </label>
            </div>

						<ng-container *ngIf="selectPaymentAbbr=='MBW' && this.selectPayment==payment.idpaymentwebsite ">
							<label class="control-label" for="telephone">{{ 'telephone' | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
							<input type="text" [required]="this.selectPayment==payment.idpaymentwebsite ? 'true' : 'false'"   [(ngModel)]="contat_MBW" name="contat_MBW"  maxlength="20" id="contat_MBW"   class="form-control c-square valRequired valNumber" />
						</ng-container>

          </ng-container>
        </ng-container>



      </div>
    </div>


  </modal-content>

  <modal-footer>
    <ng-container   *ngIf="this.selectPayment">
      <button type="button" style="border-radius: 0px;background-color:#ccc;"   (click)="changepayment()"   class="pull-right btn btn-outline btn-default btn-cart button-aylen cartBtn full-width float-left ">{{ 'changepaymentmean' | translate }}</button>
    </ng-container>
    </modal-footer>
</modal>


<div id="loading" [hidden]="this.hiddenLoad" style=" opacity: 0.9;">
	<div class="mybox" id="loading-image">
		<div class="loada" id="loadingGeral"  ></div>
		<p class="noMargin">&nbsp;</p>
		<h3 class="title_h3 " id="txtLoad"></h3>
	</div>
</div>
