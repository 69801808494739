import { FormGroup } from '@angular/forms';

export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function NifValidatorPT(controlName:any, matchingControlName:any) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.NifValidatorPT) {
          // return if another validator has already found an error on the matchingControl
          return;
      }
      if(matchingControl.value==""){
        matchingControl.setErrors(null); return;
      }

      const nif = matchingControl.value;
      if(nif==null){
        matchingControl.setErrors(null); return;
      }
      if(  control.value=="179" || control.value=="179-1" || control.value=="179-2"  || control.value=="179-3"){
        if (!['1', '2', '3', '5', '6', '8'].includes(nif.substr(0, 1)) && !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.substr(0, 2))) { matchingControl.setErrors({ NifValidatorPT: true }); } const total = Number(nif[0]) * 9 + Number(nif[1]) * 8 + Number(nif[2]) * 7 + Number(nif[3]) * 6 + Number(nif[4]) * 5 + Number(nif[5]) * 4 + Number(nif[6]) * 3 + Number(nif[7]) * 2; const modulo11 = total - Math.trunc(total / 11) * 11; const comparador = modulo11 === 1 || modulo11 === 0 ? 0 : 11 - modulo11;
        if(Number(nif[8]) === comparador){
          matchingControl.setErrors(null);
        }else{
          matchingControl.setErrors({ NifValidatorPT: true });
        }
      }else{
        matchingControl.setErrors(null);
      }

  }


}
