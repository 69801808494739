import { Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import { ModalManager } from 'ngb-modal';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute   } from '@angular/router';
import { LocalstorageService } from '../localstorage.service';
import { SessionService } from '../session.service';
import { LogoutService } from '../logout.service';
import { SharedService } from '../shared.service';
import { Title, Meta }     from '@angular/platform-browser';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
import { WishlistService } from '../wishlist.service';
import { TitlechangeService } from '../titlechange.service';
//table
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { HttpClient, HttpParams  } from '@angular/common/http';
import {merge, Observable, Subscription, Subject, fromEvent, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap, debounceTime,  distinctUntilChanged, filter} from 'rxjs/operators';
import { LowerCasePipe } from '@angular/common';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { Angulartics2 } from 'angulartics2';
import { UrlpipePipe } from '../urlpipe.pipe';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CheckwebpService } from '../checkwebp.service';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {
  subscribe:any;
  breadcrumbs: any[] = [];

  private _subscription: Subscription[];
  //table
  displayedColumns: string[] = ['image', 'item.description', 'item.code', 'color.name', 'icon'];
  dataTable: tableStruture[] = [];
  httpDatabase: HttpDatabase | null;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  @ViewChild("inputSearch") inputSearch ;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() keyPressOnChild = new EventEmitter();
  wait:any;

  itemsList:any[] = []
  emptyItem: boolean = true;
  deviceDesktop:any;
  browserwebp:any;
  defaultImage:any;

  constructor(public titlechangeService:TitlechangeService,private httpClient: HttpClient, public logoutservice:LogoutService,public wishlistService:WishlistService,private router: ActivatedRoute,private seoService: SeoService, public localize: LocalizeRouterService,private metaService: Meta, public titleService: Title, public sharedService: SharedService,  public sessionService: SessionService, public localstorageService: LocalstorageService,private route: Router, public apiService: ApiService, private modalService: ModalManager, public translate: TranslateService, private formBuilder: FormBuilder,private lowercase:LowerCasePipe,private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce,private angulartics2: Angulartics2,private urlpipePipe:UrlpipePipe,public deviceService: DeviceDetectorService,public checkwebp: CheckwebpService) {
    this._subscription = [];
    this.wait=false;
    let objBread:any = {};
    objBread.url = "wishlist";
    objBread.description = "wishlist";
    this.breadcrumbs.push(objBread);

    this.deviceDesktop=false;
    const isDesktopDevice = this.deviceService.isDesktop();
    if(isDesktopDevice){
      this.deviceDesktop=true;
    }
    this.browserwebp = this.checkwebp.canUseWebP();
    if(this.browserwebp){
      this.defaultImage = "assets/images/img_default.webp";
    }else{
      this.defaultImage = "assets/images/img_default.png";
    }
  }

  ngOnInit(): void {
    this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
        this.metatags(lang.lang);
      });
      this.metatags(this.translate.currentLang);
      this.getWishList()
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
    for (var i = 0; i < this._subscription.length; i++) {
       if(this._subscription[i]){
         this._subscription[i].unsubscribe();
       }
    }
  }

    public setTitle( newTitle: string) {
  	   this.titleService.setTitle( newTitle  );
       this.titlechangeService.send();
    }


    removeLine(iditem, idcolor){
      this.apiService.removewishlist(iditem, idcolor).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          this.keyPressOnChild.emit();
          this.wishlistService.send();
          this.getWishList();
        }else{
          this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }

  metatags(lang){
    this.apiService.getMetatags(40,9,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }

        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getWishList(){
    this.apiService.getWishListtable().subscribe((obj: any) => {
      if(typeof obj!=="undefined" && obj.items){

        if(obj.updated){
          this.wishlistService.send();
        }
        obj.items.forEach(element => {
          if(element.details[0].withPromotion == 1){ element.withPromotion = true}
          else{ element.withPromotion = false; }
          element.pricepromotionTxt=  this.showPrice(element.details[0], 2);
          element.pricepromotion = (Number(element.pricepromotionTxt.replaceAll(',', '.')))
          element.pricedefaultTxt = this.showPrice(element.details[0], 1);
          element.pricedefault = (Number(element.pricedefaultTxt.replaceAll(',', '.')))
          element.url = this.urlmounthref(element.details[0], element.details[0].description,element.iditem)
        })
        this.itemsList = obj.items; 
        this.resultsLength = obj.total_count;

        this.emptyItem = false;
      }
      else{
        this.emptyItem = true;
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    })
  }

  urlmount(product:any, desc:any, iditem:any, idcolor?:any, idsize?:any){


    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";

    if(this.translate.currentLang && product.catlangs && product.catlangs[this.translate.currentLang] && product.catlangs[this.translate.currentLang].description) {
      cat = product.catlangs[this.translate.currentLang].description;
    }
    brand = (product.brandName) ? product.brandName : '';

    if(product.itemwithattr==1){
      let id_color = idcolor;
      if(!idcolor){
        id_color = this.color(product);
      }
      let id_size = idsize;
      if(!idsize){
        id_size = this.size(product, id_color);
      }
      color = product.colors[id_color][id_size].name;
      if(product.colors[id_color][id_size].priceFinal) { pval = product.colors[id_color][id_size].priceFinal; }
    }else{
      pval = product.priceFinal;
    }

    this.angulartics2GAEcommerce.ecAddProduct({
          'id': product.iditem,
          'name': desc,
          'category': cat,
          'brand': brand,
          'variant': color,
          'position': 0
        });
        this.angulartics2GAEcommerce.ecSetAction("click", {});



  this.angulartics2.eventTrack.next({
    action: 'productClick',
    properties: {
      label: 'productClick',
      currency: "EUR",
      content_type: 'product_group',
      "event":"productClick",
      gtmCustom:{
        'ecommerce': {
          'remove': undefined,
          'add': undefined,
          'purchase': undefined,
          'checkout': undefined,
          'detail': undefined,
          'impressions': undefined,
          'click': {
            'actionField': {'list': 'List Items Click'},    // 'detail'
            'products': [{
              'id': product.iditem,
              'name': desc,
              'price': pval,
              'brand': brand,
              'category': cat,
              'variant': color
             }]
           }
         }
      }
    }
  });

  // this.angulartics2.eventTrack.next({
  //   action: 'view_item',
  //   properties: {
  //     'view_item': {
  //       'currency': 'EUR', 'value': pval,    // 'detail'
  //       'items': [{
  //         'item_id': product.iditem,
  //         'item_name': desc,
  //         'price': pval,
  //         'item_brand': brand,
  //         'item_category': cat,
  //         'item_variant': color
  //        }]
  //      }
  //   }
  // })

setTimeout(()=>{
    if(idsize){
      this.route.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor, idsize]);return false;
    }else
    if(idcolor){
      this.route.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor]);return false;
    }else{
      this.route.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem)]);return false;
    }
    }, 100);
  }

  urlmounthref(product:any, desc:any, iditem:any, idcolor?:any, idsize?:any){


    let cat:any = "";
    let brand:any = ""; 
    let color:any = "";
    let pval:any = "";

    if(this.translate.currentLang && product.catlangs && product.catlangs[this.translate.currentLang] && product.catlangs[this.translate.currentLang].description) {
      cat = product.catlangs[this.translate.currentLang].description;
    }
    brand = (product.brandName) ? product.brandName : '';

    if(product.itemwithattr==1){
      let id_color = idcolor;
      if(!idcolor){
        id_color = this.color(product);
      }
      let id_size = idsize;
      if(!idsize){
        id_size = this.size(product, id_color);
      } 
      color = product.colors[id_color][id_size].name;
      if(product.colors[id_color][id_size].priceFinal) { pval = product.colors[id_color][id_size].priceFinal; }
    }else{
      pval = product.priceFinal;
    }
    if(idsize){
      return this.apiService.PHP_API_URL_DEFAULT + '/' + this.translate.currentLang + '/product/' +this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem)+ '/' +idcolor + '/' + idsize;
      // this.route.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor, idsize]);return false;
    }else
    if(idcolor){
      return this.apiService.PHP_API_URL_DEFAULT + '/' + this.translate.currentLang + '/product/' + this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem) + '/' + idcolor;
      // this.route.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor]);return false;
    }else{
      return this.apiService.PHP_API_URL_DEFAULT + '/' + this.translate.currentLang + '/product/' + this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem) + '/' + iditem;
      // this.route.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem)]);return false;
    }
  }
  color(i:any){
    if(i.itemwithattr==1){
      if(i.colorpriority && i.colors[i.colorpriority] ){
        return i.colorpriority;
      }else{
        return Object.keys(i.colors)[0];
      }
    }else{
      return "";
    }
  }

  size(i:any, op:any){
    if(i.itemwithattr==1){
      if(op>0){
        return Object.keys(i.colors[op])[0];
      }
      if(i.colorpriority && i.colors[i.colorpriority] ){
        return Object.keys(i.colors[i.colorpriority])[0];
      }else{
        let col = Object.keys(i.colors)[0];
        return Object.keys(i.colors[col])[0];
      }
    }else{
      return "";
    }
  }

  showPrice(item:any, op:Number){
    if(op==1){
      if(item.itemwithattr==1){
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = Object.keys(item.colors[item.colorpriority])[0];
          return item.colors[item.colorpriority][key].priceOriginalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceOriginalTxt;
        }
      }else{
        return item.priceOriginalTxt;
      }
    }else{
      if(item.itemwithattr==1){
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = Object.keys(item.colors[item.colorpriority])[0];
          return item.colors[item.colorpriority][key].priceFinalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceFinalTxt;
        }
      }else{
        return item.priceFinalTxt;
      }
    }

  }

  showDiscount(item:any){
    return Math.round(100-(100* Number(item.pricepromotion)/Number(item.pricedefault)));
  }

  ngAfterViewInit() {

    // fromEvent(this.inputSearch.nativeElement, 'keyup').pipe(
    //   map((event: any) => {
    //     return event.target.value;
    //   })
    //   , debounceTime(1000)
    //   , distinctUntilChanged()
    // ).subscribe((text: string) => {

    //   this.keyPressOnChild.emit();

    // });


    this.httpDatabase = new HttpDatabase(this.httpClient, this.apiService);


    //   const sub50 = this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // this._subscription.push(sub50);

    // merge(this.sort.sortChange, this.paginator.page, this.keyPressOnChild)
    //   .pipe(
    //     startWith({}),
    //     switchMap(() => {
    //       this.isLoadingResults = true;
    //       return this.httpDatabase!.getOrders(
    //         this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize, this.inputSearch.nativeElement.value);
    //     }),
    //     map(data => {
    //       // Flip flag to show that loading has finished.
    //       this.isLoadingResults = false;
    //       this.isRateLimitReached = false;
    //       this.resultsLength = data['total_count'];

    //       if(data['code']==302){
    //           this.sessionService.sendsession(true, ""); this.route.navigate([this.localize.translateRoute('/')] );
    //       }

    //       return data['items'];
    //     }),
    //     catchError(() => {
    //       this.isLoadingResults = false;
    //       // Catch if the GitHub API has reached its rate limit. Return empty data.
    //       this.isRateLimitReached = true;
    //       return observableOf([]);
    //     })
    //   ).subscribe(data => this.dataTable = data);
  }
}




export interface tableApi {
  items: tableStruture[];
  total_count: number;
}

export interface tableStruture {
  image: string;
  description: string;
  code: string;
  color: string;
  icon: string;
}

export class HttpDatabase {
  constructor(private httpClient: HttpClient, private apiService: ApiService ) {}

  getOrders(sort: string, order: string, page: number, pageSize:number, search:any): Observable<tableApi> {
    search = encodeURIComponent(search);
    const href = this.apiService.PHP_API_SERVER;
    const requestUrl =
        `${href}?controller=userweb&action=getwishlisttable&sort=${sort}&order=${order}&page=${page + 1}&pagesize=${pageSize}&search=${search}`;

    return this.httpClient.get<tableApi>(requestUrl,  {withCredentials: true});
  }
}
