<div class="title-breadcrumbs">
	<div class="title-breadcrumbs-inner">
		<div class="container-fluid">
			<nav class="woocommerce-breadcrumb">
				<a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate }}</a>
				<span class="separator"> / </span>
				{{ 'recover_password' | translate }}
			</nav>
		</div>
	</div>
</div>

<div class="my-account white-bg pt-50 pb-30">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-6 colCenter">
				<span class="title_h3  c-font-16 helveticaltsd c-font-bold font-theme-color mb-20">{{ 'recover_password' | translate }}</span>
				 <form  [formGroup]="resetForm" (ngSubmit)="onSubmit()">
					<input type="hidden" name="token" value="" />

					<div class="form-group ">
						<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 p-0 float-left noPadMob mb1rm">
							<label class="control-label" for="password">{{ 'password' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
							<input type="password" id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password" class="form-control c-square valRequired" />
							  <div *ngIf="this.submitted && f.password.errors" class="invalid-feedback">
								 <div *ngIf="f.password.errors.required">{{ 'msg_valRequired' | translate }}</div>
							 </div>
						</div>
						<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pr-0 pl-20 float-left noPadMob">
							<label class="control-label" for="passwordconf">{{ 'confirmpassword' | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
							<input type="password" id="passwordconf" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" formControlName="confirmPassword" class="form-control c-square valRequired valMatch" />
							  <div *ngIf="this.submitted && f.confirmPassword.errors" class="invalid-feedback">
								 <div *ngIf="f.confirmPassword.errors.required">{{ 'msg_valRequired' | translate }}</div>
								 <div *ngIf="f.confirmPassword.errors.mustMatch">{{ 'msg_valMatchP' | translate }}</div>
							 </div>
						</div>
					</div>


					<div class="form-group help-text c-margin-t-40">
						<div class="row">
							<div class="col-md-8"><span class="help-block"><i class="c-theme-font fa fa-asterisk"></i> {{ 'requiredfields' | translate }}.</span></div>
							<div class="col-md-4 text-right">
								<button class="btn c-theme-btn c-btn-square c-btn-uppercase c-btn-bold" type="submit" style="background-color:#f0f0f0;border-radius:0;">{{ 'save' | translate }}</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
