import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams,HttpErrorResponse  } from '@angular/common/http';
import { Observable,throwError  } from  'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // PHP_API_SERVER = "http://localhost/daniel/armazensronfe/armazensronfe-angular/backend/index.php";
  //readonly imagesDir: string = "http://192.168.1.66/betagesbox/bo/views/assets/img/";
  GET_TAGS_META=["robots", "description", "author", "keywords", "og:title", "og:description", "og:image"];
  PHP_API_SERVER = "https://armazensronfe.pt/backend/index.php";
  PHP_API_URL_DEFAULT = "https://armazensronfe.pt";
  readonly imagesDir: string = "https://gesboxsoftware.com/bo/views/assets/img/";
  constructor(private httpClient: HttpClient, private translate: TranslateService, private zone: NgZone) {}

  readCompany(drop: number, country:any): Observable<Object>{

    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getinfo&drop=${drop}&country=${country}`,  {withCredentials: true});
  }

  getallbrands(lang: String): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getallbrands&lang=${lang}`);
  }

  getPresentation(lang: String): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getpresentation&lang=${lang}`);
  }

  getbreadcrumbs(bread: any, initialurl:any): Observable<Object> {
    var queryString = Object.keys(bread).map((key) => { return 'params['+encodeURIComponent(key)+']' + '=' + encodeURIComponent(bread[key]) }).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getbreadcrumbs&initialurl=`+initialurl+`&`+queryString);
  }

  getlanguages(drop: number): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getlanguages&drop=${drop}`);
  }

  getMenu(lang: String): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getmenu&lang=${lang}`);
  }

  getrelateditem(iditem: any): Observable<Object> {
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=getrelated&iditem=${iditem}`);
  }

  getGap(lang: String): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getgap&lang=${lang}`);
  }

  getBlog(lang: String, limit: String): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getblog&lang=${lang}&limit=${limit}`);
  }

  addwishlist(iditem: any, idcolor: any): Observable<Object>{
    if(idcolor==null || idcolor==undefined){
      idcolor = "";
    }
    var formData = new FormData();
    formData.append("iditem", iditem);
    formData.append("idcolor", idcolor);
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=addwishlist`, formData,  {withCredentials: true});
  }


  getwishlist(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=getwishlist`,  {withCredentials: true});
  }

  removewishlist(iditem: any, idcolor: any): Observable<Object>{
    if(idcolor==null || idcolor==undefined){
      idcolor = "";
    }
    var formData = new FormData();
    formData.append("iditem", iditem);
    formData.append("idcolor", idcolor);
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=removewishlist`, formData,  {withCredentials: true});
  }

  getBanners(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getbanners`);
  }

  getItemsHomePage(lang: String): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=getitemshomepage`);
  }

  getBrandsFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getbrands&`+queryString);
  }

  getSubCatFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getsubcat&`+queryString);
  }

  getSub2CatFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getsub2cat&`+queryString);
  }

  getCatFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getcat&`+queryString);
  }
  getCatGrid(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getcatgrid&`+queryString);
  }

  getColorsFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getcolors&`+queryString);
  }

  getSizesFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getsizes&`+queryString);
  }

  getGenresFilter(filter: any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getgenres&`+queryString);
  }

  getItemsShow(filter: any, orderItem: any, page:any): Observable<Object>{
    var queryString = Object.keys(filter).map((key) => {  return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])}).join('&');
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=getitems&`+queryString+"&order="+orderItem+"&page="+page);
  }

  addCart(arr: any): Observable<Object>{
    var formData = new FormData();
    arr.forEach(function(element, index){
      Object.entries(element).forEach(([key, value]) => {
        if(value==null){
          value="";
        }
         formData.append("cart["+index+"]["+key+"]", value.toString());
      });
    });
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=cart&action=add`, formData, {withCredentials: true});
  }

  getCart(ischeckout:any): Observable<Object>{

    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=get&checkout=`+ischeckout,  {withCredentials: true});
  }

  getCartbyattr(country:any, shippingcompany:any, shippingmethod:any, payment_sel:any): Observable<Object>{

    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=get&checkout=1&country=`+country+`&shippingcompany=`+shippingcompany+`&shippingmethod=`+shippingmethod+ `&payment_sel=`+payment_sel,  {withCredentials: true});
  }

  removeCart(arr:any): Observable<Object>{
    var formData = new FormData();
    arr.forEach(function(element, index){
      Object.entries(element).forEach(([key, value]) => {
        if(value==null){
          value="";
        }
         formData.append("cart["+index+"]["+key+"]", value.toString());
      });
    });
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=cart&action=remove`, formData, {withCredentials: true});
  }


  getItem(item:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=item&action=get&id=`+item,  {withCredentials: true});
  }

  getContacts(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=contactus`);
  }

  orderContact(contact:any, lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=company&action=savecontactus&lang=`+lang, contact);
  }

  getPage(page:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getpage&id=`+page);
  }

  newsletter(email:any, lang:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=newsletter&email=`+email+`&lang=`+lang);
  }

  getCountry(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getcountry`);
  }

  getCallingcode(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getcallingcode`);
  }

  getpostalcode(postalcode:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getpostalcode&postalcode=${postalcode}`);
  }


  createuserweb(form:any, lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=createuserweb&lang=`+lang, form);
  }

  getShippings(countryclient:any, country:any, address:any, postalcode:any, locality:any): Observable<Object>{
    countryclient = encodeURIComponent(countryclient); country = encodeURIComponent(country);
    address = encodeURIComponent(address); postalcode = encodeURIComponent(postalcode);
    locality = encodeURIComponent(locality);  return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=getshippings&countryclient=`+countryclient+`&country=`+country+`&address=`+address+`&postalcode=`+postalcode+`&locality=`+locality ,  {withCredentials: true});
  }

  getShippingsAbbr(countryclient:any, country:any, address:any, postalcode:any, locality:any, idshippingmode:any): Observable<Object>{
    countryclient = encodeURIComponent(countryclient); country = encodeURIComponent(country);
    address = encodeURIComponent(address); postalcode = encodeURIComponent(postalcode);
    locality = encodeURIComponent(locality); idshippingmode = encodeURIComponent(idshippingmode);  return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=getshippingsabbr&idshippingmode=`+idshippingmode+`&abbreviation=1&countryclient=`+countryclient+`&country=`+country+`&address=`+address+`&postalcode=`+postalcode+`&locality=`+locality ,  {withCredentials: true});
  }

  getPickups(country:any, shippingcomp:any, postalcode:any): Observable<Object>{
    return this.httpClient.get<Object>(`https://gesboxsoftware.com/apges/api.php?controller=pickup&action=get&country=`+country+`&shippingcompany=`+shippingcomp+`&postalcode=`+postalcode);
  }


  addVoucher(voucher:any, email:any,shippingmethod?:any, shippingcompany?:any, country?:any): Observable<Object>{
    voucher = encodeURIComponent(voucher);email = encodeURIComponent(email);
    let queryextra = "";
    if((shippingmethod && shippingmethod !='') && (shippingcompany && shippingcompany !='') && (country && country !='')){ queryextra = `&shippingmethod=${shippingmethod}&shippingcompany=${shippingcompany}&country=${country}`; }
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=addvoucher&voucher=${voucher}&email=${email}`+queryextra,  {withCredentials: true});
  }

  removeVoucher(idvoucher:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=cart&action=removevoucher&idvoucher=${idvoucher}`,  {withCredentials: true});
  }


  buy(formData:any, lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=order&action=buy&lang=`+lang, formData, {withCredentials: true}).pipe(catchError(this.errorHandler));
  }

  changepayment(id:any, idpayment:any, lang:any, name:any, email:any, mbw:any): Observable<Object>{
    var formData = new FormData();
    formData.append("id", id.toString());
    formData.append("idpayment", idpayment.toString());
    formData.append("name", name.toString());
    formData.append("email", email.toString());
    if(mbw){
      formData.append("contact", mbw.toString());
    }
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=order&action=changepayment&lang=`+lang, formData);
  }

  getOrder(id:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=order&action=getorder&id=`+id, {withCredentials: true});
  }

  getMetatags(id:any, type:any, lang:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=metatag&action=get&lang=`+lang+`&type=`+type+`&id=`+id);
  }

  getUrlAlternate(params1?:any, params2?:any, params3?:any, params4?:any, params5?:any, params6?:any, params7?:any): Observable<Object>{
  var formData = new FormData();
  if(params1){
    formData.append("params1", params1);
  }
  if(params2){
    formData.append("params2", params2);
  }
  if(params3){
    formData.append("params3", params3);
  }
  if(params4){
    formData.append("params4", params4);
  }
  if(params5){
    formData.append("params5", params5);
  }
  if(params6){
    formData.append("params6", params6);
  }
  if(params7){
    formData.append("params7", params7);
  }
  return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=metatag&action=getUrlAlternate`, formData );
}

  signin(formData:any,   lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=signin&lang=`+lang, formData, {withCredentials: true});
  }

  logout(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=logout`,  {withCredentials: true});
  }


  logoutuserweb(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=logoutuserweb`,  {withCredentials: true});
  }

  getSession(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=getsession`,  {withCredentials: true });
  }

  recoverPass(email:any, lang:any): Observable<Object>{
    email = encodeURIComponent(email);
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=recoverpass&email=${email}&lang=${lang}`);
  }


  emailCheck(email:any): Observable<Object>{
    email = encodeURIComponent(email);
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=checkemail&email=${email}`);
  }


  changepassword(formData:any,   lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=changepassword&lang=`+lang, formData, {withCredentials: true});
  }

  getUserInfo(lang:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=getinfo&lang=`+lang, {withCredentials: true});
  }


  changeuserdata(formData:any,   lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=changedata&lang=`+lang, formData, {withCredentials: true});
  }

  createUserwebwithoutNewsletter(formData:any,   lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=createsimple&lang=`+lang, formData, {withCredentials: true});
  }

  getShowDescription(type:any, id:any): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=filters&action=getshowdescription&type=`+type+`&id=`+id );
  }


  loginFacebook(formData:any,   lang:any): Observable<Object>{
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=loginfacebook&lang=`+lang, formData, {withCredentials: true});
  }


  getordergenerate(): Observable<Object>{
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=order&action=getordergenerate`, {withCredentials: true});
  }

  changelang(lang: String): Observable<Object>{ 
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=changelang&lang=${lang}`, {withCredentials: true});
  }

  getcookies(lang:any){
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=company&action=getcookies&lang=${lang}`, {withCredentials: true});
  }

  createUserCookies(formData: any){
    return this.httpClient.post<Object>(`${this.PHP_API_SERVER}?controller=company&action=createusercookies`, formData, {withCredentials: true});
  }

  getWishListtable(sort?: any, order?: any, page?: any, search?: any, pageSize?:any){
    let querstr = '';
    if(sort){ querstr += `&sort=${sort}`; } if(order){querstr += `&order=${order}`} if(page){ querstr += `&page=${page + 1}`; } if(search){ search = encodeURIComponent(search); querstr += `&search=${search}`;} if(pageSize){ querstr += `&pagesize=${pageSize}`;}
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=userweb&action=getwishlisttable` + querstr, {withCredentials: true});
  }

  getOrderGrid(search?:any, sort?: string, order?: string, page?: number, pageSize?:number){
    let querstr = '';
    if(sort){ querstr += `&sort=${sort}`; } if(order){querstr += `&order=${order}`} if(page){ querstr += `&page=${page + 1}`; } if(search){ search = encodeURIComponent(search); querstr += `&search=${search}`;} if(pageSize){ querstr += `&pagesize=${pageSize}`;}
    return this.httpClient.get<Object>(`${this.PHP_API_SERVER}?controller=order&action=getordergrid` + querstr, {withCredentials: true});
  }

  errorHandler(error: HttpErrorResponse) {
    this.translate.get( 'error_timeout' ).subscribe(translations => {  Swal.fire(translations);    });
    if (error.error instanceof ErrorEvent) {
      console.log('Error website');
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log('Error server');
    }
    this.zone.run(() =>
      this.translate.get( 'error_timeout' ).subscribe(translations => {  Swal.fire(translations);    })
    );
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  throwNewError(){
    this.translate.get( 'error_timeout' ).subscribe(translations => {  Swal.fire(translations);    });
  }
  
}
