<div class="title-breadcrumbs">
  <div class="title-breadcrumbs-inner">
    <div class="container-fluid">
      <nav class="woocommerce-breadcrumb">
        <a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate  }}</a>
        <ng-container *ngIf="this.pageInfo  && this.pageInfo[this.translate.currentLang] "  >
          <span class="separator"> / </span>
          <ng-container *ngIf="this.page<=6"  >
          <a *ngIf="this.pageInfo  && this.pageInfo[this.translate.currentLang]" [routerLink]=" ([ '/'+ (this.pageInfo[this.translate.currentLang].subject | urlpipe | lowercase) ] | localize)" href="javascript:void(0)" >{{   (this.pageInfo[this.translate.currentLang].subject |  titlecase)   }}</a>
          </ng-container>
          <ng-container *ngIf="this.page>6"  >
          <a *ngIf="this.pageInfo  && this.pageInfo[this.translate.currentLang]" [routerLink]=" (['/page', this.page, (this.pageInfo[this.translate.currentLang].subject | urlpipe | lowercase) ] | localize)" href="javascript:void(0)" > {{   (this.pageInfo[this.translate.currentLang].subject |  titlecase)   }}</a>
          </ng-container>
        </ng-container>
      </nav>
    </div>
  </div>
</div>




<div class="container-fluid c-container-50 ">
  <div class="c-layout-sidebar-content c-border-2x  pt-50 pb-70 text-justify">
    <div class="row">
      <div class="col-md-12 c-font-14 text-center img-page">

        <ng-container *ngIf=" this.pageInfo &&  this.pageInfo[this.translate.currentLang]">
          <ng-container *ngFor="let gap of this.pageInfo[this.translate.currentLang].containers;  ">



            <div *ngIf="gap.type==0 && gap.langs[this.translate.currentLang]" class='text-justify' [innerHTML]="gap.langs[this.translate.currentLang][0].text" ></div>


            <ng-container *ngIf="gap.type==1 && gap.langs[this.translate.currentLang] && gap.langs[this.translate.currentLang].length==1 ">
              <div class="row">

              <img    class="max-full-width" [src]="this.apiService.imagesDir+gap.langs[this.translate.currentLang][0].text" />

              </div>
              <div class="row">

              <br>

              </div>
            </ng-container >

            <ng-container *ngIf=" false && gap.type==1 && gap.langs[this.translate.currentLang] && gap.langs[this.translate.currentLang].length!=1 ">
              <div class="c-content-testimonials-4 homeSlider"  data-auto-play="5000" data-navigation="true"  data-slider="owl" data-items="1">
                <owl-carousel class="owl-theme owl-noMargin c-theme owl-single  "     [options]="carouselOptionsDetail"    >
                  <ng-container   *ngFor="let img of  gap.langs[this.translate.currentLang]  ">
                    <div class="item">
                      <img [src]="this.apiService.imagesDir+img.text" class="full-width" />
                    </div>
                  </ng-container>
                </owl-carousel>
              </div>
            </ng-container >


            <ng-container *ngIf="gap.type==2   ">
              <div class="clearfix"></div><div class="col-md-12 videoEmbed"><iframe src="{{ gap.langs[this.translate.currentLang][0].text }}?rel=0" frameborder="0" allowfullscreen></iframe></div>
            </ng-container >

          </ng-container >
        </ng-container >
      </div>
    </div>
  </div>
</div>
