<!--
<div class="snowflakes" aria-hidden="true">
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
  <div class="snowflake">
  ❅
  </div>
  <div class="snowflake">
  ❆
  </div>
  <div class="snowflake">
  ❄
  </div>
</div>
-->

<ng-container *ngIf="  this.banners && this.banners[this.localize.parser.currentLang] && this.banners[this.localize.parser.currentLang][10] ">

  <div *ngIf="this.deviceDesktop" class="c-content-box c-overflow-hide c-bs-grid-reset-space  mb5 ">
    <div class="row ">
      <div style="width:100%">
        <div class="c-content-testimonials-4 c-content-media-2-slider noMargin"  >
          <owl-carousel-o  #owlElement1     [options]="carouselOptions"  [carouselClasses]="['owl-theme', 'sliding']"  >
            <ng-container *ngFor="let banner of this.banners[this.localize.parser.currentLang][10];  ">
              <ng-template carouselSlide>
                <a (click)="urlStringconvert(banner.link, banner.name, banner.idbanner)" onclick="return false;" [href]="urlStringconverthref(banner.link, banner.name, banner.idbanner)" >
                  <div class="item">
                    <div class="c-content-product-5">
                      <img class="fullWidth" [defaultImage]="this.browserwebp ? defaultImage : apiService.imagesDir +banner.image" [lazyLoad]="apiService.imagesDir + ((this.browserwebp && banner.image_webp) ? banner.image_webp : banner.image)" />
                      <div class="c-detail bannerText">
                        <div class="midContent">
                          <button *ngIf="banner.description" class="btn c-bg-black ccwhite helveticaltsd c-font-13 btn-lg btn-block c-btn-square c-font-uppercase ">
                            {{  banner.description }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>



  <div *ngIf="!this.deviceDesktop" class="c-content-box c-overflow-hide c-bs-grid-reset-space mb5  ">
    <div class="row">
      <div  style ="   padding-right: 5px;padding-left:5px   ;width:100%">
        <div class="c-content-testimonials-4 c-content-media-2-slider noMargin" data-navigation="true" data-slider="owl" data-single-item="true" data-auto-play="4000">
          <owl-carousel-o  #owlElement2     [options]="carouselOptions"  [carouselClasses]="['owl-theme', 'sliding']"  >
            <ng-container *ngFor="let banner of this.banners[this.localize.parser.currentLang][10];  ">
              <ng-template carouselSlide>
                <a (click)="urlStringconvert(banner.link, banner.name, banner.idbanner)" onclick="return false;" [href]="urlStringconverthref(banner.link, banner.name, banner.idbanner)">
                  <div class="c-content-product-5">
                    <img class="fullWidth" [defaultImage]="this.browserwebp ? defaultImage : apiService.imagesDir +banner.image_mobile" [lazyLoad]="apiService.imagesDir + ((this.browserwebp && banner.image_mobile_webp) ? banner.image_mobile_webp : banner.image_mobile)"  />
                    <div class="c-detail bannerText">
                      <div class="midContent">
                        <button *ngIf="banner.description" class="btn c-bg-black ccwhite helveticaltsd c-font-13 btn-lg btn-block c-btn-square c-font-uppercase ">
                          {{  banner.description }}
                        </button>
                      </div>
                    </div>
                  </div>
                </a>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>


</ng-container>




<ng-container *ngIf="  this.banners && this.banners[this.localize.parser.currentLang] && this.banners[this.localize.parser.currentLang][1] && this.banners[this.localize.parser.currentLang][2] ">

  <div class="c-content-box c-overflow-hide c-bg-white">
<div class="c-content-product-3 c-bs-grid-reset-space">
  <div class="row">


      <div [style]="this.deviceDesktop ? 'padding-left: 0px;padding-right:2.5px ' : 'padding-right: 5px;padding-left:5px' "   class="col-md-6   mb-xs-5 px-xs-5 mb5"  >

          <owl-carousel-o  #owlElement3     [options]="carouselOptions"  [carouselClasses]="['owl-theme', 'sliding']"  >
            <ng-container *ngFor="let banner of this.banners[this.localize.parser.currentLang][1];  ">
              <ng-template carouselSlide>
                <div class="item">
                  <a  (click)="urlStringconvert(banner.link, banner.name, banner.idbanner)" onclick="return false;" [href]="urlStringconverthref(banner.link, banner.name, banner.idbanner)">
                    <div class="c-content-product-5">
                      <img class="fullWidth" [defaultImage]="this.browserwebp ? defaultImage : apiService.imagesDir +banner.image" [lazyLoad]="apiService.imagesDir + ((this.browserwebp && banner.image_webp) ? banner.image_webp : banner.image)"/>
                      <div class="c-detail bannerText">
                        <div class="midContent">
                          <button *ngIf="banner.description" class="btn c-bg-black ccwhite helveticaltsd c-font-13 btn-lg btn-block c-btn-square c-font-uppercase ">
                            {{  banner.description }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>

      </div>

      <div  [style]="this.deviceDesktop ? 'padding-left: 2.5px;padding-right:0px ' : 'padding-right: 5px;padding-left:5px' "  class=" col-md-6   mb-xs-5 px-xs-5 mb5"  >
        <owl-carousel-o  #owlElement4     [options]="carouselOptions"  [carouselClasses]="['owl-theme', 'sliding']"  >
          <ng-container *ngFor="let banner of this.banners[this.localize.parser.currentLang][2];  ">
            <ng-template carouselSlide>
              <a  (click)="urlStringconvert(banner.link, banner.name, banner.idbanner)" onclick="return false;" [href]="urlStringconverthref(banner.link, banner.name, banner.idbanner)">
                <div class="c-content-overlay">
                  <img class="fullWidth"  [defaultImage]="this.browserwebp ? defaultImage : apiService.imagesDir +banner.image" [lazyLoad]="apiService.imagesDir + ((this.browserwebp && banner.image_webp) ? banner.image_webp : banner.image)" />
                  <div class="c-detail bannerText">
                    <div class="midContent">
                      <button *ngIf="banner.description" class="btn c-bg-black ccwhite helveticaltsd c-font-13 btn-lg btn-block c-btn-square c-font-uppercase ">
                        {{  banner.description }}
                      </button>
                    </div>
                  </div>
                </div>
              </a>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

  </div>
</div></div>
</ng-container>



<ng-container *ngIf="  this.banners && this.banners[this.localize.parser.currentLang] && this.banners[this.localize.parser.currentLang][11] && this.banners[this.localize.parser.currentLang][12] ">
  <div class="c-content-box c-overflow-hide c-bs-grid-reset-space">
    <div class="row">
      <div style="width:50%" class="  pr-2_5 px-xs-5 mb5">
        <a (click)="urlStringconvert(this.banners[this.localize.parser.currentLang][11][0].link, this.banners[this.localize.parser.currentLang][11][0].name, this.banners[this.localize.parser.currentLang][11][0].idbanner)" onclick="return false;" [href]="urlStringconverthref(this.banners[this.localize.parser.currentLang][11][0].link, this.banners[this.localize.parser.currentLang][11][0].name, this.banners[this.localize.parser.currentLang][11][0].idbanner)">
          <div class="c-detail banneronlyText">
            <div class="midContent">
              {{ this.banners[this.localize.parser.currentLang][11][0].description }}
            </div>
          </div>
        </a>
      </div>
      <div style="width:50%" class="  pl-2_5 px-xs-5 mb5">
        <a (click)="urlStringconvert(this.banners[this.localize.parser.currentLang][12][0].link, this.banners[this.localize.parser.currentLang][12][0].name, this.banners[this.localize.parser.currentLang][12][0].idbanner)" onclick="return false;" [href]="urlStringconverthref(this.banners[this.localize.parser.currentLang][12][0].link, this.banners[this.localize.parser.currentLang][12][0].name, this.banners[this.localize.parser.currentLang][12][0].idbanner)">
          <div class="c-detail banneronlyText">
            <div class="midContent">
              {{ this.banners[this.localize.parser.currentLang][12][0].description }}
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</ng-container>




<ng-container *ngIf="  this.banners && this.banners[this.localize.parser.currentLang] && this.banners[this.localize.parser.currentLang][3]  ">
  <div class=" ">
    <div [style]="this.deviceDesktop ? 'padding-left: 0px; ' : 'padding-right: 5px;padding-left:5px' " class=" ">
      <a (click)="urlStringconvert(this.banners[this.localize.parser.currentLang][3][0].link, this.banners[this.localize.parser.currentLang][3][0].name, this.banners[this.localize.parser.currentLang][3][0].idbanner)" onclick="return false;" [href]="urlStringconverthref(this.banners[this.localize.parser.currentLang][3][0].link, this.banners[this.localize.parser.currentLang][3][0].name, this.banners[this.localize.parser.currentLang][3][0].idbanner)">
        <div class="c-content-box c-size-sm c-bg-grey  mb5 noPadding  mx-xs-5">
          <div class="c-content-overlay ">
            <img style="width: 100%;" *ngIf="!this.deviceDesktop && this.banners[this.localize.parser.currentLang][3][0].image_mobile" class="fullWidth  " [defaultImage]="this.browserwebp ? defaultImage : apiService.imagesDir +this.banners[this.localize.parser.currentLang][3][0].image_mobile" [lazyLoad]="apiService.imagesDir + ((this.browserwebp && this.banners[this.localize.parser.currentLang][3][0].image_mobile_webp) ? this.banners[this.localize.parser.currentLang][3][0].image_mobile_webp : this.banners[this.localize.parser.currentLang][3][0].image_mobile)" />
            <img style="width: 100%;" *ngIf="this.deviceDesktop || !this.banners[this.localize.parser.currentLang][3][0].image_mobile" class="fullWidth   " [defaultImage]="this.browserwebp ? defaultImage : apiService.imagesDir +this.banners[this.localize.parser.currentLang][3][0].image" [lazyLoad]="apiService.imagesDir + ((this.browserwebp && this.banners[this.localize.parser.currentLang][3][0].image_webp) ? this.banners[this.localize.parser.currentLang][3][0].image_webp : this.banners[this.localize.parser.currentLang][3][0].image)" />
            <div class="c-detail bannerText">
              <div class="midContent">
                <button *ngIf="this.banners[this.localize.parser.currentLang][3][0].description" class="btn c-bg-black ccwhite helveticaltsd c-font-13 btn-lg btn-block c-btn-square c-font-uppercase ">
                  {{ this.banners[this.localize.parser.currentLang][3][0].description }}
                </button>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</ng-container>




<ng-container *ngIf="  this.banners && this.banners[this.localize.parser.currentLang] && this.banners[this.localize.parser.currentLang][4] && this.banners[this.localize.parser.currentLang][5] && this.banners[this.localize.parser.currentLang][6] && this.banners[this.localize.parser.currentLang][7]">

  <div class="c-content-box c-overflow-hide c-bg-white">
<div class="c-content-product-3 c-bs-grid-reset-space">
  <div class="row">
    <div  [style]="this.deviceDesktop ? 'padding-left: 0px; ' : 'padding-right: 5px;padding-left:5px' " class="col-md-6 pr-2_5  mb5 px-xs-5">
      <a  (click)="urlStringconvert(this.banners[this.localize.parser.currentLang][4][0].link, this.banners[this.localize.parser.currentLang][4][0].name, this.banners[this.localize.parser.currentLang][4][0].idbanner)" onclick="return false;" [href]="urlStringconverthref(this.banners[this.localize.parser.currentLang][4][0].link, this.banners[this.localize.parser.currentLang][4][0].name, this.banners[this.localize.parser.currentLang][4][0].idbanner)">
        <div class="c-content-overlay">
          <img style="width:100%" class="fullWidth" [defaultImage]="this.browserwebp ? defaultImage : apiService.imagesDir +this.banners[this.localize.parser.currentLang][4][0].image" [lazyLoad]="apiService.imagesDir + ((this.browserwebp && this.banners[this.localize.parser.currentLang][4][0].image_webp) ? this.banners[this.localize.parser.currentLang][4][0].image_webp : this.banners[this.localize.parser.currentLang][4][0].image)" />
          <div class="c-detail bannerText">
            <div class="midContent">
              <button *ngIf="this.banners[this.localize.parser.currentLang][4][0].description" class="btn c-bg-black ccwhite helveticaltsd c-font-13 btn-lg btn-block c-btn-square c-font-uppercase ">
              {{ this.banners[this.localize.parser.currentLang][4][0].description }}
              </button>
            </div>
          </div>
        </div>
      </a>
    </div>
    <div class="col-md-6 ">
      <div class="row mb5">
        <div [style]="this.deviceDesktop ? 'padding-left: 0px; ' : 'padding-right: 5px;padding-left:5px' " class="col-md-12 pl-2_5 pr-2_5 px-xs-5 pb-2_5 py-xs-0  mb-xs-5">
          <div class="c-content-product-5">
            <a  (click)="urlStringconvert(this.banners[this.localize.parser.currentLang][5][0].link, this.banners[this.localize.parser.currentLang][5][0].name, this.banners[this.localize.parser.currentLang][5][0].idbanner)"  onclick="return false;" [href]="urlStringconverthref(this.banners[this.localize.parser.currentLang][5][0].link, this.banners[this.localize.parser.currentLang][5][0].name, this.banners[this.localize.parser.currentLang][5][0].idbanner)">
              <img style="width:100%" class="fullWidth"  [defaultImage]="this.browserwebp ? defaultImage : apiService.imagesDir +this.banners[this.localize.parser.currentLang][5][0].image" [lazyLoad]="apiService.imagesDir + ((this.browserwebp && this.banners[this.localize.parser.currentLang][5][0].image_webp) ? this.banners[this.localize.parser.currentLang][5][0].image_webp : this.banners[this.localize.parser.currentLang][5][0].image)" />
              <div class="c-detail bannerText">
                <div class="midContent">
                  <button *ngIf="this.banners[this.localize.parser.currentLang][5][0].description" class="btn c-bg-black ccwhite helveticaltsd c-font-13 btn-lg btn-block c-btn-square c-font-uppercase ">
                  {{ this.banners[this.localize.parser.currentLang][5][0].description }}
                  </button>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="row mb5">
        <div [style]="this.deviceDesktop ? 'padding-left: 0px; ' : 'padding-right: 5px;padding-left:5px' "  class="col-md-6 col-sm-6 pl-2_5 pr-2_5 pb-2_5 pt-2_5 py-xs-0 px-xs-5 mb-xs-5 mb5 ">
          <div class="c-content-product-5 c-content-overlay">

            <a (click)="urlStringconvert(this.banners[this.localize.parser.currentLang][6][0].link, this.banners[this.localize.parser.currentLang][6][0].name, this.banners[this.localize.parser.currentLang][6][0].idbanner)" onclick="return false;" [href]="urlStringconverthref(this.banners[this.localize.parser.currentLang][6][0].link, this.banners[this.localize.parser.currentLang][6][0].name, this.banners[this.localize.parser.currentLang][6][0].idbanner)">
              <img style="width:100%;    " class="fullWidth" [defaultImage]="this.browserwebp ? defaultImage : apiService.imagesDir +this.banners[this.localize.parser.currentLang][6][0].image" [lazyLoad]="apiService.imagesDir + ((this.browserwebp && this.banners[this.localize.parser.currentLang][6][0].image_webp) ? this.banners[this.localize.parser.currentLang][6][0].image_webp : this.banners[this.localize.parser.currentLang][6][0].image)" />
              <div class="c-detail bannerText">
                <div class="midContent">

                  <button *ngIf="this.banners[this.localize.parser.currentLang][6][0].description" class="btn c-bg-black ccwhite helveticaltsd c-font-13 btn-lg btn-block c-btn-square c-font-uppercase ">
                  {{ this.banners[this.localize.parser.currentLang][6][0].description }}
                  </button>

                </div>
              </div>
            </a>
          </div>
        </div>
        <div [style]="this.deviceDesktop ? 'padding-left: 0px;padding-right:2.5px' : 'padding-right: 5px;padding-left:5px' "    class="col-md-6 col-sm-6 pl-2_5  pr-2_5 pb-2_5 pt-2_5 py-xs-0 px-xs-5 mb-xs-5  mb5">
          <div class="c-content-product-5 c-content-overlay">

            <a (click)="urlStringconvert(this.banners[this.localize.parser.currentLang][7][0].link, this.banners[this.localize.parser.currentLang][7][0].name, this.banners[this.localize.parser.currentLang][7][0].idbanner)" onclick="return false;" [href]="urlStringconverthref(this.banners[this.localize.parser.currentLang][7][0].link, this.banners[this.localize.parser.currentLang][7][0].name, this.banners[this.localize.parser.currentLang][7][0].idbanner)">
              <img style="width:100%;    padding-left: 0px;" class="fullWidth" [defaultImage]="this.browserwebp ? defaultImage : apiService.imagesDir +this.banners[this.localize.parser.currentLang][7][0].image" [lazyLoad]="apiService.imagesDir + ((this.browserwebp && this.banners[this.localize.parser.currentLang][7][0].image_webp) ? this.banners[this.localize.parser.currentLang][7][0].image_webp : this.banners[this.localize.parser.currentLang][7][0].image)" />
              <div class="c-detail bannerText">
                <div class="midContent">


                  <button *ngIf="this.banners[this.localize.parser.currentLang][7][0].description" class="btn c-bg-black ccwhite helveticaltsd c-font-13 btn-lg btn-block c-btn-square c-font-uppercase ">
                  {{ this.banners[this.localize.parser.currentLang][7][0].description }}
                  </button>


                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

</ng-container>





<ng-container *ngIf="  this.banners && this.banners[this.localize.parser.currentLang] && this.banners[this.localize.parser.currentLang][8] && this.banners[this.localize.parser.currentLang][9]">


  <div class="c-content-box c-overflow-hide c-bg-white  ">
	<div class="c-content-product-3 c-bs-grid-reset-space">
		<div class="row">
			<div [style]="this.deviceDesktop ? 'padding-left: 0px;padding-right:2.5px' : 'padding-right: 5px;padding-left:5px' "   class="col-md-6    px-xs-5 mb-xs-5"  >

				<a (click)="urlStringconvert(this.banners[this.localize.parser.currentLang][8][0].link, this.banners[this.localize.parser.currentLang][8][0].name, this.banners[this.localize.parser.currentLang][8][0].idbanner)" onclick="return false;" [href]="urlStringconverthref(this.banners[this.localize.parser.currentLang][8][0].link, this.banners[this.localize.parser.currentLang][8][0].name, this.banners[this.localize.parser.currentLang][8][0].idbanner)">
					<div class="c-content-overlay">
						<img style="width:100%;    padding-right: 0px;" class="fullWidth" [defaultImage]="this.browserwebp ? defaultImage : apiService.imagesDir +this.banners[this.localize.parser.currentLang][8][0].image" [lazyLoad]="apiService.imagesDir + ((this.browserwebp && this.banners[this.localize.parser.currentLang][8][0].image_webp) ? this.banners[this.localize.parser.currentLang][8][0].image_webp : this.banners[this.localize.parser.currentLang][8][0].image)" />
						<div class="c-detail bannerText">
							<div class="midContent">

								<button *ngIf="this.banners[this.localize.parser.currentLang][8][0].description" class="btn c-bg-black ccwhite helveticaltsd c-font-13 btn-lg btn-block c-btn-square c-font-uppercase ">
								{{ this.banners[this.localize.parser.currentLang][8][0].description }}
								</button>

							</div>
						</div>
					</div>
				</a>
			</div>
			<div [style]="this.deviceDesktop ? 'padding-left: 2.5px;padding-right:0px' : 'padding-right: 5px;padding-left:5px;margin-top:5px' "    class="col-md-6   px-xs-5 mb-xs-5"  >

				<a (click)="urlStringconvert(this.banners[this.localize.parser.currentLang][9][0].link, this.banners[this.localize.parser.currentLang][9][0].name, this.banners[this.localize.parser.currentLang][9][0].idbanner)" onclick="return false;" [href]="urlStringconverthref(this.banners[this.localize.parser.currentLang][9][0].link, this.banners[this.localize.parser.currentLang][9][0].name, this.banners[this.localize.parser.currentLang][9][0].idbanner)">
					<div class="c-content-overlay">
						<img style="width:100%;    padding-left: 0px;" class="fullWidth" [defaultImage]="this.browserwebp ? defaultImage : apiService.imagesDir +this.banners[this.localize.parser.currentLang][9][0].image" [lazyLoad]="apiService.imagesDir + ((this.browserwebp && this.banners[this.localize.parser.currentLang][8][0].image_webp) ? this.banners[this.localize.parser.currentLang][9][0].image_webp : this.banners[this.localize.parser.currentLang][9][0].image)" />
						<div class="c-detail bannerText">
							<div class="midContent">

								<button *ngIf="this.banners[this.localize.parser.currentLang][9][0].description" class="btn c-bg-black ccwhite helveticaltsd c-font-13 btn-lg btn-block c-btn-square c-font-uppercase ">
								{{ this.banners[this.localize.parser.currentLang][9][0].description }}
								</button>

							</div>
						</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</div>

</ng-container>








<modal #ModalDetail  id="buyFast" >
  <modal-header>

    <h3 class="c-font-24 c-font-sbold name_item m-0" *ngIf="this.selectItem"> {{ this.selectItem.langs[this.localize.parser.currentLang].description }} </h3>

  </modal-header>
  <modal-content>


    <div class=" row">
      <div class="  col-md-12 col-xs-12 col-sm-12">
        <div class="infoProd" *ngIf="this.selectItem ">

          <p class="noPadding col-xs-12 m-0 helveticaltsd c-font-13">{{ 'refa' | translate }} : {{ this.selectItem.code }}</p>


          <input type="hidden" name="iditem" value="{{ this.selectItem.iditem }}"/><input type="hidden" name="code" value="{{ this.selectItem.code }}"/>
          <div class="b-margin-20">
            <ng-container   *ngIf="this.selectItem.itemwithattr==1 ">
              <div class="col-xs-12 c-color-wrapper"><span class="c-input-title helveticaltsdr c-font-11 ccblack full-width float-left">{{ 'colors' | translate }}:</span><span class="c-custom-labels" data-toggle="buttons">


                <ng-container   *ngFor="let color of  this.selectItem.colors | keyvalue ; let i=index ; first as isFirst ">


                  <label  [class]=" color.key==this.selectItem.colorActive ?  'btn c-btn-square active' : 'btn c-btn-square '  "><div class="labelColor" style="  background-size: 100% 100%;"><img alt="{{ this.selectItem.langs[this.localize.parser.currentLang].description   }} - {{ showcolorInfo(color,2) }} - {{ 'Name_APP' | translate }}" src="{{  (this.browserwebp && color.value[objectKeys(color.value)[0]].images[0].webp) ? this.apiService.imagesDir+color.value[objectKeys(color.value)[0]].images[0].webp : this.apiService.imagesDir+color.value[objectKeys(color.value)[0]].images[0].photo }}"></div><input (change)="changeColor(color.key)" class="inputTypeHidden" value="{{ color.key }}" name="color" data-colorname="{{ showcolorInfo(color,2) }}" id="inputColor_{{ color.key }}"   type="radio"  /></label>


                </ng-container>


              </span>
            </div>
          </ng-container>
          <ng-container   *ngIf="this.selectItem.itemwithattr==1 ">
            <div class="col-xs-12 c-color-wrapper">
              <span class="c-input-title helveticaltsdr c-font-11 ccblack full-width float-left">{{ 'size' | translate }}:</span>
              <span class="c-custom-labels2" data-toggle="buttons">

                <ng-container   *ngFor="let color of  this.selectItem.colors | keyvalue ; let i=index ; first as isFirst ">

                  <ng-container   *ngFor="let size of  color.value    | keyvalue ; let i1=index    ">
                    <label [style]=" color.key==this.selectItem.colorActive ?  '' : 'display:none'  "   [class]=" size.key==this.selectItem.sizeActive ?  'btn c-btn-square llabelSize  active sizeBtn' : 'btn c-btn-square sizeBtn'  "><div class="labelSize" style="  background-size: 100% 100%;">{{ size.value.number }}</div><input (change)="changeSize(size.key)" class="inputTypeHidden" value="{{ size.key }}" name="size" data-sizename="{{ size.value.number }}" id="inputSize_{{ color.key }}_{{ size.key }}"   type="radio"  /></label>
                  </ng-container>

                </ng-container>

              </span>
            </div>
          </ng-container>


        </div>





      </div>
    </div>

    <div class="imagesProd col-md-12 col-xs-12 col-sm-12">
      <div class="col-lg-12" style="width:410px !important;max-height:380px;"><div>

        <ng-container   *ngIf="this.selectItem && this.selectItem.itemwithattr==1 ">

          <div [class]="'product-details-img-content galleryDisplay gallery' "   cont="{{ i }}"  >
            <div class="product-details-tab"  >
              <div class="slider-area">
                <owl-carousel-o class="slider-active product-details-large product-slider-one  "     [options]="carouselOptionsDetail"    >
                  <ng-container   *ngFor="let img of  this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].images | keyvalue ; let i1=index    ">
                    <ng-template carouselSlide  [width]="380">
                      <div class="item"><img [alt]="this.selectItem.langs[this.localize.parser.currentLang].description" [src]="(this.browserwebp && img.value.webp) ? this.apiService.imagesDir+img.value.webp : this.apiService.imagesDir+img.value.photo"  class="col-xs-12 noPadding  "/></div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </div>
            </div>
          </div>
        </ng-container>


        <ng-container   *ngIf="this.selectItem && this.selectItem.itemwithattr==0 ">
          <div class="'product-details-img-content galleryDisplay gallery "    >
            <div class="product-details-tab"  >
              <div class="slider-area">
                <owl-carousel-o class="slider-active product-details-large product-slider-one  "     [options]="carouselOptionsDetail"    >
                  <ng-container   *ngFor="let img of  this.selectItem.images ; let i1=index    ">
                    <ng-template carouselSlide  [width]="380">
                      <div class="item"><img [alt]="this.selectItem.langs[this.localize.parser.currentLang].description" [src]="(this.browserwebp && img.webp) ? this.apiService.imagesDir+img.webp : this.apiService.imagesDir+img.photo"  class="col-xs-12 noPadding  "/></div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>


  <div class="priceProd col-md-12 noPadding">

    <div class="clearfix"></div>
    <div class="col-md-6  float-left col-xs-3 mt-20">
      <p class="product-price">
        <del *ngIf="  this.selectItem &&  this.selectItem.withPromotion && this.selectItem.withPromotion==1  " id="pricestart" class="old">{{ loadPriceFastBuy(1) }}€</del>
        <span id="pricefinal">{{ loadPriceFastBuy(2) }}€</span></p>
      </div>
      <div class="col-md-6 col-xs-9  float-left mt-20"><button type="button" (click)="buyProduct()" class="btn-style cr-btn cartBtn full-width c-square text-center font-12 float-left fastBuyAddCart btn-danger">{{ 'add_to_cart' | translate }}</button></div>

    </div>
    <div class="noPadding col-md-12 mt-10">
      <div class="col-sm-6 col-md-6 float-left">
        <button (click)="closeModal()" type="button" data-dismiss="modal" style="  border-radius: 0px;background-color:#f0f0f0;" class="btn btn-default btn-outline btn-cart button-aylen cartBtn full-width float-left ">{{ 'keepbuying' | translate }}</button>
      </div>
      <div class="col-sm-6 col-md-6 float-left">
        <button type="button" style="border-radius: 0px;background-color:#f0f0f0;"  (click)="closeModal()"   [routerLink]="(['/checkout'] | localize)" class="btn btn-outline btn-default btn-cart button-aylen cartBtn full-width float-left ">{{ 'finishorder' | translate }}</button>
      </div>
    </div>
  </div>


</modal-content>
</modal>
