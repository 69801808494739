import {NgModule} from '@angular/core';


import {MatPaginatorModule} from '@angular/material/paginator';

import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion';

import { CommonModule } from '@angular/common';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MatTableModule,
    MatExpansionModule
  ],
  exports: [

    MatExpansionModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule
  ]
})
export class MaterialModule { }
