import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Title, Meta }     from '@angular/platform-browser';
import { SeoService } from '../seo.service';
import {   ActivatedRoute   } from '@angular/router';
import { TitlechangeService } from '../titlechange.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contacts:any;
  resolveCaptcha:any;
  contactForm:FormGroup;
  submitted = false;
  subscribe:any;
  constructor(public titlechangeService:TitlechangeService,private router: ActivatedRoute,private seoService: SeoService, private metaService: Meta, public titleService: Title, public apiService: ApiService, public translate: TranslateService, private formBuilder: FormBuilder) {
    this.contactForm = this.formBuilder.group({
      name: new FormControl('',[  Validators.required]),
      email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")  ] ),
      telephone: new FormControl('',[ Validators.required, Validators.pattern("^[0-9]{9}$")]),
      message: new FormControl('', [Validators.required])
    });

	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/contact');
      this.metatags(lang.lang);
    });
  }

  ngOnInit(): void {
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/contact' );

    this.seoService.clearAlternateUrl();

    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/contact', 'x-default');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/contact', 'pt');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/contact', 'en');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/es/contact', 'es');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/contact', 'fr');

	  this.metatags(this.translate.currentLang);
    this.apiService.getContacts().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.contacts=obj.info;
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.seoService.clearAlternateUrl();
		this.seoService.removeTag('rel=canonical');
		this.seoService.removeTag('rel=alternate');
  }


  public setTitle( newTitle: string) {
	   this.titleService.setTitle( newTitle  );
     this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(3,9,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }
        /*Object.keys(obj.info).forEach(key => {
          this.metaService.removeTag('name="'+obj.info[key].name+'"');
        });*/
        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }

  get f() { return this.contactForm.controls; }

  resolved(captchaResponse: string) {
    this.resolveCaptcha = captchaResponse;

  }

  onSubmit(captchaElem) {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    if(!this.resolveCaptcha){
      return;
    }
    var formData = new FormData();
    formData.append("captcha", this.resolveCaptcha.toString());
    Object.keys(this.contactForm.controls).forEach(key => {
      formData.append("contact["+key+"]", this.contactForm.controls[key].value.toString());
    });
    this.apiService.orderContact(formData, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.translate.get( obj.msg ).subscribe(translations => {
          this.submitted = false;
          captchaElem.reset();
          this.contactForm.reset();
        Swal.fire({
          position: 'top-end',
          customClass: {
            container: 'swal-index'
          },
          icon: 'success',
          title: translations,
          showConfirmButton: false,
          timer: 2000
        });
        });
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

}
