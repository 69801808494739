import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlpipe'
})
export class UrlpipePipe implements PipeTransform {

  transform(value: string): string {
    if(!value){
      return value;
    }
    let  element = document.createElement('div');let str=value;if(str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }
    return str.replace(/\*/g, '').replace(/\,/g, '').replace(/\+/g, '').replace(/\?/g, '').replace(/\)/g, '').replace(/\(/g, '').replace(/\}/g, '').replace(/\{/g, '').replace(/\%/g, '').replace(/\#/g, '').replace(/!/g, '').replace(/$/g, '').replace(/§/g, '').replace(/@/g, '').replace(/|/g, '').replace(/`/g, '').replace(/´/g, '').replace(/'/g, '').replace(/\\/g, '').replace(/\//g, '').replace(/%/g, '').replace(/"/g, '').replace(/ /g, '-').replace(/&/g, 'e').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

}
