import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta }     from '@angular/platform-browser';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { SeoService } from '../seo.service';
import { TitlechangeService } from '../titlechange.service';
@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {
  subscribe:any;

  constructor(public titlechangeService:TitlechangeService,private seoService: SeoService, private metaService: Meta,  public titleService: Title, public apiService: ApiService, public translate: TranslateService) {
	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
      this.metatags(lang.lang);
    });
  }

  ngOnInit(): void {
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/pagenotfound');

    this.seoService.clearAlternateUrl();
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/pagenotfound', 'pt');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/pagenotfound', 'en');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/es/pagenotfound', 'es');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/pagenotfound', 'fr');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pagenotfound', 'x-default');
	this.metatags(this.translate.currentLang);
  }

  ngOnDestroy(): void {
    this.seoService.clearAlternateUrl();
    this.subscribe.unsubscribe();
    this.seoService.removeTag('rel=canonical');
    this.seoService.removeTag('rel=alternate');
  }

  public setTitle( newTitle: string) {
	   this.titleService.setTitle( newTitle);
     this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(10,9,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }

        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }
}
