import { Component, OnInit, ViewChild  } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CartsharedService } from '../cartshared.service';
import { Subscription } from 'rxjs';
import { SharedService } from '../shared.service';
import { Options } from 'select2';
import { MustMatch, NifValidatorPT } from '../must-match.validator';
 import { Router, ActivatedRoute , ParamMap  } from '@angular/router';
import { LocalstorageService } from '../localstorage.service';
import { Title, Meta }     from '@angular/platform-browser';
import { LoginService } from '../login.service';
import { SessionService } from '../session.service';
declare var $: any;
import { SocialAuthService , FacebookLoginProvider } from 'angularx-social-login';
import { ModalManager } from 'ngb-modal';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
import { CheckwebpService } from '../checkwebp.service';
import { WishlistService } from '../wishlist.service';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { TitlechangeService } from '../titlechange.service';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  @ViewChild('invoicecountry') invoicecountry;
  @ViewChild('invoiceaddress') invoiceaddress;
  @ViewChild('invoicelocality') invoicelocality;
  @ViewChild('invoicepostalcode') invoicepostalcode;
  @ViewChild('shippingcountry') shippingcountry;
  @ViewChild('shippingaddress') shippingaddress;
  @ViewChild('shippinglocality') shippinglocality;
  @ViewChild('shippingpostalcode') shippingpostalcode;
  @ViewChild('voucherCode') voucherCodeInput;
  @ViewChild('ModalPasswordCh') ModalPassword;
  @ViewChild('emailrecoverPassCh') emailrecoverPass;
  @ViewChild('ModalSignIn') modalSignIn;
  optionsCountry: Options;
  optionsCalling: Options;
  signinForm:FormGroup;
  subscribeFace:any;
  countries:any;
  telephone_callingcode:any;
  cartsubscription:Subscription;
  clickEventsubscriptionLogin:any;
  cart:any;
  cartTotal:any;
  companyInfo:any;
  shippingTotal:any;
  checkboxReplicate:any;
  checkboxPassword:any;
  shippingInfo:any;
  selectShippingmode:any;
  selectShippingcompany:any;
  selectPayment:any;
  checkoutForm:FormGroup;
  submitted = false;
  submittedSign = false;
  pickupShipping:any;
  pickupShippingArr:any;
  pickupShippingIndex:any;
  voucherError:any;
  vouchersArr:any;
  waitingsubmit:any;
  userInfo:any;
  loadUser:any;
  subscribe:any;
  hiddenLoad:any;
  emailCheck:any;
  modalRecoverRef:any;
  browserwebp:any;
  backcheck:any;
  paymentold:any;
	cob_value:any;
  postal_code_country:any;
  deviceDesktop:any;

  companyPresentation:any;
  email_support: any;

  constructor(public titlechangeService:TitlechangeService,public wishlistService:WishlistService,private angulartics2: Angulartics2, private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, public checkwebp: CheckwebpService,private seoService: SeoService, public localize: LocalizeRouterService,private aroute: ActivatedRoute,private modalService: ModalManager,private authService: SocialAuthService,public sessionService: SessionService,private loginService: LoginService, private metaService: Meta, public titleService: Title, public localstorageService: LocalstorageService,private route: Router,  public apiService: ApiService, public translate: TranslateService, private formBuilder: FormBuilder, public cartsharedService: CartsharedService, public sharedService: SharedService,private deviceService: DeviceDetectorService) {
    this.backcheck = this.aroute.snapshot.paramMap.get('back');
    this.hiddenLoad=true;
    this.waitingsubmit=false;
    this.loadUser=false;
    this.emailCheck=false;
    this.cob_value=0;
    this.postal_code_country = {};
    this.browserwebp = this.checkwebp.canUseWebP();

    this.deviceDesktop=false;
    const isDesktopDevice = this.deviceService.isDesktop();
    if(isDesktopDevice){
      this.deviceDesktop=true;
    }

    this.signinForm = this.formBuilder.group({
        rememberme: new FormControl('' ),
        email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
        password: ['', [Validators.required, Validators.minLength(1)]]
      });
    this.checkoutForm = this.formBuilder.group({
      firstname: new FormControl('',[  Validators.required]),
      lastname: new FormControl('',[  Validators.required]),
      email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")  ] ),
      telephone: new FormControl('',[ Validators.required ]),

      telephone_callingcode: new FormControl('351' ),
      nif: new FormControl(''  ),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('' ),
      postalcode: new FormControl('', [Validators.required]),
      locality: new FormControl('', [Validators.required]),
      idcountry: new FormControl('', [Validators.required]),

      shipping_responsable: new FormControl('', ),
      shipping_address: new FormControl('', ),
      shipping_address2: new FormControl('', ),
      shipping_postalcode: new FormControl('', ),
      shipping_locality: new FormControl('', ),
      shipping_idcountry: new FormControl('', ),
      password: ['',  [ Validators.minLength(6)]],
      confirmPassword: ['' ]
    }, {
            validator: [MustMatch('password', 'confirmPassword'), NifValidatorPT('idcountry', 'nif') ]
        });



    this.cartsubscription =    this.cartsharedService.getcartEvent().subscribe((obj)=>{
      this.cart = obj[0];
      this.cartTotal = obj[1];
      this.companyInfo = obj[2];
      this.shippingTotal = obj[3];
      this.vouchersArr = obj[4];
    });

    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/checkout' );
	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/checkout');
      this.metatags(lang.lang);
    });

      this.clickEventsubscriptionLogin =  this.loginService.getloginEvent().subscribe(()=>{
        this.getUserInfo(1);
      });


    this.shippingTotal = 0;
    this.pickupShippingIndex=-1;
  }

  get f() { return this.checkoutForm.controls; }
  get fSign() { return this.signinForm.controls; }

  ngOnDestroy(): void {
    this.cartsubscription.unsubscribe();
	   this.subscribe.unsubscribe();
     this.clickEventsubscriptionLogin.unsubscribe();
     this.seoService.clearAlternateUrl();
     this.seoService.removeTag("rel=canonical");
  }


  changeEmailClear(event){
  if(this.signinForm && this.signinForm.controls.email && this.signinForm.controls.email.value){
    this.signinForm.controls.email.setValue ( this.signinForm.controls.email.value.toString().trim().toLowerCase() );
  }
}


  selectPaymentf(idpaymentwebsite, payment){
		this.selectPayment=idpaymentwebsite;
		if(this.paymentold=="COB" || payment=="COB"){
			this.getShippingCosts();
		}
    if(payment=="COB"){
      const hTMLInputElement = (document.getElementById("radioC"+this.selectShippingcompany)) as HTMLInputElement;
      if(hTMLInputElement!=null){
        hTMLInputElement.checked = false;
      }
      this.selectShippingcompany=null;
    }
		this.paymentold = payment;
	}

  selectShipmethod(idshippingmode) {
    const hTMLInputElement = (document.getElementById("radioP"+this.selectPayment+"_"+this.selectShippingmode)) as HTMLInputElement;
    if(hTMLInputElement!=null){
      hTMLInputElement.checked = false;
    }

    this.selectShippingmode=idshippingmode;
    this.selectPayment=null;
    this.getShippingCosts();
  }


  sendinitialcheckout(step:any, option:any): void {
		let desc:any= "";
		let cat:any = "";
		let brand:any = "";
		let color:any = "";
		let pval:any = "";
		let idcolor:any;
		let idsize:any;
		let galayer:any = [];
    let galayerga4:any = [];

		for (let index = 0; index < this.cart.length; index++) {

			if(this.translate.currentLang && this.cart[index].langs[this.translate.currentLang]){
				desc = this.cart[index].langs[this.translate.currentLang].description;
			}else{
				desc = this.cart[index].description;
			}
			if(this.translate.currentLang && this.cart[index].catlangs && this.cart[index].catlangs[this.translate.currentLang] && this.cart[index].catlangs[this.translate.currentLang].description) {
				cat = this.cart[index].catlangs[this.translate.currentLang].description;
			}
			brand = (this.cart[index].brandName) ? this.cart[index].brandName : '';
			if(this.cart[index].itemwithattr==1){
				idcolor = Object.keys(this.cart[index].colors)[0];
				idsize = Object.keys(this.cart[index].colors[idcolor])[0];
				color = this.cart[index].colors[idcolor][idsize].name;
				if(this.cart[index].colors[idcolor][idsize].priceFinal) { pval = this.cart[index].colors[idcolor][idsize].priceFinal; }
			}else{
				pval = this.cart[index].priceFinal;
			}

			this.angulartics2GAEcommerce.ecAddProduct({
						'id': this.cart[index].iditem,
						'name': desc,
						'category': cat,
						'brand': brand,
						'quantity': this.cart[index].quantity,
						'price': pval,
						'variant': color,
						'position': index
					});
					this.angulartics2GAEcommerce.ecSetAction("checkout", {'step':step, 'option':option});

					galayer.push({
						'id': this.cart[index].iditem,
						'name': desc,
						'price': pval,
						'brand': brand,
						'quantity': this.cart[index].quantity,
						'category': cat,
						'variant': color
					 });

           galayerga4.push({
						'item_id': this.cart[index].iditem,
						'item_name': desc,
						'price': pval,
						'item_brand': brand,
						'quantity': this.cart[index].quantity,
						'item_category': cat,
						'item_variant': color,
          });
		}

		if(step=="1"){
			this.angulartics2.eventTrack.next({
				action: 'Checkout',
				properties: {
					label: 'Checkout',
					currency: "EUR",
					content_type: 'product_group',
					"event":"Checkout",
					gtmCustom:{
						'ecommerce': {
							'purchase': undefined,
		          'click': undefined,
		          'add': undefined,
		          'detail': undefined,
		          'impressions': undefined,
		          'remove': undefined,
							'checkout': {
								'actionField': {'list': 'Checkout', 'step':step, 'option':option},    // 'detail'
								'products': galayer
							 }
						 }
					}
				}
			});

      // this.angulartics2.eventTrack.next({
      //   action: 'begin_checkout',
      //   properties: {
      //     'currency': 'EUR', 
      //     'value':this.cartTotal.total,    // 'detail'
      //     'items': galayerga4
      //   }
      // })
		}


	}


  openModalRecover(){
        this.modalRecoverRef = this.modalService.open(this.ModalPassword, {
            size: 'md',
            windowClass: 'modal-md'  ,
            hideCloseButton: false,
            centered: true,
            animation: true,
            keyboard: false,
            closeOnOutsideClick: false,
			backdropClass: 'modal-backdrop'
        });
    }

    closeModalRecover(){
        this.modalService.close(this.modalRecoverRef);
    }

  onSubmitSignin() {
    this.submittedSign = true;
    if (this.signinForm.invalid) {
      return;
    }
    var formData = new FormData();
    Object.keys(this.signinForm.controls).forEach(key => {
      if(this.signinForm.controls[key].value){
        formData.append("userweb["+key+"]", this.signinForm.controls[key].value.toString());
      }else{
        formData.append("userweb["+key+"]", "");
      }
    });
    this.apiService.signin(formData, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.submittedSign = false;
        this.localstorageService.set("userLogging", true);
        this.localstorageService.set("userLoggingName", obj.info);
        this.sessionService.sendsession(true, obj.info.name);
        this.sharedService.sendClickEvent(this.translate.currentLang);
        this.signinForm.controls["email"].setValue("");
        this.signinForm.controls["password"].setValue("");
        this.getUserInfo(1);
        this.wishlistService.send();
        this.modalService.close(this.modalSignIn);
      }else if(obj.code==302){   }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

  closeModalSignIn(){
    this.modalService.close(this.modalSignIn);
  }

  signInFB(){
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }


  getUserInfo(op:any){

    this.apiService.getUserInfo(this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(!this.localstorageService.get("userLogging")){
          this.apiService.logoutuserweb().subscribe((obj: any)=>{});
          return;
        }
        this.emailCheck = true;
        this.loadUser = true;
        this.userInfo=obj.info;
        let first=this.userInfo.name;
        first = this.userInfo.name.substr(0,this.userInfo.name.indexOf(' '));
        let last = this.userInfo.name.substr(this.userInfo.name.indexOf(' ')+1);
        if(this.checkoutForm.controls['firstname'].value=="" || op==0){
          this.checkoutForm.controls['firstname'].setValue(first);
        }
        if(this.checkoutForm.controls['lastname'].value=="" || op==0){
          this.checkoutForm.controls['lastname'].setValue(last);
        }
        if(this.checkoutForm.controls['address'].value=="" || op==0){
          this.checkoutForm.controls['address'].setValue(this.userInfo.address);
        }
        this.checkoutForm.controls['address2'].setValue(this.userInfo.address2);
        if(this.checkoutForm.controls['postalcode'].value=="" || op==0){
          this.checkoutForm.controls['postalcode'].setValue(this.userInfo.postalcode);
        }
        if(this.checkoutForm.controls['locality'].value=="" || op==0){
          this.checkoutForm.controls['locality'].setValue(this.userInfo.locality);
        }
        if(this.userInfo.nif){ this.userInfo.nif.trim() }
        this.checkoutForm.controls['nif'].setValue(this.userInfo.nif);
        let countryclient:any="0";
        let changec=false;
        if(op==0){
          changec=true;
          if(this.userInfo.idregion){
            this.checkoutForm.controls['idcountry'].setValue(this.userInfo.idcountry+"-"+this.userInfo.idregion);
              countryclient = this.userInfo.idcountry+"-"+this.userInfo.idregion;
          }else{
            this.checkoutForm.controls['idcountry'].setValue(this.userInfo.idcountry);
            countryclient = this.userInfo.idcountry;
          }
        }else{
          if(this.userInfo.idregion){
              countryclient = this.userInfo.idcountry+"-"+this.userInfo.idregion;
          }else{
            countryclient = this.userInfo.idcountry;
          }
          if(this.checkoutForm.controls['idcountry'].value!=countryclient){
            changec=true;
            this.checkoutForm.controls['idcountry'].setValue(countryclient);
          }
        }

        this.checkoutForm.controls['email'].setValue(this.userInfo.email);
        this.checkoutForm.controls['telephone_callingcode'].setValue(this.userInfo.telephone_callingcode);
        this.checkoutForm.controls['telephone'].setValue(this.userInfo.telephone);

        if(changec){
          this.apiService.getShippings(countryclient, countryclient, this.userInfo.address, this.userInfo.postalcode, this.userInfo.locality).subscribe((obj: any)=>{
            if(typeof obj!=="undefined" && obj.code==200){
              this.shippingInfo = obj.info;
              if(this.shippingInfo[0]){
                this.selectShippingmode = this.shippingInfo[0].idshippingmode;
              }
            }else{
              this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
            }
          });
        }

      }else if(obj.code==302){   }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }


  ngOnInit(): void {


    this.selectShippingcompany=null;
    this.pickupShipping=0;
    this.selectShippingmode=null;
    this.selectPayment=null;
	  this.metatags(this.translate.currentLang);
    this.getCountry();
    this.getCallingcode();
    this.checkboxReplicate=0;
    this.checkboxPassword=1;


    this.getUserInfo(0);



	this.optionsCalling = {
      templateSelection: function (data, container) {
		return "+"+data.text;
	 },
      templateResult: function (data, container) {
		return "+"+data.text;
	 }
    };

  }

  public setTitle( newTitle: string) {
	  this.titleService.setTitle( newTitle  );
    this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(4,9,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title && obj.title.content){

          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }


        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }


  ngAfterViewInit(){
    if(!this.cart){
      this.getCart(0);
      this.getCompany();
      this.getPresentation(this.translate.currentLang);
    }else{
			this.sendinitialcheckout("1", "");
		}
  }



  sendinitialpurchase(obj): void {

		let desc:any= "";
		let cat:any = "";
		let brand:any = "";
		let color:any = "";
		let pval:any = "";
		let idcolor:any;
		let idsize:any;
		let galayer:any = [];
    let galayerga4:any = [];

		for (let index = 0; index < this.cart.length; index++) {

			if(this.translate.currentLang && this.cart[index].langs[this.translate.currentLang]){
				desc = this.cart[index].langs[this.translate.currentLang].description;
			}else{
				desc = this.cart[index].description;
			}
			if(this.translate.currentLang && this.cart[index].catlangs && this.cart[index].catlangs[this.translate.currentLang] && this.cart[index].catlangs[this.translate.currentLang].description) {
				cat = this.cart[index].catlangs[this.translate.currentLang].description;
			}
			brand = (this.cart[index].brandName) ? this.cart[index].brandName : 'Brandsibuy';
			if(this.cart[index].itemwithattr==1){
				idcolor = Object.keys(this.cart[index].colors)[0];
				idsize = Object.keys(this.cart[index].colors[idcolor])[0];
				color = this.cart[index].colors[idcolor][idsize].name;
				if(this.cart[index].colors[idcolor][idsize].priceFinal) { pval = this.cart[index].colors[idcolor][idsize].priceFinal; }
			}else{
				pval = this.cart[index].priceFinal;
			}
			galayer.push({
				'id': this.cart[index].iditem,
				'name': desc,
				'price': pval,
				'brand': brand,
				'quantity': this.cart[index].quantity,
				'category': cat,
				'variant': color
			 });
       galayerga4.push({
				'item_id': this.cart[index].iditem,
				'item_name': desc,
				'price': pval,
				'item_brand': brand,
				'quantity': parseInt(this.cart[index].quantity),
				'item_category': cat,
				'item_variant': color,
      });
		}

    this.angulartics2GAEcommerce.ecSetAction("purchase" , {'id': obj.number, 'revenue':obj.total, 'shipping':this.shippingTotal});
		this.angulartics2.eventTrack.next({
			action: 'Purchase',
			properties: {
				label: 'Purchase',
				currency: "EUR",
				content_type: 'product_group',
				"event":"Purchase",
				gtmCustom:{
					'ecommerce': {
	          'click': undefined,
	          'add': undefined,
	          'checkout': undefined,
	          'detail': undefined,
	          'impressions': undefined,
	          'remove': undefined,
						'purchase': {
							'actionField': {'id': obj.number, 'revenue':obj.total, 'shipping':this.shippingTotal},    // 'detail'
							'products': galayer
						 }
					 }
				}
			}
		});

    this.angulartics2.eventTrack.next({
      action: 'purchase',
      properties: {
        'currency':'EUR', 'transaction_id': obj.number, 'value':obj.total, 'shipping':this.shippingTotal,    // 'detail'
        'items': galayerga4,
       }
    })
	}

  getCart(change: any){
    if(change==0){
      this.apiService.getCart(1).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          if(obj.info){
            this.cart = obj.info;
            this.cartTotal = obj.calc.values.summary;
            this.sendinitialcheckout("1", "");
            if(obj.shippingCost){
              this.shippingTotal = obj.shippingCost;
            }else{
              this.shippingTotal = 0;
            }
            if(obj.vouchers){
              this.vouchersArr = obj.vouchers;
            }else{
              this.vouchersArr = [];
            }
          }
        }else{
          this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }
  }

  getCompany(){
    this.apiService.readCompany(0, "").subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.companyInfo = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getPresentation(lang:String){
    this.apiService.getPresentation(lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.companyPresentation = obj.info;
        if(this.companyPresentation.email_support && this.companyPresentation.email_support != ''){
          this.email_support = this.companyPresentation.email_support;
        }
        else{
          if(this.companyPresentation.email && this.companyPresentation.email != ''){
            this.email_support = this.companyPresentation.email
          }
          else{
            this.email_support = '';
          }
        }
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }

  getCountry(){
    this.apiService.getCountry().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.countries = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  getCallingcode(){
    this.apiService.getCallingcode().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.telephone_callingcode = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  cartImg(item:any){
    if(item.itemwithattr==1){
        let color = Object.keys(item.colors)[0];
        let key = Object.keys(item.colors[color])[0];
        if(this.browserwebp && item.colors[color][key].images[0].webp){
          return this.apiService.imagesDir+item.colors[color][key].images[0].webp;
        }
        return this.apiService.imagesDir+item.colors[color][key].images[0].photo;
    }else{
        if(this.browserwebp && item.images[0].webp){
          return this.apiService.imagesDir+item.images[0].webp;
        }
        return this.apiService.imagesDir+item.images[0].photo;
    }
  }

  cartDesc(ct:any){
    if(ct.itemwithattr==1){
      let color = Object.keys(ct.colors)[0];
      let size = Object.keys(ct.colors[color])[0];
      return ct.langs[this.translate.currentLang].description+" - " + ct.colors[color][size].langs[this.translate.currentLang].name + " - "+ct.colors[color][size].number;
    }else{
      return ct.langs[this.translate.currentLang].description;
    }
  }

  cartPrice(item:any, quantity:any ,op:Number){
    if(op==1){
      if(item.itemwithattr==1){
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          let price = item.colors[color][key].priceOriginal * quantity;
          return price.toString().replace(".", ",");
      }else{
        let price = item.priceOriginal * quantity;
        return price.toString().replace(".", ",");
      }
    }else{
      if(item.itemwithattr==1){
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          let price = item.colors[color][key].priceFinal *quantity;
          return price.toString().replace(".", ",");
      }else{
        let price = item.priceFinal;
        return price.toString().replace(".", ",");
      }
    }
  }

  cartPriceTxt(item:any, op:Number){
    if(op==1){
      if(item.itemwithattr==1){
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          let price = item.colors[color][key].priceOriginalTxt.toString().replace(".", ",");
          return price;
      }else{
        return item.priceOriginalTxt.replace(".", ",");
      }
    }else{
      if(item.itemwithattr==1){
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceFinalTxt.toString().replace(".", ",");
      }else{
        return item.priceFinalTxt.toString().replace(".", ",");
      }
    }
  }


  removeItemCart(ct:any, i:any){
    let arr:any=[];
      let obji:any = {} ;
    if(ct.itemwithattr==1){
      let color = Object.keys(ct.colors)[0];
      let size = Object.keys(ct.colors[color])[0];
      obji.idcolor = color;
      obji.idsize = size;
      obji.iditem = ct.iditem;
    }else{
      obji.iditem = ct.iditem;
    }


    let desc:any= "";
		let cat:any = "";
		let brand:any = "";
		let color:any = "";
		let pval:any = "";


		if(this.translate.currentLang && ct.langs[this.translate.currentLang]){
			desc = ct.langs[this.translate.currentLang].description;
		}else{
			desc = ct.description;
		}
		if(this.translate.currentLang && ct.catlangs && ct.catlangs[this.translate.currentLang] && ct.catlangs[this.translate.currentLang].description) {
			cat = ct.catlangs[this.translate.currentLang].description;
		}
		brand = (ct.brandName) ? ct.brandName : '';

		if(ct && ct.itemwithattr==1){
			color = ct.colors[obji.idcolor][obji.idsize].name;
			if(ct.colors[obji.idcolor][obji.idsize].priceFinal) { pval = ct.colors[obji.idcolor][obji.idsize].priceFinal; }
		}else{
			pval = ct.priceFinal;
		}


    arr.push(obji);
    this.apiService.removeCart(arr).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
         this.sharedService.sendClickEvent(this.translate.currentLang);


         this.angulartics2GAEcommerce.ecAddProduct({
              'id': ct.iditem,
              'name': desc,
              'category': cat,
              'brand': brand,
              'variant': color,
              'price': pval,
              'quantity': ct.quantity,
              'position': 0
            });
            this.angulartics2GAEcommerce.ecSetAction("remove", {});

            this.angulartics2.eventTrack.next({
      				action: 'removeFromCart',
      				properties: {
                label: 'removeFromCart',
      					currency: "EUR",
      					content_type: 'product_group',
                "event":"removeFromCart",
                gtmCustom:{
                  'ecommerce': {
										'purchase': undefined,
					          'click': undefined,
					          'add': undefined,
					          'checkout': undefined,
					          'detail': undefined,
					          'impressions': undefined,
                    'remove': {
                      'actionField': {'list': 'removeFromCart'},    // 'detail'
                      'products': [
                        {
                          'id': ct.iditem,
                          'name': desc,
                          'price': pval,
                          'brand': brand,
                          'category': cat,
                          'variant': color,
                          'quantity': ct.quantity
                         }
                      ]
                     }
                   }
                }
      				}
      			});
            this.angulartics2.eventTrack.next({
              action: 'remove_from_cart',
              properties: {
                'remove_from_cart': {
                  'currency': 'EUR', 'value':pval,    // 'detail'
                  'items': [
                    {
                      'item_id': ct.iditem,
                      'item_name': desc,
                      'price': pval,
                      'item_brand': brand,
                      'item_category': cat,
                      'item_variant': color,
                      'quantity': ct.quantity
                     }
                  ]
                 }
              }
            })

      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }


  descProd(item){
    if(item.quantity>1){
      let arr:any=[];
      let obj: any = {};
      if(item && item.itemwithattr==1){
        obj.idcolor = item.idcolor;
        obj.idsize = item.idsize;
        obj.fullcode = item.code+"-"+obj.idcolor+"-"+obj.idsize;
        obj.idcolor_integration = item.colors[item.idcolor][item.idsize].idcolor_integration ;
        obj.idsize_integration = item.colors[item.idcolor][item.idsize].idsize_integration ;
      }else{
        obj.fullcode = item.code;
      }
      obj.iditem = item.iditem;
      obj.idmanager_company = item.idmanager_company;
      obj.iditem_integration = item.iditem_integration;
      obj.quantitycomplete = 1;
      obj.quantity = Number(item.quantity)-1;
      arr.push(obj);

      let desc:any= "";
	    let cat:any = "";
	    let brand:any = "";
	    let color:any = "";
	    let pval:any = "";

	    if(this.translate.currentLang && item.langs[this.translate.currentLang]){
	      desc = item.langs[this.translate.currentLang].description;
	    }else{
	      desc = item.description;
	    }
	    if(this.translate.currentLang && item.catlangs && item.catlangs[this.translate.currentLang] && item.catlangs[this.translate.currentLang].description) {
	      cat = item.catlangs[this.translate.currentLang].description;
	    }
	    brand = (item.brandName) ? item.brandName : '';

			if(item && item.itemwithattr==1){
				color = item.colors[item.idcolor][item.idsize].name;
				if(item.colors[item.idcolor][item.idsize].priceFinal) { pval = item.colors[item.idcolor][item.idsize].priceFinal; }
			}else{
				pval = item.priceFinal;
			}


      this.apiService.addCart(arr).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          this.sharedService.sendClickEvent(this.translate.currentLang);


          this.angulartics2GAEcommerce.ecAddProduct({
	              'id': item.iditem,
	              'name': desc,
	              'category': cat,
	              'brand': brand,
	              'variant': color,
	              'price': pval,
	              'quantity': 1,
	              'position': 0
	            });
	            this.angulartics2GAEcommerce.ecSetAction("remove", {});

	            this.angulartics2.eventTrack.next({
	      				action: 'removeFromCart',
	      				properties: {
	                label: 'removeFromCart',
	      					currency: "EUR",
	      					content_type: 'product_group',
	                "event":"removeFromCart",
	                gtmCustom:{
	                  'ecommerce': {
											'purchase': undefined,
						          'click': undefined,
						          'add': undefined,
						          'checkout': undefined,
						          'detail': undefined,
						          'impressions': undefined,
	                    'remove': {
	                      'actionField': {'list': 'removeFromCart'},    // 'detail'
	                      'products': [
	                        {
	                          'id': item.iditem,
	                          'name': desc,
	                          'price': pval,
	                          'brand': brand,
	                          'category': cat,
	                          'variant': color,
	                          'quantity': 1
	                         }
	                      ]
	                     }
	                   }
	                }
	      				}
	      			});
              this.angulartics2.eventTrack.next({
                action: 'remove_from_cart',
	      				properties: {
                  'remove_from_cart': {
                    'currency': 'EUR', 'value':pval,    // 'detail'
                    'items': [
                      {
                        'item_id': item.iditem,
                        'item_name': desc,
                        'price': pval,
                        'item_brand': brand,
                        'item_category': cat,
                        'item_variant': color,
                        'quantity': 1
                       }
                    ]
                   }
                }
              })

        }else{
          this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        }
      });
    }
  }

  incProd(item){
    let arr:any=[];
    let obj: any = {};
    if(item && item.itemwithattr==1){
      obj.idcolor = item.idcolor;
      obj.idsize = item.idsize;
      obj.fullcode = item.code+"-"+obj.idcolor+"-"+obj.idsize;
      obj.idcolor_integration = item.colors[item.idcolor][item.idsize].idcolor_integration ;
      obj.idsize_integration = item.colors[item.idcolor][item.idsize].idsize_integration ;
    }else{
      obj.fullcode = item.code;
    }
    obj.iditem = item.iditem;
    obj.idmanager_company = item.idmanager_company;
    obj.iditem_integration = item.iditem_integration;
    obj.quantitycomplete = 1;
    obj.quantity = Number(item.quantity)+1;
    arr.push(obj);


    let desc:any= "";
    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";

    if(this.translate.currentLang && item.langs[this.translate.currentLang]){
      desc = item.langs[this.translate.currentLang].description;
    }else{
      desc = item.description;
    }
    if(this.translate.currentLang && item.catlangs && item.catlangs[this.translate.currentLang] && item.catlangs[this.translate.currentLang].description) {
      cat = item.catlangs[this.translate.currentLang].description;
    }
    brand = (item.brandName) ? item.brandName : '';

		if(item && item.itemwithattr==1){
			color = item.colors[item.idcolor][item.idsize].name;
			if(item.colors[item.idcolor][item.idsize].priceFinal) { pval = item.colors[item.idcolor][item.idsize].priceFinal; }
		}else{
			pval = item.priceFinal;
		}


    this.apiService.addCart(arr).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.sharedService.sendClickEvent(this.translate.currentLang);

        this.angulartics2GAEcommerce.ecAddProduct({
              'id': item.iditem,
              'name': desc,
              'category': cat,
              'brand': brand,
              'variant': color,
              'price': pval,
              'quantity': 1,
              'position': 0
            });
            this.angulartics2GAEcommerce.ecSetAction("add", {});

            this.angulartics2.eventTrack.next({
      				action: 'AddToCart',
      				properties: {
                label: 'AddToCart',
      					currency: "EUR",
      					content_type: 'product_group',
                "event":"AddToCart",
                gtmCustom:{
                  'ecommerce': {
										'purchase': undefined,
					          'click': undefined,
					          'checkout': undefined,
					          'detail': undefined,
					          'impressions': undefined,
					          'remove': undefined,
                    'add': {
                      'actionField': {'list': 'AddToCart'},    // 'detail'
                      'products': [
                        {
                          'id': item.iditem,
                          'name': desc,
                          'price': pval,
                          'brand': brand,
                          'category': cat,
                          'variant': color,
                          'quantity': 1
                         }
                      ]
                     }
                   }
                }
      				}
      			});

            this.angulartics2.eventTrack.next({
              action: 'add_to_cart',
      				properties: {
                'add_to_cart': {
                  'currency': 'EUR', 'value':pval ,    // 'detail'
                  'items': [
                    {
                      'item_id': item.iditem,
                      'item_name': desc,
                      'price': pval,
                      'item_brand': brand,
                      'item_category': cat,
                      'item_variant': color,
                      'quantity': 1
                     }
                  ]
                 }
              }
            })


      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }


  changecountry(){
    let countryclient  =  this.invoicecountry.nativeElement.value ;
    let country  = "";
    let address = "";
    let postalcode  = "";
    let locality = "";
    if(this.checkboxReplicate==1){
        country  =  this.shippingcountry.nativeElement.value ;
        address  =  this.shippingaddress.nativeElement.value ;
        postalcode  =  this.shippingpostalcode.nativeElement.value ;
        locality  =  this.shippinglocality.nativeElement.value ;
        if(country==""){
          country  =  this.invoicecountry.nativeElement.value ;
        }
    }else{
        country  =  this.invoicecountry.nativeElement.value ;
        address  =  this.invoiceaddress.nativeElement.value ;
        postalcode  =  this.invoicepostalcode.nativeElement.value ;
        locality  =  this.invoicelocality.nativeElement.value ;
    }

    this.apiService.getShippings(countryclient, country, address, postalcode, locality).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.shippingInfo = obj.info;
        if(this.shippingInfo[0]){
          this.selectShippingmode = this.shippingInfo[0].idshippingmode;
          if(this.selectPayment!=null){
            let entry = false;
            this.shippingInfo[0].payments.forEach(childObj=> {
               if(childObj.idpaymentwebsite==this.selectPayment){
                 entry = true;
               }
            });
            if(!entry){

              const hTMLInputElement = (document.getElementById("radioP"+this.selectPayment+"_"+this.selectShippingmode)) as HTMLInputElement;
          		if(hTMLInputElement!=null){
          			hTMLInputElement.checked = false;
          		}
              this.selectPayment=null;
            }
          }

        }


        this.selectShippingcompany=null;
        this.pickupShipping=0;
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });


  }

  autoCheck(){
    if(this.selectPayment!=null){
      $("#radioP"+this.selectPayment).prop("checked", true);
    }
    return "";
  }

  autoCheckShipp(){
    if(this.selectShippingcompany!=null){
      $("#radioC"+this.selectShippingcompany).prop("checked", true);
    }
    return "";
  }

  getShippingCosts(){
    let country = "";
    if(this.checkboxReplicate==1){
      country = this.shippingcountry.nativeElement.value ;
    }else{
      country = this.invoicecountry.nativeElement.value ;
    }


		let payment_sel = this.selectPayment;
		let el = document.getElementById("abbr_radioP"+this.selectPayment+"_"+this.selectShippingmode) as HTMLInputElement;
		if(el) { payment_sel = el.value; }


    this.apiService.getCartbyattr(country, this.selectShippingcompany, this.selectShippingmode, payment_sel).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.info){
          this.cartTotal = obj.calc.values.summary;
          this.cob_value = obj.priceCob;
          if(obj.shippingCost){
            this.shippingTotal = obj.shippingCost;
          }
          else {
            this.shippingTotal = 0;
          }
        }
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }



  getpostalcode(elem_country:any, elem_postalcode:any, elem_locality:any){
    if(this.checkoutForm.get(elem_country).value=="" || this.checkoutForm.get(elem_country).value==179 || this.checkoutForm.get(elem_country).value=="179-1" || this.checkoutForm.get(elem_country).value=="179-2"  || this.checkoutForm.get(elem_country).value=="179-3"){
      this.apiService.getpostalcode(this.checkoutForm.get(elem_postalcode).value).subscribe((obj: any)=>{
        if(this.checkoutForm.get(elem_country).value!=obj.regionid){
					//obj.code=201;
				}

        if(typeof obj!=="undefined" && obj.code==200){
          if(obj.regionid!=""){
  				      this.checkoutForm.controls[elem_country].setValue(obj.regionid) ;
          }
          this.checkoutForm.controls[elem_locality].setValue(obj.designation) ;
          if(obj.regionid!=""){
                this.changecountry();
          }
        }else{
          this.translate.get( 'msg_valCP' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }
  }


  addressShippingchange(){
    this.checkboxReplicate=!this.checkboxReplicate;
    this.selectShippingcompany=null;
    this.pickupShipping=0;
    this.changecountry();
  }

  changeShippingCompany(idshippingcompany:any, abbreviation:any){
    if(abbreviation=="CNP2"){this.pickupShipping=1;}else{this.pickupShipping=0;}



    this.selectShippingcompany=idshippingcompany;
    this.getShippingCosts();
  }

  searchPickups(codepostal ){
    let country = "";
    if(this.checkboxReplicate==1){
      country = this.shippingcountry.nativeElement.options[this.shippingcountry.nativeElement.selectedIndex].attr ;
    }else{
      country = this.invoicecountry.nativeElement.options[this.invoicecountry.nativeElement.selectedIndex].attr ;
    }
    country = country.split("-")[0];
    if(codepostal.value==""){
      let postalcode  = "";
      if(this.checkboxReplicate==1){
          postalcode  =  this.shippingpostalcode.nativeElement.value ;
      }else{
          postalcode  =  this.invoicepostalcode.nativeElement.value ;
      }
      let postalcode1 = postalcode.toString().split("-", 2);
      codepostal.value=postalcode1[0].toString();
      if(codepostal.value==""){
        this.translate.get( 'fill_in_postal' ).subscribe(translations => {  Swal.fire(translations);    });
        return;
      }
    }
    this.apiService.getPickups(country, "CNP2", codepostal.value).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.pickupShippingArr = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  addVoucher(voucherCode){
    if(voucherCode==""){
      return ;
    }
    if(this.checkoutForm.controls['email'].value==""){
      this.voucherError=1;
      const matchingControl = this.checkoutForm.controls['email'];
      matchingControl.setErrors({ required: true }); return ;
    }else{
      this.voucherError=0;
    }

    let country = "";
    if(this.checkboxReplicate==1){
      if(this.shippingcountry && this.shippingcountry.nativeElement.value){
        country = this.shippingcountry.nativeElement.value;
      }
      else{
        country = "";
      }
      if(country == ""){ country = this.invoicecountry.nativeElement.value;}
    }else{
      country = this.invoicecountry.nativeElement.value;
    }


    this.apiService.addVoucher(voucherCode, this.checkoutForm.controls['email'].value, this.selectShippingmode, this.selectShippingcompany, country).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){

        if(obj.info){
          if(!this.vouchersArr){
            this.vouchersArr=[];
          }
          //this.vouchersArr.push(obj.info);
          this.voucherCodeInput.nativeElement.value="";
          this.getCart(0);
        }
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  removeVoucher(index:any, idvoucher:any){
    this.apiService.removeVoucher(idvoucher).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.vouchersArr.splice(index, 1);
        this.getCart(0);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  buy(checkConditions, checkGIFT, checkGIFTTEXT){



    if(!checkConditions.checked){
        Swal.fire({title:this.translate.instant("ACCEPT_TERMS"), customClass: { container: 'swal-index' } });
      return;
    }

    if(this.waitingsubmit){
      return;
    }
    this.submitted = true;

    this.waitingsubmit=true;
    if (this.checkoutForm.invalid) {
      this.waitingsubmit=false;
      return;
    }

    if(this.checkboxReplicate==1){
			if(this.checkoutForm.get('shipping_idcountry').value=="179-1" && !(this.checkoutForm.get('shipping_postalcode').value.substr(0,4)>=9500 && this.checkoutForm.get('shipping_postalcode').value.substr(0,4)<=9995) ){
				this.waitingsubmit=false;
				this.translate.get( "msg_valCP" ).subscribe(translations => {
					Swal.fire({title:translations, customClass: { container: 'swal-index' } });
				});
				return;
			}else if(this.checkoutForm.get('shipping_idcountry').value=="179-2" && !(this.checkoutForm.get('shipping_postalcode').value.substr(0,4)>=9000  && this.checkoutForm.get('shipping_postalcode').value.substr(0,4)<=9495) ){
				this.waitingsubmit=false;
				this.translate.get( "msg_valCP" ).subscribe(translations => {
					Swal.fire({title:translations, customClass: { container: 'swal-index' } });
				});
				return;
			}else if(this.checkoutForm.get('shipping_idcountry').value=="179-3" && !(this.checkoutForm.get('shipping_postalcode').value.substr(0,4)>=1000 && this.checkoutForm.get('shipping_postalcode').value.substr(0,4)<=8995) ){
        this.waitingsubmit=false;
				this.translate.get( "msg_valCP" ).subscribe(translations => {
					Swal.fire({title:translations, customClass: { container: 'swal-index' } });
				});
				return;
      }
		}else{
      if(this.checkoutForm.get('idcountry').value=="179-1" && !(this.checkoutForm.get('postalcode').value.substr(0,4)>=9500 && this.checkoutForm.get('postalcode').value.substr(0,4)<=9995) ){
				this.waitingsubmit=false;
				this.translate.get( "msg_valCP" ).subscribe(translations => {
					Swal.fire({title:translations, customClass: { container: 'swal-index' } });
				});
				return;
			}else if(this.checkoutForm.get('idcountry').value=="179-2" && !(this.checkoutForm.get('postalcode').value.substr(0,4)>=9000  && this.checkoutForm.get('postalcode').value.substr(0,4)<=9495) ){
				this.waitingsubmit=false;
				this.translate.get( "msg_valCP" ).subscribe(translations => {
					Swal.fire({title:translations, customClass: { container: 'swal-index' } });
				});
				return;
			}else if(this.checkoutForm.get('idcountry').value=="179-3" && !(this.checkoutForm.get('postalcode').value.substr(0,4)>=1000 && this.checkoutForm.get('postalcode').value.substr(0,4)<=8995) ){
        this.waitingsubmit=false;
				this.translate.get( "msg_valCP" ).subscribe(translations => {
					Swal.fire({title:translations, customClass: { container: 'swal-index' } });
				});
				return;
      }
		}

    let re;
    re = /^[0-9]{9}$/;
    if(!re.test(this.checkoutForm.controls['telephone'].value.toString())  && (this.checkoutForm.controls['idcountry'].value.toString()=="179" || this.checkoutForm.controls['idcountry'].value.toString()=="179-1" || this.checkoutForm.controls['idcountry'].value.toString()=="179-2" || this.checkoutForm.controls['idcountry'].value.toString()=="179-3") ){
        this.translate.get( "msg_valTelephone" ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        this.waitingsubmit = false;
        return;
    }else{
      re = /^[0-9]{7,15}$/;
      if(!re.test(this.checkoutForm.controls['telephone'].value.toString())){
        this.translate.get( "msg_valTelephone" ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        this.waitingsubmit = false;
        return;
      }
    }

    if(this.pickupShipping==1 && this.pickupShippingIndex<0){
      this.waitingsubmit=false;
      this.translate.get( "choise_pickup_point" ).subscribe(translations => {
        Swal.fire({title:translations, customClass: { container: 'swal-index' } });
      });
      this.waitingsubmit=false;
      return;
    }
    if(this.selectShippingmode==1 && this.selectShippingcompany<=0){
      this.waitingsubmit=false;
      this.translate.get( "choise_shippingcompany" ).subscribe(translations => {
        Swal.fire({title:translations, customClass: { container: 'swal-index' } });
      });
      return;
    }

    let option_2 = this.selectPayment;
		let el2 = document.getElementById("abbr_radioP"+this.selectPayment+"_"+this.selectShippingmode) as HTMLInputElement;
		if(el2) { option_2 = el2.value; }

    if(option_2=="MBW" && !document.getElementById("telephone_mbw_"+this.selectShippingmode)){
        Swal.fire({title:this.translate.instant("INSERT_VALID_MBWAY"), customClass: { container: 'swal-index' } });
      this.waitingsubmit=false;
      return;
    }

    var formData = new FormData();

    if(checkGIFT.checked){
      formData.append("checkout[gift]", "1");
      formData.append("checkout[gift_text]", checkGIFTTEXT.value);
    }

    if(option_2=="MBW" && document.getElementById("telephone_mbw_"+this.selectShippingmode)){
      let valueMBW = (<HTMLInputElement>document.getElementById("telephone_mbw_"+this.selectShippingmode)).value;
        if(valueMBW.match(/^[0-9]{9}$/)==null){

            Swal.fire({title:this.translate.instant("INSERT_VALID_MBWAY"), customClass: { container: 'swal-index' } });
          this.waitingsubmit=false;
          return;
        }
        formData.append("checkout[telephone_mbw]", valueMBW.toString());
    }

    if(this.selectShippingmode==1 && this.selectShippingcompany==null){
			this.waitingsubmit=false;
			Swal.fire({title:this.translate.instant( "choisepaymentmean_shippingcompany" ), customClass: { container: 'swal-index' } });
			return;
		}


    this.hiddenLoad=false;

    Object.keys(this.checkoutForm.controls).forEach(key => {
      if(this.checkoutForm.controls[key].value){
        formData.append("checkout["+key+"]", this.checkoutForm.controls[key].value.toString());
      }else{
        formData.append("checkout["+key+"]", "");
      }
    });
    if(this.checkoutForm.controls['password'].value.toString()==""){
      formData.append("passworddef", "0");
    }else{
      formData.append("passworddef", (this.checkboxPassword ? 1 : 0).toString());
    }
    formData.append("addressshipisdif", (this.checkboxReplicate ? 1 : 0).toString());

    formData.append("shippingmode", this.selectShippingmode);
    formData.append("shippingcompany", this.selectShippingcompany);
    formData.append("payment", this.selectPayment);
    if(this.vouchersArr){
      this.vouchersArr.forEach( (myObject, index) => {
         formData.append("voucher["+index+"]", myObject.idvoucher);
      });
    }
    if(this.pickupShipping==1){
      formData.append("pickup", this.pickupShipping);
      Object.keys(this.pickupShippingArr[this.pickupShippingIndex]).forEach(key => {
        formData.append("pickupInfo["+key+"]", this.pickupShippingArr[this.pickupShippingIndex][key]);
      });

    }

    let option_ = this.selectPayment;
		let el = document.getElementById("text_radioP"+this.selectPayment+"_"+this.selectShippingmode) as HTMLInputElement;
		if(el) { option_ = el.value; }
    this.sendinitialcheckout("2", option_);


       option_ = "";
    this.apiService.buy(formData, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){


        if(obj.abbr_payment!="CC" && obj.abbr_payment!="PYPL"){
					this.sendinitialpurchase(obj);
				}


        this.submitted = false;


        let storedNames = JSON.parse(this.localstorageService.get("orderspc"));
        if(!storedNames){
          storedNames = [];
        }
        storedNames.push(obj.id.toString());
        this.localstorageService.set('orderspc', JSON.stringify(storedNames));
        if(obj.newUrl && obj.newUrl==1){
          window.location.href = obj.newUrlDesc;
        }else{
          this.hiddenLoad=true;
          this.sharedService.sendClickEvent(this.translate.currentLang);
          this.route.navigate([this.localize.translateRoute('/showorder'),  obj.id ] );
        }
      }else if(typeof obj!=="undefined" && obj.code==202){

        if(obj.abbr_payment!="CC" || obj.abbr_payment!="PYPL"){
					this.sendinitialpurchase(obj);
				}

        this.hiddenLoad=true;
        this.sharedService.sendClickEvent(this.translate.currentLang);
        this.route.navigate([this.localize.translateRoute('/showorder'),  obj.id ] );
      }else{

        let html="";
				if(obj.msg=="notcreatedorderdropshippingstock"){
					this.sharedService.sendClickEvent(this.translate.currentLang);
					if(obj.cartremove && obj.cartremove.delete && obj.cartremove.delete.length){
						html += "<br><b>"+this.translate.instant( 'REMOVED_PRODUCTS' )+"</b><br>";
						for (let index = 0; index < obj.cartremove.delete.length; index++) {
							html += obj.cartremove.delete[index] + "<br>";
						}
						html += "";
					}
					if(obj.cartremove && obj.cartremove.change && obj.cartremove.change.length){
						html += "<br><b>"+this.translate.instant( 'CHANGE_QUANTITY_PRODUCTS' )+"</b><br>";
						for (let index = 0; index < obj.cartremove.change.length; index++) {
							html += obj.cartremove.change[index] + "<br>";
						}
						html += "";
					}
				}

        this.waitingsubmit=false;
        this.submitted = false;
        if(obj.noitem){
          this.sharedService.sendClickEvent(this.translate.currentLang);
        }
        this.translate.get( obj.msg ).subscribe(translations => { this.hiddenLoad=true; if(obj.msg=="notcreatedorder"){ translations = translations.replace(/:1:/, this.email_support );  }  Swal.fire({title:translations, html:html, customClass: { container: 'swal-index' } });    });
      }
    }, (error:any)=>{ this.waitingsubmit=false; this.hiddenLoad=true;
    this.submitted = false; });
  }

  changeEmail(){
    if(this.checkoutForm.controls['email'].value!="" && !this.userInfo){
      this.apiService.emailCheck(this.checkoutForm.controls['email'].value).subscribe((obj: any)=>{
        if(obj.code==200){
          this.emailCheck=true;
        }else{
          this.emailCheck=false;
        }
      });
    }
    if(this.vouchersArr && this.vouchersArr.length>0){
      this.apiService.removeVoucher("").subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          this.vouchersArr=[];
          this.getCart(0);
        }else{
          this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }
  }


  recoverPassch(){
    this.apiService.recoverPass(this.emailrecoverPass.nativeElement.value.toString(), this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.translate.get( obj.msg ).subscribe(translations => {
        Swal.fire({
          position: 'top-end',
          customClass: {
            container: 'swal-index'
          },
          icon: 'success',
          title: translations,
          showConfirmButton: false,
          timer: 2000
        });
        });
        this.closeModalRecover();
        this.emailrecoverPass.nativeElement.value="";
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

  openModalSignIn(){
    this.modalRecoverRef = this.modalService.open(this.modalSignIn, {
        size: 'md',
        windowClass: 'modal-md'  ,
        hideCloseButton: false,
        centered: true,
        animation: true,
        keyboard: false,
        closeOnOutsideClick: true,
        backdropClass: 'modal-backdrop'
    });
}

}
