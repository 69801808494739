<div class="container  text-center">
	<div class="fourOhFour c-margin-t-50">
		<img src="assets/images/404.png" />
		<div class="clearfix"></div>
		<br/>
		<h1>{{ 'Oops' | translate }}</h1>


		<h3 class="pt">{{ 'pagenotfound' | translate }}</h3>
		<h4>{{ 'pagenotfounddescription' | translate }}</h4>
		<br/>
		<h4><a class="btn c-font-black-hover c-btn-uppercase c-btn-bold c-btn-border-1x c-btn-square c-theme-btn" [routerLink]="(['/'] | localize)">{{ 'goback' | translate }}</a></h4>
		<br/>
	</div>
</div>
