import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { Options } from 'select2';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MustMatch, NifValidatorPT } from '../must-match.validator';
import { Title, Meta }     from '@angular/platform-browser';
import { SeoService } from '../seo.service';
import {   ActivatedRoute   } from '@angular/router';
import { TitlechangeService } from '../titlechange.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm:FormGroup;
  resolveCaptcha:any;
  submitted = false;
  countries:any;
  subscribe:any;
  telephone_callingcode:any;
  optionsCalling: Options;


  constructor(public titlechangeService:TitlechangeService,private router: ActivatedRoute,private seoService: SeoService, private metaService: Meta, public titleService: Title, public apiService: ApiService, public translate: TranslateService, private formBuilder: FormBuilder) {


    this.registerForm = this.formBuilder.group({
      firstname: new FormControl('',[  Validators.required]),
      lastname: new FormControl('',[  Validators.required]),
      address: new FormControl(''),
      address2: new FormControl('' ),
      postalcode: new FormControl(''),
      locality: new FormControl(''),
      idcountry: new FormControl('', [Validators.required]),
      nif: new FormControl(''  ),
      newsletter: new FormControl('' ),
      telephone_callingcode: new FormControl(''),
      telephone: new FormControl('' ),
      email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")  ] ),
      password: ['', [Validators.required, Validators.minLength(4)]],
      confirmPassword: ['', Validators.required]

    }, {
            validator: [MustMatch('password', 'confirmPassword'), NifValidatorPT('idcountry', 'nif')]
        });


	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{

        this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/register' );

      this.metatags(lang.lang);
    });
  }



  get f() { return this.registerForm.controls; }

  ngOnInit(): void {
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/register' );
    this.seoService.clearAlternateUrl();
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/register', 'x-default');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/register', 'pt');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/register', 'en');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/es/register', 'es');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/register', 'fr');

	  this.metatags(this.translate.currentLang);
    this.getCountry();
    this.getCallingcode();

	this.optionsCalling = {
      templateSelection: function (data, container) {
		return "+"+data.text;
	 },
      templateResult: function (data, container) {
		return "+"+data.text;
	 }
    };
  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag('rel=canonical');
    this.seoService.removeTag('rel=alternate');
  }

  public setTitle( newTitle: string) {
	  this.titleService.setTitle( newTitle);
    this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(2,9,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }
      /*  Object.keys(obj.info).forEach(key => {
          this.metaService.removeTag('name="'+obj.info[key].name+'"');
        });*/
        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }


  getCountry(){
    this.apiService.getCountry().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.countries = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  getCallingcode(){
    this.apiService.getCallingcode().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.telephone_callingcode = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  resolved(captchaResponse: string) {
    this.resolveCaptcha = captchaResponse;
  }

  onSubmit(captchaElem) {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    if(!this.resolveCaptcha){
      return;
    }


    if(!this.registerForm.get('email').value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
      this.translate.get( "msg_valEmail" ).subscribe(translations => {
        Swal.fire({title:translations, customClass: { container: 'swal-index' } });
      });
      return;
    }

    if( this.registerForm.get('idcountry').value=="179-1" && !(this.registerForm.get('postalcode').value.substr(0,4)>=9500 && this.registerForm.get('postalcode').value.substr(0,4)<=9995) ){
				this.translate.get( "msg_valCP" ).subscribe(translations => {
					Swal.fire({title:translations, customClass: { container: 'swal-index' } });
				});
				return;
			}else if( this.registerForm.get('idcountry').value=="179-2" && !(this.registerForm.get('postalcode').value.substr(0,4)>=9000 && this.registerForm.get('postalcode').value.substr(0,4)<=9495) ){
  				this.translate.get( "msg_valCP" ).subscribe(translations => {
  					Swal.fire({title:translations, customClass: { container: 'swal-index' } });
  				});
  				return;
  			}else if(this.registerForm.get('idcountry').value=="179-3" && !(this.registerForm.get('postalcode').value.substr(0,4)>=1000 && this.registerForm.get('postalcode').value.substr(0,4)<=8995) ){

  				this.translate.get( "msg_valCP" ).subscribe(translations => {
  					Swal.fire({title:translations, customClass: { container: 'swal-index' } });
  				});
  				return;
        }
    let re;
    re = /^[0-9]{9}$/;
    if(!re.test(this.registerForm.controls['telephone'].value.toString())  && (this.registerForm.controls['idcountry'].value.toString()=="179" || this.registerForm.controls['idcountry'].value.toString()=="179-1" || this.registerForm.controls['idcountry'].value.toString()=="179-2" || this.registerForm.controls['idcountry'].value.toString()=="179-3") ){
        this.translate.get( "msg_valTelephone" ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        this.submitted = false;
        return;
    }else{
      re = /^[0-9]{7,15}$/;
      if(!re.test(this.registerForm.controls['telephone'].value.toString())){
        this.translate.get( "msg_valTelephone" ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        this.submitted = false;
        return;
      }
    }
    var formData = new FormData();
    formData.append("captcha", this.resolveCaptcha.toString());
    Object.keys(this.registerForm.controls).forEach(key => {
      if(this.registerForm.controls[key].value){
        formData.append("userweb["+key+"]", this.registerForm.controls[key].value.toString());
      }else{
        formData.append("userweb["+key+"]", "");
      }
    });
    this.apiService.createuserweb(formData, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.translate.get( obj.msg ).subscribe(translations => {
          this.submitted = false;
          captchaElem.reset();
          this.registerForm.reset();
          this.registerForm.controls['nif'].setValue("");
          this.registerForm.controls['telephone_callingcode'].setValue("351");
        Swal.fire({
          position: 'top-end',
          customClass: {
            container: 'swal-index'
          },
          icon: 'success',
          title: translations,
          showConfirmButton: false,
          timer: 2000
        });
        });
      }else{
        captchaElem.reset();
        this.resolveCaptcha = null;
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }


  getpostalcode(){
    if(this.registerForm.get('idcountry').value=="" || this.registerForm.get('idcountry').value==179 || this.registerForm.get('idcountry').value=="179-1" || this.registerForm.get('idcountry').value=="179-2"  || this.registerForm.get('idcountry').value=="179-3"){
      this.apiService.getpostalcode(this.registerForm.get('postalcode').value).subscribe((obj: any)=>{
        if(this.registerForm.get('idcountry').value!="" && this.registerForm.get('idcountry').value!=obj.regionid){
					//obj.code=201;
				}

        if(typeof obj!=="undefined" && obj.code==200){
          if(obj.regionid!=""){
            this.registerForm.controls['idcountry'].setValue(obj.regionid) ;
          }
          this.registerForm.controls['locality'].setValue(obj.designation) ;
        }else{
          this.translate.get( 'msg_valCP' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }
  }





}
