import { Component, OnInit , ViewChild} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import Swal from 'sweetalert2'
 import { SharedService } from '../shared.service';
import { HostListener } from '@angular/core';
import { LocalstorageService } from '../localstorage.service';
import { Title, Meta }     from '@angular/platform-browser';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { Angulartics2 } from 'angulartics2';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UrlpipePipe } from '../urlpipe.pipe';
import { LowerCasePipe } from '@angular/common';
import { SeoService } from '../seo.service';
import { OwlOptions  } from 'ngx-owl-carousel-o';
import { CheckwebpService } from '../checkwebp.service';
import { ModalManager } from 'ngb-modal';
import { SessionService } from '../session.service';
import { WishlistService } from '../wishlist.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import {DomSanitizer} from '@angular/platform-browser';
import { Location } from '@angular/common';

import { TitlechangeService } from '../titlechange.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  _window:any;
  @ViewChild('ModalDetail') ModalDetail;
  breadcrumbs:Array<Object> = [];
  iditem:any;idcolor:any;idsize:any;
  countParams:any;
  item:any;
  qtd:any;
  qtdInitial:any;
  subscribe:any;
  clickEventsubscriptionSession:any;

  objectKeys = Object.keys;

  public relateditemarr: any = [];

  selectItem:any;
  modalDetailRef:any;

  carouselOptionsDetail: OwlOptions = {
      loop:true,
      margin:10,

      autoWidth: true,
      nav : true,
      navText: [' <img style="width:100%" class="imgCarrousel" alt="<" src="assets/images/lefticon2.png"> ', ' <img alt=">" class="imgCarrousel" style="width:100%" src="assets/images/righticon2.png"> '],
      autoplay: true,
      items: 1
  }
  subscribe2:any;
  browserwebp:any;
    breadcumbschange:any=false;

    selectRelatedColor:any;
    selectRelatedSize:any;
    defaultImage:any;
    userLogging:any;
    deviceDesktop:any;
    urlProd:any;
    navigationId:any;
    entryOne:any;
  constructor(public titlechangeService:TitlechangeService, public location: Location, private sanitizer:DomSanitizer, public deviceService: DeviceDetectorService, public wishlistService:WishlistService, public sessionService: SessionService,public modalService: ModalManager, private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, public checkwebp: CheckwebpService,private seoService: SeoService, private lowercase:LowerCasePipe, private urlpipePipe:UrlpipePipe, public localize: LocalizeRouterService, private angulartics2: Angulartics2, private metaService: Meta, public titleService: Title, public localstorageService: LocalstorageService,  public apiService: ApiService, public translate: TranslateService, private route: ActivatedRoute, public router: Router, public sharedService: SharedService) {
    this._window=window;
    this.browserwebp = this.checkwebp.canUseWebP();
    this.navigationId=1;
  /*  if(this.location && (this.location.getState()) ){
      this.navigationId=(this.location.getState())['navigationId'];
    }*/
    this.entryOne=false;
    if(this.browserwebp){
      this.defaultImage = "assets/images/img_default.webp";
    }else{
      this.defaultImage = "assets/images/img_default.png";
    }
    this.urlProd="";
    this.deviceDesktop=false;
    const isDesktopDevice = this.deviceService.isDesktop();
    if(isDesktopDevice){
      this.deviceDesktop=true;
    }

    this.selectRelatedColor = [];
    this.selectRelatedSize = [];


    if(this.localstorageService.get("userLogging")){
      this.userLogging=this.localstorageService.get("userLogging");
    }

    this.clickEventsubscriptionSession =    this.sessionService.getsessionEvent().subscribe((obj)=>{
      this.userLogging = obj[0];
    });

    //this.iditem = this.route.snapshot.paramMap.get('id');
    this.idcolor = this.route.snapshot.paramMap.get('color');
    this.idsize = this.route.snapshot.paramMap.get('size');

    if(this.route.snapshot.paramMap.get('description')){
      let splitS = this.route.snapshot.paramMap.get('description').split("-") ;
        this.iditem = parseInt(splitS[splitS.length-1]);
    }

    if(!this.iditem || this.iditem==0){
      this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
    }

    this.qtd=1;
  /*  this.breadcrumbsLoad(breadcrumbs);*/
    this.subscribe2= this.route.params.subscribe(params => {
      let a:any = false;

      let splitS = params.description.split("-") ;
      let id = parseInt(splitS[splitS.length-1]);

      if(this.iditem==id  ){
        a=true;
      }
      this.iditem = id;
      if(this.iditem!=id){
        this.idcolor = null;
        this.idsize = null;
      }


      this.breadcumbschange=true;

      if(this.countParams==Object.keys(params).length && !a){ this.entryOne=false;
        this.ngOnInit();
      }
      this.countParams = Object.keys(params).length;
    });

	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
      if(this.item && this.item.langs && this.item.langs[lang.lang].description){
        this.route.snapshot.url[1].path = this.item.langs[lang.lang].url+"-"+this.item.iditem ;
      }


      this.urlProd=window.location.href;

      if(this.item &&  this.item.langs && typeof this.item.langs[lang.lang]!=="undefined"){
        this.seoService.updateCanonicalUrl( this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/product/'+this.item.langs[lang.lang].url+ '-'+ this.item.iditem );
      }else{
        this.seoService.removeTag("rel=canonical");
      }
      this.apiService.GET_TAGS_META.forEach(key => {
        this.metaService.removeTag('name="' + key + '"');
      });


      this.metatags(lang.lang,0);
    });




  }


  @HostListener('window:popstate', ['$event'])
  onPopState(event) {

    var pathArray = window.location.pathname.split('/');
    let key = 2;
    if(pathArray[2]=="pt" || pathArray[2]=="en" || pathArray[2]=="es" || pathArray[2]=="fr"){
      key = 3;
    }
    if(event.target.origin==this.apiService.PHP_API_URL_DEFAULT || event.target.origin=="http://localhost:4200"){
      if(pathArray[key] && (pathArray[key]=="mostrar" || pathArray[key]=="spectacle" || pathArray[key]=="mostrar" || pathArray[key]=="show")){
        this.localstorageService.set("detailBack", 1);
      }
    }
  }

  breadcrumbsLoad(breadcrumbs:any){
    this.breadcrumbs = [];
    if(breadcrumbs){

    }else{

        this.translate.get( 'detail' ).subscribe(translations => {
          let obj:any = {} ;
          let obji:any = {} ;
          //obji.id = this.iditem;
          obj.url = [];
          //obj.url.push(obji);
          obj.description=translations;
          this.breadcrumbs.push(obj);
        });
        this.localstorageService.remove('show_breadscrumbs');
    }
  }



  addToFavorite(){
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true
    });


    if( (this.item && this.item.itemwithattr==0 && this.item.wishlist==0) || (this.item && this.item.itemwithattr==1 && this.item.colors[this.idcolor][this.idsize].wishlist==0) ){
      this.apiService.addwishlist(this.iditem, this.idcolor).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          if(this.item.itemwithattr==0){
            this.item.wishlist=1;
          }else{
            this.item.colors[this.idcolor][this.idsize].wishlist=1;
          }
          Toast.fire({
            icon: 'success',
            title: this.translate.instant( obj.msg )
          });
          this.wishlistService.send();
        }else{
          this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        }
      });
    }else{
      this.apiService.removewishlist(this.iditem, this.idcolor).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          if(this.item.itemwithattr==0){
            this.item.wishlist=0;
          }else{
            this.item.colors[this.idcolor][this.idsize].wishlist=0;
          }
          Toast.fire({
            icon: 'success',
            title: this.translate.instant( obj.msg )
          });
          this.wishlistService.send();
        }else{
          this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        }
      });
    }
  }

  ngOnInit(): void {

    this.metatags(this.translate.currentLang,1);

    this.urlProd=window.location.href;
  //  this.getItem();
    this.getRelated();
    if(this.localstorageService.get('show_breadscrumbs') && this.route.snapshot.paramMap.get('breadcrumbs')!=""){
      this.breadcrumbs = this.localstorageService.get('show_breadscrumbs');
      this.breadcumbschange=false;
      this.localstorageService.remove('show_breadscrumbs');
    }else{

      let createBreadcrumbsP = this.localstorageService.get('createBreadcrumbsAll');

      if(createBreadcrumbsP && createBreadcrumbsP!=null){
        this.breadcrumbs = [];
       if(createBreadcrumbsP){
           for (let index = 0; index < createBreadcrumbsP.length; index++) {

             this.breadcrumbs.push({ "url": (typeof createBreadcrumbsP[index]["url"][this.translate.currentLang]!="undefined" ? createBreadcrumbsP[index]["url"][this.translate.currentLang] : createBreadcrumbsP[index]["url"][0] ), "description":(typeof createBreadcrumbsP[index]["description"][this.translate.currentLang]!="undefined" ? createBreadcrumbsP[index]["description"][this.translate.currentLang] : createBreadcrumbsP[index]["description"][0] ) });
           }

       }

       if(this.item){
        let objBread:any = {};
  			objBread.url = "";
          objBread.description = (this.translate.currentLang && this.item && typeof this.item.langs!="undefined" ) ? this.item.langs[this.translate.currentLang].description : this.item.description;

  			this.breadcrumbs.push(objBread);
      }
    }else{
      this.breadcumbschange=false;
			this.breadcrumbsLoad(false);
    }

		}

    //this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/'+this.route.snapshot.url[0]+'/'+this.route.snapshot.url[1]);
  }




  urlmount(product:any, desc:any, iditem:any, idcolor?:any, idsize?:any){


    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";

    if(this.translate.currentLang && product.catlangs && product.catlangs[this.translate.currentLang] && product.catlangs[this.translate.currentLang].description) {
      cat = product.catlangs[this.translate.currentLang].description;
    }
    brand = (product.brandName) ? product.brandName : '';

    if(product.itemwithattr==1){
      let id_color = idcolor;
      if(!idcolor){
        id_color = this.color(product);
      }
      let id_size = idsize;
      if(!idsize){
        id_size = this.size(product, id_color);
      }
      color = product.colors[id_color][id_size].name;
      if(product.colors[id_color][id_size].priceFinal) { pval = product.colors[id_color][id_size].priceFinal; }
    }else{
      pval = product.priceFinal;
    }

    this.angulartics2GAEcommerce.ecAddProduct({
          'id': product.iditem,
          'name': desc,
          'category': cat,
          'brand': brand,
          'variant': color,
          'position': 0
        });
        this.angulartics2GAEcommerce.ecSetAction("click", {});



  this.angulartics2.eventTrack.next({
    action: 'productClick',
    properties: {
      label: 'productClick',
      currency: "EUR",
      content_type: 'product_group',
      "event":"productClick",
      gtmCustom:{
        'ecommerce': {
          'remove': undefined,
          'add': undefined,
          'purchase': undefined,
          'checkout': undefined,
          'detail': undefined,
          'impressions': undefined,
          'click': {
            'actionField': {'list': 'List Items Click'},    // 'detail'
            'products': [{
              'id': product.iditem,
              'name': desc,
              'price': pval,
              'brand': brand,
              'category': cat,
              'variant': color
             }]
           }
         }
      }
    }
  });

setTimeout(()=>{
    if(idsize){
      this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor, idsize]);return false;
    }else
    if(idcolor){
      this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor]);return false;
    }else{
      this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem)]);return false;
    }
    }, 100);
  }



  openModal(item){
    this.modalDetailRef = this.modalService.open(this.ModalDetail, {
        size: 'lg',
        windowClass: 'modal-lg'  ,
        hideCloseButton: false,
        centered: false,
        animation: true,
        keyboard: false,
        closeOnOutsideClick: false,
  backdropClass: 'modal-backdrop'
    })
    this.selectItem = item;
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colors[item.colorpriority] ){
        this.selectItem.colorActive = item.colorpriority;
      }else{
        this.selectItem.colorActive = Object.keys(item.colors)[0];
      }
      this.selectItem.sizeActive = Object.keys(item.colors[this.selectItem.colorActive])[0];
      this.selectItem.images = this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].images;
    }

    }

    showcolorInfo(color:any, op:any){
      let key = Object.keys(color.value)[0];
      if(op==1){
        return color.value[key].code;
      }else{
        if(!color.value[key].langs[this.translate.currentLang]){
          return color.value[key].name;
        }
        return color.value[key].langs[this.translate.currentLang].name;
      }
    }


  getBackgorundColor(color:any){
    let key = Object.keys(color)[0];
    if(!color[key].images || color[key].images.length==0){
      return  this.apiService.imagesDir+color[key].originthumbnails.photo ;
    }
    return  this.apiService.imagesDir+color[key].images[0].photo ;
  }
  getColorSelect(item: any){
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colorpriority in item.colors){
        return item.colorpriority;
      }else{
        let color = Object.keys(item.colors)[0];
        return color;
      }
    }
    return 0;
  }

  color(i:any){
    if(i.itemwithattr==1){
      if(i.colorpriority && i.colors[i.colorpriority] ){
        return i.colorpriority;
      }else{
        return Object.keys(i.colors)[0];
      }
    }else{
      return "";
    }
  }

  size(i:any, op:any){
    if(i.itemwithattr==1){
      if(op>0){
        return Object.keys(i.colors[op])[0];
      }
      if(i.colorpriority && i.colors[i.colorpriority] ){
        return Object.keys(i.colors[i.colorpriority])[0];
      }else{
        let col = Object.keys(i.colors)[0];
        return Object.keys(i.colors[col])[0];
      }
    }else{
      return "";
    }
  }

  loadImgThun(item: any){
    if(item.itemwithattr==1){
      if(item.colors.length==0){
        console.log("ERROR "+item.iditem);
        return "";
      }
      if(item.colorpriority && item.colorpriority in item.colors){
        let key = Object.keys(item.colors[item.colorpriority])[0];
        if(this.browserwebp && item.colors[item.colorpriority][key].originthumbnails.webp){
          return [this.apiService.imagesDir+item.colors[item.colorpriority][key].originthumbnails.webp, item.colors[item.colorpriority][key].originthumbnails.height ];
        }
        return [this.apiService.imagesDir+item.colors[item.colorpriority][key].originthumbnails.photo, item.colors[item.colorpriority][key].originthumbnails.height ];
      }else{
        let color = Object.keys(item.colors)[0];
        let key = Object.keys(item.colors[color])[0];
        if(this.browserwebp && item.colors[color][key].originthumbnails.webp){
          return [this.apiService.imagesDir+item.colors[color][key].originthumbnails.webp, item.colors[color][key].originthumbnails.height ];
        }
        return [this.apiService.imagesDir+item.colors[color][key].originthumbnails.photo, item.colors[color][key].originthumbnails.height ];
      }
    }else{
        if(this.browserwebp && item.originthumbnails.webp){
          return [this.apiService.imagesDir+item.originthumbnails.webp, item.originthumbnails.height ];
        }
        return [this.apiService.imagesDir+item.originthumbnails.photo, item.originthumbnails.height ];
    }
  }



  ChangeVariantsImage(index, color, size){
    this.selectRelatedColor[index] = color;
    this.selectRelatedSize[index] = size;

    let thumbnails;let thumbnailsheight;
    if(this.relateditemarr[index].itemwithattr==1){
      if(this.relateditemarr[index].colors.length==0){
        console.log("ERROR "+this.relateditemarr[index].iditem);
        return "";
      }
        if(this.browserwebp && this.relateditemarr[index].colors[color][size].originthumbnails.webp){
          thumbnails =this.apiService.imagesDir+this.relateditemarr[index].colors[color][size].originthumbnails.webp; thumbnailsheight= this.relateditemarr[index].colors[color][size].originthumbnails.height  ;
        }else{
          thumbnails = this.apiService.imagesDir+this.relateditemarr[index].colors[color][size].originthumbnails.photo; thumbnailsheight= this.relateditemarr[index].colors[color][size].originthumbnails.height  ;
        }
    }else{
        if(this.browserwebp && this.relateditemarr[index].originthumbnails.webp){
          thumbnails = this.apiService.imagesDir+this.relateditemarr[index].originthumbnails.webp; thumbnailsheight= this.relateditemarr[index].originthumbnails.height ;
        }else{
          thumbnails = this.apiService.imagesDir+this.relateditemarr[index].originthumbnails.photo;thumbnailsheight= this.relateditemarr[index].originthumbnails.height  ;
        }
    }
    this.relateditemarr[index].thumbnails = thumbnails;
    this.relateditemarr[index].thumbnailsheight = thumbnailsheight;
  }


  getRelated(){
    this.apiService.getrelateditem(this.iditem).subscribe((obj: any)=>{
       if(typeof obj!=="undefined" && obj.code==200){

         let imgSize;
         for (let i = 0; i < obj.info.length; i++) {
             imgSize = this.loadImgThun(obj.info[i]);
             obj.info[i].thumbnails = imgSize[0];
             obj.info[i].thumbnailsheight = imgSize[1];

             if(obj.info[i].itemwithattr==1){
               this.selectRelatedColor[i] = this.color(obj.info[i]);
               this.selectRelatedSize[i] = this.size(obj.info[i], 0);
             }else{
               this.selectRelatedColor[i] = -1;
               this.selectRelatedSize[i] = -1;
             }
         }

         for (let k = 0; k < obj.info.length; k++) {
           Object.entries(obj.info[k].langs).forEach(([key, value]) => {
               obj.info[k].langs[key].url = this.apiService.PHP_API_URL_DEFAULT+"/"+key+"/product/"+value['url']+"-"+obj.info[k].iditem ;
           });
         }

         this.relateditemarr = obj.info;
       }else{
         this.relateditemarr = [];
       }
    });
    //this._subscription.push(subs222);
  }

  public setTitle( newTitle: string) {
	  this.titleService.setTitle( newTitle  );
    this.titlechangeService.send();
  }

  metatags(lang, load){
    if(this.entryOne==true){
      return;
    }
    this.entryOne=true;
    this.apiService.getMetatags(this.iditem,1,lang).subscribe((obj: any)=>{
      this.entryOne=false;
      if(load){
        this.getItem();
      }
	  let extraString = ""
      if(typeof obj!=="undefined" && obj.code==200){
		  if(this.iditem) {extraString += "i"+this.iditem;}
		  if(this.idcolor) {extraString += "cl"+this.idcolor;}
		  if(this.idsize) {extraString += "sz"+this.idsize;}


        if(obj.title && obj.title.content){
          this.setTitle( obj.title.content);
        }
		else if(this.item && this.item.langs) {
			let title = this.item.langs[this.translate.currentLang].description + " " + extraString;
			this.setTitle( title);
		}
    this.apiService.GET_TAGS_META.forEach(key => {
      this.metaService.removeTag('name="'+key+'"');
    });
        if(obj.info.length==0){
          return;
        }


			Object.keys(obj.info).forEach(key => {

			  if(obj.info[key].name=="description" && this.item && this.item.langs) {
				obj.info[key].content =  this.item.langs[this.translate.currentLang].description + " - " + obj.info[key].content + " " + extraString + " " ;
			  }
        if(obj.info[key].name=="og:description" && this.item && this.item.langs) {
				obj.info[key].content =  this.item.langs[this.translate.currentLang].description + " - " + obj.info[key].content + " " + extraString + " " ;
			  }
        let txt = document.createElement("textarea");
        txt.innerHTML = obj.info[key].content;
        txt.value = txt.value.replace("&nbsp;", " ");
        obj.info[key].content=txt.value;

			});
			this.metaService.addTags(obj.info);

      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }



  getItem(){
    this.apiService.getItem(this.iditem).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.info.length==0){
          this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
        }
        let desc:any;
        let brand:any = "";
        let color:any = "";
        let cat:any = "";
        let url_:any = "";
        if(this.translate.currentLang){
          desc = obj.info.langs[this.translate.currentLang].description;
          url_ = obj.info.langs[this.translate.currentLang].url;
        }else{
          desc = obj.info.description;
          url_ = obj.info.url;
        }
        if(this.route.snapshot.url[1].path!=url_+"-"+this.iditem){

          this.router.navigate( [this.localize.translateRoute('/product'), url_+"-"+this.iditem]   );
        }

        this.seoService.updateCanonicalUrl( this.apiService.PHP_API_URL_DEFAULT+'/'+this.localize.parser.currentLang+'/product/'+url_+ '-'+ this.iditem );


        if(this.translate.currentLang && obj.info.catlangs && obj.info.catlangs[this.translate.currentLang] && obj.info.catlangs[this.translate.currentLang].description) {
          cat = obj.info.catlangs[this.translate.currentLang].description;
        }

        brand = (obj.info.brandName) ? obj.info.brandName : '';

        if(obj.info.itemwithattr==1){
          if(!obj.info.colors || obj.info.colors.length==0){
            this.router.navigate([this.localize.translateRoute('/pagenotfound')], {skipLocationChange: true} );return;
          }
          if(!this.idcolor){
            if(obj.info.colorpriority && obj.info.colorpriority in obj.info.colors){
              this.idcolor = obj.info.colorpriority;
            }else{
              this.idcolor = Object.keys(obj.info.colors)[0];
            }
          }else{
            if(typeof obj.info.colors[this.idcolor] == "undefined"){
              this.idcolor = Object.keys(obj.info.colors)[0];
            }
          }
          if(!this.idsize){
            this.idsize = Object.keys(obj.info.colors[this.idcolor])[0];
          }else{
            if(typeof obj.info.colors[this.idcolor][this.idsize] == "undefined"){
              this.idsize = Object.keys(obj.info.colors[this.idcolor])[0];
            }
          }
          if(!obj.info.colors[this.idcolor][this.idsize].qtdCart || obj.info.colors[this.idcolor][this.idsize].qtdCart==0){
            this.qtd=1;
            this.qtdInitial = 0;
          }else{
            this.qtd=Number(obj.info.colors[this.idcolor][this.idsize].qtdCart);
            this.qtdInitial = this.qtd;
          }
          color = obj.info.colors[this.idcolor][this.idsize].name;
        }else{
          if(!obj.info.qtdCart || obj.info.qtdCart==0){
            this.qtd=1;
            this.qtdInitial = 0;
          }else{
            this.qtd=Number(obj.info.qtdCart);
            this.qtdInitial = this.qtd;
          }
        }

        let pval:any = "";
			if(obj.info.itemwithattr==1 && obj.info.colors[this.idcolor][this.idsize].priceFinal) { pval = obj.info.colors[this.idcolor][this.idsize].priceFinal; }else{ pval=obj.info.priceFinal; }

        this.angulartics2GAEcommerce.ecAddProduct({
              'id': this.iditem,
              'name': desc,
              'category': cat,
              'brand': brand,
              'variant': color,
              'price': pval,
              'position': 0
            });
            this.angulartics2GAEcommerce.ecSetAction("detail", {});

			this.angulartics2.eventTrack.next({
				action: 'detail',
				properties: {
          label: 'detail',
					content_name: desc,
					content_category: cat,
					value: pval,
					currency: "EUR",
          "event":"Detail",
          gtmCustom:{
            'ecommerce': {
              'purchase': undefined,
              'click': undefined,
              'add': undefined,
              'checkout': undefined,
              'impressions': undefined,
              'remove': undefined,
              'detail': {
                'actionField': {'list': 'Detail Product'},    // 'detail' actions have an optional list property.
                'products': [{
                  'id': this.iditem,
                  'name': desc,// Name or ID is required.
                  'price': pval,
                  'brand': brand,
                  'category': cat,
                  'variant': color
                 }]
               }
             }
          }
				}
			});


          this.item = obj.info;
        let objBread:any = {};
			objBread.url = "";
        objBread.description = (this.item.langs[this.translate.currentLang]) ? this.item.langs[this.translate.currentLang].description : this.item.description;

			this.breadcrumbs.push(objBread);



        this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/product/'+this.item.langs['pt'].url+"-"+this.item.iditem, 'x-default');
        this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/product/'+this.item.langs['pt'].url+"-"+this.item.iditem, 'pt');
        this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/product/'+this.item.langs['en'].url+"-"+this.item.iditem, 'en');
        this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/es/product/'+this.item.langs['es'].url+"-"+this.item.iditem, 'es');
        this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/product/'+this.item.langs['fr'].url+"-"+this.item.iditem, 'fr');


      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  loadPriceFastBuy(op:Number){
    if(!this.selectItem){
      return 0;
    }
    if(op==1){
      if(this.selectItem.itemwithattr==1){
        return this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].priceOriginalTxt;
      }else{
        return this.selectItem.priceOriginalTxt;
      }
    }else{
      if(this.selectItem.itemwithattr==1){
        return this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].priceFinalTxt;
      }else{
        return this.selectItem.priceFinalTxt;
      }
    }
  }



  buyProductFast(){
    let arr:any=[];
    let obj: any = {};

    let desc:any= "";
  let cat:any = "";
  let brand:any = "";
  let color:any = "";
  let pval:any = "";
  let arrsendlitics = [];
  let arrsendlitics_incga4 = [];

  if(this.translate.currentLang && this.selectItem.langs[this.translate.currentLang]){
    desc = this.selectItem.langs[this.translate.currentLang].description;
  }else{
    desc = this.selectItem.description;
  }

  if(this.translate.currentLang && this.selectItem.catlangs && this.selectItem.catlangs[this.translate.currentLang] && this.selectItem.catlangs[this.translate.currentLang].description) {
    cat = this.selectItem.catlangs[this.translate.currentLang].description;
  }
  brand = (this.selectItem.brandName) ? this.selectItem.brandName : '';

    if(this.selectItem && this.selectItem.itemwithattr==1){
      if(this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].priceFinal) { pval = this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].priceFinal; }
      color = this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].name;
      arrsendlitics.push({
          'id': this.selectItem.iditem,
          'name': desc,
          'price': pval,
          'brand': brand,
          'category': cat,
          'variant': color,
          'quantity': 1
         });

      arrsendlitics_incga4.push({
        'item_id': this.item.iditem,
        'item_name': desc,
        'price': pval,
        'item_brand': brand,
        'item_category': cat,
        'item_variant': color,
        'quantity': 1
      })

      obj.idcolor = this.selectItem.colorActive;
      obj.idsize = this.selectItem.sizeActive;
      obj.fullcode = this.selectItem.code+"-"+obj.idcolor+"-"+obj.idsize;
      obj.idcolor_integration = this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].idcolor_integration ;
      obj.idsize_integration = this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].idsize_integration ;
    }else{
      pval = this.selectItem.priceFinal;

      arrsendlitics.push({
      'id': this.selectItem.iditem,
      'name': desc,
      'price': pval,
      'brand': brand,
      'category': cat,
      'variant': color,
      'quantity': 1
     });

     arrsendlitics_incga4.push({
      'item_id': this.selectItem.iditem,
      'item_name': desc,
      'price': pval,
      'item_brand': brand,
      'item_category': cat,
      'item_variant': color,
      'quantity': 1
    })

      obj.fullcode = this.selectItem.code;
    }
    obj.iditem = this.selectItem.iditem;
    obj.idmanager_company = this.selectItem.idmanager_company;
    obj.iditem_integration = this.selectItem.iditem_integration;
    obj.quantity = 1;
    arr.push(obj);

    let img;
    if(this.selectItem.itemwithattr = 1){
      img = this.apiService.imagesDir + this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].images[0].photo;
    }
    else{
      img = this.item.images[0].photo;
    }

    let html = '</br></br><img alt="" src="'+img+'" width="100">';
    html += '</br>';
    this.apiService.addCart(arr).subscribe((obj: any)=>{

      if(typeof obj!=="undefined" && obj.code==200){


        this.sharedService.sendClickEvent(this.translate.currentLang);
        this.translate.get( obj.msg ).subscribe(translations => {

          html += '<p class="text-center mt-2">'+translations+'</p>';
          Swal.fire({
            position: 'center',
            customClass: {
              container: 'swal-index'
            },
            html: html,
            showConfirmButton: true,
            showCancelButton: true,
            reverseButtons:true,
            confirmButtonText: this.translate.instant('checkout'),
            cancelButtonText: this.translate.instant('keepbuying')
  
          }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                   this.router.navigate( [this.localize.translateRoute('/checkout')]  );
                }
              });
          });
  



        arrsendlitics.forEach((val) => {

        this.angulartics2GAEcommerce.ecAddProduct({
              'id': val.id,
              'name': val.name,
              'category': val.category,
              'brand': val.brand,
              'variant': val.variant,
              'price': val.price,
              'quantity': val.quantity,
              'position': 0
            });
            this.angulartics2GAEcommerce.ecSetAction("add", {});

      });


      this.angulartics2.eventTrack.next({
        action: 'AddToCart',
        properties: {
          label: 'AddToCart',
          currency: "EUR",
          content_type: 'product_group',
          "event":"AddToCart",
          gtmCustom:{
            'ecommerce': {
              'remove': undefined,
              'click': undefined,
              'purchase': undefined,
              'checkout': undefined,
              'detail': undefined,
              'impressions': undefined,
              'add': {
                'actionField': {'list': 'AddToCart'},    // 'detail'
                'products': arrsendlitics
               }
             }
          }
        }
      });

      // this.angulartics2.eventTrack.next({
      //   action: 'add_to_cart',
      //   properties: {
      //     'add_to_cart': {
      //       'currency': 'EUR', 'value': pval,    // 'detail'
      //       'items': arrsendlitics_incga4
      //     }
      //   }
      // })
      }else{




        if(typeof obj.subcode!=="undefined"){
          this.qtd = Number(obj.stock);
          if(this.selectItem && this.selectItem.itemwithattr==1){
            this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].stock = Number(obj.stock);
          }else{
            this.selectItem.stock = Number(obj.stock) ;
          }
        }


        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

  imgArr(){
    if(this.item.itemwithattr==1){
      if(this.idcolor){
        let size = Object.keys(this.item.colors[this.idcolor])[0];
          return this.item.colors[this.idcolor][size].images;
      }else{
        if(this.item.colorpriority && this.item.colorpriority in this.item.colors){
          let size = Object.keys(this.item.colors[this.item.colorpriority])[0];
           return this.item.colors[this.item.colorpriority][size].images;
        }else{
          let color = Object.keys(this.item.colors)[0];
          let size = Object.keys(this.item.colors[color])[0];
          return this.item.colors[color][size].images;
        }
      }
    }else{
      return this.item.images;
    }
  }


  showDiscount(item:any, idcolor:any, idsize:any){

    if(item.itemwithattr==1){
      if(idcolor && idcolor in item.colors){
        if(idsize){
          return Math.round(100-(100*item.colors[idcolor][idsize].priceFinal/item.colors[idcolor][idsize].priceOriginal));
        }else{
          let key = Object.keys(item.colors[idcolor])[0];
          return Math.round(100-(100*item.colors[idcolor][key].priceFinal/item.colors[idcolor][key].priceOriginal));
        }
      }else
      if(item.colorpriority && item.colorpriority in item.colors){
        let key = "0";
        if(idsize){
           key = idsize;
        }else{
           key = Object.keys(item.colors[item.colorpriority])[0];
        }
        return   Math.round(100-(100*item.colors[item.colorpriority][key].priceFinal/item.colors[item.colorpriority][key].priceOriginal));
      }else{
        let color = Object.keys(item.colors)[0];
        let key = "0";
        if(idsize){
           key = idsize;
        }else{
           key = Object.keys(item.colors[color])[0];
        }
        return Math.round(100-(100*item.colors[color][key].priceFinal/item.colors[color][key].priceOriginal));
      }
    }else{
      return Math.round(100-(100*item.priceFinal/item.priceOriginal*100));
    }
  }

  showColorName(itm:any){
      let key = Object.keys(itm)[0];
      return itm[key].langs[this.translate.currentLang].name;
  }

  showbyColorName(){
      if(!this.item){
        return "";
      }
      let color = Object.keys(this.item.colors)[0];
      if(this.idcolor){
        color = this.idcolor;
      }
      if(!this.item.colors[color]){
          color = Object.keys(this.item.colors)[0];
      }
      let key = Object.keys(this.item.colors[color])[0];
      return this.item.colors[color][key].langs[this.translate.currentLang].name;
  }

  getImg(itm:any){
      let key = Object.keys(itm)[0];
      if(this.browserwebp && itm[key].images[0].webp){
        return this.apiService.imagesDir+itm[key].images[0].webp;
      }
      return this.apiService.imagesDir+ itm[key].images[0].photo;
  }

  getSize(itm:any){
    if(this.idsize && this.idsize in itm){
      return itm[this.idsize].number;
    }else{
      let key = Object.keys(itm)[0];
      return itm[key].number;
    }
  }


  ngOnDestroy(): void {
    this.clickEventsubscriptionSession.unsubscribe();
    this.subscribe2.unsubscribe();
    this.subscribe.unsubscribe();
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
  }

  showPrice(item, op:any, idcolor:any, idsize:any){
    if(!item){
      return "";
    }

    if(op==1){

      if(item.itemwithattr==1){
        if(idcolor && idcolor in item.colors){
          if(idsize){
            return  item.colors[idcolor][idsize].priceOriginalTxt ;
          }else{
            let key = Object.keys(item.colors[idcolor])[0];
            return  item.colors[idcolor][key].priceOriginalTxt ;
          }
        }else
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = "0";
          if(idsize){
             key = idsize;
          }else{
             key = Object.keys(item.colors[item.colorpriority])[0];
          }
          return item.colors[item.colorpriority][key].priceOriginalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = "0";
          if(idsize){
             key = idsize;
          }else{
             key = Object.keys(item.colors[color])[0];
          }
          return item.colors[color][key].priceOriginalTxt;
        }
      }else{
        return item.priceOriginalTxt;
      }
    }else{

      if(item.itemwithattr==1){
        if(idcolor && idcolor in item.colors){
          if(idsize){
            return  item.colors[idcolor][idsize].priceFinalTxt ;
          }else{
            let key = Object.keys(item.colors[idcolor])[0];
            return  item.colors[idcolor][key].priceFinalTxt ;
          }
        }else
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = "0";
          if(idsize){
             key = idsize;
          }else{
             key = Object.keys(item.colors[item.colorpriority])[0];
          }
          return item.colors[item.colorpriority][key].priceFinalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = "0";
          if(idsize){
             key = idsize;
          }else{
             key = Object.keys(item.colors[color])[0];
          }
          return item.colors[color][key].priceFinalTxt;
        }
      }else{
        return item.priceFinalTxt;
      }
    }
  }


  getStock(){
    if(!this.item){
      return "0";
    }
      let item = this.item;
      if(item.itemwithattr==1){
        if(this.idcolor && this.idcolor in item.colors){
          if(this.idsize){
            return  item.colors[this.idcolor][this.idsize].stock ;
          }else{
            let key = Object.keys(item.colors[this.idcolor])[0];
            return  item.colors[this.idcolor][key].stock ;
          }
        }else
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = "0";
          if(this.idsize){
             key = this.idsize;
          }else{
             key = Object.keys(item.colors[item.colorpriority])[0];
          }
          return item.colors[item.colorpriority][key].stock;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = "0";
          if(this.idsize){
             key = this.idsize;
          }else{
             key = Object.keys(item.colors[color])[0];
          }
          return item.colors[color][key].stock;
        }
      }else{
        return item.stock;
      }
  }

  changeColorFast(color){
    this.selectItem.colorActive = color;
    this.selectItem.sizeActive = Object.keys(this.selectItem.colors[this.selectItem.colorActive])[0];
    this.selectItem.images = this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].images;
  }

  changeSizeFast(size){
    this.selectItem.sizeActive =size;
  }

  changeColor(color:any){
      this.idcolor=color;
      this.idsize = Object.keys(this.item.colors[this.idcolor])[0];
      if(!this.item.colors[this.idcolor][this.idsize].qtdCart || this.item.colors[this.idcolor][this.idsize].qtdCart==0){
        this.qtd=1;
      }else{
        this.qtd=Number(this.item.colors[this.idcolor][this.idsize].qtdCart);
      }
  }


  changeSize(size:any){
      this.idsize=size;
      if(!this.item.colors[this.idcolor][this.idsize].qtdCart || this.item.colors[this.idcolor][this.idsize].qtdCart==0){
        this.qtd=1;
      }else{
        this.qtd=Number(this.item.colors[this.idcolor][this.idsize].qtdCart);
      }
  }

  cicle(color: any){
    let key = Object.keys(color)[0];
    return color[key].images;
  }

  buyProduct(){
    let arr:any=[];
    let obj: any = {};

    let desc:any= "";
    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";
    let arrsendlitics = [];
    let arrsendlitics_incga4 = [];
    let arrsendlitics_descga4 = [];

    if(this.translate.currentLang && this.item.langs[this.translate.currentLang]){
      desc = this.item.langs[this.translate.currentLang].description;
    }else{
      desc = this.item.description;
    }
    if(this.translate.currentLang && this.item.catlangs && this.item.catlangs[this.translate.currentLang] && this.item.catlangs[this.translate.currentLang].description) {
      cat = this.item.catlangs[this.translate.currentLang].description;
    }
    brand = (this.item.brandName) ? this.item.brandName : '';

    let quan_tity = 0;
    if(this.qtdInitial>this.qtd){
      quan_tity = this.qtdInitial-this.qtd;
    }else if(this.qtdInitial<this.qtd){
      quan_tity = this.qtd-this.qtdInitial;
    }

    if(this.item && this.item.itemwithattr==1){
      obj.idcolor = this.idcolor;
      obj.idsize = this.idsize;
      obj.fullcode = this.item.code+"-"+obj.idcolor+"-"+obj.idsize;
      obj.idcolor_integration = this.item.colors[this.idcolor][this.idsize].idcolor_integration ;
      obj.idsize_integration = this.item.colors[this.idcolor][this.idsize].idsize_integration ;




        color = this.item.colors[this.idcolor][this.idsize].name;
          if(this.item.colors[this.idcolor][this.idsize].priceFinal) { pval = this.item.colors[this.idcolor][this.idsize].priceFinal; }
          arrsendlitics.push({
            'id': this.item.iditem,
            'name': desc,
            'price': pval,
            'brand': brand,
            'category': cat,
            'variant': color,
            'quantity': quan_tity
           });
           arrsendlitics_incga4.push({
            'item_id': this.item.iditem,
            'item_name': desc,
            'price': pval,
            'item_brand': brand,
            'item_category': cat,
            'item_variant': color,
            'quantity': quan_tity
           });


    }else{
      obj.fullcode = this.item.code;

      pval = this.item.priceFinal;
      arrsendlitics.push({
        'id': this.item.iditem,
        'name': desc,
        'price': pval,
        'brand': brand,
        'category': cat,
        'variant': color,
        'quantity': quan_tity
       });
       arrsendlitics_incga4.push({
        'item_id': this.item.iditem,
        'item_name': desc,
        'price': pval,
        'item_brand': brand,
        'item_category': cat,
        'item_variant': color,
        'quantity': quan_tity
       });
    }
    obj.iditem = this.item.iditem;
    obj.idmanager_company = this.item.idmanager_company;
    obj.iditem_integration = this.item.iditem_integration;
    obj.quantitycomplete = 1;
    obj.quantity = this.qtd;
    arr.push(obj);

    let img;
    if(this.item.itemwithattr = 1){
      img = this.apiService.imagesDir + this.item.colors[obj.idcolor][obj.idsize].images[0].photo;
    }
    else{
      img = this.item.images[0].photo;
    }

    let html = '</br></br><img alt="" src="'+img+'" width="100">';
    html += '</br>';
    this.apiService.addCart(arr).subscribe((obj: any)=>{

      if(typeof obj!=="undefined" && obj.code==200){

        this.sharedService.sendClickEvent(this.translate.currentLang);
        this.translate.get( obj.msg ).subscribe(translations => {

        html += '<p class="text-center mt-2">'+translations+'</p>';
        Swal.fire({
          position: 'center',
          customClass: {
            container: 'swal-index'
          },
          html: html,
          showConfirmButton: true,
          showCancelButton: true,
          reverseButtons:true,
          confirmButtonText: this.translate.instant('checkout'),
          cancelButtonText: this.translate.instant('keepbuying')

        }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                 this.router.navigate( [this.localize.translateRoute('/checkout')]  );
              }
            });
        });





        if(this.qtdInitial<this.qtd){
          arrsendlitics.forEach((val) => {

            this.angulartics2GAEcommerce.ecAddProduct({
                  'id': val.id,
                  'name': val.name,
                  'category': val.category,
                  'brand': val.brand,
                  'variant': val.variant,
                  'price': val.price,
                  'quantity': val.quantity,
                  'position': 0
                });
                this.angulartics2GAEcommerce.ecSetAction("add", {});

  				});


          this.angulartics2.eventTrack.next({
    				action: 'AddToCart',
    				properties: {
              label: 'AddToCart',
    					currency: "EUR",
    					content_type: 'product_group',
              "event":"AddToCart",
              gtmCustom:{
                'ecommerce': {
                  'purchase': undefined,
                  'click': undefined,
                  'checkout': undefined,
                  'detail': undefined,
                  'impressions': undefined,
                  'remove': undefined,
                  'add': {
                    'actionField': {'list': 'AddToCart'},    // 'detail'
                    'products': arrsendlitics
                   }
                 }
              }
    				}
    			});
          // this.angulartics2.eventTrack.next({
          //   action: 'add_to_cart',
    			// 	properties: {
          //     'add_to_cart': {
          //       'currency': 'EUR', 'value': pval,    // 'detail'
          //       'items': arrsendlitics_incga4
          //     }
          //   }
          // })
        }else if(this.qtdInitial>this.qtd){
          arrsendlitics.forEach((val) => {

            this.angulartics2GAEcommerce.ecAddProduct({
                  'id': val.id,
                  'name': val.name,
                  'category': val.category,
                  'brand': val.brand,
                  'variant': val.variant,
                  'price': val.price,
                  'quantity': val.quantity,
                  'position': 0
                });
                this.angulartics2GAEcommerce.ecSetAction("remove", {});

  				});


          this.angulartics2.eventTrack.next({
    				action: 'removeFromCart',
    				properties: {
              label: 'removeFromCart',
    					currency: "EUR",
    					content_type: 'product_group',
              "event":"removeFromCart",
              gtmCustom:{
                'ecommerce': {
                  'purchase': undefined,
                  'click': undefined,
                  'checkout': undefined,
                  'detail': undefined,
                  'impressions': undefined,
                  'add': undefined,
                  'remove': {
                    'actionField': {'list': 'removeFromCart'},    // 'detail'
                    'products': arrsendlitics
                   }
                 }
              }
    				}
    			});

          this.angulartics2.eventTrack.next({
            action: 'remove_from_cart',
    				properties: {
              'remove_from_cart': {
                'currency': 'EUR', 'value':pval,    // 'detail'
                'items': arrsendlitics_descga4
               }
            }
          })
        }
this.qtdInitial = this.qtd;
      }else{

        if(typeof obj.subcode!=="undefined"){
          this.qtd = Number(obj.stock);
          if(this.item && this.item.itemwithattr==1){
            this.item.colors[this.idcolor][this.idsize].stock = Number(obj.stock);
          }else{
            this.item.stock = Number(obj.stock) ;
          }
        }

        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }


  descProd(){
    if(this.qtd>1){
      this.qtd--;
    }
  }

  incProd(){
    if(this.item && this.item.itemwithattr==1){
      if(this.qtd<this.item.colors[this.idcolor][this.idsize].stock){
        this.qtd++;
      }
    }else{
      if(this.qtd<this.item.stock){
        this.qtd++;
      }
    }
  }

  reorderSize(sizes:any){
    let order = Object.values(sizes);
    order.sort(function(a:any, b:any) {
      return parseInt(a["order"]) - parseInt(b["order"]);
    });
    return order;
  }

  getSizes(size: any) {
    return this.item.colors[this.idcolor][size.value.idsize];
  } 

  getSizesDetail(size: any){
    return this.selectItem.colors[this.selectItem.colorActive][size.value.idsize];
  } 
}
