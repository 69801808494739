<div class="title-breadcrumbs">
  <div class="title-breadcrumbs-inner">
    <div class="container-fluid">
      <nav class="woocommerce-breadcrumb">
        <a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate  }}</a>
        <span class="separator"> / </span>
        <a [routerLink]="(['/showorder', this.idorder] | localize)"  href="javascript:void(0)" > {{ 'order_confirmation' | translate }}</a>
        <span class="separator">: </span>
        <a [routerLink]="(['/showorder', this.idorder] | localize)"  href="javascript:void(0)" *ngIf="this.orderInfo">{{   this.orderInfo.document.numberdoc }}</a>
      </nav>
    </div>
  </div>
</div>

<ng-container *ngIf=" this.orderInfo  ">
	<div class="c-content-box c-size-sm c-overflow-hide c-bg-white pt-50 px-0">
		<div class="row justify-content-center w-100 mb-5" style="margin-left: inherit;">
			<div class="col-xs-12 col-md-8">
				<div class="">
					<div class="box-content text-center">
						<span><b>{{ 'tank_you_for_your_order' | translate }}</b></span><span class="doc-numb"> {{ this.orderInfo.document.numberdoc }}</span>
						<p class="text-order mt-2">{{ 'text_order' | translate }}</p>
						
						<ng-container *ngIf="!this.orderInfo.document.datepayment && this.orderInfo.document.cancellationreason ==null ">
              <ng-container *ngIf="(this.orderInfo.document.clientcondition==2 || this.orderInfo.document.clientcondition==4) || this.alreadyClient ">
							         <span class="c-font-12" [innerHTML]=" replacementString('orderfinish_notpay_accountexist' | translate, '<b>'+ this.orderInfo.document.numberdoc+'</b>')   " ></span>
              </ng-container>
              <ng-container *ngIf="(this.orderInfo.document.clientcondition==1 || this.orderInfo.document.clientcondition==3) && this.alreadyClient==false ">
							<span class="c-font-12" [innerHTML]=" replacementString('orderfinish_notpay' | translate, '<b>'+ this.orderInfo.document.numberdoc+'</b>')   " ></span>
              </ng-container>
						</ng-container>
						<ng-container *ngIf="this.orderInfo.document.datepayment">
							<ng-container *ngIf="(this.orderInfo.document.clientcondition==2 || this.orderInfo.document.clientcondition==4) || this.alreadyClient ">
							<span class="c-font-12" [innerHTML]=" replacementString('orderfinish_pay_accountexist' | translate, '<b>'+ this.orderInfo.document.numberdoc+'</b>')   " ></span>
							</ng-container>
							<ng-container *ngIf="(this.orderInfo.document.clientcondition==1 || this.orderInfo.document.clientcondition==3) && this.alreadyClient==false ">
							<span class="c-font-12" [innerHTML]=" replacementString('orderfinish_pay' | translate, '<b>'+ this.orderInfo.document.numberdoc+'</b>')   " ></span>
							</ng-container>
						</ng-container>
					</div>
				</div>
				<div class="featured-box  info-order-box" *ngIf="(this.orderInfo.document.clientcondition==1 || this.orderInfo.document.clientcondition==3) && this.alreadyClient==false ">
					<div class="box-content" >
					<form  [formGroup]="userForm" (ngSubmit)="onSubmit()">
						<h4 class="mt-0">{{ 'confirm_data_personal' | translate }}</h4>
						<div class="row">
							<div class="col-12 c-margin-b-20">
								<div class="form-group">
									<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 p-0 float-left noPadMob mb1rm">
										<label class="control-label" for="firstname">{{ 'firstname' | translate }}<i class="font-theme-color pl-02 c-font-12  fa fa-asterisk"></i></label>
										<input type="text" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" formControlName="firstname" maxlength="32" id="firstname" class="form-control c-square valRequired " />
										<div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
										   <div *ngIf="f.firstname.errors.required">{{ 'msg_valRequired' | translate }}</div>
									   </div>
									</div>
									<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 pr-0 pl-20 float-left noPadMob">
										<label class="control-label" for="lastname">{{ 'lastname' | translate }}<i class="font-theme-color pl-02 c-font-12  fa fa-asterisk hidden"></i></label>
										<input type="text" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" formControlName="lastname" maxlength="32" id="lastname" class="form-control c-square valRequired valIgnore" />
										<div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
										   <div *ngIf="f.lastname.errors.required">{{ 'msg_valRequired' | translate }}</div>
									   </div>
									</div>
								</div>
								<div class="b2breg" style="display:block">
									<div class="form-group">
										<label class="control-label" for="address">{{ 'address' | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
										<input type="text" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address" id="address" maxlength="32" class="form-control valRequired c-square" />
										  <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
											 <div *ngIf="f.address.errors.required">{{ 'msg_valRequired' | translate }}</div>
										 </div>
									</div>
									<div class="form-group">
										<label class="control-label" for="address2">{{ 'addresscomplement' | translate }} </label>
										<input type="text" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" formControlName="address2"   maxlength="32" id="address2" class="form-control c-square"/>
										  <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
											 <div *ngIf="f.address2.errors.required">{{ 'msg_valRequired' | translate }}</div>
										 </div>
									</div>
									<div class="form-group">
										<div class="form-group col-md-6 pad0 mb-0">
											<label class="control-label" for="postalcode">{{ "postalcod" | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
											<input type="text" [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" formControlName="postalcode"   id="postalcode" (change)="getpostalcode()" class="form-control valRequired c-square" />
											<div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
											   <div *ngIf="f.postalcode.errors.required">{{ 'msg_valRequired' | translate }}</div>
										   </div>
											</div>
										<div class="form-group col-md-6 pr-0 pl20 mb-0">
											<label class="control-label" for="locality">{{ "locality" | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
											<input type="text"  [ngClass]="{ 'is-invalid': submitted && f.locality.errors }" formControlName="locality"   id="locality" class="form-control valRequired c-square" />
											<div *ngIf="submitted && f.locality.errors" class="invalid-feedback">
											   <div *ngIf="f.locality.errors.required">{{ 'msg_valRequired' | translate }}</div>
										   </div>
										</div>
									</div>

									<div class="form-group">
										<div class="form-group col-md-6 pad0 mb-0 country-select">
											<label class="control-label full-width float-left">{{ 'country' | translate }}</label>
											<select *ngIf=" this.countries "   [ngClass]="{ 'is-invalid': submitted && f.idcountry.errors }" formControlName="idcountry"   id="idcountry" class="form-control c-square c-theme countryControl full-width float-left" >
												<option value="" >{{ 'selectcountry' | translate }}</option>

													<ng-container *ngFor="let country of this.countries | keyvalue;  ">
																			<option value="{{ country.key }}"  >{{ country.value.description[this.translate.currentLang] }}</option>
													</ng-container >
												</select>
												<div *ngIf="submitted && f.idcountry.errors" class="invalid-feedback">
												   <div *ngIf="f.idcountry.errors.required">{{ 'msg_valRequired' | translate }}</div>
											   </div>
										</div>
										<div class="form-group col-md-6 pr-0 pl20 mb-0">

														<label class="control-label" for="nif"><span class="niftext">{{ 'nif' | translate }}</span><span class="niffrtext" style="display:none;">{{ 'nif_fr' | translate }}</span><i class="font-theme-color pl-02 c-font-12 fa fa-asterisk hidden"></i></label>
														<input type="text"   [ngClass]="{ 'is-invalid': submitted && f.nif.errors }" formControlName="nif" id="nif" class="form-control c-square nifControl " />
										  <div *ngIf="submitted && f.nif.errors" class="invalid-feedback">
											 <div *ngIf="f.nif.errors.NifValidatorPT">{{ 'msg_valNif' | translate }}</div>
										 </div>
										</div>
									</div>
									<div class="form-group">
										<div class="form-group col-md-6 pad0 mb-0">
											<label class="control-label" for="email">{{ 'email' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
											<input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email" id="email" class="form-control c-square valRequired valEmail" />
											<div *ngIf="this.submitted && f.email.errors" class="invalid-feedback">
											   <div *ngIf="f.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
											   <div *ngIf="f.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
										   </div>
										</div>
										<div class="form-group col-md-6 pr-0 pl20 mb-0">
											<label class="control-label" for="telephone">{{ 'telephone' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
											<div class="display-flex-xs" style="width: 100%;">
											  <div class="col-md-4 p-0 col-md-4 float-left inital_code_base_div">
											  <ng-container *ngIf="this.telephone_callingcode  ">
													<ng-select2 [options]="this.optionsCalling" [data]="this.telephone_callingcode" [value]="351" class="inital_code_base selectpicker" [ngClass]="{ 'is-invalid': submitted && f.telephone_callingcode.errors }" formControlName="telephone_callingcode"  id="initialcode_phone">
													</ng-select2 >
											 </ng-container>
										  </div>
											<div class="col-md-12 p-0 float-left width-phone-xs">

										  <input type="text" maxlength="20" [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }" formControlName="telephone" id="telephone" class="form-control c-square valRequired valNumber" />
										  </div></div>

										  <div *ngIf="submitted && f.telephone.errors" class="invalid-feedback">
											 <div *ngIf="f.telephone.errors.required">{{ 'msg_valRequired' | translate }}</div>
										  </div>
										</div>
									</div>
								</div>
							</div>

							<div class="col-md-12 pad_top10">
								<div class="row">
									<div class="form-group col-md-6 pr-0">
									<label class="control-label" for="password">{{ 'password' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
									<input type="password" id="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password" class="form-control c-square valRequired" />
									  <div *ngIf="this.submitted && f.password.errors" class="invalid-feedback">
										 <div *ngIf="f.password.errors.required">{{ 'msg_valRequired' | translate }}</div>
									 </div>
								</div>

									<div class="form-group col-md-6">
									<label class="control-label" for="passwordconf">{{ 'confirmpassword' | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
									<input type="password" id="passwordconf" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" formControlName="confirmPassword" class="form-control c-square valRequired valMatch" />
									  <div *ngIf="this.submitted && f.confirmPassword.errors" class="invalid-feedback">
										 <div *ngIf="f.confirmPassword.errors.required">{{ 'msg_valRequired' | translate }}</div>
										 <div *ngIf="f.confirmPassword.errors.mustMatch">{{ 'msg_valMatchP' | translate }}</div>
									 </div>
								</div>
								</div>
							</div>


							<div class="col-xs-12 col-md-12  mt-10 pull-right">
						  <div class="form-group ">
							<button style="background-color:#f1f1f1;line-height:1.2;" class="btn col-lg-offset-3 col-md-offset-3 c-theme-btn c-btn-square c-btn-uppercase c-btn-bold buyButton pull-right" type="submit">{{ 'create_account_client' | translate }}</button>
						  </div>
						</div>
						</div>

					</form>
					</div>
				</div>
				<div class="col-xs-12 col-md-12">
					<div class="featured-box featured-box-secondary mb-5">
						<div class="box-content">
							<div class="row" >
								<div class="col-md-6 info-order-box" style="float: none;" *ngIf="this.orderInfo.addressdelivery">
									<h6 class="mt-20">{{ 'delivery_details' | translate }}</h6>
									<p class="m0"><strong>{{ 'full_name' | translate }}:</strong> {{ this.orderInfo.addressdelivery.name }}</p>
									<p class="m0"><strong>{{ 'address' | translate }}:</strong>
										{{ this.orderInfo.addressdelivery ? this.orderInfo.addressdelivery.address : '' }}
										{{ this.orderInfo.addressdelivery ? this.orderInfo.addressdelivery.address2 : '' }} <br/>
									</p>
									<p class="m0"><strong>{{ 'postalcod' | translate }}:</strong>
										{{ this.orderInfo.addressdelivery ? this.orderInfo.addressdelivery.postalcode : '' }}, {{ this.orderInfo.addressdelivery ? this.orderInfo.addressdelivery.city : '' }}
									</p>
									<p class="m0"><strong>{{ 'country' | translate }}:</strong> {{ this.orderInfo.addressclient ? this.orderInfo.addressdelivery.country : '' }}</p>
									<p class="m0" *ngIf="this.orderInfo.shipping">
										<strong>{{ 'shipmode' | translate }}:</strong> {{  'shipping_'+this.orderInfo.shipping.abbrshipingmode | translate}}
										<span *ngIf="this.orderInfo.shipping.shippingcompany"> - {{  this.orderInfo.shipping.shippingcompany}}</span>
									</p>
								</div>
								<div class="col-md-6 info-order-box" style="float: none;" *ngIf="this.orderInfo.addressclient">
									<h6 class="mt-20">{{ 'billing_details' | translate }}</h6>
									<p class="m0"><strong>{{ 'full_name' | translate }}:</strong> {{ this.orderInfo.details.nameclient }}</p>
									<p class="m0"><strong>{{ 'nif' | translate }}:</strong> {{ this.orderInfo.details.nifclient }}</p>
									<p class="m0"><strong>{{ 'email' | translate }}:</strong> {{ this.orderInfo.client.email }}</p>
									<p class="m0"><strong>{{ 'telephone' | translate }}:</strong> +{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.contact : '' }}</p>
									<p class="m0"><strong>{{ 'address' | translate }}:</strong>
										{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.address : '' }}
										{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.address2 : '' }} <br/>
									</p>
									<p class="m0"><strong>{{ 'postalcod' | translate }}:</strong> {{ this.orderInfo.addressclient ? this.orderInfo.addressclient.postalcode : '' }}, {{ this.orderInfo.addressclient ? this.orderInfo.addressclient.city : '' }}</p>
									<p class="m0"><strong>{{ 'country' | translate }}:</strong> {{ this.orderInfo.addressclient ? this.orderInfo.addressclient.country : '' }}</p>
								</div>	
	
				  <div class="col-xs-12 col-lg-12">
					<button *ngIf="!this.orderInfo.document.datepayment && this.orderInfo.document.methoddescriptionabbr!='COB'" (click)="changePaymentMean()" class="btn  c-btn-red btn-sm c-btn-sbold  pull-right btn-danger" style="border-radius:0;height: auto;">{{ 'payorder' | translate }}</button>
				  </div>
								<div class="col-md-12">
	
									<div class="col-xs-12 col-lg-12"><h6 class="mt-20 text-center">{{ 'paymentdesc' | translate }} </h6></div>
	
									<ng-container *ngIf="this.orderInfo.document.datepayment">
										<ng-container *ngIf=" this.orderInfo.document.method=='CC'">
											<div class="col-md-12 text-center"><img src="assets/images/visa_master_icon.png" width="150px" /></div>
										</ng-container>
										<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='PYPL'">
											<div class="col-md-12 text-center"><img src="assets/images/paypal_icon.png" width="150px" /></div>
										</ng-container>
										<ng-container *ngIf=" this.orderInfo.document.method=='MB'">
										  <div class="col-md-12 text-center"><img src="assets/images/mb_text_icon.png" width="150px" /></div>
										</ng-container>
					  					<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='COB'">
										  <div class="col-md-12 text-center">{{ 'COB' | translate }}</div>
										</ng-container>
										<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='TB' && this.orderInfo.bank.length>0">
										  <div class="form-group col-md-12">
											<p class="m0 text-center"><strong>{{ 'IBAN' | translate }}:</strong> {{  this.orderInfo.bank[0].nib }}</p>
											<p class="m0 text-center"><strong>{{ 'bank' | translate }}:</strong> {{  this.orderInfo.bank[0].bank }}</p>
											<p class="m0 text-center"><strong>{{ 'SWIFT' | translate }}:</strong> {{  this.orderInfo.bank[0].swift }}</p>
										  </div>
										</ng-container>
									</ng-container>

									<ng-container *ngIf=" this.orderInfo.document.method=='MB' && !this.orderInfo.document.datepayment">
										<div class="mt-3">
											<p class="m0 text-center"><strong>{{ 'entity' | translate }}:</strong> {{  this.orderInfo.document.entity }}</p>
											<p class="m0 text-center"><strong>{{ 'reference' | translate }}:</strong> {{  this.orderInfo.document.reference }}</p>
											<p class="m0 text-center"><strong>{{ 'value' | translate }}:</strong> {{  this.orderInfo.document.total | currency:'symbol':''  }}{{ this.orderInfo.document.currencysymbol }}</p>
										</div>
									  </ng-container>
	
									<ng-container *ngIf="!this.orderInfo.document.datepayment && this.orderInfo.document.cancellationreason ==null">
										<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='PYPL'">
											<div class="col-md-12 text-center"><img src="assets/images/paypal_icon.png" width="150px" /></div>
										</ng-container>
										<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='CC'">
											<div class="col-md-12 text-center"><img src="assets/images/visa_master_icon.png" width="150px" /></div>
										</ng-container>
					  					<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='COB'">
										  <div class="col-md-12 text-center">{{ 'COB' | translate }}</div>
										</ng-container>
										<ng-container *ngIf=" this.orderInfo.document.methoddescriptionabbr=='TB' && this.orderInfo.bank.length>0">
										  <div class="form-group col-md-12">
											<p class="m0 text-center"><strong>{{ 'IBAN' | translate }}:</strong> {{  this.orderInfo.bank[0].nib }}</p>
											<p class="m0 text-center"><strong>{{ 'bank' | translate }}:</strong> {{  this.orderInfo.bank[0].bank }}</p>
											<p class="m0 text-center"><strong>{{ 'SWIFT' | translate }}:</strong> {{  this.orderInfo.bank[0].swift }}</p>
										  </div>
										</ng-container>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				<div class="featured-box featured-box-secondary">
					<div class="box-content">
						<h6 class="mt-0">
							{{ 'resume_order_client' | translate }}

							<div class="float-right">
							<a class="btn wpcf7-form-control search_button filter-sm-button title_filter_items" [href]="this.apiService.PHP_API_SERVER+'?controller=order&action=get&doc='+btoa(this.idorder)" target="_blank">
							  <i class="fas fa-file-pdf"></i>&nbsp;
							  <span>{{ 'doctitle' | translate }}</span>
							</a>
						  </div>
						</h6>
						<div class="mt-2"></div>

						<table class="cart-totals cart-totals-checkout full-width table-orderinfo mt-2">
							<thead>
								<tr>
									<th class="product-image text-uppercase p-2" colspan="2">{{ 'product_s' | translate }}</th>
									<th class="product-name text-center text-uppercase p-2 d-none d-lg-block">{{ 'unitprice' | translate }}</th>
									<th class="product-name text-center text-uppercase p-2">{{ 'quantity' | translate }}</th>
									<th class="product-subtotal text-right text-uppercase p-2">{{ 'total' | translate }}</th>
								</tr>
							</thead>
							<tbody>
							 <ng-container *ngFor="let itm of this.orderInfo.lines ">
								<ng-container *ngIf="itm.rate!=0 && this.firstRate==0">
									<tr ng-init="this.firstRate=1" class="padding_separate_table"><td></td><td></td><td></td><td></td></tr>
								</ng-container>

								<tr>
								  <ng-container *ngIf="itm.prodinfo && itm.prodinfo.images && itm.prodinfo.images[0] && itm.prodinfo.images[0][0]">
									<td class="product-image text-left">
									  <img style="width:50px; height:auto;margin:0 auto;" [src]="this.apiService.imagesDir+itm.prodinfo.images[0][0].photo">
									</td>
									<td class="product-name text-left"><b>{{ itm.itemdescription }}</b><br>{{ 'ref' | translate }}: {{ itm.itemcode ? itm.itemcode : '' }}</td>
								  </ng-container>
								  <ng-container *ngIf="itm.prodinfo && itm.prodinfo.images && itm.prodinfo.images[0] && itm.prodinfo.images[0][0]">
									<td class="text-center hide-mobile"><b>
										<ng-container *ngIf="itm.promotion == 1">
											<span class="old-price">{{  (itm.quantity*showPrice(itm.prodinfo.infoitem, itm.idcolor)| currency:'symbol':'').replace(",", " ").replace(".", ",")  }}{{ this.orderInfo.document.currencysymbol }}</span>
											<span class="new-price">{{  (itm.priceunit*itm.quantity| currency:'symbol':'').replace(",", " ").replace(".", ",")  }}{{ this.orderInfo.document.currencysymbol }}</span>
										</ng-container>
										<ng-container *ngIf="itm.promotion == 0">
											{{  (itm.priceunit*itm.quantity| currency:'symbol':'').replace(",", " ").replace(".", ",")  }} {{ this.orderInfo.document.currencysymbol }}
										</ng-container>
									</b>
									</td>
									<td class="text-center"><b>{{ itm.quantity | number:'1.0-0' }}</b></td>
									<td class="text-right"><b>{{  (itm.priceshow*itm.quantity| currency:'symbol':'').replace(",", " ").replace(".", ",")  }} {{ this.orderInfo.document.currencysymbol }}</b></td>
								  </ng-container>
								</tr>
						     </ng-container>
							</tbody>
						</table>
						<div class="divider-table mb-2 mt-2"></div>
						<ng-container *ngIf="this.orderInfo.lines ">
							<div class="col-lg-4 col-md-12 mr-0 ml-auto">
								<table class="full-width">
									<tbody>
										<tr *ngIf="this.orderInfo.document.summarysum">
											<th>{{ 'subtotal' | translate }}<br></th>
											<td class="text-right">
												<span class="amount ">{{ this.orderInfo.document.summarysum | currency:'symbol':'' }}</span>
												<span class="text-right">{{ this.orderInfo.document.currencysymbol }}</span>
											</td>
										</tr>
										<ng-container *ngFor="let itm of this.orderInfo.lines">
											<tr *ngIf="!(itm.prodinfo && itm.prodinfo.images && itm.prodinfo.images[0] && itm.prodinfo.images[0][0])">
												<th><span style="font-weight: 500; font-size: 12px;">{{ itm.itemdescription }}</span><br></th>
												<td class="text-right">
													<span class="amount ">{{ ((this.orderInfo.document.typevalue==1 ? itm.priceshow*itm.quantity  : itm.priceunit*itm.quantity) | number:'1.2-2').replace(",", " ").replace(".", ",")  }}</span>
													<span class="text-right">{{ this.orderInfo.document.currencysymbol }}</span>
												</td>
											</tr>
										</ng-container>
										<tr *ngIf="this.orderInfo.document.summarydiscount && this.orderInfo.document.summarydiscount != '0.00'">
											<th><span style="font-weight: 500; font-size: 12px;">{{ 'discount' | translate }}</span><br></th>
											<td class="text-right">
												<span class="amount ">{{ this.orderInfo.document.summarydiscount | currency:'symbol':'' }}</span>
												<span class="text-right">{{ this.orderInfo.document.currencysymbol }}</span>
											</td>
										</tr>
										<tr class="total" *ngIf="this.orderInfo.document.total">
											<th><b>{{ 'total' | translate }}</b><br><span style="font-size:11px;font-weight:normal;">{{ 'vatincluded' | translate }}</span></th>
											<td class="text-right">
												<span class="amount ">{{ (this.orderInfo.document.total | currency:'symbol':'').replace(",", " ").replace(".", ",") }}</span>
												<span class="text-right">{{ this.orderInfo.document.currencysymbol }}</span>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</ng-container>
						<ng-container *ngIf="!this.orderInfo.document.datepayment && this.orderInfo.document.cancellationreason ==null">
						<table class="cart-choose-payment full-width mt-20 mb-20">
							<tbody>
								<tr class="total">
									<th>&nbsp;</th>
									<td></td>
								</tr>
							</tbody>
						</table>
						</ng-container>
					</div>
				</div>
			</div>
			</div>
		</div>
	</div>


  <div class="container-fluid c-container-50" [hidden]="true">
    <div class="c-layout-sidebar-content c-border-2x pt-20  c-margin-t-10 c-margin-b-20">
      <div class="row">
        <div class="form-group col-md-6">
          <fieldset>
            <legend>{{ 'clientinfo' | translate }}</legend>
            <div class="form-group col-md-12">
              <label class="control-label"  >{{ 'name' | translate }} </label>
              <input type="text"   value="{{ this.orderInfo.details.nameclient }}" class="form-control c-square " disabled />
            </div>

            <div class="form-group col-md-12">
              <label class="control-label"  >{{ 'email' | translate }} </label>
              <input type="text"   value="{{ this.orderInfo.client.email }}" class="form-control c-square " disabled />
            </div>

            <div class="form-group col-md-6">
              <label class="control-label"  >{{ 'nif' | translate }} </label>
              <input type="text"   value="{{ this.orderInfo.details.nifclient }}" class="form-control c-square " disabled />
            </div>
            <div class="form-group col-md-6">
              <label class="control-label"  >{{ 'telephone' | translate }} </label>
              <input type="text"  disabled value="{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.contact : '' }}"  class="form-control c-square" />
            </div>

            <div class="form-group col-md-12">
              <label class="control-label"  >{{ 'address' | translate }} </label>
              <input type="text"  disabled value="{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.address : '' }}"  class="form-control c-square" />
            </div>

            <div class="form-group col-md-12">
              <label class="control-label"  >{{ 'addresscomplement' | translate }} </label>
              <input type="text"  disabled value="{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.address2 : '' }}"  class="form-control c-square" />
            </div>

            <div class="form-group col-md-6">
              <label class="control-label"  >{{ 'postalcode' | translate }} </label>
              <input type="text"  disabled value="{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.postalcode : '' }}"  class="form-control c-square" />
            </div>
            <div class="form-group col-md-6">
              <label class="control-label"  >{{ 'locality' | translate }} </label>
              <input type="text"  disabled value="{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.city : '' }}"  class="form-control c-square" />
            </div>
            <div class="form-group col-md-6">
              <label class="control-label"  >{{ 'country' | translate }} </label>
              <input type="text"  disabled value="{{ this.orderInfo.addressclient ? this.orderInfo.addressclient.country : '' }}"  class="form-control c-square" />
            </div>
          </fieldset>
        </div>

      </div>
    </div>
  </div>
</ng-container >

<ng-container *ngIf=" !this.orderInfo  ">
  {{ 'ORDER_NOT_FOUND' | translate }}
</ng-container >

<modal #ModalChangePayment    >

	<modal-header>
	{{ 'paymentchoose' | translate  }}
	</modal-header>
  <modal-content>
    <div class=" row">
      <div class="  col-md-12 col-xs-12 col-sm-12">
        <ng-container   *ngIf="this.payments">
          <ng-container   *ngFor="let payment of this.payments;">
            <div   class="c-radio">
              <input value="{{ payment.idpaymentwebsite }}" (change)="this.selectPayment=payment.idpaymentwebsite;selectPaymentAbbr=payment.abbr"  abrv="{{ payment.abbr }}" type="radio" name="payment"   class="c-radio" id="radioP{{ payment.idpaymentwebsite }}">
              <label class="c-font-bold c-font-14" for="radioP{{ payment.idpaymentwebsite }}">
                <span class="inc"></span><span class="check"></span>
                <span class="box"></span> {{ payment.abbr | translate  }}
              </label>
            </div>


            <ng-container *ngIf="selectPaymentAbbr=='MBW' && this.selectPayment==payment.idpaymentwebsite ">
							<label class="control-label" for="telephone">{{ 'telephone' | translate }} <i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
							<input type="text" [required]="this.selectPayment==payment.idpaymentwebsite ? 'true' : 'false'"   [(ngModel)]="contat_MBW" name="contat_MBW"  maxlength="20" id="contat_MBW"   class="form-control c-square valRequired valNumber" />
						</ng-container>


          </ng-container>
        </ng-container>



      </div>
    </div>


  </modal-content>

  <modal-footer>
    <ng-container   *ngIf="this.selectPayment">
      <button type="button" style="border-radius: 0px;background-color:#ccc;line-height: 1.2;"   (click)="changepayment()"   class="pull-right btn btn-outline btn-default btn-cart button-aylen cartBtn full-width float-left ">{{ 'pay' | translate }}</button>
    </ng-container>
    </modal-footer>
</modal>
