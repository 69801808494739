import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartsharedService {
  private subject = new Subject<any>();
  public cart: any;
  public cartTotal:any;
  constructor() { }

  sendcart(cart: any, cartTotal: any, companyInfo:any, shippingTotal:any, vouchers:any) {
    this.cart = cart;
    this.cartTotal = cartTotal;
    this.subject.next([cart, cartTotal, companyInfo, shippingTotal, vouchers]);
  }
  getcartEvent( ): Observable<any>{
    return this.subject.asObservable();
  }

}
