import { Component, OnInit, ViewChild , HostListener, Inject, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationStart  } from '@angular/router';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core';
 import { ModalManager } from 'ngb-modal';
 import { SharedService } from '../shared.service';
import { LocalstorageService } from '../localstorage.service';

import { Title, Meta, DomSanitizer }     from '@angular/platform-browser';
import { MenusharedService } from '../menushared.service';
import { MenugetService } from '../menuget.service';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
declare var $: any;
import { UrlpipePipe } from '../urlpipe.pipe';
import { LowerCasePipe } from '@angular/common';
import { SeoService } from '../seo.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { CheckwebpService } from '../checkwebp.service';
import { OwlOptions  } from 'ngx-owl-carousel-o';

import { DeviceDetectorService } from 'ngx-device-detector';
import { TitlechangeService } from '../titlechange.service';
import { isUndefined } from 'util';

@Component({
  selector: 'app-show',
  changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit, OnDestroy {
  @ViewChild('ModalDetail') ModalDetail;
  @ViewChild('ModalFilters') ModalFilters;
  entryNgInit:any;
  subscribeMenu:any;
  objectKeys = Object.keys;
  filter: any ={} ;
  filterInitial: any ={} ;
  orderby: any ={} ;
  setIntervalClear:any;
  brands:any;
  genres:any;
  subcat:any;
  sub2cat:any;
  cat:any;
  colors:any;
  sizes:any;
  breadcrumbs:Array<Object> = [];
  createBreadcrumbsP:any = [];
  createBreadcrumbsAll:any = [];
  orderItem:any;
  scrollPosition:any;
  itemsList:any;
  emptyItem:any;
  countParams:any;
    modalDetailRef:any;
    selectItem:any;
    carouselOptionsDetail: OwlOptions = {
        loop:false,
    		margin:0,
        autoplay: true,
        autoHeight: true,
        autoWidth: true,
    		nav:true,
    		navText: ['<img style="width:100%" class="imgCarrousel" alt="<" src="assets/images/lefticon2.png"> ', ' <img alt=">" class="imgCarrousel" style="width:100%" src="assets/images/righticon2.png">'],
    		items: 1
    }
    page:any;
    stopScroll:any;
    subscribe:any;
    descText:any;
    menuParent:any;
    browserwebp:any;
    defaultImage:any;
    checkchangelang:any;
    loadingShow:any=false;
    subscribe2:any;
    mouseoverElement:any;
    sizeOver:any;
    deviceDesktop:any;
    urlsAlternate:any;
    filterstemporary:any;
    metaentry:any;

    insidemenuclick: boolean = false;
    menuhided: boolean = true;

    public finishedLoad: boolean = false  // boolean when end of data is reached

    modalFiltersRef

  constructor(public titlechangeService:TitlechangeService,protected sanitizer: DomSanitizer,public deviceService: DeviceDetectorService,private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, public checkwebp: CheckwebpService,public localize: LocalizeRouterService,private router: Router,private seoService: SeoService, private lowercase:LowerCasePipe, private urlpipePipe:UrlpipePipe, private menugetService:MenugetService, private menusharedService:MenusharedService, private angulartics2: Angulartics2, private metaService: Meta, public titleService: Title, public localstorageService: LocalstorageService, private modalService: ModalManager, private route: ActivatedRoute, public apiService: ApiService, public translate: TranslateService, public sharedService: SharedService) {
  this.checkchangelang=false;
  this.sizeOver = [];
    this.apiService.GET_TAGS_META.forEach(key => {
      this.metaService.removeTag('name="'+key+'"');
    });
    this.filterstemporary = "";

    this.deviceDesktop=false;
    const isDesktopDevice = this.deviceService.isDesktop();
    if(isDesktopDevice){
      this.deviceDesktop=true;
    }

    this.browserwebp = this.checkwebp.canUseWebP();
    if(this.browserwebp){
      this.defaultImage = "assets/images/img_default.webp";
    }else{
      this.defaultImage = "assets/images/img_default.png";
    }
    this.entryNgInit=false;
this.itemsList = [];
    if(this.localstorageService.get('detailBack') && this.localstorageService.get('detailBack')==1){

      try{
        this.filter = this.localstorageService.get('show_filter');
        this.filterInitial = this.localstorageService.get('show_filterInitial');
        this.orderItem = this.localstorageService.get('show_orderItem');
        this.page = this.localstorageService.get('show_page');
        this.stopScroll = this.localstorageService.get('show_stopScroll');

        this.createBreadcrumbsP = this.localstorageService.get('createBreadcrumbsP');
        this.createBreadcrumbsAll = this.localstorageService.get('createBreadcrumbsAll');
        this.colors = this.localstorageService.get('show_colors');
            this.localstorageService.getasync('show_itemsList', 1).then((res) => this.extractData(res));






        this.brands = this.localstorageService.get('show_brands');
        this.subcat = this.localstorageService.get('show_subcat');
        this.cat = this.localstorageService.get('show_cat');
        this.sub2cat = this.localstorageService.get('show_sub2cat');

        this.sizes = this.localstorageService.get('show_sizes');
        this.genres = this.localstorageService.get('show_genres');
        this.scrollPosition = this.localstorageService.get('show_position');
        this.emptyItem = this.localstorageService.get('show_emptyItem');

        if(this.filter.novelty==1){
          $("#new_filter").prop("checked", true);
        }

      /*  let allitems = this.keeplistservice.getdir();
        for (let index = 1; index <= this.page; index++) {
          this.itemsList = this.itemsList.concat(allitems[index]);
        }*/

          // maybe check the itemsList before?
          this.finishedLoad = true;
            

      }catch(error){
        this.localstorageService.remove('detailBack');
        this.getItems(0);

      }
    }else{
      this.filter.menu = null;
      this.filter.genre = null;
      this.filter.cat = null;
      this.filter.subcat = null;
      this.filter.sub2cat = null;
      this.filter.brand = null;
      this.filter.color = null;
      this.filter.size = null;
      this.filter.collection = null;
      this.filter.search = null;




      if(this.route.snapshot.paramMap.get('desc1')){
        if(this.route.snapshot.paramMap.get('desc1')=="novidades" || this.route.snapshot.paramMap.get('desc1')=="news" || this.route.snapshot.paramMap.get('desc1')=="nouveautes" || this.route.snapshot.paramMap.get('desc1')=="novedades"  ){
          this.filter['menu'] = -2;
          this.createBreadcrumbsP[0] = ["menu", -2];
        }else if(this.route.snapshot.paramMap.get('desc1')=="stockoff" || this.route.snapshot.paramMap.get('desc1')=="stock-off" || this.route.snapshot.paramMap.get('desc1')=="saldos"   ){
          this.filter['menu'] = -1;
          this.createBreadcrumbsP[0] = ["menu", -1];
        }else{
          let desc1 = this.geturlvalue(this.route.snapshot.paramMap.get('desc1'));
          let desc1_d = this.getdescriptionurl(desc1.slice(0,1));
          if(desc1_d!=="0"){
            desc1 = desc1.substring(1);
            if(desc1_d=="search"){
              desc1 = this.route.snapshot.paramMap.get('desc1').slice(0, -1);
            }
            this.filter[desc1_d] = desc1;
            this.createBreadcrumbsP[0] = [desc1_d, desc1];
          }else{
            this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  );return;
          }
        }
      }

      if(this.route.snapshot.paramMap.get('desc2')){
        let desc2 = this.geturlvalue(this.route.snapshot.paramMap.get('desc2'));
        let desc2_d = this.getdescriptionurl(desc2.slice(0,1));
        if(desc2_d!=="0"){
          desc2 = desc2.substring(1);
          if(desc2=="" || isNaN(Number(desc2))){
            this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  );return;
          }
          this.filter[desc2_d] = desc2;
          this.createBreadcrumbsP[1] = [desc2_d, desc2];
        }else{
          this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  );return;
        }
      }

      if(this.route.snapshot.paramMap.get('desc3')){
        let desc3 = this.geturlvalue(this.route.snapshot.paramMap.get('desc3'));
        let desc3_d = this.getdescriptionurl(desc3.slice(0, 1));
        if(desc3_d!=="0"){
          desc3 = desc3.substring(1);
          if(desc3=="" || isNaN(Number(desc3))){
            this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  );return;
          }
          this.filter[desc3_d] = desc3;
          this.createBreadcrumbsP[2] = [desc3_d, desc3];
        }else{
          this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  );return;
        }
      }


      if(this.route.snapshot.paramMap.get('desc4')){
        let desc4 = this.geturlvalue(this.route.snapshot.paramMap.get('desc4'));
        let desc4_d = this.getdescriptionurl(desc4.slice(0,1));
        if(desc4_d!=="0"){
          desc4 = desc4.substring(1);
          if(desc4=="" || isNaN(Number(desc4))){
            this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
          }
          this.filter[desc4_d] = desc4;
          this.createBreadcrumbsP[3] = [desc4_d, desc4];
        }else{
          this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
        }
      }


      if(this.route.snapshot.paramMap.get('desc5')){
        let desc5 = this.geturlvalue(this.route.snapshot.paramMap.get('desc5'));
        let desc5_d = this.getdescriptionurl(desc5.slice(0,1));
        if(desc5_d!=="0"){
          desc5 = desc5.substring(1);
          if(desc5=="" || isNaN(Number(desc5))){
            this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
          }
          this.filter[desc5_d] = desc5;
          this.createBreadcrumbsP[4] = [desc5_d, desc5];
        }else{
          this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
        }
      }


      if(this.route.snapshot.paramMap.get('desc6')){
        let desc6 = this.geturlvalue(this.route.snapshot.paramMap.get('desc6'));
        let desc6_d = this.getdescriptionurl(desc6.slice(0,1));
        if(desc6_d!=="0"){
          desc6 = desc6.substring(1);
          if(desc6=="" || isNaN(Number(desc6))){
            this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
          }
          this.filter[desc6_d] = desc6;
          this.createBreadcrumbsP[5] = [desc6_d, desc6];
        }else{
          this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
        }
      }


      if(this.route.snapshot.paramMap.get('desc7')){
        let desc7 = this.geturlvalue(this.route.snapshot.paramMap.get('desc7'));
        let desc7_d = this.getdescriptionurl(desc7.slice(0,1));
        if(desc7_d!=="0"){
          desc7 = desc7.substring(1);
          if(desc7=="" || isNaN(Number(desc7))){
            this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
          }
          this.filter[desc7_d] = desc7;
          this.createBreadcrumbsP[6] = [desc7_d, desc7];
        }else{
          this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
        }
      }

    /*  if(this.route.snapshot.paramMap.get('paramname1')){
        this.filter[this.route.snapshot.paramMap.get('paramname1')] = this.route.snapshot.paramMap.get('paramval1');
      }

      if(this.route.snapshot.paramMap.get('paramname2')){
        this.filter[this.route.snapshot.paramMap.get('paramname2')] = this.route.snapshot.paramMap.get('paramval2');
      }
      if(this.route.snapshot.paramMap.get('paramname3')){
        this.filter[this.route.snapshot.paramMap.get('paramname3')] = this.route.snapshot.paramMap.get('paramval3');
      }*/


      this.orderItem = 0;
      this.filter.color=null;
      this.filter.size=null;
      this.filter.novelty=0;

      if(this.route.snapshot.queryParams['size']){
        this.filter.size = this.route.snapshot.queryParams['size'];
      }

      if(this.route.snapshot.queryParams['color']){
        let r = this.route.snapshot.queryParams['color'].toString().split(",");
        if(r.length>0){
          let rls ;
          for (let ix = 0; ix < r.length; ix++) {
              rls = r[ix].toString().split("-");
              if(rls[0]){
                if(!this.filter.color){
                  this.filter.color = rls[0];
                }else{
                  this.filter.color += ","+rls[0];
                }
              }
              if(rls[1]){
                if(this.filterstemporary){
                  this.filterstemporary += ",";
                }
                this.filterstemporary += rls[1].replace(".",",");
              }
          }
        }
      }


      this.filterInitial = Object.assign({}, this.filter);
      this.page=1;
      this.stopScroll=false;
      this.scrollPosition=0;
      this.emptyItem = 0;

    }



    this.subscribe2 = this.route.params.subscribe(params => {

    /*  if(params['desc1'] && params['desc1']=="search"){
        this.filter.search = params['paramval1'];
      }*/

      this.filter.search = null;
      this.filterInitial.search = null;
      if(params['desc1']){
        let desc1_search = this.geturlvalue(params['desc1']);
        let desc1_d_search = this.getdescriptionurl(desc1_search.slice(0,1));
        if(desc1_d_search=="search"){


          this.checkchangelang=false;
          desc1_search = params['desc1'].slice(0, -1);
          this.filter.search = desc1_search;
          this.filterInitial.search = desc1_search;
          this.createBreadcrumbsP = [];
          this.createBreadcrumbsP[0] = ["e", desc1_search];



          if(params['desc2']){
            let desc2 = this.geturlvalue(params['desc2']);
            let desc2_d = this.getdescriptionurl(desc2.slice(0,1));
            if(desc2_d!=="0"){
              desc2 = desc2.substring(1);

              this.createBreadcrumbsP[1] = [desc2_d, desc2];
            }
          }

          if(params['desc3']){
            let desc3 = this.geturlvalue(params['desc3']);
            let desc3_d = this.getdescriptionurl(desc3.slice(0, 1));
            if(desc3_d!=="0"){
              desc3 = desc3.substring(1);
              this.createBreadcrumbsP[2] = [desc3_d, desc3];
            }
          }


          if(params['desc4']){
            let desc4 = this.geturlvalue(params['desc4']);
            let desc4_d = this.getdescriptionurl(desc4.slice(0,1));
            if(desc4_d!=="0"){
              desc4 = desc4.substring(1);
              this.createBreadcrumbsP[3] = [desc4_d, desc4];
            }
          }



          if(params['desc5']){
            let desc5 = this.geturlvalue(params['desc5']);
            let desc5_d = this.getdescriptionurl(desc5.slice(0,1));
            if(desc5_d!=="0"){
              desc5 = desc5.substring(1);
              this.createBreadcrumbsP[4] = [desc5_d, desc5];
            }
          }


          if(params['desc6']){
            let desc6 = this.geturlvalue(params['desc6']);
            let desc6_d = this.getdescriptionurl(desc6.slice(0,1));
            if(desc6_d!=="0"){
              desc6 = desc6.substring(1);
              this.createBreadcrumbsP[5] = [desc6_d, desc6];
            }
          }


          if(params['desc7']){
            let desc7 = this.geturlvalue(params['desc7']);
            let desc7_d = this.getdescriptionurl(desc7.slice(0,1));
            if(desc7_d!=="0"){
              desc7 = desc7.substring(1);
              this.createBreadcrumbsP[6] = [desc7_d, desc7];
            }
          }




        }
      }


      if(this.countParams==Object.keys(params).length && this.checkchangelang==false){
        this.createBreadcrumbsP = [];
        this.filter.menu = null;
        this.filter.genre = null;
        this.filter.cat = null;
        this.filter.subcat = null;
        this.filter.sub2cat = null;
        this.filter.brand = null;
        this.filter.collection = null;
        this.filter.color = null;
        this.filter.size = null;

        this.filterInitial.color = null;
        this.filterInitial.size = null;
        this.filterInitial.menu = null;
        this.filterInitial.genre = null;
        this.filterInitial.cat = null;
        this.filterInitial.subcat = null;
        this.filterInitial.sub2cat = null;
        this.filterInitial.brand = null;
        this.filterInitial.collection = null;



         /*if(params['paramname1']){
           this.filterInitial[params['paramname1']] = params['paramval1'];
           this.filter[params['paramname1']] = params['paramval1'];
         }
         if(params['paramname2']){
           this.filterInitial[params['paramname2']] = params['paramval2'];
           this.filter[params['paramname2']] = params['paramval2'];
         }
         if(params['paramname3']){
           this.filterInitial[params['paramname3']] = params['paramval3'];
           this.filter[params['paramname3']] = params['paramval3'];
         }*/


         if(params['desc1']){
           if(params['desc1']=="novidades" || params['desc1']=="news" || params['desc1']=="nouveautes" || params['desc1']=="novedades"  ){
             this.filter['menu'] = -2;
             this.filterInitial['menu'] = -2;
             this.createBreadcrumbsP[0] = ["menu", -2];
           }else if(params['desc1']=="stockoff" || params['desc1']=="stock-off" || params['desc1']=="saldos"   ){
             this.filter['menu'] = -1;
             this.filterInitial['menu'] = -1;
             this.createBreadcrumbsP[0] = ["menu", -1];
           }else{
              let desc1 = this.geturlvalue(params['desc1']);
              let desc1_d = this.getdescriptionurl(desc1.slice(0,1));
              if(desc1_d!=="0"){
                desc1 = desc1.substring(1);
                if(desc1_d=="search"){
                  desc1 = params['desc1'].slice(0, -1);
                }
                this.createBreadcrumbsP[0] = [desc1_d, desc1];
                this.filter[desc1_d] = desc1;
                this.filterInitial[desc1_d] = desc1;
              }
            }
          }

          if(params['desc2']){
            let desc2 = this.geturlvalue(params['desc2']);
            let desc2_d = this.getdescriptionurl(desc2.slice(0,1));
            if(desc2_d!=="0"){
              desc2 = desc2.substring(1);
              this.filter[desc2_d] = desc2;
              this.filterInitial[desc2_d] = desc2;
              this.createBreadcrumbsP[1] = [desc2_d, desc2];
            }
          }

          if(params['desc3']){
            let desc3 = this.geturlvalue(params['desc3']);
            let desc3_d = this.getdescriptionurl(desc3.slice(0, 1));
            if(desc3_d!=="0"){
              desc3 = desc3.substring(1);
              this.filter[desc3_d] = desc3;
              this.filterInitial[desc3_d] = desc3;
              this.createBreadcrumbsP[2] = [desc3_d, desc3];
            }
          }


          if(params['desc4']){
            let desc4 = this.geturlvalue(params['desc4']);
            let desc4_d = this.getdescriptionurl(desc4.slice(0,1));
            if(desc4_d!=="0"){
              desc4 = desc4.substring(1);
              this.filter[desc4_d] = desc4;
              this.filterInitial[desc4_d] = desc4;
              this.createBreadcrumbsP[3] = [desc4_d, desc4];
            }
          }

          if(params['desc5']){
            let desc5 = this.geturlvalue(params['desc5']);
            let desc5_d = this.getdescriptionurl(desc5.slice(0,1));
            if(desc5_d!=="0"){
              desc5 = desc5.substring(1);
              this.filter[desc5_d] = desc5;
              this.filterInitial[desc5_d] = desc5;
              this.createBreadcrumbsP[4] = [desc5_d, desc5];
            }
          }



          if(params['desc6']){
            let desc6 = this.geturlvalue(params['desc6']);
            let desc6_d = this.getdescriptionurl(desc6.slice(0,1));
            if(desc6_d!=="0"){
              desc6 = desc6.substring(1);
              this.filter[desc6_d] = desc6;
              this.filterInitial[desc6_d] = desc6;
              this.createBreadcrumbsP[5] = [desc6_d, desc6];
            }
          }


          if(params['desc7']){
            let desc7 = this.geturlvalue(params['desc7']);
            let desc7_d = this.getdescriptionurl(desc7.slice(0,1));
            if(desc7_d!=="0"){
              desc7 = desc7.substring(1);
              this.filter[desc7_d] = desc7;
              this.filterInitial[desc7_d] = desc7;
              this.createBreadcrumbsP[6] = [desc7_d, desc7];
            }
          }

        if(this.localstorageService.get('detailBack')){
          this.localstorageService.remove('detailBack');
        }
        this.itemsList=[];
        this.ngOnInit();
      }
      if(this.checkchangelang){
        this.checkchangelang=false;
      }
      this.localstorageService.set('show_filterInitial', this.filterInitial);
      this.localstorageService.set('show_filter', this.filter);
      this.countParams = Object.keys(params).length;
    });

    this.subscribeMenu =  this.menusharedService.get().subscribe((obj)=>{
         this.menuParent = obj[0];
         //this.checkchangelang=true;
         //this.createBreadcrumbs();
         //this.checkchangelang=false;
    });

    /*this.route.url.subscribe(params => {
      if(!this.checkchangelang){
        this.createBreadcrumbs();
      }
    });*/

    this.menugetService.send();




	this.subscribe =  this.translate.onLangChange.subscribe(lang=>{
    this.checkchangelang=true;
      this.apiService.GET_TAGS_META.forEach(key => {
        this.metaService.removeTag('name="'+key+'"');
      });


         let desc1_search = this.geturlvalue(this.route.snapshot.paramMap.get('desc1'));
         let desc1_d_search = this.getdescriptionurl(desc1_search.slice(0,1));

         if(desc1_d_search!="search"){

           this.breadcrumbs = [];
          if(this.createBreadcrumbsAll){
              for (let index = 0; index < this.createBreadcrumbsAll.length; index++) {

                this.breadcrumbs.push({ "url": (typeof this.createBreadcrumbsAll[index]["url"][lang.lang]!="undefined" ? this.createBreadcrumbsAll[index]["url"][lang.lang] : this.createBreadcrumbsAll[index]["url"][0] ), "description":(typeof this.createBreadcrumbsAll[index]["description"][lang.lang]!="undefined" ? this.createBreadcrumbsAll[index]["description"][lang.lang] : this.createBreadcrumbsAll[index]["description"][0] ) });
              }
              this.savebreadcrumbs(this.breadcrumbs);
          }

           let op = 1;
           if(this.menuParent && this.menuParent.length>0){

             let parse = this.urlsAlternate[lang.lang].replace(this.apiService.PHP_API_URL_DEFAULT+"/", "");

             parse = parse.split('/');
             parse.shift();

               for (let ix = 1; ix < this.route.snapshot.url.length; ix++) {
                 this.route.snapshot.url[ix].path = parse[ix]  ;
               }

          }
         }else{
         this.translate.get('searchresult').subscribe(translations => {
            this.breadcrumbs = [];
  					let obj: any = {};
  					obj.url = ["/show/"+this.filterInitial.search + "e"];
  					obj.description = translations + this.filterInitial.search;
  					this.breadcrumbs.push(obj);
  				});

          let parse = this.urlsAlternate[lang.lang].replace(this.apiService.PHP_API_URL_DEFAULT+"/", "");

          parse = parse.split('/');
          parse.shift();

          for (let ix = 1; ix < this.route.snapshot.url.length; ix++) {
            this.route.snapshot.url[ix].path = parse[ix]  ;
          }


          this.savebreadcrumbs(this.breadcrumbs);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="' + key + '"');
        });
        this.seoService.clearAlternateUrl();
        this.geturlalternate();
      this.metatags(lang.lang);

    });

  }

  trackByFn(index, item){
    return "item_"+item.iditem;
  }


  geturlalternate(){
  this.seoService.clearAlternateUrl();
  let desc1;
  let desc2;
  let desc3;
  let desc4;
  let desc5;
  let desc6;
  let desc7;
  if(this.route.snapshot.paramMap.get('desc1')){
    if(this.route.snapshot.paramMap.get('desc1')=="specials" || this.route.snapshot.paramMap.get('desc1')=="promotions" || this.route.snapshot.paramMap.get('desc1')=="promocoes"   ){
      desc1 = "m-1";
    }else{
      desc1 = this.geturlvalue(this.route.snapshot.paramMap.get('desc1'));
      if(desc1=="e"){
        desc1 = this.route.snapshot.paramMap.get('desc1');
      }
    }

  }
  if(this.route.snapshot.paramMap.get('desc2')){
     desc2 = this.geturlvalue(this.route.snapshot.paramMap.get('desc2'));
  }
  if(this.route.snapshot.paramMap.get('desc3')){
     desc3 = this.geturlvalue(this.route.snapshot.paramMap.get('desc3'));
  }
  if(this.route.snapshot.paramMap.get('desc4')){
     desc4 = this.geturlvalue(this.route.snapshot.paramMap.get('desc4'));
  }
  if(this.route.snapshot.paramMap.get('desc5')){
     desc5 = this.geturlvalue(this.route.snapshot.paramMap.get('desc5'));
  }
  if(this.route.snapshot.paramMap.get('desc6')){
     desc6 = this.geturlvalue(this.route.snapshot.paramMap.get('desc6'));
  }
  if(this.route.snapshot.paramMap.get('desc7')){
     desc7 = this.geturlvalue(this.route.snapshot.paramMap.get('desc7'));
  }
  this.apiService.getUrlAlternate(desc1, desc2, desc3, desc4, desc5, desc6, desc7).subscribe((obj: any)=>{
    if(typeof obj!=="undefined" && obj.code==200){

      this.urlsAlternate = obj.info;

      for (var [key, value] of Object.entries(obj.info)) {
        this.seoService.updateAlternateUrl(value.toString(), key);
      }

      this.seoService.updateAlternateUrl(obj.info['pt'], 'x-default');


    }else{
      this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
    }
  });
}

  stopScrollF(v){
    this.stopScroll=v;
    this.localstorageService.set('show_stopScroll', this.stopScroll);
  }

  calcnewurl(desc, abbr, id){

  if(desc!=""){
    desc = this.lowercase.transform(this.urlpipePipe.transform(desc));
    desc += "-"+abbr+id;
  }
    let finalurl = [this.localize.translateRoute('/show') ];
  let desc1 = this.route.snapshot.paramMap.get('desc1');
  let desc2 = this.route.snapshot.paramMap.get('desc2');
  let desc3 = this.route.snapshot.paramMap.get('desc3');
  let desc4 = this.route.snapshot.paramMap.get('desc4');
  let desc5 = this.route.snapshot.paramMap.get('desc5');
  let desc6 = this.route.snapshot.paramMap.get('desc6');
  let desc7 = this.route.snapshot.paramMap.get('desc7');
  if(!desc1){
    return "";
  }
  let entry = false;
  let objcal;let objcalletter;
  if(desc1=="novidades" || desc1=="news" || desc1=="nouveautes" || desc1=="novedades"  ){
    finalurl.push(desc1);
  }else if(desc1=="specials" || desc1=="promotions" || desc1=="promocoes"   ){
    finalurl.push(desc1);
  }else{
     objcal = this.geturlvalue(desc1);
     objcalletter = objcal.slice(0,1);
     if(objcalletter==abbr){
       if(desc!=""){
         finalurl.push(desc);
       }
       desc2=null;desc3=null;desc4=null;desc5=null;desc6=null;desc7=null;
       entry = true;
     }else{
       finalurl.push(desc1);
     }
  }

  if(desc2){
    objcal = this.geturlvalue(desc2);
    objcalletter = objcal.slice(0,1);
    if(objcalletter==abbr){
      if(desc!=""){
        finalurl.push(desc);
      }
       entry = true;
       desc3=null;desc4=null;desc5=null;desc6=null;desc7=null;
    }else{
      finalurl.push(desc2);
    }
  }else if(!entry){
    if(desc!=""){
      finalurl.push(desc);
    }
     entry = true;
     desc3=null;desc4=null;desc5=null;desc6=null;desc7=null;
  }


  if(desc3){
    objcal = this.geturlvalue(desc3);
    objcalletter = objcal.slice(0,1);
    if(objcalletter==abbr){
      if(desc!=""){
        finalurl.push(desc);
      }
       desc4=null;desc5=null;desc6=null;desc7=null;
       entry = true;
    }else{
      finalurl.push(desc3);
    }
  }else if(!entry){
    if(desc!=""){
      finalurl.push(desc);
    }
    entry = true;
     desc4=null;desc5=null;desc6=null;desc7=null;
  }


  if(desc4){
    objcal = this.geturlvalue(desc4);
    objcalletter = objcal.slice(0,1);

    if(objcalletter==abbr){
      if(desc!=""){
        finalurl.push(desc);
      }
      entry = true;
       desc5=null;desc6=null;desc7=null;
    }else{
      finalurl.push(desc4);
    }
  }else if(!entry){
    if(desc!=""){
      finalurl.push(desc);
    }
    entry = true;
     desc5=null;desc6=null;desc7=null;
  }




  if(desc5){
    objcal = this.geturlvalue(desc5);
    objcalletter = objcal.slice(0,1);

    if(objcalletter==abbr){
      if(desc!=""){
        finalurl.push(desc);
      }
      entry = true;
       desc6=null;desc7=null;
    }else{
      finalurl.push(desc5);
    }
  }else if(!entry){
    if(desc!=""){
      finalurl.push(desc);
    }
    entry = true;
     desc6=null;desc7=null;
  }



  if(desc6){
    objcal = this.geturlvalue(desc6);
    objcalletter = objcal.slice(0,1);

    if(objcalletter==abbr){
      if(desc!=""){
        finalurl.push(desc);
      }
      entry = true;
        desc7=null;
    }else{
      finalurl.push(desc6);
    }
  }else if(!entry){
    if(desc!=""){
      finalurl.push(desc);
    }
    entry = true;
      desc7=null;
  }


  if(desc7){
    if(desc!=""){
      finalurl.push(desc);
    }
    entry = true;
  }else if(!entry){
    entry = true;
    if(desc!=""){
      finalurl.push(desc);
    }
  }


  let obj = {};




    if(this.filter.color!=null && this.filter.color!="null"){

      let tl = [];
      obj['color']="";
      let colr1 = this.filter.color.toString().split(",");
      for (let index = 0; index < colr1.length; index++) {


        let colr = this.colors.find(i => i.id === colr1[index]);
        if(colr){
          if(obj['color']!=""){
            obj['color'] += ",";
          }
          let colr_child = colr.child;
          colr_child = colr_child.replace(",", ".");
          obj['color'] += colr1[index]+"-"+colr_child;
        }
      }
    }


  if(this.filter.size!=null && this.filter.size!="null"){
    obj['size'] =  this.filter['size'].toString() ;
  }


  if(Object.keys(obj).length === 0){
    this.router.navigate( finalurl  );
  }else{
    this.router.navigate( finalurl , { queryParams: obj }  );
  }


}


calcnewurlQuery(){
  this.stopScrollF(false); 
  let finalurl = [this.localize.translateRoute('/show') ];
  let desc1 = this.route.snapshot.paramMap.get('desc1');
  if(desc1){     finalurl.push(desc1);  }
  let desc2 = this.route.snapshot.paramMap.get('desc2');
  if(desc2){     finalurl.push(desc2);  }
  let desc3 = this.route.snapshot.paramMap.get('desc3');
  if(desc3){     finalurl.push(desc3);  }
  let desc4 = this.route.snapshot.paramMap.get('desc4');
  if(desc4){     finalurl.push(desc4);  }
  let desc5 = this.route.snapshot.paramMap.get('desc5');
  if(desc5){     finalurl.push(desc5);  }
  let desc6 = this.route.snapshot.paramMap.get('desc6');
  if(desc6){     finalurl.push(desc6);  }
  let desc7 = this.route.snapshot.paramMap.get('desc7');
  if(desc7){     finalurl.push(desc7);  }
  let obj = {};




    if(this.filter.color!=null && this.filter.color!="null"){

      let tl = [];
      obj['color']="";
      let colr1 = this.filter.color.toString().split(",");
      for (let index = 0; index < colr1.length; index++) {


        let colr = this.colors.find(i => i.id === colr1[index]);
        if(colr){
          if(obj['color']!=""){
            obj['color'] += ",";
          }
          let colr_child = colr.child;
          colr_child = colr_child.replace(",", ".");
          obj['color'] += colr1[index]+"-"+colr_child;
        }
      }
    }


  if(this.filter.size!=null && this.filter.size!="null"){
    obj['size'] =  this.filter['size'].toString() ;
  }

  this.router.navigate( finalurl , { queryParams: obj } );
  this.itemsList = [];
  this.getItems(0);
}

  addFilterColor(idcolor){
    if(idcolor!=null && this.filter.color==null){
      this.filter.color = ""+idcolor;
    }else{
      idcolor = idcolor.toString();
      let colr = this.filter.color.toString().split(",");
      if(colr.indexOf(idcolor) != -1)
      {
         colr.splice(colr.indexOf(idcolor), 1);
      }else{
        colr.push(idcolor);
      }
      this.filter.color = colr.join(",");
    }
  }

  checkcolorSel(idcolor){
    if(this.filter.color==null){
      return false;
    }
    let colr = this.filter.color.toString().split(",");

    if(colr.indexOf(idcolor) != -1)
    {
       return true;
    }else{
      return false;
    }
  }


  checksizeSel(idsize){
    if(this.filter.size==null){
      return false;
    }
    let colr = this.filter.size.toString().split(",");

    if(colr.indexOf(idsize) != -1)
    {
       return true;
    }else{
      return false;
    }
  }



  addFilterSize(idsize){
    if(idsize!=null && this.filter.size==null || (idsize!=null && this.filter.size=='')){
      this.filter.size = ""+idsize;
    }else{
      idsize = idsize.toString();
      let colr = this.filter.size.toString().split(",");
      if(colr.indexOf(idsize) != -1)
      {
         colr.splice(colr.indexOf(idsize), 1);
      }else{
        colr.push(idsize);
      }
      this.filter.size = colr.join(",");
    }
  }


  changeSizeList(item, color){
    return  item.colors[color];
  }

  extractData(result){
    this.itemsList = result;
   if(this.itemsList==null){
     this.localstorageService.remove('detailBack');
     this.getItems(0);
   }

   if(this.entryNgInit==true){
     this.extractDataEvent();
   }else{
     this.setIntervalClear = setInterval(() => {this.extractDataEvent(); }, 100);
   }
  // this.entryNgInit=true;
 }

  extractDataEvent(){

   /*if(this.entryNgInit==true && this.localstorageService.get('show_position')<=document.body.scrollHeight){
     clearInterval(this.setIntervalClear);

     if(this.localstorageService.get('show_position')<500){
       window.scrollTo(0, this.localstorageService.get('show_position'));
     }else{
       window.scrollTo(0, this.localstorageService.get('show_position')-985);
     }
     this.localstorageService.remove('detailBack');
     
   }else{
     clearInterval(this.setIntervalClear);
     this.setIntervalClear = setInterval(() => {this.extractDataEvent(); }, 100);
   }*/
 }

  createBreadcrumbs(){
    this.breadcrumbs = [];


      if(this.filterInitial.search){
        this.breadcrumbs = [];



        let f = this.createBreadcrumbsP ;
        if(f.length){
          this.apiService.getbreadcrumbs(f, 'show').subscribe((obj: any) => {
            if(obj.code==200 && obj.info){
              this.breadcrumbs = [];
                this.createBreadcrumbsAll = obj.info;
                for (let index = 0; index < obj.info.length; index++) {

                  this.breadcrumbs.push({ "url": (typeof obj.info[index]["url"][this.translate.currentLang]!="undefined" ? obj.info[index]["url"][this.translate.currentLang] : obj.info[index]["url"][0] ), "description":(typeof obj.info[index]["description"][this.translate.currentLang]!="undefined" ? obj.info[index]["description"][this.translate.currentLang] : obj.info[index]["description"][0] ) });
                }

                this.savebreadcrumbs(this.breadcrumbs);
            }
          });

        }
      }else{

        this.apiService.getbreadcrumbs(this.createBreadcrumbsP, 'show').subscribe((obj: any) => {
          if(obj.code==200 && obj.info){
            this.breadcrumbs = [];
              this.createBreadcrumbsAll = obj.info;
              for (let index = 0; index < obj.info.length; index++) {

                this.breadcrumbs.push({ "url": (typeof obj.info[index]["url"][this.translate.currentLang]!="undefined" ? obj.info[index]["url"][this.translate.currentLang] : obj.info[index]["url"][0] ), "description":(typeof obj.info[index]["description"][this.translate.currentLang]!="undefined" ? obj.info[index]["description"][this.translate.currentLang] : obj.info[index]["description"][0] ) });
              }

              this.savebreadcrumbs(this.breadcrumbs);
          }
        });


      }

  }


/*  createBreadcrumbsRecursive(menu:any, obj:any , params:any, index:any){
    menu.forEach((menucicle: any, key: any) => {
        if(menucicle.id==this.filterInitial[menucicle.attr]){
          if(menucicle.descriptiontranslate){
            this.translate.get( menucicle.descriptiontranslate ).subscribe(translations => {

              let objSend:any = {};
              obj.push(this.lowercase.transform(this.urlpipePipe.transform(translations)));
              objSend.url = JSON.parse(JSON.stringify(obj));
              objSend.description=translations;



              if(this.route.snapshot.url[index].path!=this.lowercase.transform(this.urlpipePipe.transform(translations)) ){
                this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
              }



              this.breadcrumbs.push(objSend);
            });
          }else{
            let objSend:any = {};
            obj.push(this.lowercase.transform(this.urlpipePipe.transform(menucicle.description))+"-"+menucicle.attr1+menucicle.id);
            objSend.url = JSON.parse(JSON.stringify(obj));
            objSend.description=menucicle.description;

            this.breadcrumbs.push(objSend);


            if(this.route.snapshot.url[index].path!=this.lowercase.transform(this.urlpipePipe.transform(menucicle.description))+"-"+menucicle.attr1+menucicle.id){
              this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
            }
          }
          if(menucicle.level_info && menucicle.level_info.length>0){
            index++;
            this.createBreadcrumbsRecursive(menucicle.level_info, obj ,params, index);
          }
          return false;
        }
    });
  }*/

    replaceurl(menu:any,  op:any){
      menu.forEach((menucicle: any, key: any) => {
          if(menucicle.id==this.filterInitial[menucicle.attr]){
            if(menucicle.descriptiontranslate){
              this.translate.get( menucicle.descriptiontranslate ).subscribe(translations => {
                this.route.snapshot.url[op].path = this.lowercase.transform(this.urlpipePipe.transform(translations))  ;
              });
            }else{
              this.route.snapshot.url[op].path = this.lowercase.transform(this.urlpipePipe.transform(menucicle.description))+"-"+menucicle.attr1+menucicle.id ;
            }
            op = op+1;
            if(menucicle.level_info && menucicle.level_info.length>0 &&  this.route.snapshot.paramMap.get('desc'+op)){
              this.replaceurl(menucicle.level_info, op);
            }
            return false;
          }
      });
    }


  savebreadcrumbs(breadscrumbs){

    this.localstorageService.set('createBreadcrumbsP', this.createBreadcrumbsP);
    this.localstorageService.set('createBreadcrumbsAll', this.createBreadcrumbsAll);
    this.localstorageService.set('show_breadscrumbs', breadscrumbs);
  }


  public setTitle( newTitle: string, extra: string = "") {
	 this.titleService.setTitle( newTitle + " " +  extra);
   this.titlechangeService.send();
  }

  metatags(lang){
    if(this.metaentry){
      return;
    }
    this.metaentry=true;
	  if(this.filter.search) {
		  this.translate.get( 'searchresult' ).subscribe(translations => {  this.titleService.setTitle( translations + " " + this.filter.search); this.titlechangeService.send();    });
		  return;
	  }

	let metaType = 9;
	let metaId = 1;
	let extraString = "";

	if(this.filter.menu) {
		metaId = this.filter.menu;
		metaType = 2;
		extraString += "m"+this.filter.menu;
	}
	if(this.filter.genre) {
		metaId = this.filter.genre;
		metaType = 8;
		extraString += "g"+this.filter.genre;
	}
	if(this.filter.cat) {
		metaId = this.filter.cat;
		metaType = 4;
		extraString += "c"+this.filter.cat;
	}
	if(this.filter.subcat) {
		metaId = this.filter.subcat;
		metaType = 5;
		extraString += "s"+this.filter.subcat;
	}
	if(this.filter.sub2cat) {
		metaId = this.filter.sub2cat;
		metaType = 6;
		extraString += "u"+this.filter.sub2cat;
	}
	if(this.filter.brand) {
		metaId = this.filter.brand;
		metaType = 7;
		extraString += "b"+this.filter.brand;
	}

  if(!lang){
    return;
  }
    this.apiService.getMetatags(metaId,metaType,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title && obj.title.content){
          this.setTitle( obj.title.content, extraString);
        }
        this.metaentry = false;
        this.seoService.updateCanonicalUrl( location.protocol + '//' + location.host + location.pathname );
        if(obj.info.length==0){
          return;
        }

		this.translate.get( 'meta_description' ).subscribe(translations => {
			Object.keys(obj.info).forEach(key => {

			  if(obj.info[key].name=="description") {
				obj.info[key].content =  obj.info[key].content + " " + extraString + " " + translations;
			  }

			});
			this.metaService.addTags(obj.info);
		});


      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  checkoutGo(){
    this.modalService.close(this.modalDetailRef);
  }

  hideMenu(){
    this.menuhided = true;
    $(".div_select_order").hide();
    $(".div_select_brands").hide();
    $(".div_select_subcategories").hide();
    $(".div_select_sub2categories").hide();
    $(".div_select_colors").hide();
    $(".div_select_sizes").hide();
    $(".div_select_genres").hide();
		$(".chosen-brand b").addClass("closeSelect");
		$(".chosen-brand b").removeClass("openSelect");
    $('.filters-right').css('width', 'auto');
    $('.bk-filters').hide();
    if($(window).width()<=991){
      $('#filterOptions').hide();$('.filter-icon').show();$('.filter-text').hide();
    }
  }

  setmenuhided(){
    this.menuhided = false;
  }

 @HostListener('window:scroll', ['$event'])
  onScrollw(event) {

    if((this.scrollPosition<window.scrollY-100 || this.scrollPosition>window.scrollY+100) && !this.localstorageService.get('detailBack')){
      this.scrollPosition = window.scrollY;
      this.localstorageService.set('show_position', this.scrollPosition);
      // console.log(this.localstorageService.get('show_position'));
    }
  }

  onScroll(event) {
    if(this.stopScroll==false){
      this.page++;
      this.localstorageService.set('show_page', this.page);
      this.getItems(1);
    }
  }
  


  urlmount(product:any, desc:any, iditem:any, idcolor?:any, idsize?:any){


    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";

    if(this.translate.currentLang && product.catlangs && product.catlangs[this.translate.currentLang] && product.catlangs[this.translate.currentLang].description) {
      cat = product.catlangs[this.translate.currentLang].description;
    }
    brand = (product.brandName) ? product.brandName : '';

    if(product.itemwithattr==1){
      let id_color = idcolor;
      if(!idcolor){
        id_color = this.color(product);
      }
      let id_size = idsize;
      if(!idsize){
        id_size = this.size(product, id_color);
      }
      color = product.colors[id_color][id_size].name;
      if(product.colors[id_color][id_size].priceFinal) { pval = product.colors[id_color][id_size].priceFinal; }
    }else{
      pval = product.priceFinal;
    }

    this.angulartics2GAEcommerce.ecAddProduct({
          'id': product.iditem,
          'name': desc,
          'category': cat,
          'brand': brand,
          'variant': color,
          'position': 0
        });
        this.angulartics2GAEcommerce.ecSetAction("click", {});



  this.angulartics2.eventTrack.next({
    action: 'productClick',
    properties: {
      label: 'productClick',
      currency: "EUR",
      content_type: 'product_group',
      "event":"productClick",
      gtmCustom:{
        'ecommerce': {
          'remove': undefined,
          'add': undefined,
          'purchase': undefined,
          'checkout': undefined,
          'detail': undefined,
          'impressions': undefined,
          'click': {
            'actionField': {'list': 'List Items Click'},    // 'detail'
            'products': [{
              'id': product.iditem,
              'name': desc,
              'price': pval,
              'brand': brand,
              'category': cat,
              'variant': color
             }]
           }
         }
      }
    }
  });

  // this.angulartics2.eventTrack.next({
  //   action: 'view_item',
  //   properties: {
  //     'view_item': {
  //       'currency': 'EUR', 'value': pval,    // 'detail'
  //       'items': [{
  //         'item_id': product.iditem,
  //         'item_name': desc,
  //         'price': pval,
  //         'item_brand': brand,
  //         'item_category': cat,
  //         'item_variant': color
  //        }]
  //      }
  //   }
  // })

setTimeout(()=>{
    if(idsize){
      this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor, idsize]);return false;
    }else
    if(idcolor){
      this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor]);return false;
    }else{
      this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem)]);return false;
    }
    }, 100);
  }

  ngOnInit() {
    this.seoService.updateCanonicalUrl( location.protocol + '//' + location.host + location.pathname );
    this.createBreadcrumbs();
    this.apiService.GET_TAGS_META.forEach(key => {
      this.metaService.removeTag('name="' + key + '"');
    });
    this.geturlalternate();
    this.metatags(this.translate.currentLang);
    if(!this.localstorageService.get('detailBack')){

      this.page=1;
      this.localstorageService.set('show_page', this.page);
      this.stopScroll=false;
      this.localstorageService.set('show_stopScroll', this.stopScroll);
      this.getBrandsFilter();

      this.sub2cat=null;
      this.subcat=null;
      this.cat=null;

      this.localstorageService.set('show_subcat', null);
      this.localstorageService.set('show_cat', null);
      this.localstorageService.set('show_sub2cat', null);

      if(this.filter.cat!=null && this.filter.cat>0){
        if(this.filter.subcat!=null && this.filter.subcat>0){
          this.getSub2CatFilter();
        }else{
          this.getSubCatFilter();
        }
      }else{
        this.getCatFilter();
      }

      // this.getCatFilter();
      // this.getSubCatFilter();
      // this.getSub2CatFilter();

      this.getColors();
      this.getSizes();
      this.getGenres();
      this.getItems(0);

    }else{

    }
    this.getDescText();
  }

  ngOnDestroy(): void {
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
    this.subscribe.unsubscribe();
    this.subscribeMenu.unsubscribe();
    this.subscribe2.unsubscribe();
  }

  ngAfterViewInit(){

    // If we have a flag set
    if(this.localstorageService.get('detailBack')){
        window.setTimeout(() => { // We set a timer to scroll, this is to make sure data loads
          window.scrollTo({behavior: 'smooth',top:this.scrollPosition, left:0}); // Scroll smoothly to the previous part
          this.localstorageService.remove('detailBack'); // Remove the flag so we can have the new page position
        }, 700);
        
      }
    this.entryNgInit=true;
  }


  getDescText(){
    let type=0;
    let id=0;
    if(this.filter.genre){
      type=0;
      id=this.filter.genre;
    }
    if(this.filter.cat){
      type=1;
      id=this.filter.cat;
    }
    if(this.filter.subcat){
      type=2;
      id=this.filter.subcat;
    }
    if(this.filter.sub2cat){
      type=3;
      id=this.filter.sub2cat;
    }
    if(this.filter.brand){
      type=4;
      id=this.filter.brand;
    }
    this.apiService.getShowDescription(type, id).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.descText = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


cicle(color: any){
  let key = Object.keys(color)[0];
  return color[key].images;
}

showcolorInfo(color:any, op:any){
  let key = Object.keys(color.value)[0];
  if(op==1){
    return color.value[key].code;
  }else{
    if(!color.value[key].langs[this.translate.currentLang]){
      return color.value[key].name;
    }
    return color.value[key].langs[this.translate.currentLang].name;
  }
}


  loadImgThun(item: any, colorSelect?:any){
    if(item.itemwithattr==1){
      if(item.colors.length==0){
        console.log("ERROR "+item.iditem);
        return "";
      }

      if(colorSelect && item.colors[colorSelect] === undefined){
        colorSelect = undefined;
      }
      if(colorSelect && typeof colorSelect!="undefined"){
        let key = Object.keys(item.colors[colorSelect])[0];
        if(this.browserwebp && item.colors[colorSelect][key].originthumbnails.webp){
          return [this.apiService.imagesDir+item.colors[colorSelect][key].originthumbnails.webp, item.colors[colorSelect][key].originthumbnails.height, colorSelect ];
        }
        return [this.apiService.imagesDir+item.colors[colorSelect][key].originthumbnails.photo, item.colors[colorSelect][key].originthumbnails.height, colorSelect ];
      }else
      if(item.colorpriority && item.colorpriority in item.colors){
        let key = Object.keys(item.colors[item.colorpriority])[0];
        if(this.browserwebp && item.colors[item.colorpriority][key].originthumbnails.webp){
          return [this.apiService.imagesDir+item.colors[item.colorpriority][key].originthumbnails.webp, item.colors[item.colorpriority][key].originthumbnails.height, item.colorpriority ];
        }
        return [this.apiService.imagesDir+item.colors[item.colorpriority][key].originthumbnails.photo, item.colors[item.colorpriority][key].originthumbnails.height, item.colorpriority ];
      }else{
        let color = Object.keys(item.colors)[0];
        let key = Object.keys(item.colors[color])[0];
        if(this.browserwebp && item.colors[color][key].originthumbnails.webp){
          return [this.apiService.imagesDir+item.colors[color][key].originthumbnails.webp, item.colors[color][key].originthumbnails.height, color ];
        }
        return [this.apiService.imagesDir+item.colors[color][key].originthumbnails.photo, item.colors[color][key].originthumbnails.height, color ];
      }
    }else{
        if(this.browserwebp && item.originthumbnails.webp){
          return [this.apiService.imagesDir+item.originthumbnails.webp, item.originthumbnails.height, null ];
        }
        return [this.apiService.imagesDir+item.originthumbnails.photo, item.originthumbnails.height, null ];
    }
  }

  loadImg(item: any){
    if(item.itemwithattr==1){
      if(item.colors.length==0){
        console.log("ERROR "+item.iditem);
        return "";
      }
      if(item.colorpriority && item.colorpriority in item.colors){
        let key = Object.keys(item.colors[item.colorpriority])[0];
        if(this.browserwebp && item.colors[item.colorpriority][key].images[0].webp){
          return this.apiService.imagesDir+item.colors[item.colorpriority][key].images[0].webp;
        }
        return this.apiService.imagesDir+item.colors[item.colorpriority][key].images[0].photo;
      }else{
        let color = Object.keys(item.colors)[0];
        let key = Object.keys(item.colors[color])[0];
        if(this.browserwebp && item.colors[color][key].images[0].webp){
          return this.apiService.imagesDir+item.colors[color][key].images[0].webp;
        }
        return this.apiService.imagesDir+item.colors[color][key].images[0].photo;
      }
    }else{
        if(this.browserwebp && item.images[0].webp){
          return this.apiService.imagesDir+item.images[0].webp;
        }
        return this.apiService.imagesDir+item.images[0].photo;
    }
  }

  getColorSelect(item: any){
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colorpriority in item.colors){
        return item.colorpriority;
      }else{
        let color = Object.keys(item.colors)[0];
        return color;
      }
    }
    return 0;
  }


  getBackgorundColor(color:any){
    let key = Object.keys(color)[0];
    if(!color[key].images || color[key].images.length==0){
      return  this.apiService.imagesDir+color[key].originthumbnails.photo ;
    }
    return  this.apiService.imagesDir+color[key].images[0].photo ;
  }

  showPrice(item:any, op:Number){
    if(op==1){
      if(item.itemwithattr==1){
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = Object.keys(item.colors[item.colorpriority])[0];
          return item.colors[item.colorpriority][key].priceOriginalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceOriginalTxt;
        }
      }else{
        return item.priceOriginalTxt;
      }
    }else{
      if(item.itemwithattr==1){
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = Object.keys(item.colors[item.colorpriority])[0];
          return item.colors[item.colorpriority][key].priceFinalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceFinalTxt;
        }
      }else{
        return item.priceFinalTxt;
      }
    }

  }

  showDiscount(item:any){
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colorpriority in item.colors){
        let key = Object.keys(item.colors[item.colorpriority])[0];
        return   Math.round(100-(100*item.colors[item.colorpriority][key].priceFinal/item.colors[item.colorpriority][key].priceOriginal));
      }else{
        let color = Object.keys(item.colors)[0];
        let key = Object.keys(item.colors[color])[0];
        return Math.round(100-(100*item.colors[color][key].priceFinal/item.colors[color][key].priceOriginal));
      }
    }else{
      return Math.round(100-(100*item.priceFinal/item.priceOriginal));
    }

  }


  getBrandsFilter(){
    let filters = this.getFinalFilters();
    this.apiService.getBrandsFilter(filters).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.brands = obj.info;
        this.localstorageService.set('show_brands', this.brands);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getSubCatFilter(){
    let filters = this.getFinalFilters();
    this.apiService.getSubCatFilter(filters).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.subcat = obj.info;
        this.localstorageService.set('show_subcat', this.subcat);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  getCatFilter(){
    let filters = this.getFinalFilters();
    this.apiService.getCatFilter(filters).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.cat = obj.info;
        this.localstorageService.set('show_cat', this.cat);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getSub2CatFilter(){
    let filters = this.getFinalFilters();
    this.apiService.getSub2CatFilter(filters).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.sub2cat = obj.info;
        this.localstorageService.set('show_sub2cat', this.sub2cat);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  getColors(){
    let filters = this.getFinalFilters();
    this.apiService.getColorsFilter(filters).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.colors = obj.parent;
        this.localstorageService.set('show_colors', this.colors);
        this.filterstemporary="";
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  getSizes(){
    let filters = this.getFinalFilters();
    this.apiService.getSizesFilter(filters).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.sizes = obj.info;
        this.localstorageService.set('show_sizes', this.sizes);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getGenres(){
    let filters = this.getFinalFilters();
    this.apiService.getGenresFilter(filters).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.genres = obj.info;
        this.localstorageService.set('show_genres', this.genres);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  changeColordefault(indexprimary, color){
    let imgSize = this.loadImgThun(this.itemsList[indexprimary], color);
    this.itemsList[indexprimary].thumbnails = imgSize[0];
    this.itemsList[indexprimary].thumbnailsheight = imgSize[1];
    this.itemsList[indexprimary].thumbnailscolor = imgSize[2];
  }


  filterNewItem(){
    var element = <HTMLInputElement> document.getElementById("new_filter");
    var isChecked = element.checked;
    if(isChecked){
  		this.filter.novelty=1;
  	}else{
  		this.filter.novelty=0;
  	}
  }

  cleanFilters(){
    this.filter = Object.assign({}, this.filterInitial);
    this.filter.color = null;
    this.filter.size = null;
    this.filter.brand = null; 
    if(this.filter.novelty==1){
      $("#new_filter").prop("checked", true);
    }else{
      $("#new_filter").prop("checked", false);
    }
    this.localstorageService.set('show_filter', this.filter);
    this.stopScroll=false;
    this.localstorageService.set('show_stopScroll', this.stopScroll);
    this.calcnewurlQuery()
    // this.getItems(0);
  }

  checkHover(item){
    if(this.deviceDesktop){this.mouseoverElement=item.iditem;}
  }

  getFinalFilters(){
    let filters = Object.assign({}, this.filter);
    if(this.filter.color!=null && this.filter.color!="null"){
      let allcolors = [];
      if(this.colors && this.colors.length>0){
        let colr1 = this.filter.color.toString().split(",");
        for (let index = 0; index < colr1.length; index++) {
          let colr = this.colors.find(i => i.id === colr1[index]);
          if(colr){
            allcolors.push(colr.child);
          }
        }
        filters.color = allcolors.join(",");
      }else{
          filters.color = this.filterstemporary;
      }
    }
    return filters;
  }

  getItems(i:any){
    this.loadingShow=true;
    if(i==0){
      this.page = 1;
      this.localstorageService.set('show_page', this.page);
    //  this.itemsList = [];
    }
    this.localstorageService.set('show_filter', this.filter);
    this.localstorageService.set('show_orderItem', this.orderItem);

    if(this.localize && this.localize.parser){
      this.filter['lang'] = this.localize.parser.currentLang;
    }else if(this.translate && this.translate.currentLang){
      this.filter['lang'] = this.translate.currentLang;
    }
    let filters = this.getFinalFilters();
    this.apiService.getItemsShow(filters, this.orderItem, this.page).subscribe((obj: any)=>{
      this.loadingShow=false;
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.info.length==0){
          if(this.page==1){
            this.emptyItem=1;6
            this.localstorageService.set('show_emptyItem', this.emptyItem);
          }

          this.stopScroll = true;
          this.localstorageService.set('show_stopScroll', this.stopScroll);

          if(i==0){
            this.itemsList = obj.info;
            this.localstorageService.set('show_itemsList' , obj.info, 1);
            //this.keeplistservice.send(obj.info,this.page);
          }

        }else{
          let imgSize;
          for (let i = 0; i < obj.info.length; i++) {
            let colorselected = undefined;


            if(this.route.snapshot.queryParams['color']){
              let querycolor = this.route.snapshot.queryParams['color']
              querycolor = querycolor.replace(".",",");
              querycolor = querycolor.replace("-",",");
              let subArrcolor = querycolor.split(",");
              let finalArrColor = [];
              subArrcolor.forEach(element => {
                let subs = null;
                let subspoint = null;
                if(element.includes("-")){
                    subs = element.split("-"); 
                }
                if(element.includes(".")){
                  subspoint = element.split(".");
                }
                if(subs){
                  subs.forEach(element => {
                    finalArrColor.push(element);
                  })
                }
                if(subspoint){
                  subspoint.forEach(element => {
                    finalArrColor.push(element);
                  })
                }
                if(subs===null && subspoint===null) {
                  finalArrColor.push(element)
                }
              })

              Object.keys(obj.info[i].colors).forEach( elementitem => {
                finalArrColor.forEach(elementcolor => {
                  if(elementcolor === elementitem){
                    colorselected = elementcolor;
                    return;
                  }
                });
              });

            }
            
              imgSize = this.loadImgThun(obj.info[i],colorselected);
              obj.info[i].thumbnails = imgSize[0];
              obj.info[i].thumbnailsheight = imgSize[1];
              obj.info[i].thumbnailscolor = imgSize[2];
          }

          for (let k = 0; k < obj.info.length; k++) {
            Object.entries(obj.info[k].langs).forEach(([key, value]) => {
                obj.info[k].langs[key].url = this.apiService.PHP_API_URL_DEFAULT+"/"+key+"/product/"+this.lowercase.transform(this.urlpipePipe.transform(value['description'])+"-"+obj.info[k].iditem);
            });
          }


          this.emptyItem=0;
          this.localstorageService.set('show_emptyItem', this.emptyItem);
          if(i==0 || !this.itemsList){
            this.itemsList = obj.info;
          }else{
            let iList = this.itemsList.concat(obj.info);
            this.itemsList = iList;
          }
          this.localstorageService.set('show_itemsList', this.itemsList, 1);



          let desc:any= "";
  			let cat:any = "";
        let brand:any = "";
        let color:any = "";
        let pval:any = "";
        let idcolor:any;
        let idsize:any;
        let galayer:any = [];
        let galayerga4:any = [];
        for (let index = 0; index < obj.info.length; index++) {
          if(this.translate.currentLang && obj.info[index].langs[this.translate.currentLang]){
    			  desc = obj.info[index].langs[this.translate.currentLang].description;
    			}else{
    			  desc = obj.info[index].description;
    			}
          if(this.translate.currentLang && obj.info[index].catlangs && obj.info[index].catlangs[this.translate.currentLang] && obj.info[index].catlangs[this.translate.currentLang].description) {
    				cat = obj.info[index].catlangs[this.translate.currentLang].description;
    			}
          brand = (obj.info[index].brandName) ? obj.info[index].brandName : '';
          if(obj.info[index].itemwithattr==1){
            idcolor = Object.keys(obj.info[index].colors)[0];
            idsize = Object.keys(obj.info[index].colors[idcolor])[0];
            color = obj.info[index].colors[idcolor][idsize].name;
            if(obj.info[index].colors[idcolor][idsize].priceFinal) { pval = obj.info[index].colors[idcolor][idsize].priceFinal; }
          }else{
            pval = obj.info[index].priceFinal;
          }

          this.angulartics2GAEcommerce.ecAddImpression({
                'id': obj.info[index].iditem,
                'name': desc,
                'category': cat,
                'brand': brand,
                'variant': color,
                'position': index
              });
              //this.angulartics2GAEcommerce.ecSetAction("List Results", {});
              galayer.push({
                'id': obj.info[index].iditem,
                'name': desc,
                'price': pval,
                'brand': brand,
                'category': cat,
                'variant': color
               });
               galayerga4.push({
                'item_id': obj.info[index].iditem,
                'item_name': desc,
                'price': pval,
                'item_brand': brand,
                'item_category': cat,
                'item_variant': color
              });
        }

        this.angulartics2.eventTrack.next({
  				action: 'impressions',
  				properties: {
            label: 'impressions',
  					currency: "EUR",
            "event":"Impressions",
            gtmCustom:{
              'ecommerce': {
                'remove': undefined,
                'add': undefined,
                'purchase': undefined,
                'checkout': undefined,
                'detail': undefined,
                'click': undefined,
                'impressions': {
                  'actionField': {'list': 'List Results'},    // 'detail'
                  'products': galayer
                 }
               }
            }
  				}
  			});

        // this.angulartics2.eventTrack.next({
        //   action: 'view_item_list',
  			// 	properties: {
        //     'view_item_list': {
        //       'item_list_id': 'List_Results',    // 'detail'
        //       'items': galayerga4
        //      }
        //   }
        // })
          //this.keeplistservice.send(obj.info,this.page);
        }

      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }


  changeColor(color){
    this.selectItem.colorActive = color;
    this.selectItem.sizeActive = Object.keys(this.selectItem.colors[this.selectItem.colorActive])[0];
  }

  changeSize(size){
    this.selectItem.sizeActive =size;
  }

loadPriceFastBuy(op:Number){
  if(!this.selectItem){
    return 0;
  }
  if(op==1){
    if(this.selectItem.itemwithattr==1){
      return this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].priceOriginalTxt;
    }else{
      return this.selectItem.priceOriginalTxt;
    }
  }else{
    if(this.selectItem.itemwithattr==1){
      return this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].priceFinalTxt;
    }else{
      return this.selectItem.priceFinalTxt;
    }
  }
}

  color(i:any){
    if(i.itemwithattr==1){
      if(i.colorpriority && i.colors[i.colorpriority] ){
        return i.colorpriority;
      }else{
        return Object.keys(i.colors)[0];
      }
    }else{
      return "";
    }
  }

  size(i:any, op:any){
    if(i.itemwithattr==1){
      if(op>0){
        return Object.keys(i.colors[op])[0];
      }
      if(i.colorpriority && i.colors[i.colorpriority] ){
        return Object.keys(i.colors[i.colorpriority])[0];
      }else{
        let col = Object.keys(i.colors)[0];
        return Object.keys(i.colors[col])[0];
      }
    }else{
      return "";
    }
  }

  openModal(item){
    this.modalDetailRef = this.modalService.open(this.ModalDetail, {
        size: 'lg',
        windowClass: 'modal-lg'  ,
        hideCloseButton: false,
        centered: false,
        animation: true,
        keyboard: false,
        closeOnOutsideClick: false,
  backdropClass: 'modal-backdrop'
    })
    this.selectItem = item;
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colors[item.colorpriority] ){
        this.selectItem.colorActive = item.colorpriority; 
      }else{
        this.selectItem.colorActive = Object.keys(item.colors)[0];
      }
      this.selectItem.sizeActive = Object.keys(item.colors[this.selectItem.colorActive])[0];
    }

    }

    closeModal(){
        this.modalService.close(this.modalDetailRef);
    }


  openModalFilters(){
    this.modalFiltersRef = this.modalService.open(this.ModalFilters, {
      size: 'md',
      windowClass: 'modal-md'  ,
      hideCloseButton: false,
      centered: false,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: false,
      backdropClass: 'modal-backdrop'
    })
  }

  closeModalFilters(){
    this.modalService.close(this.modalFiltersRef);
  }


    buyProduct(){
      let arr:any=[];
      let obj: any = {};

      let desc:any= "";
    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";
    let arrsendlitics = [];
    let arrsendliticsga4 = [];

    if(this.translate.currentLang && this.selectItem.langs[this.translate.currentLang]){
      desc = this.selectItem.langs[this.translate.currentLang].description;
    }else{
      desc = this.selectItem.description;
    }

    if(this.translate.currentLang && this.selectItem.catlangs && this.selectItem.catlangs[this.translate.currentLang] && this.selectItem.catlangs[this.translate.currentLang].description) {
      cat = this.selectItem.catlangs[this.translate.currentLang].description;
    }
    brand = (this.selectItem.brandName) ? this.selectItem.brandName : '';

      if(this.selectItem && this.selectItem.itemwithattr==1){
        if(this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].priceFinal) { pval = this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].priceFinal; }
        color = this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].name;
        arrsendlitics.push({
            'id': this.selectItem.iditem,
            'name': desc,
            'price': pval,
            'brand': brand,
            'category': cat,
            'variant': color,
            'quantity': 1
           });
        arrsendlitics.push({
        'id': this.selectItem.iditem,
        'name': desc,
        'price': pval,
        'brand': brand,
        'category': cat,
        'variant': color,
        'quantity': 1
        });

        obj.idcolor = this.selectItem.colorActive;
        obj.idsize = this.selectItem.sizeActive;
        obj.fullcode = this.selectItem.code+"-"+obj.idcolor+"-"+obj.idsize;
        obj.idcolor_integration = this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].idcolor_integration ;
        obj.idsize_integration = this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].idsize_integration ;
      }else{
        pval = this.selectItem.priceFinal;

        arrsendlitics.push({
        'id': this.selectItem.iditem,
        'name': desc,
        'price': pval,
        'brand': brand,
        'category': cat,
        'variant': color,
        'quantity': 1
       });

       arrsendliticsga4.push({
        'item_id': this.selectItem.iditem,
        'item_name': desc,
        'price': pval,
        'item_brand': brand,
        'item_category': cat,
        'item_variant': color,
        'quantity': 1
       });

        obj.fullcode = this.selectItem.code;
      }
      obj.iditem = this.selectItem.iditem;
      obj.idmanager_company = this.selectItem.idmanager_company;
      obj.iditem_integration = this.selectItem.iditem_integration;
      obj.quantity = 1;
      arr.push(obj);

      let img;
      if(this.selectItem.itemwithattr = 1){
        img = this.apiService.imagesDir + this.selectItem.colors[obj.idcolor][obj.idsize].images[0].photo;
      }
      else{
        img = this.selectItem.images[0].photo;
      }
  
      let html = '</br></br><img alt="" src="'+img+'" width="100">';
      html += '</br>';
      this.apiService.addCart(arr).subscribe((obj: any)=>{

        if(typeof obj!=="undefined" && obj.code==200){


          this.sharedService.sendClickEvent(this.translate.currentLang);
          this.translate.get( obj.msg ).subscribe(translations => {
          
          html += '<p class="text-center mt-2">'+translations+'</p>';

          Swal.fire({
            position: 'center',
            customClass: {
              container: 'swal-index'
            },
            html: html,
            showConfirmButton: true,
            showCancelButton: true,
            reverseButtons:true,
            confirmButtonText: this.translate.instant('checkout'),
            cancelButtonText: this.translate.instant('keepbuying'),
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
               this.router.navigate( [this.localize.translateRoute('/checkout')]  );
            }
          });
          });



          arrsendlitics.forEach((val) => {

          this.angulartics2GAEcommerce.ecAddProduct({
                'id': val.id,
                'name': val.name,
                'category': val.category,
                'brand': val.brand,
                'variant': val.variant,
                'price': val.price,
                'quantity': val.quantity,
                'position': 0
              });
              this.angulartics2GAEcommerce.ecSetAction("add", {});

				});


        this.angulartics2.eventTrack.next({
  				action: 'AddToCart',
  				properties: {
            label: 'AddToCart',
  					currency: "EUR",
  					content_type: 'product_group',
            "event":"AddToCart",
            gtmCustom:{
              'ecommerce': {
                'remove': undefined,
                'click': undefined,
                'purchase': undefined,
                'checkout': undefined,
                'detail': undefined,
                'impressions': undefined,
                'add': {
                  'actionField': {'list': 'AddToCart'},    // 'detail'
                  'products': arrsendlitics
                 }
               }
            }
  				}
  			});

        // this.angulartics2.eventTrack.next({
        //   action: 'add_to_cart',
  			// 	properties: {
        //     'add_to_cart': {
        //       'currency': 'EUR', 'value':pval,    // 'detail'
        //       'items': arrsendliticsga4
        //      }
        //   }
        // })

        }else{
          this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations,  customClass: { container: 'swal-index' } });    });
        }
      });
    }

    changeTextMobile(arr, pos){
      if(!arr){
        return 0;
      }
      let item1 = arr.find(i => i.id === pos);
      if(item1 && item1.langs && item1.langs[this.translate.currentLang]){
        return item1.langs[this.translate.currentLang].description;
      }else if(item1){
        return item1.description;
      }
    }

    getSizesSelected(arr,pos){
      if(!arr){
        return '';
      }
      let sizes = pos.toString().split(",");
      let arrSelectedSize = [];
      sizes.forEach(element => {
        if(arrSelectedSize.length<= 3){
          let a = arr.find(i => i.id === element);
          arrSelectedSize.push(a.description);
        }
        else{
          return arrSelectedSize
        }
      });
      return arrSelectedSize;
      
    }

    changeTextMobile2(arr, pos){
      if(!arr){
        return 0;
      }
      let item1 = arr.find(i => i.id === pos);
      if(item1){
        return item1.code;
      }
    }


    geturlvalue(s){
      let t = [];
      let num ;
      for (let _i = s.length-1; _i >= 0; _i--) {
         num = s[_i];
      	t.push(num);
        if((/[a-zA-Z]/).test(num)){
      	  break;
        }
      }
      let t1=t.reverse().join("").toString();
      return t1;
    }

   getdescriptionurl(desc){
     switch (desc) {
        case 'm':
          return "menu";
          break;
        case 'g':
          return "genre";
          break;
        case 'c':
          return "cat";
          break;
        case 's':
          return "subcat";
          break;
        case 'b':
          return "brand";
          break;
        case 'o':
          return "collection";
          break;
        case 'u':
          return "sub2cat";
          break;
        case 'e':
          return "search";
          break;
        default:
          return "0";
      }
   }

   changeOrderBy($event){
    this.orderItem = $event.target.value;
    // this.stopScroll = false;this.itemsList=[];
    // this.getItems(0);this.hideMenu();
   }

   @HostListener("click")
   clickedmenu() {
     this.insidemenuclick = true;
   }
   @HostListener("document:click")
  clickedOut() {
    let a  = this.insidemenuclick
      ? "Event Triggered"
      : "Event Triggered Outside Component";

      if(!this.insidemenuclick && !this.menuhided){
        $('#filterOptions').show();$('.filter-icon').hide();$('.filter-text').show(); $('.filters-right').hide(); $('.bk-filters').show();
      }
    this.insidemenuclick = false;
  }

  checkhideshowmenu(){
    if(!this.menuhided){
      $('#filterOptions').show();$('.filter-icon').hide();$('.filter-text').show(); $('.filters-right').hide(); $('.bk-filters').show();
    }
  }

  checkFilterSelMult(id:any, op:any){
    if(this.filter[op]==null){
      return false;
    }
    let colr = this.filter[op].toString().split(",");

    if(colr.indexOf(id) != -1)
    {
       return true;
    }else{
      return false;
    }
  }

  addFilterMulti(id:any, op:any){
    // this.loaded=false;
    this.stopScroll = false;
    if(id!=null && this.filter[op]==null){
      this.filter[op] = ""+id;
    }else{
      id = id.toString();
      let colr = this.filter[op].toString().split(",");
      if(colr.indexOf(id) != -1)
      {
         colr.splice(colr.indexOf(id), 1);
      }else{
        colr.push(id);
      }
      this.filter[op] = colr.join(",");
      if(this.filter[op]==""){
        this.filter[op] = null;
      }
    }
  }

  colorSected(idcolor: string){
    if(this.filter.color){
      return this.filter.color.includes(idcolor);
    }
  }

}
