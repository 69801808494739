import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'

import { Title, Meta }     from '@angular/platform-browser';
import { SeoService } from '../seo.service';
import {   ActivatedRoute, Router, NavigationEnd   } from '@angular/router';
declare var $: any;
import { TitlechangeService } from '../titlechange.service';
@Component({
  selector: 'app-allbrands',
  templateUrl: './allbrands.component.html',
  styleUrls: ['./allbrands.component.scss']
})
export class AllbrandsComponent implements OnInit {

  allbrands:any;
  bandsmenu:any;
  menu:any;
  objectKeys = Object.keys;
  subscribe:any;
  subscribe2:any;
  stop_scroll:any = false;
  constructor(public titlechangeService:TitlechangeService,private route: Router, private router: ActivatedRoute,private seoService: SeoService, private metaService: Meta, public titleService: Title, public apiService: ApiService, public translate: TranslateService) {

    this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
      this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+lang.lang+'/contact');
        this.metatags(lang.lang);
        this.loadBrands(lang.lang);
      });






      this.subscribe2 =  this.router.params.subscribe(params => {

        if( params.letter){
           this.stop_scroll=true;

           if(document.getElementById( 'target_'+params.letter )){

             $('html, body').animate({scrollTop: $("#target_"+params.letter).offset().top-200}, 800);
            // window.scrollTo(0, document.getElementById( 'target_'+params.letter ).offsetTop );

           }

        }
      });

  }



  ngOnInit(): void {
    this.stop_scroll=false;
    this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/allbrands' );

    this.seoService.clearAlternateUrl();

    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/allbrands', 'x-default');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/allbrands', 'pt');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/allbrands', 'en');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/es/allbrands', 'es');
    this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/allbrands', 'fr');

	  this.metatags(this.translate.currentLang);

    this.loadBrands(this.translate.currentLang);



  }


  loadLetter(){
    if(this.stop_scroll==false && this.router.snapshot.paramMap.get('letter')){
       this.stop_scroll=true;
       $('html, body').animate({scrollTop: $("#target_"+this.router.snapshot.paramMap.get('letter')).offset().top-200}, 800);
    }
  }

  loadBrands(lang){
    this.apiService.getallbrands(lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.allbrands=obj.info; this.bandsmenu = obj.bandsmenu;this.menu = obj.menu;

      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }


  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.subscribe2.unsubscribe();
    this.seoService.clearAlternateUrl();
		this.seoService.removeTag('rel=canonical');
		this.seoService.removeTag('rel=alternate');
  }





  public setTitle( newTitle: string) {
	   this.titleService.setTitle( newTitle  );
     this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(30,9,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }
        /*Object.keys(obj.info).forEach(key => {
          this.metaService.removeTag('name="'+obj.info[key].name+'"');
        });*/
        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }


}
