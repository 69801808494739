<a href="https://armazensronfe.pt"  style="display:none" ><h1>{{ 'welcometowebsite' | translate }}</h1></a>


<div class="wrapper">

	<div  class="c-topbar c-bg-black-bar   color-white noPadding fixOnScroll" >
		<div class="container-fluid c-tb-padding-10">
			<p style="color:white" class="ccwhite helveticaltsd text-center noMargin c-font-13 m0" >
				<span *ngIf="this.companyPresentation">{{ this.companyPresentation.title_header }}</span>
			</p>
		</div>
	</div>



	<header class="c-layout-header">

		<div  class="sizeDocumenthtml" >
		</div>

		<div class="header_area hdr_1" id="menu_header">
			<div class="container-fluid-header">

				<div class="c-navbar-wrapper clearfix pt-20 ">
					<div style="margin-top:15px" class="col-12 d-none d-sm-none d-md-none d-lg-block float-left noPadding">
						<div class="col-4 pad0" >

							<ul style="margin-left: 15px;" class="c-line-select ">
								<li class="c-lang   c-last">
									<ul class="   languageDesktop" role="menu">
										<li><i class="zmdi zmdi-globe  pr-05 font-18"></i></li>
										<!-- <li [class]="lang ==localize.parser.currentLang ? 'activeLang' : null"  *ngFor="let lang of languagesPermit; let last = last;" >
											<a [class]="lang == localize.parser.currentLang ? 'noPadding notBlack' : 'noPadding'" (click)="this.localize.changeLanguage(lang);    this.savelang(lang);   " href="javascript:;">{{lang | uppercase  }}</a>

											<span [style.visibility]="(last) ? 'hidden' : 'visible' " class="separator_li">&nbsp;|&nbsp;</span>
										</li> -->
										<li>
											<div class="header-dropdown">
												<a href="javascript:;">{{localize.parser.currentLang | uppercase }}</a>
												<div class="header-menu">
													<ul>
														<li *ngFor="let lang of languagesPermit; let last = last;" style="width: 140px;" class="nav-item" [class.selected]="lang == this.translate.currentLang" (click)="this.localize.changeLanguage(lang); this.savelang(lang);">
															<img [src]="'assets/images/' + lang + '.png'" alt="" class="counrty-lang">
															<a href="javascript:;">{{getLang(lang)}}</a>
														</li>
													</ul>
												</div><!-- End .header-menu -->
											</div><!-- End .header-dropdown -->
										</li>
									</ul>
								</li>
							</ul>

						</div>
						<div class="col-4 text-center pad0">
							<div class="logo_area">
								<a href="javascript:void(0)"  [routerLink]="(['/'] | localize)">
									<img [src]="this.browserwebp ? 'assets/images/logo/logo.webp' : 'assets/images/logo/logo.jpg'"  alt="{{ 'Name_APP' | translate  }}" width="130px">
								</a>
							</div>
						</div>
						<div class="col-4 pad0 text-right header-cart same-style ">
							<div class="switcher menu_page">
								<ul class="c-unlisted c-inline-list c-line-select pull-right" style="margin-right: 15px;">
									<li class="switcher-menu-active">


										<a class="dropdown-toggle" [style.display]="(userLogging) ? 'none' : 'block' " href="javascript:void(0)"  ><i class="zmdi zmdi-account  pr-05 font-14"></i>{{ 'clientarea' | translate }}</a>

										<a class="dropdown-toggle" [style.display]="(!userLogging) ? 'none' : 'block' " [routerLink]="(['/account'] | localize)" href="javascript:void(0)"><i class="zmdi zmdi-account  pr-05 font-14"></i>{{ 'myaccount' | translate }}</a>

										<ng-container *ngIf="userLogging">
											<ul style="margin-right: 15px;"  [ngClass]="(userLogging) ? 'switcher__menus  menus_drop_actions c-border  widthMenuInfoUser' : 'switcher__menus  menus_drop_actions c-border ' ">
												<li class="switcher-menu-item"><a class="multipleoptions" style="width: fit-content;" href="javascript:void(0)"><span [routerLink]="(['/account'] | localize)" >{{ 'accinfo' | translate }}</span>
													<div [routerLink]="(['/profile'] | localize)" class="btn-edit-info">{{ 'editinfo' | translate }}</div> 
												</a>
												</li>
												<li class="switcher-menu-item"><a class="" [routerLink]="(['/orders'] | localize)" href="javascript:void(0)">{{ 'orders' | translate }}</a></li>
												<li class="switcher-menu-item"><a class="" [routerLink]="(['/wishlist'] | localize)" href="javascript:void(0)">{{ 'wishlist' | translate }}</a></li>
												<li class="switcher-menu-item"><a class="" (click)="logout()" href="javascript:void(0)">{{ 'logout' | translate }}</a></li>
											</ul>
										</ng-container>

										<ng-container *ngIf="!userLogging">
											<ul style="margin-top: -1px;margin-right: 15px;" class="switcher__menus  menus_drop_actions c-border text-left">
												<li style="padding: 15px !important;">
													<form  [formGroup]="signinFormMn" (ngSubmit)="onSubmitMn()">
														<div class="form-group">
															<label class="control-label font-11" for="email">{{ 'email' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
															<input type="email" (change)="changeEmailClear($event)" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email" id="emailMn" class="form-control c-square valRequired valEmail" />
															<div *ngIf="this.submitted && f.email.errors" class="invalid-feedback">
																<div *ngIf="f.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
																<div *ngIf="f.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
															</div>
														</div>
														<div class="form-group mb-0">
															<label class="control-label font-11" for="password">{{ 'password' | translate }}<i class="font-theme-color pl-02 c-font-12 fa fa-asterisk"></i></label>
															<input type="password" id="passwordMn" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" formControlName="password" class="form-control c-square valRequired" />
															<div *ngIf="this.submitted && f.password.errors" class="invalid-feedback">
																<div *ngIf="f.password.errors.required">{{ 'msg_valRequired' | translate }}</div>
																<div *ngIf="f.password.errors.minlength">{{ 'min6char' | translate }}</div>
															</div>
														</div>

														<div class="form-group">
															<div class="noPadding col-md-12 col-xs-12 text-left float-left">
																<a (click)="openModalMn()" href="javascript:void(0)" class="c-btn-forgot font-11 helveticaltsd"> {{ 'forgot_your_password' | translate }}</a>
															</div>
														</div>


														<div class="form-group mb-0 hidden">
															<div class="form-group col-sm-12 col-xs-12 pad0">
																<div class="c-checkbox">
																	<input id="checkbox1" formControlName="rememberme" name="rememberme" class="c-check" type="checkbox">
																	<label for="checkbox1" class="c-font-14 helveticaltsd">
																		<span class="inc"></span>
																		<span class="check"></span>
																		<span class="box"></span> {{ 'remember_me' | translate }}</label>
																	</div>
																</div>
															</div>

															<div class="form-group">
																<button type="submit" class="col-md-12 col-xs-12 button wpcf7-form-control register_button font-11"><span>{{ 'login' | translate }}</span></button>
															</div>
															<div class="clearfix"></div>
															<div class="row">
																<p class="col-md-12 font-11">{{ 'or_use_facebook' | translate }}</p>
																<div class="col-md-12 text-center-xs">
																	<button type="button" (click)="signInWithFBMn()" class="col-xs-12 col-md-12 button wpcf7-form-control register_button  button_facebook"><i class="zmdi zmdi-facebook pull-left"></i><span class="font-11" style="position: relative;top: 13px !important;">{{ 'login_facebook' | translate }}</span></button>
																</div>
															</div>

															<div class="clearfix"></div>
															<hr/>
															<div class="form-group mb-0">
																<p class="font-11">{{ 'register_title' | translate }}</p>
																<button type="button" [routerLink]="(['/register'] | localize)" href="javascript:void(0)" class="col-md-12 col-xs-12 button wpcf7-form-control register_button font-11 mb-0"><span>{{ 'create_account' | translate }}</span></button>
															</div>
														</form>
													</li>
												</ul>
											</ng-container>
										</li>
										<li class="">
											<div class="header-cart same-style ">
												<div onclick="$('.cartWrapper').toggle()" class="sidebar-trigger">
													<ul>
														<li *ngIf="userLogging">
															<a href="javascript:void(0);" [routerLink]="['/wishlist'] | localize" class="c-btn-icon c-cart-toggler">
																<i class="zmdi zmdi-favorite-outline"></i>
																<span [class]="this.qtdWishlist ? 'count-style cartItemCount have_prod' : 'count-style cartItemCount' " [style.display]="(this.qtdWishlist && this.qtdWishlist>0) ? 'block' : 'none' " > {{ this.qtdWishlist ?  this.qtdWishlist : ''  }} </span>
															</a>
														</li>
														<li *ngIf="!userLogging">
															<a href="javascript:void(0);" [routerLink]="['/signin'] | localize" class="c-btn-icon c-cart-toggler">
																<i class="zmdi zmdi-favorite-outline"></i>
 															</a>
														</li>
														<li>
															<a href="javascript:void(0) " class="c-btn-icon c-cart-toggler">
																<i class="zmdi zmdi-shopping-cart-plus"></i>
																<span class="count-style cartItemCount" [style.display]="(this.cart && this.cart.length>0) ? 'block' : 'none' ">{{ this.cart ?  this.cart.length : ''  }}</span>
															</a>

															<ul class="ht-dropdown main-cart-box c-cart-menu cartWrapper" id="cartWrapper">
																<li>
																	<!-- Cart Box Start -->
																	<div class="c-cart-menu-items">
																		<ng-container *ngIf="this.cart && this.cart.length>0 && this.translate.currentLang ">
																			<div class="single-cart-box" *ngFor=" let ct of  this.cart; let i=index  " >
																				<div class="cart-img">
																					<a [routerLink]="(['/product' , (ct.langs[this.translate.currentLang].description |  urlpipe | lowercase )+ '-'+  ct.iditem, ct.idcolor, ct.idsize   ] | localize)" href="javascript:void(0)">
																						<img [alt]="ct.langs[this.translate.currentLang].description" [src]="cartImg(ct)">
																					</a>
																				</div>
																				<div class="cart-content">
																					<h6>
																						<a [routerLink]="(['/product' , (ct.langs[this.translate.currentLang].description  |  urlpipe | lowercase)+ '-'+  ct.iditem, ct.idcolor, ct.idsize   ] | localize)" href="javascript:void(0)">{{ cartDesc(ct)  }}</a>
																					</h6>
																					<span class="quantitys">{{ 'quant' | translate }}: {{ quant(ct.quantity) }}</span>
																					<ng-container *ngIf="ct.withPromotion && ct.withPromotion == 1; else nopromotion">
																						<div class="text-center">
																							<span style="text-decoration: line-through;display: inline-block; margin-right: 5px; color: #000;">{{ cartPrice(ct,1) }}€
																							</span> /
																							<span style="color: red;">{{ cartPrice(ct,2) }}€</span>
																						</div>
																					</ng-container>
																					<ng-template #nopromotion>
																						<span>{{ cartPrice(ct,2) }}€</span>
																					</ng-template>
																				</div>
																				<a href="javascript:;" (click)="removeItemCart(ct, i)"   class="del-icone"><i class="zmdi zmdi-close"></i></a>
																			</div>
																		</ng-container>

																		<li *ngIf="!this.cart || this.cart.length<=0"><h5 class="font-14">{{ 'noproductscart' | translate }}</h5></li>
																	</div>
																	<!-- Cart Box End -->

																	<!-- Cart Box End -->
																	<!-- Cart Footer Inner Start -->
																	<div class="cart-footer fix text-left" *ngIf="this.cart && this.cart.length>0">

																		<span class="title_h5">
																			<span class="shippingInfo full-width float-left pad0 font-11 shippingInfo" style="display:none;">
																				{{ 'delivery' | translate }} {{ 'withvat'  | translate }} :
																				<p class="c-cart-menu-float-r c-theme-font c-font-sbold f-right font-11"><span class="c-shipping-total shippingAddStart">0</span>€</p>
																			</span>
																			{{ 'total' | translate }} :
																			<p class="c-cart-menu-float-r c-theme-font c-font-sbold f-right"><span *ngIf="this.cartTotal" class="cartTotal">{{ this.cartTotal.totalTxt }}</span>€</p>
																		</span>
																		<div class="cart-actions">
																			<a href="javascript:void(0)" [routerLink]="(['/checkout'] | localize)" class="btn btn-md c-bg-black c-btn c-btn-square c-theme-btn font-uppercase checkout col-12 btn-red-hover">{{ 'view_cart' | translate }}</a>
																		</div>
																	</div>

																	<!-- Cart Footer Inner End -->
																</li>
															</ul>
														</li>
													</ul>
												</div>
											</div>
										</li>

										<li class="c-cart-toggler-wrapp">
											<label style="display:none" for="search"></label>
											<input #searchInput type="text" name="search" class="line-search valRequired" (keyup.enter)="searchItem(searchInput);"  placeholder=" {{ 'search' | translate }}" required/>
											<span class="c-point" (click)="searchItem(searchInput)"><i class="fa fa-search font-12"></i></span>
											<button  type="button" id="buttonSearch2" class="hidden" ></button>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-12 d-block d-lg-none float-left pl-0 pr-0 pt-0 pb-10">

							<div class="col-12 text-center pt-10 pb-10 float-left pl-0 pr-0">
								<div class="col-3 pull-left leftMenu pr-0 pl-0">
									<ul class="c-unlisted c-inline-list c-line-select pull-left float-left-li">
										<li>
											<a class="c-hor-nav-toggler c-font-white" href="javascript:void(0)"  onclick="openMenuMobile();">
												<span class="c-line"></span>
												<span class="c-line"></span>
												<span class="c-line"></span>
											</a>
										</li>
										<li class="liLangMob">
											<div class="header-dropdown" style="padding-bottom: 0rem;">
												<a href="javascript:;"><img [src]="'assets/images/' + localize.parser.currentLang + '.png'" alt="" class="counrty-lang-smartphone"></a>
												<div class="header-menu">
													<ul>
														<li *ngFor="let lang of languagesPermit; let last = last;" style="width: 140px;" class="nav-item" [class.selected]="lang == this.translate.currentLang" (click)="this.localize.changeLanguage(lang); this.savelang(lang);">
															<img [src]="'assets/images/' + lang + '.png'" alt="" class="counrty-lang">
															<a href="javascript:;">{{getLang(lang)}}</a>
														</li>
													</ul>
												</div><!-- End .header-menu -->
											</div><!-- End .header-dropdown -->
										</li>
									</ul>
								</div>
								<div class="col-6 pr-0 pl-0">
									<div class="logo_area">
										<a href="javascript:void(0)" onclick="closeMenuMobile();closeListLangs();$('.cartWrapper2').hide();$('.cartWrapper').hide()" [routerLink]="(['/'] | localize)">
											<img [src]="this.browserwebp ? 'assets/images/logo/logo.webp' : 'assets/images/logo/logo.jpg'" alt="{{ 'Name_APP' | translate  }}" width="130px">
										</a>
									</div>
								</div>
								<div class="col-3 pr-0 pl-0">

									<li class="float-right" onclick="closeMenuMobile();">
										<div onclick="$('.cartWrapper2').toggle()" class="header-cart same-style mr-0">
											<div class="sidebar-trigger">
												<ul>

													<li>

														<a href="javascript:void(0);" class="c-btn-icon c-cart-toggler">
															<i class="zmdi zmdi-shopping-cart-plus"></i>
															<span [class]="this.cart ? 'count-style cartItemCount have_prod' : 'count-style cartItemCount' " [style.display]="(this.cart && this.cart.length>0) ? 'block' : 'none' " > {{ this.cart ?  this.cart.length : ''  }} </span>
														</a>



														<ul class="ht-dropdown main-cart-box c-cart-menu cartWrapper2" id="cartWrapper">
															<li>
																<!-- Cart Box Start -->
																<div class="c-cart-menu-items">
																	<ng-container *ngIf="this.cart && this.cart.length>0 && this.translate.currentLang"  >
																		<div class="single-cart-box" *ngFor=" let ct of  this.cart; let i=index  " >
																			<div class="cart-img">
																				<a onclick="$('.cartWrapper2').hide(); " [routerLink]="(['/product' , (ct.langs[this.translate.currentLang].description |  urlpipe | lowercase)+ '-'+   ct.iditem, ct.idcolor, ct.idsize    ] | localize)" href="javascript:void(0)">
																					<img [alt]="ct.langs[this.translate.currentLang].description" [src]="cartImg(ct)">
																				</a>
																			</div>
																			<div class="cart-content">
																				<h6>
																					<a onclick="$('.cartWrapper2').hide(); " [routerLink]="(['/product' , (ct.langs[this.translate.currentLang].description |  urlpipe | lowercase)+ '-'+  ct.iditem, ct.idcolor, ct.idsize   ] | localize)" href="javascript:void(0)">{{ cartDesc(ct)  }}</a>
																				</h6>
																				<span class="quantitys">{{ 'quant' | translate }}: {{ quant(ct.quantity) }}</span>
																				<ng-container *ngIf="ct.withPromotion && ct.withPromotion == 1; else nopromotion">
																					<div class="text-left">
																						<span style="text-decoration: line-through; display: inline-block; margin-right: 5px; color: #000;">{{ cartPrice(ct,1) }}€ 
																							<span class="d-none d-lg-block">/</span>
																						</span>
																						<span style="color: red; display: inline-block;">{{ cartPrice(ct,2) }}€</span>
																					</div>
																				</ng-container>
																				<ng-template #nopromotion>
																					<div class="text-left">
																						<span>{{ cartPrice(ct,2) }}€</span>
																					</div>
																				</ng-template>
																			</div>
																			<a href="javascript:;" (click)="removeItemCart(ct, i)" class="del-icone"><i class="zmdi zmdi-close"></i></a>
																		</div>
																	</ng-container>

																	<li *ngIf="!this.cart || this.cart.length<=0"><h5 class="font-14">{{ 'noproductscart' | translate }}</h5></li>
																</div>
																<!-- Cart Box End -->

																<!-- Cart Box End -->
																<!-- Cart Footer Inner Start -->
																<div class="cart-footer fix text-left" *ngIf="this.cart && this.cart.length>0">

																	<span class="title_h5">
																		<span class="shippingInfo full-width float-left pad0 font-11 shippingInfo" style="display:none;">
																			{{ 'delivery' | translate }} {{ 'withvat'  | translate }} :
																			<p class="c-cart-menu-float-r c-theme-font c-font-sbold f-right font-11"><span class="c-shipping-total shippingAddStart">0</span>€</p>
																		</span>
																		{{ 'total' | translate }} :
																		<p class="c-cart-menu-float-r c-theme-font c-font-sbold f-right"><span *ngIf="this.cartTotal" class="cartTotal">{{ this.cartTotal.totalTxt }}</span>€</p>
																	</span>
																	<div class="cart-actions">
																		<a href="javascript:void(0)" [routerLink]="(['/checkout'] | localize)" class="btn btn-md c-bg-black c-btn c-btn-square c-theme-btn font-uppercase checkout col-12 btn-red-hover">{{ 'view_cart' | translate }}</a>
																	</div>
																</div>

																<!-- Cart Footer Inner End -->
															</li>
														</ul>
													</li>
												</ul>
											</div>
										</div>

									</li>
									<li class="float-right pr-10 search_btn">
										<i class="zmdi zmdi-search" onclick="openSearch()"></i>
									</li>

									<li class="float-right pr-10 search_btn" *ngIf="userLogging">
										<a href="javascript:void(0);" [routerLink]="['/wishlist'] | localize" class="c-btn-icon c-cart-toggler">
											<i class="zmdi zmdi-favorite-outline" style="font-size: 18pt !important;padding-top: 8px;"></i>
											<span [class]="this.qtdWishlist ? 'count-style cartItemCount have_prod' : 'count-style cartItemCount' " [style.display]="(this.qtdWishlist && this.qtdWishlist>0) ? 'block' : 'none' " > {{ this.qtdWishlist ?  this.qtdWishlist : ''  }} </span>
										</a>
									</li>

									<li class="float-right pr-10 search_btn ">
										<a href="javascript:void(0);" onclick="closeMenuMobile();" [routerLink]="(['/signin'] | localize)" class="c-btn-icon" *ngIf="!userLogging"><i class="zmdi zmdi-account" style="font-size: 18pt !important;padding-top: 8px;"></i></a>
										<a href="javascript:void(0);" onclick="closeMenuMobile();" [routerLink]="(['/account'] | localize)" class="c-btn-icon hidden-xs hidden-sm" *ngIf="userLogging"><i class="zmdi zmdi-account" style="font-size: 18pt !important;padding-top: 8px;"></i></a>
									</li>
								</div>
							</div>
							<div class="col-12 float-left full-width pad0 text-right" id="searchDivForm" style="display:none;">

								<ul class="c-unlisted c-inline-list c-line-select pull-right col-12 pad0">



									<li class="c-cart-toggler-wrapp col-12 pad0 p-3">


										<input type="hidden" name="controller" value="home" />
										<input type="hidden" name="action" value="show" />
										<input #searchInput2 type="text" name="search" class="line-search valRequired col-12 pad0" (keyup.enter)="searchItem(searchInput2);"  placeholder="{{ 'search' | translate }}" onfocus="this.placeholder = ''" required />
										<span class="c-point"  (click)="searchItem(searchInput2)"><i class="fa fa-search font-12"></i></span>
										<button type="submit" id="buttonSearch" class="hidden" ></button>

									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid-header" >
					<div class="   ">
						<div class="  pad0 m0">
							<div  class="col-12 pad0">
								<div class="main_menu_area">
									<div class="main-menu">
										<div *ngIf="this.deviceDesktop"  >
											<ul class="text-center">
												<ng-container *ngFor="let menucicle of this.menu; trackBy: trackByFnDesktop; let i_n=index;  ">
													<li class="noPadding-left" (mouseleave)="nohoverMenu(i_n)" (mouseover)="hoverMenu(i_n)" *ngIf="(menucicle.afterlogin == 1 && this.userLogging) || (menucicle.afterlogin == 0)">

														<a [routerLink]="menucicle.link==1 ? (['/show' , (menucicle?.descriptiontranslate ? (menucicle.urls[this.translate.currentLang]  ) : (menucicle.urls[this.translate.currentLang] )+'-'+menucicle.attr1+menucicle.idmenu)   ] | localize) : []"    [ngClass]="{'borderedMenu c-link ': menucicle.box == 1, 'c-link ': menucicle.box != 1}"      [style]="menustyle(menucicle)" href="javascript:void(0)"  >{{ menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate | uppercase ) : menucicle.description  }}<i *ngIf="menucicle.type" class="ion-ios-arrow-down hidden"></i></a>
	
														<ng-container *ngIf="menucicle.type && !menucicle.bandsmenu">
	
															<div [class]=" 'menuFull'+i_n +' divMenu_level1 '"  style="" >
	
																<div class="container" *ngIf="menucicle?.level_info  " style=" " >
																	<div class="row">
																		<div class="col-12 ">
	
																			<ul  class="col-2 " *ngFor="let submenu of menucicle.level_info;  "  style="text-align:left"  >
																				<li class="subLi cat_sub" style="padding: 0px 0px 0px 0px;"  class="mega-menu-title">
																					<a (click)="nohoverMenu(i_n)" style="font-weight: bold;" [routerLink]="(['/show' , (menucicle?.descriptiontranslate ? (menucicle.urls[this.translate.currentLang]  ) : (menucicle.urls[this.translate.currentLang] )+'-'+menucicle.attr1+menucicle.idmenu) , (submenu.urls[this.translate.currentLang] )+'-'+submenu.attr1+submenu.id  ] | localize)"  href="javascript:void(0)">{{ submenu.description }}</a>
	
	
	
																					<ul *ngIf="submenu?.level_info  " class="divMenu_level3"   >
																						<li class="noPadding-left" *ngFor="let submenu2 of submenu.level_info;  " >
																							<a (click)="nohoverMenu(i_n)" [routerLink]="(['/show' , (menucicle?.descriptiontranslate ? (menucicle.urls[this.translate.currentLang]  ) : (menucicle.urls[this.translate.currentLang] )+'-'+menucicle.attr1+menucicle.idmenu) , (submenu.urls[this.translate.currentLang] )+'-'+submenu.attr1+submenu.id, (submenu2.urls[this.translate.currentLang] )+'-'+submenu2.attr1+submenu2.id  ] | localize)"  href="javascript:void(0)">{{ submenu2.description }}</a>
																						</li>
																					</ul>
	
	
																				</li>
																			</ul>
	
	
																		</div>
																	</div>
																</div>
															</div>
	
														</ng-container>
	
	
														<ng-container *ngIf=" menucicle.bandsmenu">
	
															<div [class]=" 'menuFull'+i_n +' divMenu_level1 '"  style="" >
	
																<div class="container"   style=" " >
																	<div class="row">
																		<div class="col-12 ">
	
																			<div class="col-md-12" *ngIf="menucicle.letters">
																				<ul class="  c-menu-type-inline li-left  line-bottom pb-0 listBrandsUl" >
																					<div class="col-12 ignoreFlex pb-0 line-bottom" style="display: inline-block;">
																						<label class="float-left  ">{{ 'all_brands' | translate }}</label>
																					</div>
																					<li *ngFor="let letter of  menucicle.letters"><a (click)="nohoverMenu(i_n)" class="padBrand" href="javascript:void(0)"  [routerLink]=" (['/allbrands', (letter | lowercase )] | localize )">{{ letter }}</a></li>
																				</ul>
																			</div>
	
	
	
																			<div class="col-12 ignoreFlex brandsMenuHolder" style="margin-top: 10px;">
																				<div class="col-12 noPadding "><div class="col-12 noPadding "><br></div></div>
																				<div class="col-12   " style="padding-left: 5px;padding-right: 5px;" >  <div style="background-color:white" class="fullwidth seeAllLink"><a (click)="nohoverMenu(i_n)" style="line-height: 30px;" [routerLink]=" (['/show' , (menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate |  urlpipe | lowercase  ) : (menucicle.description | urlpipe | lowercase )+'-'+menucicle.attr1+menucicle.idmenu)   ] | localize)  " href="javascript:void(0)">{{ 'see_all_brands' | translate }} <i class="fa fa-arrow-circle-right"></i></a></div></div>
	
																				<div class="col-2  mt-10 brandLogo" *ngFor="let bandmenu of menucicle.bandsmenu;  ">
																					<a (click)="nohoverMenu(i_n)" [routerLink]=" (['/show' ,   (menucicle.description | urlpipe | lowercase )+'-'+menucicle.attr1+menucicle.idmenu, (bandmenu.name | urlpipe | lowercase )+'-b'+bandmenu.idbrand   ] | localize)  " href="javascript:void(0)"  ><img style="width:100%"  [src]=" this.apiService.imagesDir+bandmenu.logo "></a>
																				</div>
	
																				<div class="clearfix"></div>
																				<div class="col-12"><br></div>
	
																				<div class="col-12   " style="padding-left: 5px;padding-right: 5px;" ><div style="background-color:white" class="fullwidth seeAllLink"><a (click)="nohoverMenu(i_n)" style="line-height: 30px;" [routerLink]=" (['/show' , (menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate |  urlpipe | lowercase  ) : (menucicle.description | urlpipe | lowercase )+'-'+menucicle.attr1+menucicle.idmenu)   ] | localize)  " href="javascript:void(0)">{{ 'see_all_brands' | translate }} <i class="fa fa-arrow-circle-right"></i></a></div></div>
	
																				<div class="col-12 noPadding "><div class="col-12 noPadding "><br></div></div>
																			</div>
	
																		</div>
																	</div>
																</div>
															</div>
														</ng-container>
	
	
													</li>
												</ng-container>
											</ul>
										</div>
									</div>

								</div>
							</div>
							<div class="col-12">
								<div class="mobile-menu-area">
									<div class="mobile-menu">
										<nav *ngIf="!this.deviceDesktop" id="mobile-menu-active" #mobileMenu>
											<ul id="mobile-menu-active-ul">
												<li class="noPadding-left" #menuCicle *ngFor="let menucicle of this.menu; trackBy: trackByFnMobile; let last = last "   >
													<a *ngIf="menucicle.link==1 " onclick="closeMenuMobile();" class="href_main" [style]="menustylemobile(menucicle)"  [routerLink]="menucicle.link==1 ? (['/'+this.translate.currentLang+'/show' , (menucicle?.descriptiontranslate ? (menucicle.urls[this.translate.currentLang]  ) : (menucicle.urls[this.translate.currentLang] )+'-'+menucicle.attr1+menucicle.idmenu)   ] ) : []"  href="javascript:void(0)">{{ menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate | uppercase ) : menucicle.description  }}</a>
													<span *ngIf="menucicle.link!=1 " (click)="mobilenohref($event.target)" onclick="return false;"  class="href_main" [style]="menustylemobile(menucicle)"     href="javascript:void(0)">{{ menucicle?.descriptiontranslate ? (menucicle.descriptiontranslate | translate | uppercase ) : menucicle.description  }}</span>
													<ng-container *ngIf="menucicle.type && !menucicle.bandsmenu">
													<ul *ngIf="menucicle?.level_info  " >
														<li class="" *ngFor="let submenu of menucicle.level_info;  " >
															<a onclick="closeMenuMobile();" class="href_main" [routerLink]="(['/'+this.translate.currentLang+'/show' , (menucicle?.descriptiontranslate ? (menucicle.urls[this.translate.currentLang]  ) : (menucicle.urls[this.translate.currentLang] )+'-'+menucicle.attr1+menucicle.idmenu) , (submenu.urls[this.translate.currentLang] )+'-'+submenu.attr1+submenu.id    ] )" href="javascript:void(0)">{{ submenu.description }}</a>
															<ul *ngIf="submenu?.level_info  " >
																<li class="" *ngFor="let submenu2 of submenu.level_info;  " >
																	<a onclick="closeMenuMobile();" [routerLink]="(['/'+this.translate.currentLang+'/show' , (menucicle?.descriptiontranslate ? (menucicle.urls[this.translate.currentLang]  ) : (menucicle.urls[this.translate.currentLang] )+'-'+menucicle.attr1+menucicle.idmenu) , (submenu.urls[this.translate.currentLang] )+'-'+submenu.attr1+submenu.id, (submenu2.urls[this.translate.currentLang] )+'-'+submenu2.attr1+submenu2.id  ] )"  href="javascript:void(0)">{{ submenu2.description }}</a>
																</li>
															</ul>
														</li>

													</ul>
														</ng-container>

													<ng-container *ngIf=" menucicle.bandsmenu">
<ul   >
	<li class=""  >



		<!-- -->

			<div class="row">
				<div class="col-12 ">
<div class="row">
					<div class="col-md-12" *ngIf="menucicle.letters">
						<div class="  c-menu-type-inline li-left  line-bottom pb-0 listBrandsUl" >

							<div class="col-12 ignoreFlex pb-0 line-bottom">{{ 'all_brands' | translate }}</div>
							<div *ngFor="let letter of  menucicle.letters" class="col-1"><a onclick="closeMenuMobile();" style="font-weight: bold;" class="padBrand " href="javascript:void(0)"  [routerLink]=" (['/'+this.translate.currentLang+'/allbrands', (letter | lowercase )]  )">{{ letter }}</a></div>
						</div>
					</div>
	</div>
<div class="row">
					<div class="col-md-12   brandsMenuHolder"  >

						<div class="col-12 noPadding "><div class="col-12 noPadding "><br></div></div>
						<div class="col-12   " style="padding-left: 5px;padding-right: 5px;" >  <div style="background-color:white" class="fullwidth seeAllLink"><a onclick="closeMenuMobile();" style="line-height: 10px; font-weight: bold; text-align:center; width:100%" [routerLink]=" (['/'+this.translate.currentLang+'/show' , (menucicle?.descriptiontranslate ? (menucicle.urls[this.translate.currentLang]  ) : (menucicle.urls[this.translate.currentLang] )+'-'+menucicle.attr1+menucicle.idmenu)   ] )  " href="javascript:void(0)">{{ 'see_all_brands' | translate }} <i class="fa fa-arrow-circle-right"></i></a></div></div>

						<div class="col-6  mt-10 brandLogo" *ngFor="let bandmenu of menucicle.bandsmenu;  ">
							<a style="width: 100%;" onclick="closeMenuMobile();" [routerLink]=" (['/'+this.translate.currentLang+'/show' ,   (menucicle.urls[this.translate.currentLang])+'-'+menucicle.attr1+menucicle.idmenu, (bandmenu.url )+'-b'+bandmenu.idbrand   ] )  " href="javascript:void(0)"  ><img style="width:100%"  [src]=" this.apiService.imagesDir+bandmenu.logo "></a>
						</div>


					</div>
		</div>


				</div>
			</div>

		<!-- -->


														</li>
														</ul>
													</ng-container>

													<ng-container *ngIf="last   ">
														{{ startMenu() }}
													</ng-container>
												</li>



												<li class="noPadding-left"  >

													<a onclick="closeMenuMobile();" class="href_main" [hidden]="(userLogging) ? 'true' : '' "  ><i class="zmdi zmdi-account pr-05 font-14"></i>{{ 'clientarea' | translate }}</a>

													<a  onclick="closeMenuMobile();"class="href_main" [hidden]="(!userLogging) ? 'true' : '' " [routerLink]="(['/account'] | localize)" href="javascript:void(0)"><i class="zmdi zmdi-account pr-05 font-14"></i>{{ 'myaccount' | translate }}</a>


													<ul >
														<ng-container *ngIf="!userLogging">
															<li >
																<a [routerLink]="(['/signin'] | localize)"  href="javascript:void(0);" onclick="closeMenuMobile();" class="">{{ 'login' | translate }}</a>
															</li>
															<li   >
																<a [routerLink]="(['/register'] | localize)"  href="javascript:void(0);" onclick="closeMenuMobile();" class="">{{ 'register' | translate }}</a>
															</li>
														</ng-container>
														<ng-container *ngIf="userLogging">
															<li  ><a [routerLink]="(['/account'] | localize)" href="javascript:void(0)" onclick="closeMenuMobile();">{{ 'accinfo' | translate }}</a></li>
															<li ><a [routerLink]="(['/profile'] | localize)" href="javascript:void(0)" onclick="closeMenuMobile();">{{ 'editinfo' | translate }}</a></li>
															<li  ><a [routerLink]="(['/orders'] | localize)" href="javascript:void(0)" onclick="closeMenuMobile();">{{ 'orders' | translate }}</a></li>

															<li  ><a class="" (click)="logout()" onclick="closeMenuMobile();">{{ 'logout' | translate }}</a></li>
														</ng-container>

													</ul>
												</li>
											</ul>




										</nav>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</header>

		<!-- PAGE CONTENT -->
		<div class="box-layout">
			<router-outlet></router-outlet>



			<!-- FOOTER -->

			<footer class="style__3 footer-color">
				<div class="footer-container">
					<!--Footer Top Area Start-->
					<div class="footer-top-area ptb-100 text-center d-block d-lg-none">
						<div class="container">
							<div class="row">
								<div class="col-lg-10 offset-lg-1 col-12">

									<!--Footer Nav Start-->
									<div class="footer-nav  mb-20">

										<nav><ul class="row pad0">


											<li class="col-12   m-0" *ngFor="let gap_info of this.gap;  ">

												<a *ngIf="gap_info.idgap>6" href="javascript:void(0)" [routerLink]="(['/page',  gap_info.idgap, (gap_info.subject | urlpipe | lowercase)  ] | localize)">{{ gap_info.subject }}</a>

												<a *ngIf="gap_info.idgap<=6" href="javascript:void(0)" [routerLink]="(['/'+   (gap_info.subject | urlpipe | lowercase)  ] | localize)">{{ gap_info.subject }}</a>

											</li>

											<li class="col-12    m-0"><a href="javascript:void(0)" [routerLink]="(['/contact'] | localize)">{{ 'contacts' | translate }}</a></li>
											<ng-container *ngIf="  this.blog  && this.blog.length>0 ">
												<li class="col-12 text-center m-0"><a href="javascript:void(0)">{{ 'blog' | translate }}</a></li>
											</ng-container>

											<li><a rel="noopener" target="_blank" href="https://www.livroreclamacoes.pt/inicio">{{ 'COMPLAINT' | translate | uppercase }}</a></li>

										</ul></nav>

									</div>
									<!--Footer Nav End-->
									<!--Footer Social Icon Start-->
									<div *ngIf="this.companyPresentation" class="footer-social m-0">





									</div>
									<!--Footer Social Icon End-->
									<!--Footer Newsletter Start-->
									<div class="footer-newsletter">
										<!-- Newsletter Form -->
										<span class="newsletter_title"> {{ 'newsletter_title' | translate }}</span>

										<div id="mc_embed_signup_scroll">
											<div class="mc-form subscribe-form col-12 pad0 col-sm-12" id="mc-form">
												<form  [formGroup]="newsletterRegister" [ngClass]="{ 'is-invalid': submittedNews && (frm.email.errors || frm.terms.errors) }" >
													<label style="display:none" for="email"></label>
													<input #emailNewletterInput type="text"  formControlName="email" placeholder="{{ 'write_your_email' | translate }} " [ngClass]="{ 'is-invalid': submittedNews && frm.email.errors }" class="form-control valRequired c-square ng-pristine ng-invalid  ng-touched" autocomplete="off" id="mc-email2">
													<button type="submit" style="display:none">{{ 'newsletter_subscribe_btn' | translate }}</button>
													<button id="mc-submit2" class="col-md-12" style="" (click)="newsletterInput(emailNewletterInput.value, emailNewletterInput, emailNewletterTerms)"  type="button" >{{ 'newsletter_subscribe_btn' | translate }}</button>
													<label style="margin-top: 10px;"><input formControlName="terms" #emailNewletterTerms [ngClass]="{ 'is-invalid': submittedNews && frm.terms.errors }" type="checkbox" class=" ng-invalid" style="height: auto;"  /><span>{{ 'READ_AND_ACCEPT_CONDITIONS' | translate }}</span></label>
												</form>

												<div *ngIf="this.submittedNews && this.newsletterRegister.controls.terms.value==false" class="invalid-feedback">
													<div *ngIf="this.newsletterRegister.controls.terms.value==false">{{ 'ACCEPT_TERMS' | translate }}</div>
												</div>

												<div *ngIf="this.submittedNews && frm.email.errors" class="invalid-feedback">
													<div *ngIf="frm.email.errors.required">{{ 'insert_email' | translate }}</div>
													<div *ngIf="frm.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
												</div>
											</div>
										</div>

									</div>
									<!--Footer Newsletter End-->
									<div class="footer-payments text-center pt-20">
										<span class="newsletter_title"> {{ 'payment_secure' | translate }}</span>
										<img alt="{{ 'paymentdesc' | translate }}" [defaultImage]="this.browserwebp ? 'assets/images/img_default.webp' : 'assets/images/payments_pt.png' " [lazyLoad]="this.browserwebp ? 'assets/images/payments_pt.webp' : 'assets/images/payments_pt.png'">
									</div>
								</div>
							</div>
						</div>
					</div>

					<!--Footer Top Area End-->
					<div style="text-align: left !important;" class="footer-top-area ptb-100 text-center d-none d-lg-block fullFooter">

						<div class="row">
							<div class="full-width">
								<div class="col-3">
									<!--Footer Nav Start-->
									<div class="footer-nav">
										<nav><ul class="row pad0">


											<li class=" col-12    m-0" *ngFor="let gap_info of this.gap;  ">
												<a *ngIf="gap_info.idgap>6" href="javascript:void(0)" [routerLink]="(['/page',  gap_info.idgap, (gap_info.subject | urlpipe | lowercase)  ] | localize)">{{ gap_info.subject }}</a>

												<a *ngIf="gap_info.idgap<=6" href="javascript:void(0)" [routerLink]="(['/'+   (gap_info.subject | urlpipe | lowercase)  ] | localize)">{{ gap_info.subject }}</a>

											</li>

											<li class=" col-12    m-0"><a href="javascript:void(0)" [routerLink]="(['/contact'] | localize)">{{ 'contacts' | translate }}</a></li>

											<ng-container *ngIf="  this.blog  && this.blog.length>0 ">
												<li class=" "><a href="javascript:void(0)">{{ 'blog' | translate }}</a></li>
											</ng-container>

											<li class=" col-12    m-0"><a rel="noopener" target="_blank" href="https://www.livroreclamacoes.pt/inicio">{{ 'COMPLAINT' | translate | uppercase }}</a></li>

										</ul></nav>
									</div>
									<!--Footer Nav End-->
								</div>
								<!--Footer Social Icon End-->
								<!--Footer Newsletter Start-->
								<div class="col-6">
									<div class="footer-newsletter col-12 pad0">
										<!-- Newsletter Form -->
										<span class="newsletter_title"> {{ 'newsletter_title' | translate  }}</span>

										<div id="mc_embed_signup_scroll">
											<div class="mc-form subscribe-form col-12 pad0 col-sm-12" id="mc-form">
												<form  [formGroup]="newsletterRegister" [ngClass]="{ 'is-invalid': submittedNews && (frm.email.errors || frm.terms.errors) }" >
													<label style="display:none" for="email"></label>
													<input #emailNewletterInput2 type="text" [ngClass]="{ 'is-invalid': submittedNews && frm.email.errors }"  formControlName="email" placeholder="{{ 'newsletter_subscribe' | translate }}" class="col-md-6 col-lg-6 col-xl-8 form-control valRequired c-square ng-pristine ng-invalid  ng-touched " autocomplete="off" id="mc-email">
													<button type="submit" style="display:none">{{ 'newsletter_subscribe_btn' | translate }}</button>

													<button id="mc-submit" (click)="newsletterInput(emailNewletterInput2.value, emailNewletterInput2, emailNewletterTerms2)" class="col-md-6 col-lg-6 col-xl-4" type="button" >{{ 'newsletter_subscribe_btn' | translate }}</button>
													<label class="label-newsletter"><input type="checkbox" class=" ng-invalid   " #emailNewletterTerms2 [ngClass]="{ 'is-invalid': submittedNews && frm.terms.errors }" formControlName="terms" style="height: auto;"  /><span>{{ 'READ_AND_ACCEPT_CONDITIONS' | translate }}</span></label>
												</form>

												<div *ngIf="this.submittedNews && this.newsletterRegister.controls.terms.value==false" class="invalid-feedback">
													<div *ngIf="this.newsletterRegister.controls.terms.value==false">{{ 'ACCEPT_TERMS' | translate }}</div>
												</div>

												<div *ngIf="this.submittedNews && frm.email.errors" class="invalid-feedback">
													<div *ngIf="frm.email.errors.required">{{ 'insert_email' | translate }}</div>
													<div *ngIf="frm.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
												</div>


											</div>


										</div>

									</div>
								</div>
								<!--Footer Newsletter End-->
								<div class="col-3">
									<div class="footer-payments">
										<span class="newsletter_title"> {{ 'payment_secure' | translate }}</span><br>
										<img  alt="{{ 'paymentdesc' | translate }}" [defaultImage]="this.browserwebp ? 'assets/images/img_default.webp' : 'assets/images/payments_pt.png' " [lazyLoad]="this.browserwebp ? 'assets/images/payments_pt.webp' : 'assets/images/payments_pt.png'">





									</div>
								</div>
							</div>
						</div>

					</div>
					<!--Footer Bottom Area Start-->
					<div class="footer-bottom-area">

						<!--Footer Social Icon Start-->
						<div class="footer-social" *ngIf="this.companyPresentation">


							<ul *ngIf="this.companyPresentation.facebook || this.companyPresentation.instagram || this.companyPresentation.pinterest || this.companyPresentation.googleplus || this.companyPresentation.twitter || this.companyPresentation.youtube || this.companyPresentation.linkedin" class="c-socials c-margin-b-10">
								<li *ngIf="this.companyPresentation.facebook"><a rel="noopener" href="{{ this.companyPresentation.facebook }}" class="c-socials-a" target="_blank"><i class="fab fa-facebook-f"></i></a></li>


								<li *ngIf="this.companyPresentation.instagram"><a rel="noopener" href="{{ this.companyPresentation.instagram }}" class="c-socials-a" target="_blank"><i class="fab fa-instagram"></i></a></li>


								<li *ngIf="this.companyPresentation.pinterest" ><a rel="noopener" href="{{ this.companyPresentation.pinterest }}" class="c-socials-a" target="_blank"><i class="fab fa-pinterest"></i></a></li>


								<li *ngIf="this.companyPresentation.googleplus" ><a rel="noopener" href="{{ this.companyPresentation.googleplus }}" class="c-socials-a" target="_blank"><i class="fab fa-google-plus"></i></a></li>


								<li *ngIf="this.companyPresentation.twitter"><a rel="noopener" href="{{ this.companyPresentation.twitter }}" class="c-socials-a" target="_blank"><i class="fab fa-twitter"></i></a></li>


								<li *ngIf="this.companyPresentation.youtube"><a rel="noopener" href="{{ this.companyPresentation.youtube }}" class="c-socials-a" target="_blank"><i class="fab fa-youtube"></i></a></li>


								<li *ngIf="this.companyPresentation.linkedin"><a rel="noopener" href="{{ this.companyPresentation.linkedin }}" class="c-socials-a" target="_blank"><i class="fab fa-linkedin"></i></a></li>
							</ul>


						</div>
						<div class="container text-center home-3-copyright">
							<p class="font-size-12 color-white"> © {{year}} Armazéns Ronfe by <a target="_blank" href="http://gobox.pt">Gobox</a> <br class="d-lg-none"> - {{ 'all_rights_reserved' | translate }}</p>
						</div>
					</div>
					<!--Footer Bottom Area End-->
				</div>
			</footer>

			<!-- FOOTER -->


		</div>
	</div>


	<modal #ModalPasswordMn  id="lostPassMn" >
		<modal-header>
		</modal-header>
		<modal-content>

			<div class=" row">
				<div class="  col-md-12 col-xs-12 col-sm-12">
					<span class="title_h3 c-font-24 c-font-sbold full-width">{{ 'recover_password' | translate }}.</span>
					<div class="full-width"><p class="full-width">{{ 'email_to_retrieve_password' | translate }}</p></div>
					<div class="form-group full-width">
						<input type="email" #emailrecoverPassMn class="form-control  border-grey input-lg c-square valRequired valEmail" name="email" placeholder="{{ 'write_your_email' | translate }}">
					</div>
					<div class="form-group full-width">
						<button type="button" (click)="recoverPassMn()" class="col-xs-12 button register_button ml-15 float-right float-center-xs">{{ 'send' | translate }}</button>
					</div>
				</div>
			</div>

		</modal-content>
	</modal>

	<button class="btnGoToTop"  *ngIf="isShow" (click)="gotoTop()"><i class="ion-arrow-up-c"></i></button>

	<!-- <cookie-law   expiration="1" >{{ 'policy_cookie_desc' | translate }}
		<ng-container *ngIf="this.gapCookiepage"><a href="javascript:void(0)" class="cookieMSG" [routerLink]="(['/page/',this.gapCookiepage.idgap,   (this.gapCookiepage.url)  ] | localize)">{{ 'policy_cookie' | translate }}</a></ng-container><ng-container *ngIf="!this.gapCookiepage">{{ 'policy_cookie' | translate }}</ng-container></cookie-law> -->


		<modal #ModalCookies id="ModalCookies">
			<modal-content>
				<!-- <button type="button" (click)="this.modalService.close(this.modalCookiesRef);" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">×</span>
				</button> -->
				<h1 class="text-uppercase" style="font-size: 16px !important;">{{ 'policy_cookie' | translate }}</h1>
				<p style="font-size: 12px; line-height: 1.5;">{{'policy_cookie_desc' | translate}}
				<ng-container *ngIf="this.gapCookiepage">
					<a href="javascript:;" (click)="onCoockieToggle()" style="text-decoration: underline; margin-left: 4px; font-size: 12px;" [routerLink]="(['/page/',this.gapCookiepage.idgap,   (this.gapCookiepage.url)  ] | localize)"> {{ 'policy_cookie' | translate }} </a>
				</ng-container>
				<ng-container *ngIf="!this.gapCookiepage">
					<span style="font-size: 12px;">{{ 'policy_cookie' | translate }}</span>
				</ng-container>
			</p>
			</modal-content>
			<modal-footer>
				<div class="footer-container">
					<div class="footer-left">
					</div>
					<div class="footer-right">
						<button style="color: #fff !important; background-color:#bbb; border: #bbb; padding: 10px; margin-right: 10px;" (click)="openModalCookiesDetail();">{{ 'change_cookie_policy' | translate }}</button>
						<button style="color: #fff !important; background-color:#000; border: #000; padding: 10px;" (click)="saveCookies(1);this.modalService.close(this.modalCookiesRef);">{{'accept_all' | translate}}</button>
					</div>
				</div>
			</modal-footer>
		</modal>
		
		<modal #ModalCookiesDetail id="ModalCookiesDetail">
			<modal-content>
				<h1 class="text-uppercase" style="font-size: 16px !important;">{{ 'your_prefs' | translate }}</h1>
				<p  style="font-size: 12px; line-height: 1.5;">{{ 'cookie_policy_desc' | translate }}</p>
				<div class="cookie-container" *ngFor="let co of cookies; let i = index">
					<div class="cookie-header">
						<div class="checkbox-container">
							<input type="checkbox" [checked]="co.checked" [disabled]="co.idcookie == '1'" [id]="i">
						</div>
						<div class="title-container"><label [for]="i" style="margin-bottom: 0;"><p class="m-0" style="margin-bottom: 0;"><b>{{ co.title }}</b></p></label></div>
					</div>
					<div class="description"><p>{{ co.description }}</p></div>
				</div>
			</modal-content>
			<modal-footer>
				<div class="footer-container">
					<div class="footer-left">
					</div>
					<div class="footer-right">
						<button style="color: #fff !important; background-color:#bbb; border: #bbb; padding: 10px; margin-right: 10px;" (click)="saveCookies(0);this.modalService.close(this.modalCookiesDetailRef);this.modalService.close(this.modalCookiesRef);">{{ 'save_and_continue' | translate }}</button>
						<button style="color: #fff !important; background-color:#000; border: #000; padding: 10px;" (click)="saveCookies(1);this.modalService.close(this.modalCookiesDetailRef);this.modalService.close(this.modalCookiesRef);">{{'accept_all' | translate}}</button>
					</div>
				</div>
			</modal-footer>
		</modal>