import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgSelect2Module } from 'ng-select2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { I18nModule } from './i18n/i18n.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { ShowComponent } from './show/show.component';
import { ModalModule } from 'ngb-modal';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProductComponent } from './product/product.component';
import { ContactComponent } from './contact/contact.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { ReactiveFormsModule, FormsModule }   from '@angular/forms';
import { PageComponent } from './page/page.component';

import { RegisterComponent } from './register/register.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SigninComponent } from './signin/signin.component';
import { AccountComponent } from './account/account.component';
import { ProfileComponent } from './profile/profile.component';
import { OrdersComponent } from './orders/orders.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ShoworderComponent } from './showorder/showorder.component';
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { UrlpipePipe } from './urlpipe.pipe';

import { CookieLawModule } from 'angular2-cookie-law';
import { LowerCasePipe } from '@angular/common';
 import { DeviceDetectorModule } from 'ngx-device-detector';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { CarouselModule } from 'ngx-owl-carousel-o';

import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AllbrandsComponent } from './allbrands/allbrands.component';
import { WishlistComponent } from './wishlist/wishlist.component';


const dbConfig: DBConfig  = {
  name: 'ar',
  version: 1,
  objectStoresMeta: [{
    store: 'show_itemsList',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      { name: 'name', keypath: 'name', options: { unique: false } }
    ]
  } ]
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PagenotfoundComponent,
    ShowComponent,
    ProductComponent,
    ContactComponent,
    PageComponent,
    RegisterComponent,
    CheckoutComponent,
    SigninComponent,
    AccountComponent,
    ProfileComponent,
    OrdersComponent,
    ResetpasswordComponent,
    ShoworderComponent,
    UrlpipePipe,
    AllbrandsComponent,
    WishlistComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    I18nModule,
    SweetAlert2Module.forRoot(),

    CarouselModule,
    ModalModule,
    InfiniteScrollModule,
    RecaptchaModule,
    ReactiveFormsModule ,
    FormsModule,
  	NgSelect2Module,
    SocialLoginModule,
    MaterialModule,
    BrowserAnimationsModule,
    CookieLawModule,
    DeviceDetectorModule,
    LazyLoadImageModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    ScrollingModule
  ],
  providers: [
    Title,
    UrlpipePipe,
    LowerCasePipe,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1776497385997251'),
          }
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
