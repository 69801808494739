import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { Options } from 'select2';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MustMatch, NifValidatorPT } from '../must-match.validator';
import { SharedService } from '../shared.service';
import { SessionService } from '../session.service';
import { Router, ActivatedRoute   } from '@angular/router';
import { LogoutService } from '../logout.service';
import { Title, Meta }     from '@angular/platform-browser';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
import { TitlechangeService } from '../titlechange.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profileForm:FormGroup;
  submitted = false;
  countries:any;
  telephone_callingcode:any;
  optionsCalling: Options;
  userInfo:any;
  subscribe:any;
  constructor(public titlechangeService:TitlechangeService,private seoService: SeoService, public localize: LocalizeRouterService,private metaService: Meta, public titleService: Title, public logoutservice: LogoutService,private route: Router, public sharedService: SharedService, public sessionService: SessionService, public apiService: ApiService, public translate: TranslateService, private formBuilder: FormBuilder) {
    this.profileForm = this.formBuilder.group({
      firstname: new FormControl('',[  Validators.required]),
      lastname: new FormControl('',[  Validators.required]),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('' ),
      postalcode: new FormControl('', [Validators.required]),
      locality: new FormControl('', [Validators.required]),
      idcountry: new FormControl('', [Validators.required]),
      nif: new FormControl(''  ),

      telephone_callingcode: new FormControl('351' ),
      telephone: new FormControl('' ),
      email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")  ] ),
      password: ['', [ Validators.minLength(6)]],
      confirmPassword: ['']

    }, {
  		validator: [MustMatch('password', 'confirmPassword'), NifValidatorPT('idcountry', 'nif')]
  	});

	this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
      this.metatags(lang.lang);
    });
  }

  get f() { return this.profileForm.controls; }

  ngOnInit(): void {
    this.getCountry();
    this.getCallingcode();
  	this.metatags(this.translate.currentLang);

    	this.optionsCalling = {
        templateSelection: function (data, container) {
          return "+"+data.text;
        },
        templateResult: function (data, container) {
          return "+"+data.text;
        }
      };

    this.apiService.getUserInfo(this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.userInfo=obj.info;
        let first=this.userInfo.name;
        first = this.userInfo.name.substr(0,this.userInfo.name.indexOf(' '));
        let last = this.userInfo.name.substr(this.userInfo.name.indexOf(' ')+1);
        this.profileForm.controls['firstname'].setValue(first);
        this.profileForm.controls['lastname'].setValue(last);
        this.profileForm.controls['address'].setValue(this.userInfo.address);
        this.profileForm.controls['address2'].setValue(this.userInfo.address2);
        this.profileForm.controls['postalcode'].setValue(this.userInfo.postalcode);
        this.profileForm.controls['locality'].setValue(this.userInfo.locality);
        this.profileForm.controls['nif'].setValue(this.userInfo.nif);
        if(this.userInfo.idregion){
          this.profileForm.controls['idcountry'].setValue(this.userInfo.idcountry+"-"+this.userInfo.idregion);
        }else{
          this.profileForm.controls['idcountry'].setValue(this.userInfo.idcountry);
        }

        this.profileForm.controls['email'].setValue(this.userInfo.email);
        this.profileForm.controls['telephone_callingcode'].setValue(this.userInfo.telephone_callingcode);
        this.profileForm.controls['telephone'].setValue(this.userInfo.telephone);
      }else if(obj.code==302){ this.sessionService.sendsession(false, ""); this.sharedService.sendClickEvent(this.translate.currentLang); this.route.navigate([this.localize.translateRoute('/')] ); }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });

  }

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
  }


  public setTitle( newTitle: string) {
	 this.titleService.setTitle( newTitle);
   this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(8,9,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }
        /*Object.keys(obj.info).forEach(key => {
          this.metaService.removeTag('name="'+obj.info[key].name+'"');
        });*/
        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }



  getCountry(){
    this.apiService.getCountry().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.countries = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  getCallingcode(){
    this.apiService.getCallingcode().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.telephone_callingcode = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }



  onSubmit() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    }

    let re = /^[0-9]{9}$/;
    if(!re.test(this.profileForm.controls['telephone'].value.toString())  && (this.profileForm.controls['idcountry'].value.toString()=="179" || this.profileForm.controls['idcountry'].value.toString()=="179-1" || this.profileForm.controls['idcountry'].value.toString()=="179-2" || this.profileForm.controls['idcountry'].value.toString()=="179-3") ){
      this.translate.get( "msg_valTelephone" ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      this.submitted = false;
      return;
    }else{
      re = /^[0-9]{7,15}$/;
      if(!re.test(this.profileForm.controls['telephone'].value.toString())){
        this.translate.get( "msg_valTelephone" ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        this.submitted = false;
        return;
      }
    }

    var formData = new FormData();
    Object.keys(this.profileForm.controls).forEach(key => {
      if(this.profileForm.controls[key].value){
        formData.append("userweb["+key+"]", this.profileForm.controls[key].value.toString());
      }else{
        formData.append("userweb["+key+"]", "");
      }
    });


    this.apiService.changeuserdata(formData, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.translate.get( obj.msg ).subscribe(translations => {
          this.submitted = false;
        Swal.fire({
          position: 'top-end',
          customClass: {
            container: 'swal-index'
          },
          icon: 'success',
          title: translations,
          showConfirmButton: false,
          timer: 2000
        });
        this.route.navigate([this.localize.translateRoute('/account') ] );
        });
      }else{
        this.submitted = false;
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });

  }


  getpostalcode(){
    if(this.profileForm.get('idcountry').value=="" || this.profileForm.get('idcountry').value==179 || this.profileForm.get('idcountry').value=="179-1" || this.profileForm.get('idcountry').value=="179-2"  || this.profileForm.get('idcountry').value=="179-3"){
      this.apiService.getpostalcode(this.profileForm.get('postalcode').value).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          this.profileForm.controls['locality'].setValue(obj.designation) ;
        }else{
          this.translate.get( 'msg_valCP' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }
  }
}
