<div class="title-breadcrumbs">
		<div class="title-breadcrumbs-inner">
				<div class="container-fluid">
						<nav class="woocommerce-breadcrumb">
								<a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate }}</a>
								<span class="separator"> / </span>
								{{ 'contacts' | translate }}
						</nav>
				</div>
		</div>
</div>

<div class="contact-page-area ">
			 <!-- contact page map -->
			 <div class="contact-page-map hidden">
					 <!-- Google Map Start -->
					 <div class="container-fluid">
							 <div id="map" style="height:400px"></div>
					 </div>
					 <!-- Google Map End -->
			 </div>
			 <!-- contact page map -->
			 <!-- contact form area -->
			 <div class="contact-form-area pt-50 pb-30">
					 <div class="container-fluid">
							 <div class="row">
									 <div class="col-lg-6">
											 <div class="contact-form-inner">
													 <h2>{{ 'getintouch' | translate }}</h2>
													 <form  [formGroup]="contactForm" (ngSubmit)="onSubmit(captchaElem)">
															 <div class="row">
																	 <div class="col">
																			 <input type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name"  placeholder="{{ 'name' | translate }}" class="form-control valRequired">
                                       <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                          <div *ngIf="f.name.errors.required">{{ 'msg_valRequired' | translate }}</div>
                                      </div>
																	 </div>
															 </div>
															 <div class="row">
																	 <div class="col">
																			 <input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"  formControlName="email"  placeholder="{{ 'email' | translate }}" class="form-control valEmail valRequired">
                                       <div *ngIf="this.submitted && f.email.errors" class="invalid-feedback">
                                          <div *ngIf="f.email.errors.required">{{ 'msg_valRequired' | translate }}</div>
                                          <div *ngIf="f.email.errors.pattern">{{ 'msg_valEmail' | translate }}</div>
                                      </div>
																	 </div>
																	 <div class="col">
																			 <input type="text" [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }" formControlName="telephone"  placeholder="{{ 'telephone' | translate }}" class="form-control valNumber">
                                       <div *ngIf="submitted && f.telephone.errors" class="invalid-feedback">
                                          <div *ngIf="f.telephone.errors.required">{{ 'msg_valRequired' | translate }}</div>
                                          <div *ngIf="f.telephone.errors.pattern">{{ 'msg_valTelephone' | translate }}</div>
                                      </div>
																	 </div>
															 </div>
															 <div class="row">
																	 <div class="col">
																		 	<textarea rows="8" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" formControlName="message" placeholder="{{ 'message' | translate }} ..." aria-required="true"
																					aria-invalid="false" class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required valRequired"></textarea>
                                          <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                             <div *ngIf="f.message.errors.required">{{ 'msg_valRequired' | translate }}</div>
                                         </div>
																	 </div>
															 </div>
															 	 <div class="row">
																	 <div class="col" style="margin-top:20px;">

                                    <re-captcha #captchaElem  (resolved)="resolved($event)" siteKey="6Lds048UAAAAAPY786uuoV17-F0s-sVRkcWFUTMS"></re-captcha>
																	</div>
																	</div>

															 <div class="contact-submit">
																	 <input type="submit" value="{{ 'send' | translate }}" class="wpcf7-form-control wpcf7-submit button">
															 </div>
													 </form>
											 </div>
									 </div>
									 <div class="col-lg-6">
											 <div class="contact-address-area">
													 <h2>{{ 'where_are_we' | translate }}</h2>
													 <ul>
															 <li>
																 <p><b>{{ 'company' | translate }}</b></p>
															 	<p>
                                  <ng-container   *ngIf="this.contacts">
																		{{ 'Name_APP' | translate }} <br>
															 		{{ 'nif' | translate }}  :  {{ this.contacts.nif }}<p>
																 <p>{{ this.contacts.address }}
																	<br/>{{ this.contacts.postalcode }}, {{ this.contacts.locality }}
																 <br/>Portugal
																 </p>

																 <strong *ngIf="this.contacts.presentation[this.translate.currentLang] && this.contacts.presentation[this.translate.currentLang].contact">T: </strong>{{ this.contacts.presentation[this.translate.currentLang].contact }}
																 <br/>
                                 <strong *ngIf="this.contacts.presentation[this.translate.currentLang] && (this.contacts.presentation[this.translate.currentLang].email_support || this.contacts.presentation[this.translate.currentLang].email)">E: </strong>{{ this.contacts.presentation[this.translate.currentLang].email_support ? this.contacts.presentation[this.translate.currentLang].email_support : this.contacts.presentation[this.translate.currentLang].email }}
                                 </ng-container>
															</li>

													 </ul>

											 </div>
									 </div>
							 </div>
					 </div>
			 </div>
			 <!-- contact form area end -->
	 </div>
