import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta }     from '@angular/platform-browser';
import { UrlpipePipe } from '../urlpipe.pipe';
import { LowerCasePipe } from '@angular/common';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SeoService } from '../seo.service';
import { Location } from '@angular/common';
import { TitlechangeService } from '../titlechange.service';
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
  breadcrumbs:Array<Object> = [];
  page:any;
  pageInfo:any;
  countParams:any;
  subscribe:any;
  carouselOptionsDetail: any = {
      loop:true,
      margin:10,
      autoplay: true,
      nav:true,
      navText: ['<i class="ion-chevron-left"></i>', '<i class="ion-chevron-right"></i>'],
      items: 1
  }
  constructor(public titlechangeService:TitlechangeService,private location: Location, public localize: LocalizeRouterService, private router: Router, private seoService: SeoService, private lowercase:LowerCasePipe, private urlpipePipe:UrlpipePipe,private metaService: Meta,  public titleService: Title, private route: ActivatedRoute, public apiService: ApiService, public translate: TranslateService) {

    this.page = this.route.snapshot.paramMap.get('page');

    if(this.page==null && this.route.snapshot.data.page){
      this.page = this.route.snapshot.data.page;
    }
    if(this.page<=6 && this.route.snapshot.url[0].path=="page"){
      this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});
    }
    this.route.params.subscribe(params => {
      if(params.page){
        this.page = params.page;
      }
      if(this.countParams==Object.keys(params).length){

        this.ngOnInit();
      }
      this.countParams = Object.keys(params).length;
    });

    this.subscribe=  this.translate.onLangChange.subscribe(lang=>{

      this.languageChange(lang);

    });

  }


  languageChange(lang){
    if(this.page>6 && this.pageInfo && this.pageInfo[lang.lang] && this.pageInfo[lang.lang].subject){

      this.route.snapshot.url[2].path = this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo[lang.lang].subject));
    }else if(this.page<=6 && this.pageInfo && this.pageInfo[lang.lang] && this.pageInfo[lang.lang].subject){
      //this.route.snapshot.url[0].path = this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo[lang.lang].subject));



      location.href = (lang.lang+"/"+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo[lang.lang].subject)));
       return ;


    } else{
      this.ngOnInit();
    }
  }

  ngOnDestroy(): void {
    if(this.subscribe){
      this.subscribe.unsubscribe();
    }
    this.seoService.clearAlternateUrl();
		this.seoService.removeTag('rel=canonical');
  }

  ngOnInit(): void {
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag('rel=canonical');


    if(this.route.snapshot.data.lang && this.route.snapshot.data.lang!=this.localize.parser.currentLang){
      this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
    }

	  this.metatags(this.localize.parser.currentLang);

    this.apiService.getPage(this.page).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.pageInfo = obj.info;

        if(!this.pageInfo[this.translate.currentLang]){
          this.router.navigate( [this.localize.translateRoute('/pagenotfound')]  , {skipLocationChange: true});return;
        }

        this.seoService.clearAlternateUrl();
				if(this.page>6){
					this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.localize.parser.currentLang+'/page/'+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo[this.localize.parser.currentLang].subject)) +'/'+this.page);


          if(this.pageInfo && this.pageInfo["pt"] && this.pageInfo["pt"].subject){
            this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/page/'+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo["pt"].subject)) +'/'+this.page, 'x-default');
  					this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/page/'+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo["pt"].subject)) +'/'+this.page, 'pt');
          }

          if(this.pageInfo && this.pageInfo["en"] && this.pageInfo["en"].subject){
					       this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/page/'+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo["en"].subject)) +'/'+this.page, 'en');
          }
          if(this.pageInfo && this.pageInfo["es"] && this.pageInfo["es"].subject){
        	   this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/es/page/'+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo["es"].subject)) +'/'+this.page, 'es');
          }
          if(this.pageInfo && this.pageInfo["fr"] && this.pageInfo["fr"].subject){
            this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/page/'+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo["fr"].subject)) +'/'+this.page, 'fr');
          }

          let objBread:any = {};
  				objBread.url = ['/page', this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo[this.translate.currentLang].subject)), this.page ];
  				objBread.description = this.pageInfo[this.translate.currentLang].subject;
  				this.breadcrumbs = [];
  				this.breadcrumbs.push(objBread);

				}else {

          if(this.pageInfo && this.pageInfo["pt"] && this.pageInfo["pt"].subject){
            this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/'+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo["pt"].subject)) , 'x-default');
  					this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/pt/'+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo["pt"].subject)) , 'pt');
          }

          if(this.pageInfo && this.pageInfo["en"] && this.pageInfo["en"].subject){
					       this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/en/'+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo["en"].subject)) , 'en');
          }
          if(this.pageInfo && this.pageInfo["es"] && this.pageInfo["es"].subject){
        	   this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/es/'+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo["es"].subject)), 'es');
          }
          if(this.pageInfo && this.pageInfo["fr"] && this.pageInfo["fr"].subject){
            this.seoService.updateAlternateUrl(this.apiService.PHP_API_URL_DEFAULT+'/fr/'+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo["fr"].subject)) , 'fr');
          }

					this.seoService.updateCanonicalUrl(this.apiService.PHP_API_URL_DEFAULT+'/'+this.translate.currentLang+'/'+this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo[this.translate.currentLang].subject)));
          let objBread:any = {};
  				objBread.url = ['/'+ this.lowercase.transform(this.urlpipePipe.transform(this.pageInfo[this.translate.currentLang].subject))  ];
  				objBread.description = this.pageInfo[this.translate.currentLang].subject;
  				this.breadcrumbs = [];
  				this.breadcrumbs.push(objBread);
				}


      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  public setTitle( newTitle: string) {
	  this.titleService.setTitle( newTitle);
    this.titlechangeService.send();
  }

  metatags(lang){
    if(!lang){
      return;
    }
    this.apiService.getMetatags(this.page,3,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){

        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }

        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }

}
