<div class="title-breadcrumbs">
	<div class="title-breadcrumbs-inner">
		<div class="container-fluid">
			<nav class="woocommerce-breadcrumb">
				<a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate }}</a>
				<span class="separator"> / </span>
				{{ 'myaccount' | translate }}
			</nav>
		</div>
	</div>
</div>

<div class="my-account white-bg pt-50 pb-30">
	<div class="container-fluid">
		<div class="account-dashboard">
			<div class="row">
				<div class="col-md-12 col-lg-2   d-lg-block">
					<span class="title_h3 c-title c-font-uppercase c-font-bold">{{ 'myaccount' | translate }}</span>
					<ul class="nav flex-column dashboard-list" role="tablist">
						<li>
							<a class="nav-link active" [routerLink]="(['/account'] | localize)" href="javascript:void(0);">{{ 'accinfo' | translate }}</a>
						</li>
						<li>
							<a class="nav-link" [routerLink]="(['/profile'] | localize)" href="javascript:void(0);">{{ 'editinfo' | translate }}</a>
						</li>
						<li>
							<a class="nav-link" [routerLink]="(['/orders'] | localize)" href="javascript:void(0);">{{ 'orders' | translate }}</a>
						</li>
						<li [hidden]="true">
							<a class="nav-link" [routerLink]="(['/addresses'] | localize)" href="javascript:void(0);">{{ 'myaddresses' | translate }}</a>
						</li>
						<li  >
							<a class="nav-link" [routerLink]="(['/wishlist'] | localize)" href="javascript:void(0);">{{ 'wishlist' | translate }}</a>
						</li>
						<li>
							<a class="nav-link" (click)="this.logoutservice.sendClickEvent()" href="javascript:void(0);">{{ 'logout' | translate }}</a>
						</li>
					</ul>
				</div>
				<div class="col-md-12 col-lg-10">
					<div class="tab-content dashboard-content">
						<ng-container *ngIf="this.userInfo">
						<div id="myaccount" class="tab-pane fade show active">
							<span class="title_h3 font-uppercase" >{{ this.userInfo.name }}</span>
							<ul class="list-unstyled">
								<li>
								{{ this.userInfo.address }} {{ this.userInfo.address2 }} <br/>
								{{ this.userInfo.postalcode }}, {{ this.userInfo.locality }} <br/>
								{{ this.userInfo.countryDesc }}
								</li>
								<li>
									<span class="niftext">{{ 'nif' | translate }}</span>
									<span class="niffrtext" style="display:none;">{{ 'nif_fr' | translate }}</span>: {{ this.userInfo.nif }}
								</li>
								<li>{{ 'telephone' | translate }}: {{ this.userInfo.telephone }}</li>
								<li>{{ 'email' | translate }}: {{ this.userInfo.email }}</li>
							</ul>
						</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
