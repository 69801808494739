import { Component, OnInit , Renderer2,  OnDestroy, ViewChild , ViewChildren, HostListener  } from '@angular/core';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2'
import { ModalManager } from 'ngb-modal';
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";
import {SharedService} from "./shared.service";
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SocialAuthService , FacebookLoginProvider } from 'angularx-social-login';
declare var $: any;
import { CartsharedService } from './cartshared.service';
import { WishlistService } from './wishlist.service';
import { SessionService } from './session.service';
import { LocalstorageService } from './localstorage.service';
import { LogoutService } from './logout.service';
import { LoginService } from './login.service';

import { MenusharedService } from './menushared.service';
import { MenugetService } from './menuget.service';
import { CheckwebpService } from './checkwebp.service';

import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { NavigationStart, Event } from '@angular/router';

import { TitlechangeService } from './titlechange.service';
import { Location } from '@angular/common';

declare var ga:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit,OnDestroy{
  @ViewChild('emailNewletterInput2') emailNewletterInput2;
  @ViewChild('mobileMenu') mobileMenu;
  @ViewChildren('menuCicle') things;
  @ViewChild('ModalPasswordMn') ModalPasswordMn;
  @ViewChild('emailrecoverPassMn') emailrecoverPassMn;
  @ViewChild('ModalCookies') ModalCookies;
  @ViewChild('ModalCookiesDetail') ModalCookiesDetail;
  private _subscription: Subscription[];
  thingsChanges:any;
  contentPrinted=false;

  browserwebp: any;
  isShow: boolean;
  topPosToStartShowing = 100;

  title = 'armazensronfe';
  modalDetailRef:any;
  cart:any;
  clickEventsubscription:Subscription;
  clickEventsubscriptionSession:Subscription;
  clickEventsubscriptionLogout:Subscription;

  cartTotal:any;
  freeShipLimit = 0;
  languageWebsite: any;
  companyPresentation: any;
  companyInfo: any;
  menu: any;
  gap: any;
  submitted = false;
  blog: any;
  userLogging: Boolean= false;
  userLoggingName: any;
  shippingTotal:any;
  vouchers:any;
  subscribe:any;
  newsletterRegister:FormGroup;
  submittedNews:any;
  signinFormMn:FormGroup;
  subscribeFace:any;
  gapCookiepage:any;
  subscribeMenu:any;
  deviceInfo:any;
  deviceDesktop:any;
  companyPresentationArr:any;
  languagesPermit:any;
  qtdWishlist:any;
  year: any;

  cookies: any = [];
  showcookie: boolean = true;

  modalCookiesRef: any;
  modalCookiesDetailRef: any;

  constructor(public titlechangeService:TitlechangeService, public wishlistService:WishlistService, private angulartics2: Angulartics2, private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce,public checkwebp: CheckwebpService,public localize: LocalizeRouterService,public deviceService: DeviceDetectorService, private routea: ActivatedRoute, private menugetService:MenugetService, public menusharedService:MenusharedService,  private loginService: LoginService, private authService: SocialAuthService, private formBuilder: FormBuilder, public logoutservice: LogoutService, public localstorageService: LocalstorageService, private router: Router,
   public apiService: ApiService, public translate: TranslateService, public renderer: Renderer2
  , public sharedService: SharedService, public cartsharedService: CartsharedService, public sessionService: SessionService, public modalService: ModalManager, private Angulartics2Facebook: Angulartics2Facebook, private Angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics, private Angulartics2GoogleTagManager: Angulartics2GoogleTagManager,private _location: Location) {





    this._subscription = [];

    let subs987 =  this.titlechangeService.get().subscribe(()=>{

      setTimeout(()=>{

          ga.getByName('t0').set('&dl', location.href );
          angulartics2.pageTrack.next({});

          }, 50);

        });
        this._subscription.push(subs987);

    this.qtdWishlist=0;
    this.deviceDesktop=false;
    const isDesktopDevice = this.deviceService.isDesktop();
    if(isDesktopDevice){
      this.deviceDesktop=true;
    }
 this.localstorageService.remove('detailBack');

      this.browserwebp = this.checkwebp.canUseWebP();



    this.submittedNews = false;
    this.newsletterRegister = this.formBuilder.group({
        email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
        terms: new FormControl(false, [  Validators.requiredTrue ])
      });

      this.signinFormMn = this.formBuilder.group({
      rememberme: new FormControl('' ),
      email: new FormControl('', [ Validators.required,  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      password: ['', [Validators.required, Validators.minLength(1)]]
    });

      this.clickEventsubscription =    this.sharedService.getClickEvent().subscribe(()=>{
        this.getCart(0);
      });

      this.clickEventsubscriptionLogout =    this.logoutservice.getClickEvent().subscribe(()=>{
        this.logout();
      });

      this.clickEventsubscriptionSession =    this.sessionService.getsessionEvent().subscribe((obj)=>{
        this.userLoggingName = obj[1];this.userLogging = obj[0];
      });


    if(this.localstorageService.get("userLogging")){
      this.userLogging=this.localstorageService.get("userLogging");
    }
    if(this.localstorageService.get("userLoggingName")){
      this.userLoggingName=this.localstorageService.get("userLoggingName");
    }

    this.apiService.getSession().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==201){
        this.userLogging=false;this.localstorageService.set("userLogging", false);
        this.userLoggingName="";this.localstorageService.set("userLoggingName", "");
      }
    });

    this.subscribeMenu =  this.menugetService.get().subscribe((obj)=>{
      if(this.menu && this.menu.length>0){
        this.menusharedService.send(this.menu);
      }
    });

    let subs20 = this.wishlistService.get().subscribe((obj)=>{
      this.getwishlist();
    });
    this._subscription.push(subs20);


        Angulartics2Facebook.startTracking();
    		Angulartics2GoogleAnalytics.startTracking();
    		Angulartics2GoogleTagManager.startTracking();
  }



  ngAfterViewInit(){
    this.getCart(0);

  }


  trackByFnMobile(index, item){
    return "menuMobile_"+item.idmenu;
  }


  trackByFnDesktop(index, item){
    return "menuDesktop_"+item.idmenu;
  }


  changeEmailClear(event){
  if(this.signinFormMn && this.signinFormMn.controls.email && this.signinFormMn.controls.email.value){
    this.signinFormMn.controls.email.setValue ( this.signinFormMn.controls.email.value.toString().trim().toLowerCase() );
  }
}


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth<=991){
      this.deviceDesktop=false;
    }else{
      this.deviceDesktop=true;
    }
  }


  hoverMenu(i_n:any){
    let x = document.getElementsByClassName("menuFull"+i_n) as HTMLCollectionOf<HTMLElement>;
    x[0].style.display = "block";
  }

  nohoverMenu(i_n:any){
    let x = document.getElementsByClassName("menuFull"+i_n) as HTMLCollectionOf<HTMLElement>;
    x[0].style.display = "none";
  }


  savelang(lang:any){
    this.apiService.changelang(lang).subscribe((obj: any)=>{

    });
  }
  getLang(acc: any){
		let lang = this.translate.instant(acc);
		return lang;
	}



  get frm() { return this.newsletterRegister.controls; }
  get f() { return this.signinFormMn.controls; }
  logout(){
    this.apiService.logout().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.getCart(0);
        this.userLogging=false;this.localstorageService.set("userLogging", false);
        this.userLoggingName="";this.localstorageService.set("userLoggingName", "");
        this.router.navigate([this.localize.translateRoute('/')] );
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  signInWithFBMn(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  recoverPassMn(){
    this.apiService.recoverPass(this.emailrecoverPassMn.nativeElement.value.toString(), this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.translate.get( obj.msg ).subscribe(translations => {
        Swal.fire({
          position: 'top-end',
          customClass: {
            container: 'swal-index'
          },
          icon: 'success',
          title: translations,
          showConfirmButton: false,
          timer: 2000
        });
        });
        this.closeModal();
        this.emailrecoverPassMn.nativeElement.value="";
      }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

  onSubmitMn() {
    if(this.signinFormMn && this.signinFormMn.controls.email && this.signinFormMn.controls.email.value){
      this.signinFormMn.controls.email.setValue( this.signinFormMn.controls.email.value.toString().trim() );
    }
    this.submitted = true;
    if (this.signinFormMn.invalid) {
      return;
    }
    var formData = new FormData();
    Object.keys(this.signinFormMn.controls).forEach(key => {
      if(this.signinFormMn.controls[key].value){
        formData.append("userweb["+key+"]", this.signinFormMn.controls[key].value.toString());
      }else{
        formData.append("userweb["+key+"]", "");
      }
    });
    this.apiService.signin(formData, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.submitted = false;
        this.localstorageService.set("userLogging", true);
        this.localstorageService.set("userLoggingName", obj.info);
        this.sessionService.sendsession(true, obj.info.name);
        this.signinFormMn.controls["email"].setValue("");
        this.signinFormMn.controls["password"].setValue("");
		this.getCart(0);
    this.getwishlist();
        if(location.pathname.split("/")[2]=="checkout"){
            this.loginService.sendloginEvent();
        }else{
        }
      }else if(obj.code==302){ this.sessionService.sendsession(true, ""); this.router.navigate([this.localize.translateRoute('/')] ); }else{
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
      }
    });
  }

  openModalMn(){
        this.modalDetailRef = this.modalService.open(this.ModalPasswordMn, {
            size: 'md',
            windowClass: 'modal-md'  ,
            hideCloseButton: false,
            centered: false,
            animation: true,
            keyboard: false,
            closeOnOutsideClick: false,
			backdropClass: 'modal-backdrop'
        });
    }

    closeModal(){
        this.modalService.close(this.modalDetailRef);
    }


 replacementString(s: String, val: any){
   return s.replace(":1:", val);
 }



  startMenu() {


  if(!this.contentPrinted){
    if($('#mobile-menu-active > ul > li').length<=2) {return true;}
    this.contentPrinted=true;
    $('#mobile-menu-active').meanmenu({
  		meanScreenWidth: "991",
  		meanMenuContainer: ".mobile-menu-area .mobile-menu",
  	});

  }

}



  mobilenohref(elem){
    if($(elem).next().is(":visible")){
      $(elem).next().css("display", "none");
    }else{
      $(elem).next().css("display", "block");
    }
    //$("#mobile-menu-active-ul").css("display", "block");
  }


 searchItem(searchInput){
   this.router.navigate([this.localize.translateRoute('/show'),   searchInput.value+"e"]);
   searchInput.value = "";
   window["closeSearch"]();
 }

 newsletterInput(email:any, emailNewletterInput:any, emailNewletterTerms:any){

   this.submittedNews = true;
   if(email==""){
     this.newsletterRegister.controls.email.setErrors({ required: true });
     this.submittedNews = true;
     return;
   }

   if(this.newsletterRegister.controls.terms.value==false){
     //this.newsletterRegister.controls.terms.setErrors({ required: true });
     this.submittedNews = true;
      return;
   }

   this.submittedNews = false;
   this.apiService.newsletter(email, this.translate.currentLang).subscribe((obj: any)=>{
     if(typeof obj!=="undefined" && obj.code==200){
       this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire(translations);    });
          emailNewletterInput.value="";
          emailNewletterTerms.checked=false;
       this.newsletterRegister.controls.email.setErrors(null);
       this.submittedNews = false;
       this.newsletterRegister.controls.terms.setValue(false);
     }else{
       this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);  this.newsletterRegister.controls.email.setErrors(null);
       this.submittedNews = false;  });
     }
   });
 }


 getwishlist(){
   this.apiService.getwishlist().subscribe((obj: any)=>{
     if(typeof obj!=="undefined" && obj.code==200){
       this.qtdWishlist = obj.total;
     }else{

     }
   });
 }

  ngOnInit() {

    let date = new Date();
    this.year = date.getFullYear();

    this.getMenu(this.translate.currentLang);
    this.getCookies(this.translate.currentLang);
    this.apiService.getlanguages(0).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        let myArray: Array<string> = []
        obj.info.forEach( (myObject, index) => {
              myArray.push(myObject.acronym.toLowerCase());
        });
        this.languagesPermit = myArray;
      }
    });
    this.getPresentation(this.translate.currentLang);
this.submittedNews = false;
  this.subscribeFace=this.authService.authState.subscribe((user) => {
    if(!user){
      return;
    }
    var formData = new FormData();
    formData.append("userweb[email]", user.email);
    formData.append("userweb[name]", user.name);
    formData.append("userweb[id]", user.id);
      this.apiService.loginFacebook(formData, this.translate.currentLang).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){
          this.localstorageService.set("userLogging", true);
          this.localstorageService.set("userLoggingName", user.name);
          this.sessionService.sendsession(true, user.name);
          this.getCart(0);
          this.getwishlist();
          this.authService.signOut();
          if(location.pathname.split("/")[2]=="checkout"){
              this.loginService.sendloginEvent();
          }else{
            this.router.navigate([this.localize.translateRoute('/account')] );
          }
        }else if(obj.code==302){ this.sessionService.sendsession(true, ""); this.router.navigate([this.localize.translateRoute('/')] ); }else{
          this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        }
      });
  });



    this.getwishlist();


          this.apiService.readCompany(0, "PT").subscribe((obj: any)=>{

            if(typeof obj!=="undefined" && obj.code==200){
              this.languageWebsite = obj.info.languagewebsite;
              if(obj.info.typewebsite==0){
                this.freeShipLimit = obj.info.freeshippingb2c;
              }else{
                this.freeShipLimit = obj.info.freeshippingb2b;
              }
              this.companyInfo = obj.info;
              this.cartsharedService.sendcart(this.cart, this.cartTotal, this.companyInfo, this.shippingTotal, this.vouchers);
            }else{
              this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
            }
          });






      this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
                this.getdescriptionmenu();
                if(lang.lang && this.companyPresentationArr && this.companyPresentationArr[lang.lang]){
                    this.companyPresentation = this.companyPresentationArr[lang.lang];
                }

                this.getTranslatations(lang.lang, 0);
                this.renderer.setAttribute(document.querySelector('html'), 'lang', lang.lang);
            });



       if(this.localize.parser.currentLang){

           this.getTranslatations(this.localize.parser.currentLang, 1);
           this.renderer.setAttribute(document.querySelector('html'), 'lang', this.localize.parser.currentLang);

       }


  }

  ngOnDestroy(): void {
    this.clickEventsubscriptionLogout.unsubscribe();
    this.subscribe.unsubscribe();this.clickEventsubscriptionSession.unsubscribe();this.clickEventsubscription.unsubscribe();
    this.subscribeMenu.unsubscribe();
    this.subscribeFace.unsubscribe();
    for (var i = 0; i < this._subscription.length; i++) {
       if(this._subscription[i]){
         this._subscription[i].unsubscribe();
       }
    }
  }


  getCart(change: any){
    if(change==0){
      let ischeckout=0;
      if(location.pathname.split("/")[2]=="checkout"){
        ischeckout=1;
      }
      this.apiService.getCart(ischeckout).subscribe((obj: any)=>{
        if(!obj.session){
           this.userLogging=false;this.localstorageService.set("userLogging", false);
           this.userLoggingName="";this.localstorageService.set("userLoggingName", "");
        }
        if(typeof obj!=="undefined" && obj.code==200){
          if(obj.info){

            this.cart = obj.info;
            this.cartTotal = obj.calc.values.summary;
            if(obj.shippingCost){ 
              this.shippingTotal = obj.shippingCost;
            }
            if(obj.vouchers){
              this.vouchers = obj.vouchers;
            }
            this.cartsharedService.sendcart(this.cart, this.cartTotal, this.companyInfo, this.shippingTotal, this.vouchers);
          }
        }else{
          this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
    }
  }

  getTranslatations(lang: String, change: Number){
    if(!lang){
      return 0;
    }
    this.getGap(lang);
    this.getBlog("1", lang);



  }


  getBlog(limit: String, lang:String){
    this.apiService.getBlog(lang, limit).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.blog = obj.info;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }

  getGap(lang:String){
    this.apiService.getGap(lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.gap = obj.info;
        this.gapCookiepage=obj.cookie;
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }

  getPresentation(lang:String){
    this.apiService.getPresentation(lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.companyPresentation = obj.info;
        this.companyPresentationArr = obj.langs;
        if(!lang){
        //  this.companyPresentation = obj.langs[lang];
        }
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }

  getMenu(lang:String){
    this.apiService.getMenu(lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.menu = obj.info;
        if(!lang){
          this.getdescriptionmenu();
        }
        else{
          this.menusharedService.send(this.menu);
        }
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }




  menustyle(menu){
    let r: String = "";
    if(menu.color){
      r += "color:"+menu.color+" ;";
    }
    if(menu.box && menu.box==1){
      r += "border-style: solid;border-width: 1px; padding:2px 8px;" ;
    }
    if(menu.box && menu.box==1 && menu.color){
      r += "border-color:"+ menu.color+" ;";
    }else{
      r += "border-color:#000;";
    }
    return r;
  }
  menustylemobile(menu){
    let r: String = "";
    if(menu.color){
      r += "color:"+menu.color+" ;";
    }
    return r;
  }




   cartDesc(ct:any){
     if(ct.itemwithattr==1){
       let color = Object.keys(ct.colors)[0];
       let size = Object.keys(ct.colors[color])[0];
       return ct.langs[this.translate.currentLang].description+" - " + ct.colors[color][size].langs[this.translate.currentLang].name + " - "+ct.colors[color][size].number;
     }else{
       return ct.langs[this.translate.currentLang].description;
     }
   }

   cartPrice(item:any, op:Number){
     if(op==1){
       if(item.itemwithattr==1){
           let color = Object.keys(item.colors)[0];
           let key = Object.keys(item.colors[color])[0];
           return item.colors[color][key].priceOriginalTxt;
       }else{
         return item.priceOriginalTxt;
       }
     }else{
       if(item.itemwithattr==1){
           let color = Object.keys(item.colors)[0];
           let key = Object.keys(item.colors[color])[0];
           return item.colors[color][key].priceFinalTxt;
       }else{
         return item.priceFinalTxt;
       }
     }
   }

   cartImg(item:any){
     if(item.itemwithattr==1){
         let color = Object.keys(item.colors)[0];
         let key = Object.keys(item.colors[color])[0];
         if(this.browserwebp && item.colors[color][key].images[0].webp!=null && item.colors[color][key].images[0].webp!=""){
           return this.apiService.imagesDir+item.colors[color][key].images[0].webp;
         }
         return this.apiService.imagesDir+item.colors[color][key].images[0].photo;
     }else{
         if(this.browserwebp && item.images[0].webp!=null && item.images[0].webp!=""){
           return this.apiService.imagesDir+item.images[0].webp;
         }
         return this.apiService.imagesDir+item.images[0].photo;
     }
   }

   removeItemCart(ct:any, i:any){
     let arr:any=[];
       let obji:any = {} ;

       let desc:any= "";
       let cat:any = "";
       let brand:any = "";
       let color_name:any = "";
       let pval:any = "";
       let arrsendlitics = [];
       let arrsendliticsga4 = [];

     if(ct.itemwithattr==1){
       let color = Object.keys(ct.colors)[0];
       let size = Object.keys(ct.colors[color])[0];
       obji.idcolor = color;
       obji.idsize = size;
       obji.iditem = ct.iditem;
       color_name = ct.colors[color][size].name;
       if(ct.colors[color][size].priceFinal) { pval = ct.colors[color][size].priceFinal; }
     }else{
       pval = ct.priceFinal;
       obji.iditem = ct.iditem;
     }



     if(this.translate.currentLang && ct.langs[this.translate.currentLang]){
       desc = ct.langs[this.translate.currentLang].description;
     }else{
       desc = ct.description;
     }
     if(this.translate.currentLang && ct.catlangs && ct.catlangs[this.translate.currentLang] && ct.catlangs[this.translate.currentLang].description) {
       cat = ct.catlangs[this.translate.currentLang].description;
     }
     brand = (ct.brandName) ? ct.brandName : '';


     arrsendlitics.push({
       'id': ct.iditem,
       'name': desc,
       'price': pval,
       'brand': brand,
       'category': cat,
       'variant': color_name,
       'quantity': Number(ct.quantity)
      });

      arrsendliticsga4.push({
        'item_id': ct.iditem,
        'item_name': desc,
        'price': pval,
        'item_brand': brand,
        'item_category': cat,
        'item_variant': color_name,
        'quantity': Number(ct.quantity)
       });

     arr.push(obji);
     this.apiService.removeCart(arr).subscribe((obj: any)=>{
       if(typeof obj!=="undefined" && obj.code==200){


         arrsendlitics.forEach((val) => {

           this.angulartics2GAEcommerce.ecAddProduct({
                 'id': val.id,
                 'name': val.name,
                 'category': val.category,
                 'brand': val.brand,
                 'variant': val.variant,
                 'price': val.price,
                 'quantity': val.quantity,
                 'position': 0
               });
               this.angulartics2GAEcommerce.ecSetAction("remove", {});

         });


         this.angulartics2.eventTrack.next({
           action: 'removeFromCart',
           properties: {
             label: 'removeFromCart',
             currency: "EUR",
             content_type: 'product_group',
             "event":"removeFromCart",
             gtmCustom:{
               'ecommerce': {
                 'purchase': undefined,
                 'click': undefined,
                 'checkout': undefined,
                 'detail': undefined,
                 'impressions': undefined,
                 'add': undefined,
                 'remove': {
                   'actionField': {'list': 'removeFromCart'},    // 'detail'
                   'products': arrsendlitics
                  }
                }
             }
           }
         });

         //Ga4
         this.angulartics2.eventTrack.next({  
          action:'remove_from_cart',
          properties: {
            'remove_from_cart': {
              'currency': 'EUR', 'value':pval,    // 'detail'
              'items': arrsendliticsga4
             }
          }
         })

          this.getCart(0);
       }else{
         this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
       }
     });
   }

   gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  @HostListener('window:scroll')
  checkScroll() {



    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const window_width = window.innerWidth;
    if(scrollPosition > 50 && window_width < 769){
      $(".cartWrapper2").css({top: '70px'})
    }
    else{
      $(".cartWrapper2").css({top: '116px'})
    }

    if($(".mobile-menu nav ul:first-child").css('display') !== 'block'){
    if(scrollPosition >= this.topPosToStartShowing && !$("#menu_header").hasClass(".fixedHeader")) {
      let fixedsize = $( "#menu_header" ).height();
      $(".sizeDocumenthtml").height(fixedsize);
		  $("#menu_header").addClass("c-layout-header fixedHeader");
	  }
	  else {
      $(".sizeDocumenthtml").height(0);
		  $("#menu_header").removeClass("c-layout-header").removeClass("fixedHeader");
	  }
  }else{
    if($(document).width()>991){
      if(scrollPosition >= this.topPosToStartShowing && !$("#menu_header").hasClass(".fixedHeader")) {
        let fixedsize = $( "#menu_header" ).height();
        $(".sizeDocumenthtml").height(fixedsize);
  		  $("#menu_header").addClass("c-layout-header fixedHeader");
  	  }
  	  else {
        $(".sizeDocumenthtml").height(0);
  		  $("#menu_header").removeClass("c-layout-header").removeClass("fixedHeader");
  	  }
    }else{
      $(".sizeDocumenthtml").height(0);
      $("#menu_header").removeClass("c-layout-header").removeClass("fixedHeader");
    }

  }

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }


  getdescriptionmenu(){
    if(this.menu && this.menu.length>0){

      this.menu.forEach((menucicle: any, key: any) => {
          if(menucicle.langs && menucicle.langs[this.translate.currentLang]){
            this.menu[key].description = menucicle.langs[this.translate.currentLang];
          }
          if(menucicle.level_info){
            menucicle.level_info.forEach((submenu: any, keysub: any) => {
              if(submenu.langs && submenu.langs[this.translate.currentLang]){
                this.menu[key].level_info[keysub].description = submenu.langs[this.translate.currentLang];
                if(submenu.level_info){
                  submenu.level_info.forEach((submenu2: any, keysub2: any) => {
                    if(submenu2.langs && submenu2.langs[this.translate.currentLang]){
                      this.menu[key].level_info[keysub].level_info[keysub2].description = submenu2.langs[this.translate.currentLang];
                    }
                  });
                }
              }
            });
          }
      });

    }

    this.menusharedService.send(this.menu);
  }
  
  quant(value: any){
    if(typeof value === 'string'){
      return parseInt(value);
    }
    else{
      return value;
    }
  }

  getCookies(lang: any){
		this.apiService.getcookies(lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.info.length == 0){
          this.showcookie = false;
        }
        else{
          obj.info.forEach(element => {
            if(element.idcookie == '1'){element.checked = true;}else{ element.checked = false; }
          });
          this.cookies = obj.info;
          this.openModalCookies();
        }
      }
    })
	}

  saveCookies(checkall: any){
    if(checkall == 1){
      this.cookies.forEach(element => {
        element.checked = true;
      });
    }
    let formData = new FormData()
    for (let index = 0; index < this.cookies.length; index++) {
      formData.append("cookies["+index+"][checked]", this.cookies[index].checked.toString());
      formData.append("cookies["+index+"][idcookie]", this.cookies[index].idcookie.toString());
    }
    this.apiService.createUserCookies(formData).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.showcookie = false;
      }
    })
  }

  openModalCookies(){
    this.modalCookiesRef = this.modalService.open(this.ModalCookies, {
      size: 'md',
      windowClass: 'modal-md'  ,
      centered: true,
      animation: true,
      hideCloseButton: true,
      keyboard: false,
      closeOnOutsideClick: false,
      backdropClass: 'modal-backdrop',
      backdrop: true
    });
    this.modalCookiesRef.onOpen.subscribe(() => {
        $("modal .fade.show").addClass("in");
    });
  }

  openModalCookiesDetail(){
    this.modalCookiesDetailRef = this.modalService.open(this.ModalCookiesDetail, {
      size: 'lg',
      windowClass: 'modal-lg'  ,
      centered: true,
      animation: true,
      hideCloseButton: true,
      keyboard: false,
      closeOnOutsideClick: false,
      backdropClass: 'modal-backdrop'
    });
    this.modalCookiesDetailRef.onOpen.subscribe(() => {
        $("modal .fade.show").addClass("in");
    });
  }

}
