<div class="title-breadcrumbs">
	<div class="title-breadcrumbs-inner">
		<div class="container-fluid">
			<nav class="woocommerce-breadcrumb">
				<a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate }}</a>
				<span class="separator"> / </span>
				{{ 'wishlist' | translate }}
			</nav>
		</div>
	</div>
</div>

<div class="my-account white-bg pt-50 pb-30">
	<div class="container-fluid">
		<div class="account-dashboard">
			<div class="row">
				<div class="col-md-12 col-lg-2   d-lg-block">
					<span class="title_h3 c-title c-font-uppercase c-font-bold">{{ 'myaccount' | translate }}</span>
					<ul class="nav flex-column dashboard-list" role="tablist">
						<li>
							<a class="nav-link " [routerLink]="(['/account'] | localize)" href="javascript:void(0);">{{ 'accinfo' | translate }}</a>
						</li>
						<li>
							<a class="nav-link" [routerLink]="(['/profile'] | localize)" href="javascript:void(0);">{{ 'editinfo' | translate }}</a>
						</li>
						<li>
							<a class="nav-link" [routerLink]="(['/orders'] | localize)" href="javascript:void(0);">{{ 'orders' | translate }}</a>
						</li>
						<li [hidden]="true">
							<a class="nav-link" [routerLink]="(['/addresses'] | localize)" href="javascript:void(0);">{{ 'myaddresses' | translate }}</a>
						</li>
            <li  >
							<a class="nav-link active" [routerLink]="(['/wishlist'] | localize)" href="javascript:void(0);">{{ 'wishlist' | translate }}</a>
						</li>
						<li>
							<a class="nav-link" (click)="this.logoutservice.sendClickEvent()" href="javascript:void(0);">{{ 'logout' | translate }}</a>
						</li>
					</ul>
				</div>
				<div class="col-md-12 col-lg-10">




            <!-- <div class="mat-app-background basic-container ">
  						<div class="example-container ">
  							<div class="example-loading-shade"
  							*ngIf="isLoadingResults || isRateLimitReached">
  							<mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  							<div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
  								{{ 'ERROR_CONNECT_API' | translate }}
  							</div>
  						</div>


  						  <input matInput  type="hidden"   placeholder="{{ 'filter' | translate }}" #inputSearch>


  						<div class="example-table-container mat-elevation-z8">

  							<table mat-table [dataSource]="dataTable" class="example-table"
  							matSort matSortActive="description" matSortDisableClear matSortDirection="desc">


                <ng-container matColumnDef="image">
  								<th mat-header-cell *matHeaderCellDef  ></th>
  								<td mat-cell *matCellDef="let row"> <img *ngIf="row.image" style="max-width:70px;margin-top: 3px;
    margin-bottom: 3px;" [src]="this.apiService.imagesDir+row.image.photo" /> </td>
  							</ng-container>


  							<ng-container matColumnDef="item.description">
  								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'product' | translate }}</th>
  								<td mat-cell *matCellDef="let row">{{row.description}}</td>
  							</ng-container>


  							<ng-container matColumnDef="item.code">
  								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'refa' | translate }}</th>
  								<td mat-cell *matCellDef="let row">{{row.code }}</td>
  							</ng-container>


  							<ng-container matColumnDef="color.name">
  								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'color' | translate }}</th>
  								<td mat-cell *matCellDef="let row">{{row.name}} </td>
  							</ng-container>





  							<ng-container matColumnDef="icon">
  								<th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>

  								</th>
                  <td mat-cell *matCellDef="let row"> <a (click)="removeLine(row.iditem, row.idcolor)" href="javascript:void(0)"> <i class="zmdi zmdi-delete"></i> </a> </td>
  							</ng-container>

  							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  							<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  						</table>
  					</div>

  					<mat-paginator [hidePageSize]="true" [length]="resultsLength" [pageSize]="10"></mat-paginator>
  				</div>
  			</div> -->


			<div class="product-area pb-65 product-padding"  >
				<div class="row" id="prodHolder">
					<ng-container *ngIf="this.itemsList">
				  		<ng-container *ngFor="let item of this.itemsList;trackBy: trackByFn; let indexprimary=index    ">
							<div class="col-lg-3 col-md-4 col-xs-6 col-6 productWrapper ">
					  			<div class="product-wrapper mb-35"  id="prodBox{{ item.iditem }}">
									<div class="product-img">
									<a (click)="urlmount(item, item.description, item.iditem)"  [href]="item.url" class="">
										<img  [errorImage]="this.apiService.imagesDir+item.image.photo" [defaultImage]="this.browserwebp ? this.apiService.imagesDir+item.image.webp : this.apiService.imagesDir+item.image.photo " alt="{{ item.description  }} - {{ 'Name_APP' | translate }}"  class=" " [lazyLoad]="this.apiService.imagesDir+item.image.photo" />
									</a>
									</div>
									<div class=" product-price-2  ">
										<div class="price-box">
										  <promo style="padding: 13px 15px;border: 1px solid #dc3545; color: #dc3545;  font-size: 17px;  background: transparent;" class="c-link" *ngIf="  item.withPromotion && item.withPromotion==1  "> -{{ showDiscount( item ) }}%</promo>
										  <!-- <new *ngIf="  item.novelty==1 && ((item.datelimitnovelty && item.datelimitnovelty>=item.datenow) || !item.datelimitnovelty )" >{{ 'new' | translate }}</new> -->
										</div>
									  </div>
								<div class="rating-box product-img" style="margin-top: 5px; min-height: 30px; text-align: center;" *ngIf="item.itemwithattr==1">
									<ng-container *ngIf="item.colors && objectKeys(item.colors).length>0">
										<ng-container *ngFor="let color of item.colors | keyvalue ; let i=index">
										</ng-container>
									</ng-container>
								</div>
								<div [style]="this.deviceDesktop ? '' : ' ' " class="product-content  " style="padding-top:5px;">
									<span class="">
										<a (click)="urlmount(item, item.description, item.iditem, color(item), size(item, 0))"  style="margin-right: 0px;margin-left: 0px;"   class="imgProd row  " href="javascript:void(0)">
											<div class="col-xs-12 col-md-12">
												<div class="row">
													<div class="full-width text-center" *ngIf="item.brandName "><b>{{ item.brandName  }}</b> </div>
													<div class="full-width text-center"  style="text-overflow: ellipsis;font-size: 11px;white-space: nowrap;overflow: hidden;"><b>{{ item.description  }}</b></div>
													<div class="full-width text-center">
														<span style="font-size: 10px;">{{ 'ref' | translate }} {{ item.code }}</span>
													</div>
													<div class="full-width text-center">
														<span style="font-size: 10px;">{{ 'color' | translate }} {{ item.name }}</span>
													</div>
												</div>
											</div>
										</a>
										<ng-container *ngIf="item.withPromotion">
											<div class="full-width text-center mt-2 mb-2">
												<del>{{ item.pricedefaultTxt }} €</del>
											</div>
											<div class="full-width text-center mt-2 mb-2" style="color: red;">
												{{ item.pricepromotionTxt }} €
											</div>
										</ng-container>
										<ng-container *ngIf="!item.withPromotion">
											<div class="full-width text-center mt-2 mb-2">
												{{ item.pricedefaultTxt }} €
											</div>
										</ng-container>
										<!-- <div class="full-width text-center mt-2 mb-2" style="font-size: 14px;" [style]="item.withPromotion == 1 ? 'color: red' : ''">
											<ng-container *ngIf="item.withPromotion == 1">{{ item.pricepromotionTxt }} €</ng-container>
											<ng-container *ngIf="item.withPromotion == 0">{{ item.pricedefaultTxt }} €</ng-container>
										</div> -->
										<div class="full-width text-center"><a (click)="removeLine(item.iditem, item.idcolor)" href="javascript:void(0)"> <i class="zmdi zmdi-delete"></i> </a></div>
									</span>
								</div>
					  		</div>
						</div>
				  	</ng-container>
				</ng-container>
				<ng-container *ngIf="this.emptyItem  ">
					<span style="margin-left:15px;margin-right:15px">{{ 'noproductsfound' | translate }}</span>
				</ng-container>
			</div>
		</div>
	</div>
		</div>
		</div>
	</div>
</div>
