<div class="title-breadcrumbs">
  <div class="title-breadcrumbs-inner">
    <div class="container-fluid">
      <nav class="woocommerce-breadcrumb">
        <a [routerLink]="(['/'] | localize)" href="javascript:void(0)">{{ 'home' | translate  }}</a>
        <ng-container *ngFor="let breadcrumb of this.breadcrumbs;  "  >
          <span class="separator">/</span>&nbsp;
          <a *ngIf="breadcrumb.url" [routerLink]=" (breadcrumb.url | localize) " href="javascript:void(0)" >{{ breadcrumb.description |  titlecase }}</a>
          <a *ngIf="!breadcrumb.url"   href="javascript:void(0)" >{{ breadcrumb.description |  titlecase }}</a>
        </ng-container>
      </nav>
    </div>
  </div>
</div>



<!-- product details area start -->

<div class="product-details-area hm-3-padding ">
  <div class="container-fluid pad0">
    <div class="row pad0 mar0 col-12">
      <div   class="  hide-desktop" style="float:left;width:100%;padding-top:10px;padding-right:10px;padding-bottom:10px;">
        <a href="javascript:void(0)" (click)="this.location.back()" class="font-uppercase text-right font-11 letter-spacing-2 float-right">{{ 'back' | translate }}</a>
      </div>
      <div class="col-lg-7 col-xs-12 col-sm-12 pad0 background-white ">


        <new   *ngIf="this.item && this.item.novelty && this.item.datelimitnovelty && this.item.datelimitnovelty>=this.item.datenow" >{{ 'new' | translate }}</new>


        <ng-container   *ngIf="this.item  ">


          <ng-container   *ngIf="this.item && this.item.itemwithattr==1 ">

            <div [class]="'product-details-img-content galleryDisplay gallery' "   cont="{{ i }}"  >
              <div class="product-details-tab"  >
                <div class="slider-area">
                  <owl-carousel-o class="slider-active product-details-large product-slider-one  "     [options]="carouselOptionsDetail"    >
                    <ng-container   *ngFor="let img of  cicle(this.item.colors[this.idcolor]) | keyvalue ; let i1=index    ">
                      <ng-template carouselSlide>
                        <div class="item"><img [alt]="this.item.langs[this.translate.currentLang].description+' - '+ Name_APP|translate" [src]="(this.browserwebp && img.value.webp) ? this.apiService.imagesDir+img.value.webp :  this.apiService.imagesDir+img.value.photo"  class="col-xs-12 noPadding  "/></div>
                      </ng-template>
                    </ng-container>
                  </owl-carousel-o>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container   *ngIf="this.item && this.item.itemwithattr==0 ">
            <div class="'product-details-img-content galleryDisplay gallery "    >
              <div class="product-details-tab"  >
                <div class="slider-area">
                  <owl-carousel-o class="slider-active product-details-large product-slider-one  "     [options]="carouselOptionsDetail"    >
                    <ng-container   *ngFor="let img of  this.item.images ; let i1=index    ">
                      <ng-template carouselSlide>
                        <div class="item"><img [alt]="this.item.langs[this.translate.currentLang].description+' - '+ Name_APP|translate" [src]="(this.browserwebp && img.webp) ? this.apiService.imagesDir+img.webp : this.apiService.imagesDir+img.photo"  class="col-xs-12 noPadding  "/></div>
                      </ng-template>
                    </ng-container>
                  </owl-carousel-o>
                </div>
              </div>
            </div>
          </ng-container>

        </ng-container>




        <div style="padding: 13px 15px;top: 40px; font-weight: bold; font-size: 17px;  border: 1px solid #dc3545; color: #dc3545;    background: transparent;" *ngIf="this.item && this.item.withPromotion" [style]="this.item && this.item.withPromotion ? '' : 'display:none'" class="product-lable promo_color label_promo_slide"><span> -{{ showDiscount(this.item, this.idcolor, this.idsize) }}%</span></div>

      </div>
      <div class="col-lg-5 col-xs-12 col-sm-12   background-grey">
        <div   class="hide-mobile col-md-12" style="float:left;width:100%;padding-top:10px;z-index: 2;">
          <a style="    background-color: black;color: white !important;padding: 5px 15px;" href="javascript:void(0)" (click)="this.location.back()" class="text-right font-11 letter-spacing-2 float-right product-details-area new">{{ 'back_to_previous_page' | translate }}</a>
        </div>
        <div class="product-details-content col-md-12 col-lg-12 col-xl-12 col-xs-12 col-sm-12 m0 pt-50">


          <h2 class="text-left full-width title_item"><span>{{ (this.item && this.item.langs[this.translate.currentLang]) ? this.item.langs[this.translate.currentLang].description : '' }}</span></h2>
          <h6 class="pd-sub-title text-left font-12 font-weight-300  mb-0 pb_ref_prod" >{{ 'ref' | translate }} <span class='itemCode'>{{ this.item ? this.item.code : '' }}</span><span *ngIf="this.item && this.item.itemwithattr==1" class='showcolor'> - {{ 'color' | translate }} {{ this.item ? showbyColorName() : '' }}</span> </h6>


          <div *ngIf="this.item && this.item.brandLogo " class="product-price text-left font-12 col-xs-12 pb_ref_prod">  <img *ngIf="this.item.brandLogo" width="130" [src]="this.apiService.imagesDir+this.item.brandLogo" style="max-width:100%;margin-top: 10px;margin-bottom: 10px;" /></div>



          <div class="product-size text-left mb-20">
            <span class="title_h5 pd-sub-title font-14 hidden"><span *ngIf="this.item && this.item.itemwithattr==1">{{ 'choosecolorquant' | translate }}</span><span *ngIf="this.item && this.item.itemwithattr==0">{{ 'color' | translate }}</span>: <label class="showcolor font-12"></label></span>
            <span class="c-custom-labels" data-toggle="buttons">

              <ng-container   *ngIf="this.item && this.item.itemwithattr==1 ">
                <ng-container   *ngFor="let itm of this.item.colors | keyvalue; let i=index ">
                  <label  [class]=" this.idcolor==itm.key ?  'btn c-btn-square active' : 'btn c-btn-square '  ">
                    <div class="labelColor" style="background-size: 100% 100%;">

                      <img title="{{ showColorName(itm.value) }}" alt="{{ this.item.langs[this.translate.currentLang].description }} - {{ showColorName(itm.value) }} - {{ 'Name_APP' | translate }}" [src]="getImg(itm.value)">

                    </div>
                    <input style="position: absolute;clip: rect(0,0,0,0);" name="color" data-colorname="{{ showColorName(itm.value) }}" (change)="changeColor(itm.key)" value="{{ i }}" type="radio" />
                    <div class="text-center col-xs-12 noPadding">


                      <p class="noMargin c-font-10 c-font-bold hidden">{{ 'sizeabbr' | translate }}: {{ getSize(itm.value) }}</p>


                      <input class="form-control input-sm c-square quantInput " id="quantProd{{ this.item.code }}-{{ i }}-{{ getSize(itm.value) }}" value="1" name="quant[{{ i }}][{{ getSize(itm.value) }}]" type="hidden">

                    </div>

                  </label>

                </ng-container>
              </ng-container>



            </span>
            <ng-container   *ngIf="this.item && this.item.itemwithattr==1 ">
              <div class="chosen-white product-selectors col-xs-12 col-sm-12 noPadding c-margin-b-20 mt-10  "  >
                <label for="sizecontainer" class="c-input-title helveticaltsdr c-font-15 c-margin-b-10 ccblack">{{ 'size' | translate }}:</label>
                <div class="clearfix"></div>
                <span class="c-custom-labels2" data-toggle="buttons">
                  <ng-container *ngFor="let size of reorderSize(this.item.colors[this.idcolor]) | keyvalue; let i=index">
                    <label [class]="this.getSizes(size).idsize==this.idsize ? 'btn c-btn-square llabelSize backgroundgray active sizeBtn' : 'btn c-btn-square llabelSize sizeBtn'"><div class="labelSize" style="background-size: 100% 100%;">{{ this.getSizes(size).idsize==1 ? ('uniquesize' | translate)  : this.getSizes(size).number }}</div><input (change)="changeSize(this.getSizes(size).idsize)" class="inputTypeHidden" value="{{ this.getSizes(size).idsize }}" name="size" data-sizename="{{ this.getSizes(size).number }}" type="radio" /></label>
                  </ng-container>
                </span>
              </div>
            </ng-container>
          </div>
          <ng-container   *ngIf="this.item && this.item.withPromotion">
            <p   class="font-11 pd-sub-title text-left pb-0 mb-0">
              {{ 'promotionFrom' | translate }} {{ this.item.startPromo }} {{ 'to' | translate }} {{ this.item.endPromo }}
            </p>
          </ng-container>
          <div class="product-price pad0 text-left col-xs-12 mb-10 font-14"  >

            <ng-container   *ngIf="this.item && this.item.withPromotion">
              <span id="pricestart" class="old">{{ showPrice(this.item, 1, this.idcolor, this.idsize) }}</span>
            </ng-container>
            <span id="pricefinal" [style]="this.item && this.item.withPromotion ? 'color:red;' : ''" ><b>{{ showPrice(this.item, 2, this.idcolor, this.idsize) }}€</b></span>


          </div>

          <span *ngIf="this.item && this.item.internalcode" style="color:red;font-weight:bold; font-size:12px"><b>{{ 'EXCLUSIVE_ARTICLE' | translate }}</b></span>


          <div class="text-left col-12 pad0 mt-40 hidden">
            <div class="quickview-plus-minus float-left col-xl-4 col-lg-7 col-md-8 col-sm-6 col-xs-6 pad0">
              <div class="cart-plus-minus">
                <div class="dec qtybutton" (click)="descProd()">-</div>
                <input type="text" [class]="'cart-plus-minus-box quantInput1 stockUpdQuant c-item-' "  data_min="1"      name="quant" min="1" max="{{ getStock() }}" value="1" readonly disabled>
                <div class="inc qtybutton" (click)="incProd()">+</div>
              </div>
            </div>
            <div class="float-left col-xl-8 col-lg-5 col-md-8 col-sm-6 col-xs-6 pad0" (click)="buyProduct()">
              <button style="z-index: auto;border: 0px;" class="btn-style cr-btn cartBtn full-width c-square text-center font-12" >
                <span>{{ 'add_to_cart' | translate }}</span>
              </button>
            </div>
          </div>
          <div class="text-left col-12 pad0 mt-40 float-left mb-20">
            <div class="quickview-plus-minus float-left col-12 pad0">
              <div class="cart-plus-minus float-left">
                <div class="dec qtybutton" (click)="descProd()">-</div>
                <input type="text" class="cart-plus-minus-box quantInput1 stockUpdQuant c-item-"   data_min="1"      name="quant" min="1" max="{{ getStock() }}" value="{{ this.qtd }}" readonly disabled>
                <div class="inc qtybutton" (click)="incProd()">+</div>
              </div>
              <div class="float-left pad0" (click)="buyProduct()">
                <button style="z-index: auto;border: 0px;" class="btn-style cr-btn cartBtn full-width c-square text-center font-12 btn-danger" >
                  <span>{{ 'add_to_cart' | translate }}</span>
                </button>
              </div>

              <div *ngIf="this.userLogging" class="float-right  " style="margin-left: 20px;">
                <a (click)="addToFavorite()" style="z-index: auto;border: 1px solid;background-color: white;color:black"  class="btn-style cr-btn cartBtn full-width c-square text-center font-12 btn-default" >
                  <i *ngIf="this.item && this.item.itemwithattr==0 && this.item.wishlist==0" class="far fa-heart" aria-hidden="true"></i>
                  <i *ngIf="this.item && this.item.itemwithattr==0 && this.item.wishlist==1" class="fas fa-heart" aria-hidden="true"></i>

                  <i *ngIf="this.item && this.item.itemwithattr==1 && this.item.colors[this.idcolor][this.idsize].wishlist==0" class="far fa-heart" aria-hidden="true"></i>
                  <i *ngIf="this.item && this.item.itemwithattr==1 && this.item.colors[this.idcolor][this.idsize].wishlist==1" class="fas fa-heart" aria-hidden="true"></i>

                </a>
              </div>
            </div>
            <p *ngIf="this.item && this.item.internalcode" style="text-align: justify;font-size:10px;    line-height: 11px;" class="noPadding col-xs-12 c-margin-b-30 c-font-11"  > {{ 'online_exclusive_article_external' | translate }}  </p>
          </div>

          <div class="product-overview mt-10">

            <p [innerHTML]=" this.item ?  this.item.langs[this.translate.currentLang].composition : '' "></p>
          </div>
          <div class="product-share text-left">
            <div class="addthis_inline_share_toolbox_dejv"></div>
            <a *ngIf="this.item && this.deviceDesktop" href="javascript:void(0)" (click)="this._window.open('https://web.whatsapp.com/send?text='+this.urlProd, 'CNN_WindowName', 'menubar=yes,location=yes,resizable=yes')" class="whatsapp-share-button pull-right" rel="nofollow"  ></a>
            <a *ngIf="this.item && !this.deviceDesktop" [href]="this.sanitizer.bypassSecurityTrustUrl('whatsapp://send?text='+this.urlProd)" class="whatsapp-share-button pull-right" rel="nofollow" target="_blank"></a>
          </div>
        </div>

      </div>

    </div>


          <div style="margin-left: 0px;" class="row    col-lg-12 col-md-12 " *ngIf="this.relateditemarr && this.relateditemarr.length>0">

            <div style="text-align:center" class="  col-lg-12 col-md-12  mt-30 mb-20">
            <b>{{ 'OTHER_SUGGESTIONS' | translate }}</b>
            </div>

            <ng-container *ngFor="let product of this.relateditemarr; let i_product=index ">
              <div class="col-lg-2 col-md-2 col-xs-6 col-6 product-wrapper-padding">





                <div class="product-wrapper mb-35"  id="prodBox{{ product.iditem }}">
                  <div class="product-img" [style]="product.thumbnailsheight>800 ? 'background-color: #f3f3f3  ;    max-height: 467px; display: table; ' : ''">
                    <a  [style]="product.thumbnailsheight>800 ? 'background-color: #f3f3f3  ;    max-height: 467px;  ' : ''" (click)="urlmount(product, product.langs[this.translate.currentLang].description, product.iditem)"  onclick="return false;"    class="" [href]="product.langs[this.translate.currentLang].url">
                      <img [style]="product.thumbnailsheight >800 ? '    padding-top: 20%;    padding-bottom: 30%;    mix-blend-mode: multiply;    color: transparent;    width: 100%;    transform: scale(0.8);    display: inline;' : ''" [defaultImage]="this.browserwebp ? defaultImage : product.thumbnails " alt="{{ product.langs[this.translate.currentLang].description  }} - {{ 'Name_APP' | translate }}"  class="" [lazyLoad]="product.thumbnails" />


                    </a>

                    <div class="rating-box" *ngIf="  product.itemwithattr==1   ">
                      <ng-container   *ngIf="    product.colors && objectKeys(product.colors).length>0 ">

                      <ng-container   *ngFor="let color of  product.colors | keyvalue ; let i=index    ">
                        <ng-container *ngIf="  i<3   ">
                          <a  (mouseover)="ChangeVariantsImage(i_product, color.key, size(product, color.key))" (click)="urlmount(product, product.langs[this.translate.currentLang].description, product.iditem, color.key, size(product, color.key))"     href="javascript:void(0)">
                            <div style="display:inline-block;width:30px; height:30px; border:1px solid #ccc;margin-right:5px;background:url('{{ getBackgorundColor(color.value) }}');background-size:100% 100%;"></div>
                          </a>
                        </ng-container>


                        <ng-container *ngIf="  product.colors &&  i==4 ">
                          <a href="">
                            <div style="display:inline-block;width:30px; height:30px; border:1px solid #ccc;margin-right:5px;background:url('assets/images/plus.png');background-size:100% 100%;background-color:#FFF;"></div>
                          </a>
                        </ng-container>


                      </ng-container>
                      </ng-container>
                    </div>
                    <div (click)="openModal(product)" class="   product-action-2-2 btn btn-dark d-none d-lg-flex">



                        <h6 style="color:white">{{ 'buyfast' | translate }}</h6>

                    </div>
                  </div>
                  <div class="product-content text-center">
                    <span class="title_h4">
                      <a (click)="urlmount(product, product.langs[this.translate.currentLang].description, product.iditem, this.selectRelatedColor[i_product], this.selectRelatedSize[i_product])"     class="imgProd" href="javascript:void(0)">
                      {{ product.langs[this.translate.currentLang].description  }}
          </a>
                    </span>
                    <div class="product-price-2">
                      <div class="price-box">

                        <del *ngIf="  product.withPromotion && product.withPromotion==1  ">{{ showPrice(product, 1, this.selectRelatedColor[i_product], this.selectRelatedSize[i_product]) }}€</del>
                        <ins [style]="product.withPromotion && product.withPromotion==1 ? 'color:red;' : '' "><b>{{ showPrice(product, 2, this.selectRelatedColor[i_product], this.selectRelatedSize[i_product]) }}€</b></ins>
                        <promo style="padding: 13px 15px;border: 1px solid #dc3545; color: #dc3545;    background: transparent;" class="c-link" *ngIf="  product.withPromotion && product.withPromotion==1  "> -{{ showDiscount( product, this.selectRelatedColor[i_product], this.selectRelatedSize[i_product] ) }}%</promo>
                        <new *ngIf="  product.novelty==1 && ((product.datelimitnovelty && product.datelimitnovelty>=product.datenow) || !product.datelimitnovelty )" >{{ 'new' | translate }}</new>

                      </div>
                    </div>
                  </div>




                </div>




            </div>
            </ng-container>
          </div>


  </div>
</div>
<!-- product details area End -->




<modal #ModalDetail  id="buyFast" >
  <modal-header>

    <h6 class="c-font-12 c-font-sbold name_item m-0" *ngIf="this.selectItem"> {{ this.selectItem.langs[this.translate.currentLang].description }} </h6>

  </modal-header>
  <modal-content>


    <div class=" row"  >


      <div class="imagesProd col-md-6 col-xs-6 col-sm-6">
        <div class="col-lg-12" style="width:410px !important;max-height:380px;"><div>

          <ng-container   *ngIf="this.selectItem && this.selectItem.itemwithattr==1 ">

              <div [class]="'product-details-img-content galleryDisplay gallery'"   >
                <div class="product-details-tab" >
                  <div class="slider-area">
                        <owl-carousel-o class="slider-active product-details-large product-slider-one  "     [options]="carouselOptionsDetail"    >
                           <ng-container   *ngFor="let img of  this.selectItem.images | keyvalue ; let i1=index    ">
                            <ng-template carouselSlide       [width]="380" >
                            <div class="item"><img style="max-height:380px; object-fit: cover;"  [defaultImage]="this.apiService.imagesDir+img.value.photo" [alt]="this.selectItem.langs[this.translate.currentLang].description" [lazyLoad]="(this.browserwebp && img.value.webp) ? this.apiService.imagesDir+img.value.webp : this.apiService.imagesDir+img.value.photo"  class="col-xs-12 noPadding  "/></div>
                            </ng-template>
                          </ng-container>
                         </owl-carousel-o>
                    </div>
                </div>
              </div>
          </ng-container>
          <ng-container *ngIf="this.selectItem && this.selectItem.itemwithattr==0 ">
              <div class="'product-details-img-content galleryDisplay gallery "    >
                <div class="product-details-tab"  >
                  <div class="slider-area">
                        <owl-carousel-o class="slider-active product-details-large product-slider-one  "     [options]="carouselOptionsDetail"    >
                           <ng-container   *ngFor="let img of  this.selectItem.images ; let i1=index    ">
                             <ng-template carouselSlide       [width]="380"    >
                            <div class="item"><img  style="max-height:380px"   [defaultImage]="this.browserwebp ? defaultImage : this.apiService.imagesDir+img.photo" [alt]="this.selectItem.langs[this.translate.currentLang].description" [lazyLoad]="(this.browserwebp && img.webp) ? this.apiService.imagesDir+img.webp : this.apiService.imagesDir+img.photo"  class="col-xs-12 noPadding max-height-380 "/></div>
                            </ng-template>
                          </ng-container>
                         </owl-carousel-o>
                    </div>
                </div>
              </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="  col-md-6 col-xs-6 col-sm-6">
      <div class="infoProd mb-4" *ngIf="this.selectItem">
        <p class="noPadding col-xs-12 m-0 helveticaltsd c-font-13" style="font-size: 20px; font-weight: 600">{{ this.selectItem.description }}</p>
        <input type="hidden" name="iditem" value="{{ this.selectItem.iditem }}"/><input type="hidden" name="code" value="{{ this.selectItem.code }}"/>
        <p class="noPadding col-xs-12 m-0 helveticaltsd c-font-13 mt-2">{{ 'refa' | translate }} : {{ this.selectItem.code }}</p>
        <p *ngIf="this.selectItem.withPromotion == 1 && this.selectItem.endPromo"><b>{{'offer_ends_in' | translate}}: </b> <span>{{this.selectItem.endPromo}}</span></p>
      </div>

        <div class="b-margin-20">
          <ng-container   *ngIf="this.selectItem && this.selectItem.itemwithattr==1 ">
          <div class="col-xs-12 c-color-wrapper mt-2">
            <span class="c-input-title helveticaltsdr c-font-11 ccblack full-width float-left">{{ 'colors' | translate }}:</span>
            <span class="c-custom-labels" data-toggle="buttons">
            <ng-container   *ngFor="let color of  this.selectItem.colors | keyvalue ; let i=index ; first as isFirst ">
                <label  [class]=" color.key==this.selectItem.colorActive ?  'btn c-btn-square active' : 'btn c-btn-square '  "><div class="labelColor" style="  background-size: 100% 100%;"><img alt="{{ this.selectItem.langs[this.translate.currentLang].description   }} - {{ showcolorInfo(color,2) }} - {{ 'Name_APP' | translate }}" src="{{
                  (this.browserwebp && color.value[objectKeys(color.value)[0]].images[0].webp) ? this.apiService.imagesDir+color.value[objectKeys(color.value)[0]].images[0].webp : this.apiService.imagesDir+color.value[objectKeys(color.value)[0]].images[0].photo }}"></div><input (change)="changeColorFast(color.key)" class="inputTypeHidden" value="{{ color.key }}" name="color" data-colorname="{{ showcolorInfo(color,2) }}" id="inputColor_{{ color.key }}"   type="radio"  />
                </label>
            </ng-container>
          </span>
          </div>
        </ng-container>
          <ng-container   *ngIf="this.selectItem && this.selectItem.itemwithattr==1 ">
            <div class="col-xs-12 c-color-wrapper">
              <span class="c-input-title helveticaltsdr c-font-11 ccblack full-width float-left">{{ 'size' | translate }}:</span>
              <span class="c-custom-labels2" data-toggle="buttons">
                  <ng-container   *ngFor="let color of  this.selectItem.colors | keyvalue ; let i=index ; first as isFirst ">
                    <ng-container   *ngFor="let size of reorderSize(this.selectItem.colors[this.selectItem.colorActive]) | keyvalue; let i1=index    ">
                      <label [style]=" color.key==this.selectItem.colorActive  ?  '' : 'display:none'  "   [class]=" this.getSizesDetail(size).idsize==this.selectItem.sizeActive ?  'btn c-btn-square llabelSize backgroundgray active sizeBtn' : 'btn c-btn-square llabelSize sizeBtn'  "><div class="labelSize" style="  background-size: 100% 100%;">{{ this.getSizesDetail(size).idsize==1 ? ('uniquesize' | translate)  : this.getSizesDetail(size).number }}</div><input (change)="changeSizeFast(this.getSizesDetail(size).idsize)" class="inputTypeHidden" value="{{ this.getSizesDetail(size).idsize }}" name="size" data-sizename="{{ this.getSizesDetail(size).number }}" id="inputSize_{{ color.key }}_{{ this.getSizesDetail(size).idsize }}"   type="radio"  /></label>
                    </ng-container>
                  </ng-container>
              </span>
            </div>
        </ng-container>
      </div>
      <div class="priceProd col-md-12 noPadding">
        <div class="clearfix"></div>
        <p class="product-price">
          <del *ngIf="  this.selectItem &&  this.selectItem.withPromotion && this.selectItem.withPromotion==1  " id="pricestart" class="old">{{ loadPriceFastBuy(1) }}€</del>
          <span [style]="this.selectItem &&  this.selectItem.withPromotion && this.selectItem.withPromotion==1  ? 'color:red' : ''" id="pricefinal"><b>{{ loadPriceFastBuy(2) }}€</b></span>
        </p>
        <button type="button" (click)="buyProductFast();this.modalService.close(this.modalDetailRef);" class="btn-style cr-btn cartBtn full-width c-square text-center font-12 float-left fastBuyAddCart btn-danger">
          {{ 'add_to_cart' | translate }}
        </button>

        <div class="noPadding col-md-12 mt-2">
          <div class="col-sm-6 col-md-6 float-left pl-0 mt-3">
            <button (click)="this.modalService.close(this.modalDetailRef);" type="button" data-dismiss="modal" style="  border-radius: 0px;background-color:#f0f0f0; font-size: 12px;" class="btn btn-default btn-outline btn-cart button-aylen cartBtn full-width float-left ">{{ 'keepbuying' | translate }}</button>
          </div>
          <div class="col-sm-6 col-md-6 float-left pr-0 mt-3">
            <button type="button" style="border-radius: 0px;background-color:#ccc; font-size: 12px;"   (click)="this.modalService.close(this.modalDetailRef);" [routerLink]="(['/checkout'] | localize)" class="btn btn-outline btn-default btn-cart button-aylen cartBtn full-width float-left ">{{ 'finishorder' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>


</modal-content>
</modal>










 
