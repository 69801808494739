import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import {
  LocalizeRouterModule, LocalizeParser, ManualParserLoader, CacheMechanism,
  LocalizeRouterSettings
} from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';

import { HomeComponent } from './home/home.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ShowComponent } from './show/show.component';
import { ProductComponent } from './product/product.component';
import { ContactComponent } from './contact/contact.component';
import { PageComponent } from './page/page.component';
import { RegisterComponent } from './register/register.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SigninComponent } from './signin/signin.component';
import { AccountComponent } from './account/account.component';
import { ProfileComponent } from './profile/profile.component';
import { OrdersComponent } from './orders/orders.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ShoworderComponent } from './showorder/showorder.component';
import { AllbrandsComponent } from './allbrands/allbrands.component';

import { Angulartics2Module } from 'angulartics2';
//import { Angulartics2RouterlessModule } from 'angulartics2/routerlessmodule';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { WishlistComponent } from './wishlist/wishlist.component';


export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
  return new LocalizeRouterHttpLoader(translate, location, { ...settings, alwaysSetPrefix: true }, http);
}
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: '!show', component: ShowComponent },




  { path: '!show/:desc1', component: ShowComponent },

  { path: '!show/:desc1/:desc2', component: ShowComponent },

  { path: '!show/:desc1/:desc2/:desc3', component: ShowComponent },

  { path: '!show/:desc1/:desc2/:desc3/:desc4', component: ShowComponent },
  { path: '!show/:desc1/:desc2/:desc3/:desc4/:desc5', component: ShowComponent },

  { path: '!show/:desc1/:desc2/:desc3/:desc4/:desc5/:desc6', component: ShowComponent },
  { path: '!show/:desc1/:desc2/:desc3/:desc4/:desc5/:desc6/:desc7', component: ShowComponent },
  { path: '!product/:description', component: ProductComponent },
  { path: '!product/:description/:color', component: ProductComponent },
  { path: '!product/:description/:color/:size', component: ProductComponent },
  { path: '!contact', component: ContactComponent },
  { path: '!page/:page/:*', component: PageComponent },
  { path: '!page/:pagename', component: PageComponent },
  { path: '!register', component: RegisterComponent },
  { path: '!checkout', component: CheckoutComponent },
  { path: '!checkout/:back', component: CheckoutComponent },
  { path: '!signin', component: SigninComponent },
  { path: '!account', component: AccountComponent },
  { path: '!profile', component: ProfileComponent },
  { path: '!orders', component: OrdersComponent },
  { path: '!showorder/:orderid', component: ShoworderComponent },
  { path: '!resetpassword/:token', component: ResetpasswordComponent },
  { path: '**', component: PagenotfoundComponent },
  { path: '!pagenotfound', component: PagenotfoundComponent },

  { path: '!allbrands/:letter', component: AllbrandsComponent },
  { path: '!allbrands', component: AllbrandsComponent },

  { path: '!wishlist', component: WishlistComponent },

  //  { path: '!faqs', component: PageComponent,   data: { page: '5' , origin:{'pt':'faqs', 'es':'faqs', 'en':'faqs', 'fr':'faqs'} }  },

  //en
  //  { path: '!aboutus', component: PageComponent,   data: { page: '1', lang:'en', origin:{'pt':'quem-somos', 'es':'sobre-nosotras', 'en':'aboutus', 'fr':'qui-sommes-nous'} }  },
  //  { path: '!exchanges-and-returns', component: PageComponent,   data: { page: '4' , lang:'en', origin:{'pt':'trocas-e-devolucoes', 'es':'sobre-nosotras', 'en':'exchanges-and-returns', 'fr':'exchanges-and-returns'} }  },
  //  { path: '!cookies-policy', component: PageComponent,   data: { page: '6', lang:'en', origin:{'pt':'politica-de-cookies', 'es':'politica-de-cookies', 'en':'cookies-policy', 'fr':'politique-de-cookies'} }  },
  //  { path: '!terms-and-conditions', component: PageComponent,   data: { page: '3', lang:'en', origin:{'pt':'termos-e-condicoes', 'es':'terminos-y-condiciones', 'en':'terms-and-conditions', 'fr':'cgv'} }  },
  //  { path: '!privacy-policy', component: PageComponent,   data: { page: '2', lang:'en', origin:{'pt':'politica-de-privacidade', 'es':'politica-de-privacidad', 'en':'privacy-policy', 'fr':'politique-de-confidentialite'} }  },



  //pt
  //  { path: '!quem-somos', component: PageComponent,   data: { page: '1', lang:'pt', origin:{'pt':'quem-somos', 'es':'sobre-nosotras', 'en':'aboutus', 'fr':'qui-sommes-nous'} }  },
  //  { path: '!trocas-e-devolucoes', component: PageComponent,   data: { page: '4', lang:'pt', origin:{'pt':'trocas-e-devolucoes', 'es':'sobre-nosotras', 'en':'exchanges-and-returns', 'fr':'exchanges-and-returns'} }  },
  //  { path: '!politica-de-cookies', component: PageComponent,   data: { page: '6', lang:'pt', origin:{'pt':'politica-de-cookies', 'es':'politica-de-cookies', 'en':'cookies-policy', 'fr':'politique-de-cookies'} }  },
  //  { path: '!termos-e-condicoes', component: PageComponent,   data: { page: '3', lang:'pt', origin:{'pt':'termos-e-condicoes', 'es':'terminos-y-condiciones', 'en':'terms-and-conditions', 'fr':'cgv'} }  },
  //  { path: '!politica-de-privacidade', component: PageComponent,   data: { page: '2', lang:'pt', origin:{'pt':'politica-de-privacidade', 'es':'politica-de-privacidad', 'en':'privacy-policy', 'fr':'politique-de-confidentialite'} }  },



  //fr
  //  { path: '!qui-sommes-nous', component: PageComponent,   data: { page: '1', lang:'fr', origin:{'pt':'quem-somos', 'es':'sobre-nosotras', 'en':'aboutus', 'fr':'qui-sommes-nous'} }  },
  //  { path: '!exchanges-and-returns', component: PageComponent,   data: { page: '4', lang:'fr', origin:{'pt':'trocas-e-devolucoes', 'es':'sobre-nosotras', 'en':'exchanges-and-returns', 'fr':'exchanges-and-returns'} }  },
  //  { path: '!politique-de-cookies', component: PageComponent,   data: { page: '6', lang:'fr', origin:{'pt':'politica-de-cookies', 'es':'politica-de-cookies', 'en':'cookies-policy', 'fr':'politique-de-cookies'} }  },
  //  { path: '!cgv', component: PageComponent,   data: { page: '3', lang:'fr', origin:{'pt':'termos-e-condicoes', 'es':'terminos-y-condiciones', 'en':'terms-and-conditions', 'fr':'cgv'} }  },
  //  { path: '!politique-de-confidentialite', component: PageComponent,   data: { page: '2', lang:'fr', origin:{'pt':'politica-de-privacidade', 'es':'politica-de-privacidad', 'en':'privacy-policy', 'fr':'politique-de-confidentialite'} }  },


  //es
  //  { path: '!sobre-nosotras', component: PageComponent,   data: { page: '1', lang:'es', origin:{'pt':'quem-somos', 'es':'sobre-nosotras', 'en':'aboutus', 'fr':'qui-sommes-nous'} }  },
  //  { path: '!cambios-y-devoluciones', component: PageComponent,   data: { page: '4', lang:'es', origin:{'pt':'trocas-e-devolucoes', 'es':'sobre-nosotras', 'en':'exchanges-and-returns', 'fr':'exchanges-and-returns'} }  },
  //  { path: '!politica-de-cookies', component: PageComponent,   data: { page: '6', lang:'es', origin:{'pt':'politica-de-cookies', 'es':'politica-de-cookies', 'en':'cookies-policy', 'fr':'politique-de-cookies'} }  },
  //  { path: '!terminos-y-condiciones', component: PageComponent,   data: { page: '3', lang:'es', origin:{'pt':'termos-e-condicoes', 'es':'terminos-y-condiciones', 'en':'terms-and-conditions', 'fr':'cgv'} }  },
  //  { path: '!politica-de-privacidad', component: PageComponent,   data: { page: '2', lang:'es', origin:{'pt':'politica-de-privacidade', 'es':'politica-de-privacidad', 'en':'privacy-policy', 'fr':'politique-de-confidentialite'} }  }

];

export function shouldTranslateMap(param: string): string {
  if (isNaN(+param)) {
    return 'map';
  }
  return null;
}

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      preloadingStrategy: NoPreloading,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled"
    }
    ),
    Angulartics2Module.forRoot({ pageTracking: { excludedRoutes: [/.*/] } }),
    LocalizeRouterModule.forRoot(routes, {

    parser: {
      provide: LocalizeParser,
      useFactory: HttpLoaderFactory,
      deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
    },
    cacheMechanism: CacheMechanism.Cookie,
    cookieFormat: '{{value}};{{expires:20}};path=/'

  })],
  exports: [RouterModule, LocalizeRouterModule]
})
export class AppRoutingModule { }
