import { Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LogoutService } from '../logout.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { HttpClient, HttpParams  } from '@angular/common/http';
import {merge, Observable, Subscription, Subject, fromEvent, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap, debounceTime,  distinctUntilChanged, filter} from 'rxjs/operators';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2';
import { ModalManager } from 'ngb-modal';
import { Title, Meta }     from '@angular/platform-browser';
import { SeoService } from '../seo.service';
import { SessionService } from '../session.service';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router, ActivatedRoute   } from '@angular/router';
import { TitlechangeService } from '../titlechange.service';
import { CheckwebpService } from '../checkwebp.service';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  displayedColumns: string[] = ['numberdoc', 'date', 'total', 'paymentmethod', 'shipmode', 'state', 'icon'];
  @ViewChild('ModalChangePayment') modalChangePayment;
  dataTable: tableStruture[] = [];
  httpDatabase: HttpDatabase | null;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  @ViewChild("inputSearch") inputSearch ;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Output() keyPressOnChild = new EventEmitter();
  payments:any;
  modalChangePaymentRef:any;
  selectPayment:any;
  selectPaymentAbbr:any;
  contat_MBW:any;
  idorder:any;
  wait:any;
  hiddenLoad:any;
  subscribe:any;
	breadcrumbs: any[] = [];

  ordersGrid: any[] = [];
  browserwebp:any;

  constructor(public titlechangeService:TitlechangeService,private route: Router,public localize: LocalizeRouterService,public sessionService: SessionService,private angulartics2: Angulartics2, private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, private seoService: SeoService, private metaService: Meta, public titleService: Title,private modalService: ModalManager, private httpClient: HttpClient, public apiService: ApiService, public translate: TranslateService, public logoutservice: LogoutService,public checkwebp: CheckwebpService) {
    this.wait=false;
    this.hiddenLoad=true;
    this.browserwebp = this.checkwebp.canUseWebP();
    this.subscribe=  this.translate.onLangChange.subscribe(lang=>{
        this.metatags(lang.lang);
      });
      this.metatags(this.translate.currentLang);

      let objBread:any = {};
  		objBread.url = "orders";
  		objBread.description = "orders";
  		this.breadcrumbs.push(objBread);

   }

  ngOnInit(): void {

    // this.getOrdersGrid();

    this.apiService.getordergenerate().subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.info>0){


          if(obj.products.length>0){
            this.angulartics2GAEcommerce.ecSetAction("purchase" , {'id': obj.number, 'revenue':obj.total, 'shipping':obj.shipping});
          this.angulartics2.eventTrack.next({
      			action: 'Purchase',
      			properties: {
      				label: 'Purchase',
      				currency: "EUR",
      				content_type: 'product_group',
      				"event":"Purchase",
      				gtmCustom:{
      					'ecommerce': {
                  'remove': undefined,
                  'click': undefined,
                  'add': undefined,
                  'checkout': undefined,
                  'detail': undefined,
                  'impressions': undefined,
      						'purchase': {
      							'actionField': {'id': obj.number, 'revenue':obj.total, 'shipping':obj.shipping},    // 'detail'
      							'products': obj.products
      						 }
      					 }
      				}
      			}
      		});
          // Ga4 Purchase
          // this.angulartics2.eventTrack.next({
          //   action: 'purchase',
      		// 	properties: {
          //     'purchase': {
          //       'currency':'EUR' ,'transaction_id': obj.number, 'value':obj.total, 'shipping':obj.shipping,    // 'detail'
          //       'items': obj.products,
          //      }
          //   }
          // })
        }

        }
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });



  }

  public setTitle( newTitle: string) {
	   this.titleService.setTitle( newTitle);
     this.titlechangeService.send();
  }

  metatags(lang){
    this.apiService.getMetatags(6,9,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title){
          this.setTitle( obj.title.content);
        }
        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="'+key+'"');
        });
        if(obj.info.length==0){
          return;
        }

        this.metaService.addTags(obj.info);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  ngOnDestroy(): void {
    this.keyPressOnChild.unsubscribe();
    this.subscribe.unsubscribe();
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");

  }

  btoa(str) {
    return window.btoa(str);
  }

  getOrdersGrid(){
    this.apiService.getOrderGrid('').subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.ordersGrid = obj.items;
      }
      else if(obj.code==302){
        this.sessionService.sendsession(true, ""); this.route.navigate([this.localize.translateRoute('/')] );
      }
      else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    })
  }

  getImage(imagedoc:any){
    if(this.browserwebp && imagedoc && imagedoc.webp && imagedoc.webp != '' ){
      return this.apiService.imagesDir + imagedoc.webp;
    }
    else{
      return this.apiService.imagesDir + imagedoc.photo;
    }
  }

  showmb(line){
    this.translate.get( ['entity', 'reference', 'value'] ).subscribe(translations => {
      Swal.fire({
        title: '',
        // icon: 'info',
        imageUrl: 'assets/images/multibanco-logo.png',
        html:
          translations['entity']+': '+line.entity+'<br>'+
          translations['reference']+': '+line.reference+'<br>'+
          translations['value']+': '+line.total.replace('.', ',')+'<br>',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false
      });
     });
  }


  changePaymentMean(row){
    this.apiService.getShippingsAbbr(row.countryabbr, row.countryabbr, "", "", "", row.idshippingmode).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
         this.payments=obj.info;
         this.idorder = row.iddocument;
        this.openModal();
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  changepayment(){
    if(this.wait){
      return;
    }
    if(this.selectPaymentAbbr=="MBW"){
      if(!this.contat_MBW || this.contat_MBW==""){
        Swal.fire({title:this.translate.instant("INSERT_VALID_MBWAY"), customClass: { container: 'swal-index' } }); return;
      }
      if(this.contat_MBW.match(/^[0-9]{9}$/)==null){
          Swal.fire({title:this.translate.instant("INSERT_VALID_MBWAY"), customClass: { container: 'swal-index' } });
        return;
      }
    }
    this.hiddenLoad=false;
    this.wait=true;
    this.apiService.changepayment(this.idorder, this.selectPayment, this.translate.currentLang, "", "", this.contat_MBW).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.newUrl && obj.newUrl==1){
          window.location.href = obj.newUrlDesc;
        }else{
          this.closeModal();
          this.hiddenLoad=true;
          this.ngOnInit();this.wait=false;   window.location.href = window.location.href;
        }
      }else if(typeof obj!=="undefined" && obj.code==202){
        this.closeModal();
        this.hiddenLoad=true;
        this.ngOnInit();this.wait=false;
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire(translations);    });
      }else{
        this.wait=false; this.closeModal();
        this.hiddenLoad=true;
        this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  openModal(){
        this.modalChangePaymentRef = this.modalService.open(this.modalChangePayment, {
            size: 'md',
            windowClass: 'modal-md'  ,
            hideCloseButton: false,
            centered: false,
            animation: true,
            keyboard: false,
            closeOnOutsideClick: false,
			      backdropClass: 'modal-backdrop'
        });
    }

    closeModal(){
        this.modalService.close(this.modalChangePaymentRef);
    }


  ngAfterViewInit() {

    fromEvent(this.inputSearch.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , debounceTime(1000)
      , distinctUntilChanged()
    ).subscribe((text: string) => {

      this.keyPressOnChild.emit();

    });


    this.httpDatabase = new HttpDatabase(this.httpClient, this.apiService);


    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page, this.keyPressOnChild)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.httpDatabase!.getOrders(
            this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize, this.inputSearch.nativeElement.value);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['total_count'];

          if(data['code']==302){
              this.sessionService.sendsession(true, ""); this.route.navigate([this.localize.translateRoute('/')] );
          }

          return data['items'];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.dataTable = data);
  }

}

export interface tableApi {
  items: tableStruture[];
  total_count: number;
}

export interface tableStruture { 
  numberdoc: string;
  date: string;
  total: string;
  paymentmethod: string;
  shipmode: string;
  state: string;
  icon: string;
}

export class HttpDatabase {
  constructor(private httpClient: HttpClient, private apiService: ApiService ) {}

  getOrders(sort: string, order: string, page: number, pageSize:number, search:any): Observable<tableApi> {
    search = encodeURIComponent(search);
    const href = this.apiService.PHP_API_SERVER;
    const requestUrl =
        `${href}?controller=order&action=getorders&sort=${sort}&order=${order}&page=${page + 1}&pagesize=${pageSize}&search=${search}`;

    return this.httpClient.get<tableApi>(requestUrl,  {withCredentials: true});
  }
}
